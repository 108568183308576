import { Facet, FacetOptionState } from "../types";
import { getCommitedKey } from "./getCommitedKey";

export const isAdvancedPortFilter = (
	committedValue: FacetOptionState | undefined,
	facet: Facet
) => {
	const committedKey = getCommitedKey(committedValue);
	return (
		committedKey?.includes("-") && ["listenport", "port"].includes(facet.name)
	);
};
