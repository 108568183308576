import CloseIcon from "@mui/icons-material/Close";
import {
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { CircularCenteredLoader } from "common/atoms/loader";
import { Toolbar } from "common/atoms/toolbar";
import { ListTable } from "modules/add-to-templates/components/list-table/ListTable";
import { SearchBar } from "modules/add-to-templates/components/search-bar";
import { Annotation } from "modules/annotation";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { TagPolicyCOnfirmationDialog } from "pages/tags/components/tag-policy-list/components/tag-policy-confirmation-dialog";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import { RemoveTemplatesDialog } from "pages/templates/components/remove-templates-dialog";
import {
	ASSIGNED_TEMPLATES_TAG_COLUMNS,
	BASE_TEMPLATES_LIST_COLUMNS,
	TEMPLATES_LIST_COLUMNS,
} from "pages/templates/components/template-data-grid/constants";
import { Template } from "pages/templates/types";
import { useCallback, useMemo, useState } from "react";
import { AssignedTemplatesViewDrawerProps, TemplateDrawerType } from "./types";

export const AssignedTemplatesViewDrawer = ({
	isOpen,
	onClose,
	templateList,
	templatesSuggestMutation,
	title = "Assigned Templates",
	AssignTemplatesOption,
	page = "assets",
	isLoading,
	policyId,
	onSortChange,
	canDelete = true,
	multiselect = false,
	criteria,
	hasPermission,
	sort,
	setPage,
	setPageSize,
	pageCount,
	pageSize,
	totalCount,
	showSearch = true,
	onSearchCriteriaChange,
	policy,
	showDialog,
}: AssignedTemplatesViewDrawerProps) => {
	const [showConfirmation, setShowconfirmation] = useState(false);
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [searchText, setSearchText] = useState("");
	const [selectedTemplateList, setSelectedTemplateList] = useState<
		Array<Template>
	>([]);

	const handleConfirmationVisibility = (value: boolean) => {
		setShowconfirmation(value);
	};

	const columns = useMemo(() => {
		let COLUMNS: GridColDef[] = BASE_TEMPLATES_LIST_COLUMNS(true);
		if (!canDelete) {
			return COLUMNS;
		}
		if (page === "tags") {
			COLUMNS = ASSIGNED_TEMPLATES_TAG_COLUMNS(true);
		} else if (page === "assets") {
			COLUMNS = TEMPLATES_LIST_COLUMNS(true);
		}
		return COLUMNS;
	}, [page, canDelete]);

	const triggerUnassign = () => {
		handleConfirmationVisibility(true);
	};

	const onSelectCallback = useCallback((templates: Array<Template>) => {
		setSelectedTemplateList(templates);
	}, []);

	const isValid = () => {
		if (multiselect) {
			return Boolean(selectedTemplateList?.length > 0);
		}
	};

	const onSearchTextChange = (searchText: string) => {
		onSearchCriteriaChange?.(searchText ?? "");
		setSearchText(searchText ?? "");
	};

	const handlerOnClose = () => {
		onSearchCriteriaChange?.("");
		setSearchText("");
		onClose();
	};

	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const onSubmit = () => {
		if (showDialog) {
			setShowConfirmationModal(true);
		} else {
			triggerUnassign();
		}
	};

	return (
		<>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={handlerOnClose}
				PaperProps={{
					sx: {
						width: "70%",
						maxwidth: "1000px",
						minWidth: "600px",
					},
				}}
			>
				<Toolbar />
				<DialogContent sx={{ width: "100%", height: "100%" }}>
					<Stack
						alignItems="flex-start"
						sx={{ position: "relative", width: "100%" }}
					>
						<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
							<IconButton
								size="large"
								aria-label="close drawer"
								onClick={handlerOnClose}
								sx={{
									position: "absolute",
									right: "0px",
									top: "0px",
									zIndex: 2,
								}}
							>
								<CloseIcon fontSize="medium" />
							</IconButton>
						</Tooltip>
					</Stack>

					{!isLoading && templatesSuggestMutation?.isLoading && (
						<CircularCenteredLoader />
					)}

					<Stack sx={{ height: "100%", width: "100%" }} alignItems="flex-start">
						<Stack alignItems="flex-start">
							<Typography variant="h6" sx={{ mt: 2, width: "100%" }}>
								{window.getCTTranslatedText(title)}
							</Typography>
						</Stack>
						<Stack
							alignItems="flex-start"
							sx={{ pt: 4, width: "100%", flex: 1, overflow: "hidden" }}
						>
							<Stack
								direction="row"
								sx={{ width: "100%", justifyContent: "flex-end" }}
								spacing={3}
							>
								{showSearch && (
									<SearchBar
										id="template-list-searchbar"
										label={window.getCTTranslatedText("Search Templates")}
										placeholder={"Search Templates"}
										selectedValue={searchText}
										onChange={onSearchTextChange}
										onFocus={() => {}}
										sx={{ pb: 2 }}
									/>
								)}

								{AssignTemplatesOption && (
									<Stack sx={{ pb: 2 }}>{AssignTemplatesOption()}</Stack>
								)}
							</Stack>
							{!templatesSuggestMutation?.isLoading && (
								<Stack sx={{ width: "100%", flex: 1, overflow: "hidden" }}>
									<ListTable
										list={templateList ?? []}
										columns={columns}
										mutation={templatesSuggestMutation}
										isLoading={isLoading}
										rowClickCallback={() => {}}
										onSortChange={onSortChange}
										multiselect={multiselect && hasPermission}
										onSelectCallback={onSelectCallback}
										hasPermission={hasPermission}
										setPage={setPage}
										setPageSize={setPageSize}
										page={pageCount}
										pageSize={pageSize}
										totalCount={totalCount ?? templateList?.length}
										paginationMode={page === "tags" ? "client" : "server"}
										sorting={page === "tags" ? false : true}
										drawerType={TemplateDrawerType.Assigned}
									/>
									{selectedTemplateList.length > 0 && (
										<Annotation setComment={setComment} comment={comment} />
									)}
								</Stack>
							)}
						</Stack>
						{showConfirmation && (
							<RemoveTemplatesDialog
								criteria={criteria ?? ""}
								templates={selectedTemplateList}
								allowRemove={showConfirmation}
								handleAllowRemove={handleConfirmationVisibility}
								page={page}
								policyId={policyId}
								onConfirm={handlerOnClose}
								comment={comment}
							/>
						)}
					</Stack>
				</DialogContent>
				{multiselect && (
					<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
						<ToolbarAction
							loading={false}
							save={onSubmit}
							cancel={handlerOnClose}
							isValid={isValid()}
							actionBtnTooltip={window.getCTTranslatedText(
								"removePushToFirewall"
							)}
							hasPermission={hasPermission}
							actionBtnText={"Remove"}
							secondaryBtnText={"cancel"}
						/>
					</DialogActions>
				)}
			</Drawer>
			<TagPolicyCOnfirmationDialog
				policy={policy as TagPolicy}
				isOpen={showConfirmationModal}
				onClose={() => setShowConfirmationModal(false)}
				title={"Remove template"}
				primaryText={"RemoveTemplateText"}
				isLoading={isLoading ?? false}
				onSuccess={triggerUnassign}
				secondaryTextWarning={"RemoveTemplateWarning"}
			/>
		</>
	);
};
