import { FormControl, Skeleton, Stack, Typography } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TextFieldsValues } from "modules/form-fields/type";
import { useIntegrationUpdateConfigFormExtraAfter } from "pages/Integration/components/integration-detail-drawer/hooks/useIntegrationUpdateConfigFormExtraAfter";
import { SessionRefreshLimitOptions } from "pages/Integration/tabs/authentication/components/form-extras-after/constants";
import { useAuthConfigAPI } from "pages/Integration/tabs/authentication/hooks";
import { ConfiguredDataResult } from "pages/Integration/tabs/authentication/hooks/useAuthConfigAPI";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { ConfigCard } from "../common/components/config-card";

export const SessionTimeoutConfig = React.memo(() => {
	const isMountRef = useRef(true);
	const { data: authConfigData, isLoading: isAuthConfigDataLoading } =
		useAuthConfigAPI({
			enabled: isMountRef.current,
			refetchOnWindowFocus: false,
		});

	useEffect(() => {
		isMountRef.current = false;
	}, []);

	if (isAuthConfigDataLoading) {
		return (
			<Stack width="100%" height={"145px"}>
				<Skeleton
					variant="rectangular"
					width={"100%"}
					height={"145px"}
					animation="wave"
				/>
			</Stack>
		);
	}

	return <SessionTimeoutConfigWrapper authConfigData={authConfigData} />;
});

const SessionTimeoutConfigWrapper = React.memo(
	({ authConfigData }: { authConfigData?: ConfiguredDataResult[] }) => {
		const [textFieldsValues, setTextFieldsValues] =
			useState<TextFieldsValues>();

		const userPermissions = useUserPermissionsStore(
			state => state.userPermissions
		);

		const configID = authConfigData?.[0]?.configID;

		const getDisplayName = useCallback(
			(values: TextFieldsValues | undefined) => {
				return (
					SessionRefreshLimitOptions.find(
						option => option.value === values?.refreshTokenTTL
					)?.displayName ??
					SessionRefreshLimitOptions[SessionRefreshLimitOptions.length - 1]
						.displayName
				);
			},
			[]
		);

		const selectedValue = useMemo(
			() => getDisplayName(textFieldsValues),
			[getDisplayName, textFieldsValues]
		);

		const { isLoading, updateSamlOrOauthIntegrationConfig } =
			useIntegrationUpdateConfigFormExtraAfter(configID);

		const callUpdateConfig = () => {
			if (textFieldsValues) {
				updateSamlOrOauthIntegrationConfig(textFieldsValues);
				setShowSaveCta(false);
			}
		};

		useEffect(() => {
			if (configID) {
				setTextFieldsValues(() => authConfigData?.[0]?.config);
			}
		}, [configID, setTextFieldsValues, authConfigData]);

		const [showSaveCta, setShowSaveCta] = useState(false);

		useEffect(() => {
			setShowSaveCta(() => {
				if (configID) {
					const configValue = getDisplayName(authConfigData?.[0]?.config);
					if (configValue !== selectedValue) {
						return true;
					}
				}
				return false;
			});
		}, [authConfigData, configID, getDisplayName, selectedValue]);

		const isSaveBtnDisabled = useMemo(
			() =>
				!textFieldsValues?.refreshTokenTTL ||
				authConfigData?.[0]?.config?.refreshTokenTTL ===
					textFieldsValues?.refreshTokenTTL ||
				!userPermissions.has("UPDATE_INTEGRATION"),
			[authConfigData, textFieldsValues?.refreshTokenTTL, userPermissions]
		);

		return (
			<ConfigCard
				title="Session Timeout Limit"
				showSaveCta={showSaveCta}
				isLoading={isLoading}
				isSaveBtnDisabled={isSaveBtnDisabled}
				onSaveBtnClick={callUpdateConfig}
			>
				<Stack
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Stack>
						<Typography variant="body2">
							{window.getCTTranslatedText(
								"Select timeout limit when session is left ideal."
							)}
						</Typography>
					</Stack>
					<FormControl
						variant="standard"
						sx={{ width: "fit-content", minWidth: 150, m: 0, mt: 1 }}
					>
						<CTSelectDropDown
							field={"refreshTokenTTL"}
							displayName={window.getCTTranslatedText("SessionIdleTimeout")}
							selectedValue={selectedValue}
							key={selectedValue}
							handleUpdate={(event: TextFieldUpdate) => {
								const refreshTokenValue = SessionRefreshLimitOptions.find(
									option => option.displayName === event?.value
								)?.value;

								setTextFieldsValues(prev => ({
									...prev,
									refreshTokenTTL: refreshTokenValue,
									refreshTokenRollingEnabled: true,
									accessTokenTTL: refreshTokenValue === 15 ? 10 : 15,
								}));
							}}
							data={SessionRefreshLimitOptions.map(
								option => option.displayName
							)}
							textTransform="none"
						/>
					</FormControl>
				</Stack>
			</ConfigCard>
		);
	}
);
