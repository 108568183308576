import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { dayjsWithPlugins, getDisplayText } from "common/utils";
import { isMAC } from "common/utils/macValidator";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import en from "assets/translations/en/translation.json";

window.wordMap = {};

export const useLocale = () => {
	const { t, i18n } = useTranslation();
	const storedLangCode = useUserPreferencesStore(state => state.locale);
	const setStoredLangCode = useUserPreferencesStore(state => state.setLocale);

	window.getCTTranslatedText = (key: string, args?: { [key: string]: any }) => {
		// if (!(key in en)) {
		//   window.wordMap[key] = key;
		// }
		if (!key) {
			return "";
		}

		if (key.startsWith("http") || key.startsWith("https") || isMAC(key)) {
			return key;
		}

		let displayText = getDisplayText(key);
		return t(displayText, args);
	};

	useEffect(() => {
		const changeLocale = async (locale: string) => {
			if (locale !== "en") {
				try {
					const module = await import(`dayjs/locale/${locale}.js`);
					dayjsWithPlugins.locale(module.default);
				} catch (error) {
					console.error("Failed to load dayjs locale:", error);
					return;
				}
			} else {
				dayjsWithPlugins.locale("en");
			}

			await i18n.changeLanguage(storedLangCode);
		};

		changeLocale(storedLangCode.split("-")[0]);
	}, [storedLangCode, i18n]);

	const getInitialLangCode = () => {
		if ((storedLangCode ?? i18n.language).includes("en")) {
			return "en";
		}

		return storedLangCode ?? i18n.language;
	};

	window.CTLangCode = getInitialLangCode();

	window.changeLanguage = (langCode: string) => {
		i18n.changeLanguage(langCode);
		try {
			setStoredLangCode(langCode);
			window.location.reload();
		} catch (e) {
			console.error(e);
		}
	};
};
