import LoadingButton from "@mui/lab/LoadingButton";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";

interface CTConfirmationProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	primaryText?: string | JSX.Element;
	secondaryText?: string | JSX.Element | null;
	isLoading: boolean;
	primaryButtonText?: string;
	primaryButtonDisabled?: boolean;
	secondaryButtonText?: string;
	onSuccess: () => void;
}

export function CTConfirmation({
	open,
	onClose,
	title,
	primaryText,
	secondaryText,
	isLoading,
	primaryButtonText = "Confirm",
	primaryButtonDisabled,
	secondaryButtonText = "Cancel",
	onSuccess,
}: Readonly<CTConfirmationProps>) {
	return (
		<Dialog
			open={open}
			onClose={() => {
				onClose();
			}}
		>
			<DialogTitle>{window.getCTTranslatedText(title ?? "")}</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1">
					{typeof primaryText === "string"
						? window.getCTTranslatedText(primaryText)
						: primaryText}
				</Typography>
				{secondaryText ? (
					<>
						<br />
						<Typography variant="body2">
							{typeof secondaryText === "string"
								? window.getCTTranslatedText(secondaryText)
								: secondaryText}
						</Typography>
					</>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={() => {
						onClose();
					}}
				>
					{window.getCTTranslatedText(secondaryButtonText)}
				</Button>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={primaryButtonDisabled}
					onClick={() => {
						onSuccess();
					}}
				>
					{window.getCTTranslatedText(primaryButtonText)}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
