import { FacetOptionMappingItem } from "modules/core/types";

export enum Scope {
	Asset = "asset",
	Port = "port",
	Path = "path",
	PathStat = "pathstat",
	Agent = "agent",
	Template = "template",
	Network = "namednetwork",
	Appliance = "gateway",
	Users = "users",
	AuditLog = "event",
	Alert = "alert",
	AlertRule = "alertrule",
	Connector = "connector",
	Cluster = "collector",
	Tags = "tag",
	TagRule = "rules",
	TagRuleList = "tagrule",
	TagPolicy = "tagset",
	TagBasedPolicy = "tagbasedpolicy",
	SCIM_USER_DEPARTMENT = "scimuser",
	SCIM_GROUP = "scimgrp",
	AssetPathRecency = "assetpathrecency",
	Assistant = "assistant",
	NNHits = "namednetworkhit",
	UserGroupHits = "usergrouphits",
	WorkRequest = "workrequest",
	UnmanagedDevice = "unmanageddevice",
	CrowdStrikeEDR = "edr",
	CrowdStrikeFirewallHostGroup = "csfirewallhostgroupcriteria",
	SchedulerHistory = "schedulerhistory",
	SCIM_IDP = "scimidp",
}

export interface ScopeMetadataParams {
	scope?: Scope;
}

export enum FacetOptionUnit {
	Hour = "hour",
	Minute = "minute",
	Byte = "byte",
}

export interface ScopeMetadataColumn {
	displayName: string;
	qualifier: string;
	dataType: string;
	multivalued: boolean;
	sortable: boolean;
	listOfValues: boolean;
	values?: ScopeValuesEntity[] | null;
	unit: FacetOptionUnit | null;
	searchable: boolean;
	facetable: boolean;
	internalName: string;
	coreTag: boolean;
	userDefined: boolean;
	isCoreTagEditable: boolean;
	name?: string;
	description?: string;
	valuesDisplayOptions?: FacetOptionMappingItem;
}
export interface ScopeValuesEntity {
	display: string;
	internal: number;
}

export type ScopeMetadataResponse = {
	columns: {
		[key: string]: ScopeMetadataColumn | undefined;
	};
};

export type ScopeMetadata = {
	scope?: Scope | string;
	columns: {
		[key: string]: ScopeMetadataColumn | undefined;
	};
	namesToColumn: {
		[key: string]: ScopeMetadataColumn | undefined;
	};
};
