export const getVulnerabilitySeverityText = (severity: number) => {
	switch (severity) {
		case 2:
			return "low";
		case 3:
			return "medium";
		case 4:
			return "high";
		case 5:
			return "critical";
		default:
			return "low";
	}
};
