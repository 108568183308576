import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { TrafficCountLink } from "common/atoms/ct-guardrail";
import { CTJoinedText } from "common/atoms/ct-joined-text/CTJoinedText";
import { useAntlrParser } from "hooks/useAntlrParser/useAntlrParser";
import { FacetUtils } from "modules/facets";
import { getParsedCriteria } from "modules/facets/components/criteria-chips/CriteriaChips";
import { OVERWRITE_FACETS } from "modules/facets/constants";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { PolicyUnreviewedTraffic } from "../../types";

interface PolicyAutomationActionsProps {
	assetCount?: number;
	criteria?: string;
	isDeployLoading: boolean;
	isLoadingTraffic: boolean;
	updateDeployMode: VoidFunction;
	cancel: VoidFunction;
	trafficReviewed: boolean;
	unreviewedTraffic?: PolicyUnreviewedTraffic;
}

export const PolicyAutomationActions = ({
	assetCount,
	criteria,
	updateDeployMode,
	cancel,
	isDeployLoading,
	isLoadingTraffic,
}: PolicyAutomationActionsProps) => {
	const parseCriteria = useAntlrParser();
	const { data } = useScopeMetadata({ scope: Scope.TagBasedPolicy });

	const getCriteria = (criteria?: string) => {
		const parseResult = parseCriteria(criteria ?? "");
		if (parseResult) {
			return `filters=${encodeURIComponent(FacetUtils.getURLQueryFromFacets(getParsedCriteria(parseResult, data)))}`;
		}

		return "";
	};

	const renderLoading = () => {
		return (
			<Stack direction="row" spacing={2} alignItems="center">
				<CircularProgress size={16} />
				<Typography variant="body2">
					{window.getCTTranslatedText("Looking for traffic disruptions")}
				</Typography>
			</Stack>
		);
	};

	const renderActionText = () => {
		return (
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				justifyContent={"flex-start"}
			>
				{isLoadingTraffic ? (
					renderLoading()
				) : (
					<CTJoinedText
						items={[
							assetCount ? (
								<TrafficCountLink
									textVariant="body1"
									count={assetCount}
									link={`/assets?${getCriteria(criteria)}&${OVERWRITE_FACETS}`}
								/>
							) : (
								"0"
							),
							<Typography variant="body1" sx={{ fontWeight: 400 }}>
								{window.getCTTranslatedText("assets selected")}
							</Typography>,
						]}
					/>
				)}
			</Stack>
		);
	};

	return (
		<>
			<Stack
				sx={{
					borderRadius: 0,
					width: "100%",
					px: 3,
					py: 2,
					height: "60px",
					transition: "height 0.2s ease-in-out",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Stack
					justifyContent={"space-between"}
					alignItems="center"
					direction="row"
					spacing={2}
					height={36}
				>
					{renderActionText()}
					<Stack direction="row" spacing={2}>
						<Button variant="outlined" onClick={cancel}>
							{window.getCTTranslatedText("Cancel")}
						</Button>
						<LoadingButton
							loading={isDeployLoading}
							disabled={isDeployLoading || isLoadingTraffic}
							color="primary"
							variant="contained"
							onClick={updateDeployMode}
						>
							{window.getCTTranslatedText("Save")}
						</LoadingButton>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};
