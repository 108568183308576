import { AnalyticsAPIReq } from "common/types/types";
import { Scope } from "modules/scope-metadata/types";
import { AssetStatus } from "pages/assets/types";
import { PathDirection } from "pages/paths/types";
import {
	ProgressiveEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatus,
} from "pages/ports/types";
import { UnreviewedTraffic } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";
import { useMemo } from "react";
import {
	ChangeType,
	DetailedInputData,
	DetailedTrafficStats,
	OutputData,
	SimpleInputData,
	SimplifiedTrafficStats,
} from "./types";

export const getPathCriteria = (
	changeType: ChangeType,
	direction: PathDirection,
	isViolation?: boolean,
	selectedStatus?:
		| AssetStatus
		| ProgressiveEnforcementStatus
		| ProgressiveOutboundPortEnforcementStatus
): AnalyticsAPIReq => {
	if (changeType === ChangeType.ENFORCEMENT) {
		let criteria = {
			criteria: `${isViolation ? "'enforced' in ('allowed-test-denied-violation')" : "'reviewed' in ('unreviewed','denied','denied-by-template') AND 'enforced' not in ('denied','denied-by-template')"}`,
			groupBy: [
				direction === PathDirection.Outbound
					? "source.assetname"
					: "destination.assetname",
				isViolation ? "enforced" : "reviewed",
			],
			statistics: ['count("channelHash")'],
			scope: Scope.Path,
		};
		if (
			selectedStatus === AssetStatus.SecureInternet ||
			selectedStatus === AssetStatus.SimulateSecureAll
		) {
			criteria.criteria += ` AND 'compassdirection' in ('north-south')`;
		}
		if (selectedStatus === AssetStatus.SecureAll) {
			criteria.criteria += ` AND 'compassdirection' in ('east-west')`;
		}
		return criteria;
	}
	return {
		criteria: ``,
		groupBy: [
			direction === PathDirection.Outbound
				? "source.assetname"
				: "destination.assetname",
		],
		statistics: ['count("channelHash")'],
		scope: Scope.Path,
	};
};

export const getPortCriteria = (
	changeType: ChangeType,
	selectedStatus?:
		| AssetStatus
		| ProgressiveEnforcementStatus
		| ProgressiveOutboundPortEnforcementStatus
) => {
	if (changeType === ChangeType.ENFORCEMENT) {
		let criteria = {
			criteria:
				"'listenportreviewed' in ('unreviewed','denied','denied-by-template') AND 'listenportenforced' not in ('denied','denied-by-template')",
			groupBy: ["assetname", "listenportreviewed"],
			statistics: ['count("lpid")'],
			scope: Scope.Port,
		};
		if (
			selectedStatus === AssetStatus.SecureInternet ||
			selectedStatus === AssetStatus.SimulateSecureAll
		) {
			criteria.criteria += ` AND 'listeningonpublicinterface' in ('true')`;
		}
		if (selectedStatus === AssetStatus.SecureAll) {
			criteria.criteria += ` AND 'listeningonpublicinterface' in ('false')`;
		}
		return criteria;
	}
	return {
		criteria: ``,
		groupBy: ["assetname"],
		statistics: ['count("lpid")'],
		scope: Scope.Port,
	};
};

export const getAssetCriteria = (baseCriteria: string, status?: string) => {
	return {
		criteria: baseCriteria,
		groupBy: status ? ["assetname", status] : ["assetname"],
		statistics: ['count("assetid")'],
		scope: Scope.Asset,
	};
};

function isSimpleFormat(data: any): data is SimpleInputData {
	// Check the first non-null entry in the data
	const firstEntry = Object.values(data)[0];
	if (!firstEntry || typeof firstEntry !== "object") return false;

	// Simple format will NOT have these detailed format properties
	const detailedFormatProperties = [
		"reviewed",
		"enforced",
		"listenportreviewed",
	];
	return !detailedFormatProperties.some(prop => prop in firstEntry);
}

export function aggregateStats(
	input: DetailedInputData | SimpleInputData,
	assetStatusField: string,
	isSkippingState: boolean
): {
	[key: string]: UnreviewedTraffic;
} {
	const output: { [key: string]: UnreviewedTraffic } = {};

	const inputData = input;
	// Handle simple format
	if (isSimpleFormat(input)) {
		for (const [assetName, assetData] of Object.entries(inputData)) {
			output[assetName] = {
				progressivePorts: assetData?.statistics?.lpidcount ?? 0,
				progressivePaths: assetData?.statistics?.channelhashcount ?? 0,
			};
			if (
				assetData[assetStatusField] &&
				Object.keys(assetData[assetStatusField])?.length > 0
			) {
				output[assetName].status = Object.keys(assetData[assetStatusField])[0];
			}
			if (isSkippingState) {
				output[assetName].stateSkipped = true;
			}
		}
		return output;
	}
	// Handle detailed format
	for (const [assetName, assetData] of Object.entries(inputData)) {
		output[assetName] = {
			ports: {
				unreviewed:
					assetData.listenportreviewed?.unreviewed?.statistics?.lpidcount ?? 0,
				denied:
					assetData.listenportreviewed?.denied?.statistics?.lpidcount ?? 0,
				deniedByTemplate:
					assetData.listenportreviewed?.["denied-by-template"]?.statistics
						?.channelhashcount ?? 0,
			},
			paths: {
				unreviewed:
					assetData.reviewed?.unreviewed?.statistics?.channelhashcount ?? 0,
				denied: assetData.reviewed?.denied?.statistics?.channelhashcount ?? 0,
				deniedByTemplate:
					assetData.reviewed?.["denied-by-template"]?.statistics
						?.channelhashcount ?? 0,
				violations:
					assetData.enforced?.["allowed-test-denied-violation"]?.statistics
						?.channelhashcount ?? 0,
			},
		};
		if (
			assetData[assetStatusField] &&
			Object.keys(assetData[assetStatusField])?.length > 0
		) {
			output[assetName].status = Object.keys(assetData[assetStatusField])[0];
		}
		if (isSkippingState) {
			output[assetName].stateSkipped = true;
		}
	}

	return output;
}

export function useOverlapStatuses(trafficData?: OutputData): string[] {
	return useMemo(() => {
		let overlapStatuses: string[] = [];
		if (trafficData) {
			Object.values(trafficData).forEach(item => {
				if (item.status && !overlapStatuses.includes(item.status)) {
					overlapStatuses.push(item.status);
				}
			});
		}
		return overlapStatuses;
	}, [trafficData]);
}

export function combineTrafficStats(
	trafficData?: OutputData
): DetailedTrafficStats | SimplifiedTrafficStats {
	// Check if the input has detailed structure by examining the first non-null entry
	if (!trafficData) {
		return {
			assets: 0,
			progressivePaths: 0,
			progressivePorts: 0,
			overlaps: 0,
			skipped: 0,
		};
	}
	const sampleEntry = Object.values(trafficData).find(entry => entry !== null);
	const isDetailed =
		sampleEntry &&
		(sampleEntry.paths?.hasOwnProperty("unreviewed") ||
			sampleEntry.ports?.hasOwnProperty("unreviewed"));

	if (isDetailed) {
		const detailed: DetailedTrafficStats = {
			assets: 0,
			paths: {
				unreviewed: 0,
				denied: 0,
				deniedByTemplate: 0,
				violations: 0,
			},
			ports: {
				unreviewed: 0,
				denied: 0,
				deniedByTemplate: 0,
			},
			overlaps: 0,
			skipped: 0,
		};

		Object.values(trafficData).forEach(item => {
			detailed.assets++;
			if (item.paths && typeof item.paths !== "number") {
				detailed.paths.unreviewed += item.paths.unreviewed || 0;
				detailed.paths.denied += item.paths.denied || 0;
				detailed.paths.deniedByTemplate += item.paths.deniedByTemplate || 0;
				detailed.paths.violations += item.paths.violations || 0;
			}
			if (item.ports && typeof item.ports !== "number") {
				detailed.ports.unreviewed += item.ports.unreviewed || 0;
				detailed.ports.denied += item.ports.denied || 0;
				detailed.ports.deniedByTemplate += item.ports.deniedByTemplate || 0;
			}
			detailed.overlaps += item.status ? 1 : 0;
			detailed.skipped += item.stateSkipped ? 1 : 0;
		});

		return detailed;
	} else {
		const simplified: SimplifiedTrafficStats = {
			assets: 0,
			progressivePaths: 0,
			progressivePorts: 0,
			overlaps: 0,
			skipped: 0,
		};

		Object.values(trafficData).forEach(item => {
			simplified.assets++;
			simplified.progressivePaths +=
				typeof item.progressivePaths === "number" ? item.progressivePaths : 0;
			simplified.progressivePorts +=
				typeof item.progressivePorts === "number" ? item.progressivePorts : 0;
			simplified.overlaps += item.status ? 1 : 0;
			simplified.skipped += item.stateSkipped ? 1 : 0;
		});

		return simplified;
	}
}
