import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Stack, Toolbar } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { API_URL } from "hooks/useNetworking/constants";
import { LazyLog } from "react-lazylog";

interface LogsProps {
	agentId?: string;
	isOpen: boolean;
	onClose: () => void;
}

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotatev (180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export function LogsContainer({ agentId }: { agentId?: string }) {
	return (
		<Box minHeight={410} mb={2} mt={2} flex={1}>
			<LazyLog
				fetchOptions={{ credentials: "include" }}
				selectableLines
				enableSearch
				url={`${API_URL}/agents/${agentId}/config/gatekeeper/logs`}
			/>
		</Box>
	);
}

export function LogsDrawer({ agentId, isOpen, onClose }: LogsProps) {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "16px",
					width: "50%",
					overflow: "hidden",
				},
			}}
		>
			<Toolbar />
			<Stack alignItems={"flex-start"} sx={{ mb: 1 }}>
				<IconButton size="small" aria-label="close drawer" onClick={onClose}>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Stack>

			<LogsContainer agentId={agentId} />
		</Drawer>
	);
}
