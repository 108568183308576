import { CustomCommonStoreType } from "common/types/types";
import { CoreStoreBuilder } from "modules/core/store";
import { CoreStore } from "modules/core/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { create } from "zustand";

export interface AlertConfigStore extends CoreStore {
	selectedCriteria: string[];
	setSelectedCriteria: (selectedCriteria: string[]) => void;
}

export const useAlertConfigDrawerStore = create<AlertConfigStore>()(set => ({
	...CoreStoreBuilder(set),
	selectedCriteria: [],
	setSelectedCriteria: (selectedCriteria: string[]) =>
		set({ selectedCriteria }),
}));

export const createAlertWithFacetStore = () => {
	return create<FacetStore>()(set => ({
		...FacetStoreBuilder(set),
	}));
};
export const useAlertWthFilterFacetStore: CustomCommonStoreType =
	createAlertWithFacetStore();
