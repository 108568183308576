import { Paper, Popper, Stack, Typography } from "@mui/material";
import { getColor } from "common/constants/colors";
import { PathStatus } from "pages/paths/types";
import { useMemo } from "react";
import { PathStatusMap } from "../types";
import {
	getPathStatusBreakdown,
	getTooltipLabelFromStatus,
} from "../visx-utils";
import { EdgeDataType } from "./EdgeWithButton";

interface PathDistributionByStatusPopperProps {
	id: string;
	anchorEl: HTMLElement | null;
	data: EdgeDataType | undefined;
}

export default function PathDistributionByStatusPopper({
	id,
	anchorEl,
	data,
}: PathDistributionByStatusPopperProps) {
	const TOOLTIP_STYLES = {
		borderRadius: "4px",
	};

	const { breakdown } = useMemo(() => {
		let b = getPathStatusBreakdown(data?.statusMap);
		let map: PathStatusMap = new Map();
		if (b.totalAllowed) {
			map.set(PathStatus.Allow, 0);
		}
		if (b.totalAllowedByTest) {
			map.set(PathStatus.AllowedByTestUIOnly, 0);
		}
		if (b.totalUnreviewed) {
			map.set(PathStatus.Unreviewed, 0);
		}
		if (b.totalDenied) {
			map.set(PathStatus.Deny, 0);
		}
		return {
			breakdown: map,
			total: b.total,
			b,
		};
	}, [data?.statusMap]);

	return (
		<>
			<Popper id={id} open={Boolean(anchorEl)} anchorEl={anchorEl}>
				<Stack
					sx={{ p: 1, ...TOOLTIP_STYLES }}
					spacing={1}
					component={Paper}
					elevation={8}
				>
					<Typography>{window.getCTTranslatedText("Paths")}</Typography>
					{Array.from(breakdown?.entries() || []).map(([status]) => {
						return (
							<Stack
								direction="row"
								spacing={1}
								alignItems={"center"}
								key={status}
							>
								<svg
									style={{
										width: "12px",
										height: "12px",
									}}
								>
									<rect
										width={"100%"}
										height={"100%"}
										fill={getColor(status)}
									/>
								</svg>

								<Typography
									variant={"body2"}
									sx={{
										width: "90%",
										textTransform: "capitalize",
									}}
								>
									{window.getCTTranslatedText(
										getTooltipLabelFromStatus(status)
									)}
								</Typography>
							</Stack>
						);
					})}
				</Stack>
			</Popper>
		</>
	);
}
