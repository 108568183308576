import { CustomCommonStoreType } from "common/types/types";
import CriteriaChips from "modules/facets/components/criteria-chips/CriteriaChips";
import { useTagPolicyFacetStore } from "../../store";
import { TagPolicy } from "../../types";

export function PolicyCriteria({
	policy,
	useFacetStore = useTagPolicyFacetStore,
	readOnlyDimensions,
}: {
	policy: TagPolicy;
	useFacetStore?: CustomCommonStoreType;
	readOnlyDimensions?: Array<string>;
}) {
	return (
		<CriteriaChips
			data={policy}
			useFacetStore={useFacetStore}
			isValid={!!policy?.tagBasedPolicyId}
			readOnlyDimensions={readOnlyDimensions}
		/>
	);
}
