import {
	Box,
	FormControlLabel,
	Paper,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import { CurrentStatuses } from "common/atoms/ct-guardrail/CTGuardrail";
import { OutputData } from "common/atoms/ct-guardrail/types";
import { CircularCenteredLoader } from "common/atoms/loader";
import {
	Direction,
	SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import {
	ProgressiveEnforcementLevel,
	ProgressiveOutboundPortEnforcementLevel,
} from "pages/ports/types";
import {
	PolicyChangeType,
	PolicyUnreviewedTraffic,
	TrafficCriteria,
	UnreviewedTraffic,
} from "../../types";
import { SecurityLevelCard } from "../security-level-card";

interface PolicyAutomationGeneratorProps {
	isInboundSimulated: boolean;
	isOutboundSimulated: boolean;
	initialAttackSurfaceStatus?: SecurityStatus;
	selectedAttackSurfaceStatus: SecurityStatus;
	initialProgressiveEnforcementLevel?:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel;
	selectedProgressiveInboundEnforcementLevel: ProgressiveEnforcementLevel;
	selectedProgressiveOutboundEnforcementLevel: ProgressiveOutboundPortEnforcementLevel;
	selectedBlastRadiusStatus: SecurityStatus;
	autoPush: boolean;
	setSelectedAttackSurfaceStatus: (value: SecurityStatus) => void;
	setSelectedProgressiveInboundEnforcementLevel: (
		value: ProgressiveEnforcementLevel
	) => void;
	setSelectedProgressiveOutboundEnforcementLevel: (
		value: ProgressiveOutboundPortEnforcementLevel
	) => void;
	setSelectedBlastRadiusStatus: (value: SecurityStatus) => void;
	setAutoPush: (value: boolean) => void;
	isLoading: boolean;
	onChangeAttackSurfaceTestMode: (test: boolean) => void;
	onChangeBlastRadiusTestMode: (test: boolean) => void;
	criteria?: string;
	isZeroTrustAutomationEditable: boolean;
	policyId: string;
	policyProgressiveLastRefreshed?: string;
	unreviewedTraffic?: PolicyUnreviewedTraffic;
	trafficCriteria?: TrafficCriteria;
	assetCount?: number;
	updateUnreviewedTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic: UnreviewedTraffic
	) => void;
	trafficReviewed?: boolean;
	updateAggregateTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic?: OutputData
	) => void;
	managedBy: ManagedByValue;
	currentStatuses?: CurrentStatuses;
}

export const PolicyAutomationGenerator = ({
	isInboundSimulated,
	isOutboundSimulated,
	initialAttackSurfaceStatus,
	selectedAttackSurfaceStatus,
	setSelectedAttackSurfaceStatus,
	initialProgressiveEnforcementLevel,
	selectedProgressiveInboundEnforcementLevel,
	setSelectedProgressiveInboundEnforcementLevel,
	selectedProgressiveOutboundEnforcementLevel,
	setSelectedProgressiveOutboundEnforcementLevel,
	selectedBlastRadiusStatus,
	setSelectedBlastRadiusStatus,
	autoPush,
	setAutoPush,
	isLoading,
	onChangeAttackSurfaceTestMode,
	onChangeBlastRadiusTestMode,
	updateUnreviewedTraffic,
	criteria,
	isZeroTrustAutomationEditable,
	policyId,
	policyProgressiveLastRefreshed,
	unreviewedTraffic,
	trafficCriteria,
	assetCount,
	currentStatuses,
	trafficReviewed,
	updateAggregateTraffic,
	managedBy = ManagedByValue.ColorTokens,
}: PolicyAutomationGeneratorProps) => {
	if (isLoading) {
		return <CircularCenteredLoader />;
	}

	const isAttackSurfaceZeroTrustAutomationEditable =
		isZeroTrustAutomationEditable ||
		selectedProgressiveInboundEnforcementLevel !==
			ProgressiveEnforcementLevel.ZeroTrust;

	const isBlastRadiusZeroTrustAutomationEditable =
		selectedProgressiveOutboundEnforcementLevel ===
		ProgressiveOutboundPortEnforcementLevel.Any
			? true
			: isZeroTrustAutomationEditable;

	return (
		<Box sx={{ p: 0 }}>
			<Stack spacing={3}>
				<Box>
					<Typography variant="subtitle2">
						{window.getCTTranslatedText("Enforcement levels")}
					</Typography>
					<Typography
						variant="body2"
						sx={{ color: theme => theme.palette.text.secondary }}
					>
						{window.getCTTranslatedText(
							managedBy === ManagedByValue.ColorTokens
								? "Configure minimal enforcement level of the matching assets"
								: "Configure enforcement level of the matching assets"
						)}
					</Typography>
				</Box>

				<SecurityLevelCard
					progressiveId={PolicyChangeType.AttackSurfaceProgressive}
					enforcementId={PolicyChangeType.AttackSurfaceEnforcement}
					updateUnreviewedTraffic={updateUnreviewedTraffic}
					trafficReviewed={trafficReviewed}
					direction={Direction.Inbound}
					currentStatus={initialAttackSurfaceStatus}
					currentEnforcementStatus={
						currentStatuses?.[PolicyChangeType.AttackSurfaceEnforcement]
					}
					currentProgressiveStatus={
						currentStatuses?.[PolicyChangeType.AttackSurfaceProgressive]
					}
					selectedStatus={selectedAttackSurfaceStatus}
					setSelectedStatus={setSelectedAttackSurfaceStatus}
					initialProgressiveEnforcementLevel={
						initialProgressiveEnforcementLevel
					}
					selectedProgressiveEnforcementLevel={
						selectedProgressiveInboundEnforcementLevel
					}
					setSelectedProgressiveEnforcementLevel={
						setSelectedProgressiveInboundEnforcementLevel
					}
					simulatedStatus={isInboundSimulated}
					onChangeTestMode={onChangeAttackSurfaceTestMode}
					criteria={criteria}
					isZeroTrustAutomationEditable={
						isAttackSurfaceZeroTrustAutomationEditable
					}
					policyId={policyId}
					policyProgressiveLastRefreshed={policyProgressiveLastRefreshed}
					progressiveTraffic={
						unreviewedTraffic?.[PolicyChangeType.AttackSurfaceProgressive]
					}
					enforcementTraffic={
						unreviewedTraffic?.[PolicyChangeType.AttackSurfaceEnforcement]
					}
					progressiveCriteria={
						trafficCriteria?.[PolicyChangeType.AttackSurfaceProgressive]
					}
					enforcementCriteria={
						trafficCriteria?.[PolicyChangeType.AttackSurfaceEnforcement]
					}
					updateAggregateTraffic={updateAggregateTraffic}
					managedBy={managedBy}
				/>

				<SecurityLevelCard
					progressiveId={PolicyChangeType.BlastRadiusProgressive}
					enforcementId={PolicyChangeType.BlastRadiusEnforcement}
					updateUnreviewedTraffic={updateUnreviewedTraffic}
					trafficReviewed={trafficReviewed}
					direction={Direction.Outbound}
					currentEnforcementStatus={
						currentStatuses?.[PolicyChangeType.BlastRadiusEnforcement]
					}
					currentProgressiveStatus={
						currentStatuses?.[PolicyChangeType.BlastRadiusProgressive]
					}
					selectedStatus={selectedBlastRadiusStatus}
					selectedProgressiveEnforcementLevel={
						selectedProgressiveOutboundEnforcementLevel
					}
					setSelectedProgressiveEnforcementLevel={
						setSelectedProgressiveOutboundEnforcementLevel
					}
					setSelectedStatus={setSelectedBlastRadiusStatus}
					simulatedStatus={isOutboundSimulated}
					onChangeTestMode={onChangeBlastRadiusTestMode}
					criteria={criteria}
					isZeroTrustAutomationEditable={
						isBlastRadiusZeroTrustAutomationEditable
					}
					progressiveTraffic={
						unreviewedTraffic?.[PolicyChangeType.BlastRadiusProgressive]
					}
					enforcementTraffic={
						unreviewedTraffic?.[PolicyChangeType.BlastRadiusEnforcement]
					}
					progressiveCriteria={
						trafficCriteria?.[PolicyChangeType.BlastRadiusProgressive]
					}
					enforcementCriteria={
						trafficCriteria?.[PolicyChangeType.BlastRadiusEnforcement]
					}
					updateAggregateTraffic={updateAggregateTraffic}
					managedBy={managedBy}
				/>
			</Stack>
			<Box
				component={Paper}
				// elevation={2}
				p={2}
				mt={3}
				sx={{ opacity: isZeroTrustAutomationEditable ? 1 : 0.3 }}
			>
				<Typography variant="subtitle1">
					{window.getCTTranslatedText("Auto push to firewall")}
				</Typography>
				<FormControlLabel
					sx={{ mt: 1 }}
					disabled={!isZeroTrustAutomationEditable}
					label={
						<Typography variant="body2" ml={2}>
							{window.getCTTranslatedText(
								"Enable auto push to firewall on any changes in ports, paths, templates or named networks of the matching assets"
							)}
						</Typography>
					}
					control={
						<Switch
							value={autoPush}
							checked={autoPush}
							onChange={(_, c) => setAutoPush(c)}
							inputProps={{ "aria-label": "Use Auto Push" }}
						/>
					}
				/>
			</Box>
		</Box>
	);
};
