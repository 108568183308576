export enum DHCPModes {
	Server = "server",
	Relay = "relay",
}

export enum ConfigStatusMap {
	Syncronized = "completed",
	Failed = "failed",
	Inprogress = "inprogress",
	Pending = "pending",
	ForceUpdate = "force-update",
}

export enum DHCPOptions {
	DHCP = "dhcp",
	Mixed = "mixed",
	Static = "static",
}

export enum HAModes {
	StandAlone = "standalone",
	Primary = "primary",
	Standby = "backup",
}

export enum HAModeTypes {
	Active = "active",
	Standby = "standby",
}

export enum NetworkInterfaces {
	WAN = "WAN",
	LAN = "LAN",
	VLAN = "VLAN",
}

export enum OptInOptions {
	Yes = "Opt-in",
	No = "Opt-out",
}

export enum ProxyOptions {
	Yes = "Enable Proxy",
	No = "Disable Proxy",
}

export enum AuthenticationOptions {
	Yes = "Enable Authentication",
	No = "Disable Authentication",
}

export enum AsymmetricModeOptions {
	Yes = "Enable Asymmetric Mode",
	No = "Disable Asymmetric Mode",
}

export enum FailOpenOptions {
	Yes = "Enable Fail Open",
	No = "Disable Fail Open",
}

export enum ApplianceSteps {
	General = 0,
	Security = 1,
	Network = 2,
	Gatekeeper = 3,
	Advanced = 4,
}

export enum LoggingLevelOptions {
	Info = "info",
	Debug = "debug",
	Warn = "warn",
	Error = "error",
}
export enum AuthModeKeys {
	"PASS" = "Simple/Password",
	"AH" = "Authentication Header",
}

export enum AuthModeValues {
	"Simple/Password" = "PASS",
	"Authentication Header" = "AH",
}

export enum NetworkFieldKeys {
	Name = "namedNetworkName",
	Description = "namedNetworkDescription",
	Domain = "domain",
	IPRange = "iprange",
}

export enum BooleanFlags {
	True = "true",
	False = "false",
}

export enum ProbeProtocols {
	Modbus = "modbus",
	Ethernet = "enip",
	Siemens = "s7comm",
}

export enum ProbeProtocolLabels {
	Modbus = "Modbus",
	Ethernet = "Ethernet/IP",
	Siemens = "Siemens S7Comm",
}

export interface IPRangeInt {
	id?: string;
	value: string;
	error?: boolean;
	count?: string;
}

export interface VLAN {
	id: string;
	gatewayIP?: string;
	startIP: string;
	endIP: string;
	virtualIP?: string;
	wanVirtualIP?: string;
	gatewayAddress?: string;
	mtu: string;
}

interface AgentConfig {
	type?: string;
	domain?: string;
	deploymentKey?: string;
	autoUpgrade?: string;
	hostname?: string;
	proxy?: ProxyConfig;
	loggerLevel?: string;
	asymmetricMode?: string;
	failOpen?: string;
	pathBasedDiscovery?: string;
	peerApplianceIDs?: string[];
}

export interface ProxyConfig {
	url?: string;
	username?: string;
	password?: string;
}
export interface DHCPConfig {
	enabled: BooleanFlags.True | BooleanFlags.False;
	optInSupport?: string;
	optInDevices?: Array<string>;
	mode?: DHCPModes.Server | DHCPModes.Relay;
	serverIp?: string;
	serverIps?: Array<string> | [];
	interface?: string;
	leaseTime?: string;
	leaseFile?: string;
	dnsServers?: Array<string>;
	relayIp?: string;
	routerOverrides?: string;
}

export interface NetworkInterfaceConfig {
	name?: string;
	type: NetworkInterfaces.WAN | NetworkInterfaces.LAN | NetworkInterfaces.VLAN;
	dnsServers?: Array<string>;
	gatewayAddress?: string;
	dhcp?: LANDHCPConfig;
	vrrp?: WANVrrpConfig;
	vlans?: Array<VLANConfig>;
	mtu?: string;
}

export interface WANVrrpConfig {
	virtualIp?: string;
	peerIps?: Array<string>;
}

export interface LANDHCPConfig {
	gatewayIp?: string;
	rangeStart: string;
	rangeEnd: string;
	existingGatewayIp?: string;
}

export interface VLANConfig {
	id: string;
	vrrp?: WANVrrpConfig;
	dhcp?: {
		gatewayIp?: string;
		rangeStart: string;
		rangeEnd: string;
		existingGatewayIp?: string;
	};
	mtu?: string;
}

export interface VRRPConfig {
	enabled: BooleanFlags.True | BooleanFlags.False;
	mode?: HAModeTypes.Active | HAModeTypes.Standby;
	virtualId?: string;
	routerId: string;
	advertInt?: string;
	password: string;
	srcIp?: string;
	peerIp?: string;
	authMode?: string;
}

export interface ApplianceConfig {
	agent?: AgentConfig;
	appliances?: Array<ConfigAppliances>;
	dhcp?: DHCPConfig;
	interfaces?: Array<NetworkInterfaceConfig>;
	vrrp?: VRRPConfig;
	ipConfiguration?: DHCPOptions.DHCP | DHCPOptions.Mixed | DHCPOptions.Static;
	probeConfig: ProbeConfig;
	deviceIdentifier?: 0 | 1;
}

export interface ProbeConfig {
	deviceProbingDisabled: boolean;
	probeProtocols: Array<string>;
	vulnerabilitiesDisabled: boolean;
}

export interface ApplianceConfigPayload {
	gatekeeperConfig: ApplianceConfig;
	peerApplianceIDs?: Array<string>;
	agentLocation?: string;
}

export interface ConfigResult {
	gatekeeperConfig?: ApplianceConfig;
	status?: string;
}

export interface ProxySettings {
	proxyEnabled?: string;
	authenticationEnabled?: string;
}

export interface PeerDetails {
	peerEnabled: boolean;
	peerApplianceIDs?: string[];
}

export interface ConfigAppliances {
	agentId: string;
	hostname: string;
	wanIp?: string;
	vlans: ApplianceVLAN[];
}

export interface ApplianceVLAN {
	vlanId: string;
	wanIp: string;
	lanIp: string;
}

export enum ApplianceVLANFields {
	WANIP = "wanIp",
	LANIP = "lanIp",
	VLANID = "vlanId",
}

export interface ApplianceVLANUpdate {
	applianceIndex: number;
	index: number;
	field: keyof ApplianceVLAN;
	value: string;
	isParentWanIP?: boolean;
}

export const ALL_VLAN_IDS = "all";

export const MTUOptions = ["1500", "9000"];

export const ProxyProtocolOptions = ["http", "https"];

export const AuthModeOptions = [AuthModeKeys.PASS, AuthModeKeys.AH];

export const DiscoveryModeOptions = ["ARP", "Path"];

export const DeviceIdentifierOptions = ["MAC Address", "MAC & IP Address"];

export const Defaults = {
	LeaseDuration: "120",
	RouterId: "57",
	WanMTU: "1500",
	LanMTU: "1500",
	OptIn: OptInOptions.Yes,
	Proxy: ProxyOptions.No,
	ProxyAuth: AuthenticationOptions.No,
	ProxyProtocol: "http",
	LoggingLevel: "info",
	AuthMode: AuthModeOptions[1],
	DiscoveryMode: DiscoveryModeOptions[0],
	AsymmetricMode: AsymmetricModeOptions.No,
	FailOpen: FailOpenOptions.No,
	DeviceIdentifier: DeviceIdentifierOptions[0],
	DeviceProbingDisabled: false,
	VulnerabilitiesDisabled: false,
	ProbeProtocols: [],
};

export type InputListType = IPRangeInt[] | string[];
