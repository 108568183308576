import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useNetworkStore } from "pages/networks/store";
import { IPRangeInt, Network } from "pages/networks/types";
import { NetworkConfirmationDialog } from "../network-confirmation-dialog";

export interface NetworkRemoveProps {
	network: Network;
	ipRange: IPRangeInt;
	handleAllowRemove: Function;
	allowRemove: boolean;
}

const useRemoveNetworkRangeAPI = ({
	namedNetworkId,
}: {
	namedNetworkId: string | undefined;
}) => {
	const path = `namednetworks/${namedNetworkId}/networkranges`;
	return useMutation<any, Error, any>(["networks", path, "delete"]);
};

export function RemoveNetworkRange({
	network,
	ipRange,
	handleAllowRemove,
	allowRemove,
}: NetworkRemoveProps) {
	const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const notify = useEventSubscriptionStore(state => state.notify);

	const removeNetworkRangeAPI = useRemoveNetworkRangeAPI({
		namedNetworkId: network?.namedNetworkId,
	});

	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	const updateNetworkDetails = () => {
		if (ipRange?.id) {
			setLoading(true);

			let body: any = [
				{
					rangeID: ipRange?.id,
				},
			];

			removeNetworkRangeAPI.mutate(body, {
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: ["networks"],
					});
					requestAPIRefresh();
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "IPRangeRemovedFromThisNamedNetwork",
					});
					setTimeout(() => {
						handleAllowRemove(false);
						setLoading(false);
					}, 500);
				},
				onError: error => {
					setLoading(false);
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	return (
		<NetworkConfirmationDialog
			network={network}
			isOpen={allowRemove}
			onClose={() => handleAllowRemove(false)}
			title={window.getCTTranslatedText(`Delete IP Range`)}
			primaryText={window.getCTTranslatedText("removeIPFromNamedNetwork", {
				ipRangeValue: ipRange?.value,
			})}
			isLoading={removeNetworkRangeAPI.isLoading || loading}
			onSuccess={updateNetworkDetails}
			secondaryTextWarning={"DeleteIPRangeWarning"}
			primaryButtonText={window.getCTTranslatedText("Remove")}
		/>
	);
}
