import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Collapse, Stack, Typography } from "@mui/material";
import { CopyInstructions } from "pages/agents/components/agent-download-page";
import {
	TerminalParagraph,
	TerminalScreen,
} from "pages/agents/components/agent-download-page/Terminal";
import {
	OfflineRecoveryProps,
	RecoveryStepProps,
} from "pages/appliances/types";
import { useState } from "react";
import { useApplianceConfigGetAPI } from "../../../hooks";
import { ExpandMore } from "../../appliance-config-form/components/Logs";
import { exportConfig } from "../../appliance-config-form/utils";

const EnableUSBCommand = "sudo su ctconfig -enableUSB";
const GatekeeperFileName = "ctgatekeeper";

const RecoveryStep = ({
	index,
	title,
	children,
	isOptional = false,
}: RecoveryStepProps) => {
	return (
		<Stack spacing={1}>
			<Typography
				sx={{
					textTransform: "uppercase",
				}}
				variant="caption"
			>
				{window.getCTTranslatedText("step")} {index}
				{isOptional ? ` (${window.getCTTranslatedText("optional")})` : null}
			</Typography>
			<Typography variant="body2">
				{window.getCTTranslatedText(title)}
			</Typography>
			<Stack alignItems={"flex-start"}>{children}</Stack>
		</Stack>
	);
};

const AdvancedSteps = () => {
	return (
		<Stack spacing={1}>
			<Typography variant="body2">
				{window.getCTTranslatedText("offlineRecoveryStep2")}
			</Typography>
			<Stack alignItems={"flex-start"}>
				<TerminalScreen sx={{ mb: 1 }}>
					<TerminalParagraph sx={{ display: "flex", alignItems: "center" }}>
						<Stack sx={{ flex: 1 }}>{EnableUSBCommand}</Stack>
						<Stack direction="row" justifyContent="flex-end">
							<CopyInstructions code={EnableUSBCommand} />
						</Stack>
					</TerminalParagraph>
				</TerminalScreen>
			</Stack>
		</Stack>
	);
};

export const OfflineRecovery = ({ appliance }: OfflineRecoveryProps) => {
	const { data: config } = useApplianceConfigGetAPI({
		agentId: appliance?.agentId,
		refetchOnWindowFocus: false,
	});
	const [expandAdvanced, setExpandAdvanced] = useState(false);

	const downloadConfig = () => {
		exportConfig(config.gatekeeperConfig, GatekeeperFileName);
	};

	const handleExpandAdvanced = () => {
		setExpandAdvanced(!expandAdvanced);
	};

	const OfflineRecoverySteps = [
		{
			title: "offlineRecoveryStep1",
			children: (
				<Button onClick={downloadConfig}>
					{window.getCTTranslatedText("downloadConfigButton")}
				</Button>
			),
		},
		{
			title: "offlineRecoveryStep3",
			children: <></>,
		},
	];

	return (
		<Stack
			alignItems={"flex-start"}
			style={{ position: "relative", height: "100%" }}
			spacing={6}
		>
			{OfflineRecoverySteps.map((step, index) => {
				return (
					<RecoveryStep
						index={index + 1}
						title={step.title}
						children={step.children}
					/>
				);
			})}
			<Stack
				direction="column"
				width="100%"
				justifyContent="space-between"
				px={2}
				mt={8}
				sx={{
					background: theme =>
						theme.palette.mode === "dark"
							? theme.palette.background.paper
							: theme.palette.custom.lightGreyBg,
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					sx={{
						cursor: "pointer",
					}}
					py={1}
					onClick={handleExpandAdvanced}
				>
					<Typography variant="body1">
						{window.getCTTranslatedText("advancedSteps")}
					</Typography>
					<ExpandMore expand={expandAdvanced}>
						<ExpandMoreIcon />
					</ExpandMore>
				</Stack>
				<Collapse in={expandAdvanced} timeout="auto" unmountOnExit>
					<AdvancedSteps />
				</Collapse>
			</Stack>
		</Stack>
	);
};
