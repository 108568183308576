import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { IP_FIELD_TYPES } from "common/atoms/ct-ip-input/CTIPInput";
import { FormSectionHeader } from "common/atoms/form-section-header";
import { Appliance } from "pages/appliances/types";
import { useState } from "react";
import {
	ALL_VLAN_IDS,
	ApplianceVLANFields,
	ApplianceVLANUpdate,
	ConfigAppliances,
	HAModes,
	HAModeTypes,
	NetworkInterfaces,
	VLAN,
} from "../types";
import { IPWithMask_PLACEHOLDER } from "../utils";
import { HAStandby } from "./components";

const isActiveHAMode = (HAMode: string, HAModeType: string) => {
	return HAMode === HAModes.Primary && HAModeType === HAModeTypes.Active;
};

const isStandAloneHAMode = (HAMode: string) => {
	return HAMode === HAModes.StandAlone;
};

const isStandbyHAMode = (HAMode: string, HAModeType: string) => {
	return HAMode === HAModes.Primary && HAModeType === HAModeTypes.Standby;
};

interface PeerApplianceProps {
	agentId?: string;
	networkInterface: string;
	keyListener: (event: any) => void;
	handleVlanFieldChange: (value: ApplianceVLANUpdate) => void;
	VLANList: VLAN[];
	HAModeType: string;
	isFormDisabled: boolean;
	HAMode: string;
	appliances: ConfigAppliances[];
	setAppliances: (appliances: ConfigAppliances[]) => void;
}

export function PeerAppliances({
	agentId,
	keyListener,
	handleVlanFieldChange,
	networkInterface,
	VLANList,
	HAModeType,
	isFormDisabled,
	HAMode,
	appliances,
	setAppliances,
}: PeerApplianceProps) {
	const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

	const handleApplianceSelect = (value: Appliance, index: number) => {
		let newAppliances = [...appliances];
		newAppliances[index].agentId = value?.agentId ?? "";
		newAppliances[index].hostname = value?.agentName ?? "";
		setAppliances(newAppliances);
	};

	const handleDeleteAppliance = (index: number) => {
		let newAppliances = [...appliances];
		newAppliances.splice(index, 1);
		setAppliances(newAppliances);
	};

	const scrollIntoView = () => {
		const element = document.getElementById(`peer-appliances`);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	const handleAddAppliance = () => {
		let newAppliances = [...appliances];
		let newVLANs = VLANList.map(vlan => ({
			vlanId: vlan.id,
			wanIp: "",
			lanIp: "",
		}));
		if (networkInterface === NetworkInterfaces.LAN) {
			newVLANs = [
				{
					vlanId: ALL_VLAN_IDS,
					wanIp: "",
					lanIp: "",
				},
			];
		}
		newAppliances.push({
			agentId: "",
			hostname: "",
			vlans: newVLANs,
		});
		setExpanded({ ...expanded, [newAppliances.length - 1]: true });
		setAppliances(newAppliances);
	};

	function renderVLANs(appliance: ConfigAppliances, applianceIndex: number) {
		return (
			<>
				{appliance.vlans &&
					appliance.vlans.length > 0 &&
					appliance.vlans.map((item, index) => {
						return (
							<Stack
								py={2}
								px={3}
								sx={{
									background: theme =>
										theme.palette.mode === "dark"
											? theme.palette.background.paper
											: theme.palette.custom.lightGreyBg,
								}}
							>
								<Typography variant="body1">
									{item?.vlanId === ALL_VLAN_IDS
										? window.getCTTranslatedText("LAN")
										: window.getCTTranslatedText("VLAN") + " " + item?.vlanId}
								</Typography>
								<Stack spacing={2}>
									<Grid
										container
										columnGap={4}
										rowGap={2}
										sx={{ pl: 0 }}
										display="grid"
										gridTemplateColumns="1fr 1fr"
									>
										<Stack width="100%">
											<CTIPInput
												field="lanIPAddress"
												displayName={window.getCTTranslatedText("LANIPAddress")}
												placeholder={IPWithMask_PLACEHOLDER}
												fieldType={IP_FIELD_TYPES.SUBNET}
												value={item?.lanIp ?? ""}
												handleUpdate={(event: TextFieldUpdate) =>
													handleVlanFieldChange({
														value: event?.value,
														applianceIndex,
														index,
														field: ApplianceVLANFields.LANIP,
													})
												}
												disabled={isFormDisabled}
												onKeyUp={keyListener}
											/>
										</Stack>
										{isActiveHAMode(HAMode, HAModeType) ||
										((isStandAloneHAMode(HAMode) ||
											isStandbyHAMode(HAMode, HAModeType)) &&
											networkInterface === NetworkInterfaces.LAN) ? (
											<Stack width="100%">
												<CTIPInput
													field="wanIPAddress"
													displayName={window.getCTTranslatedText(
														"WANIPAddress"
													)}
													placeholder={IPWithMask_PLACEHOLDER}
													fieldType={IP_FIELD_TYPES.SUBNET}
													value={item?.wanIp ?? ""}
													handleUpdate={(event: TextFieldUpdate) =>
														handleVlanFieldChange({
															value: event?.value,
															applianceIndex,
															index,
															field: ApplianceVLANFields.WANIP,
														})
													}
													disabled={isFormDisabled}
													onKeyUp={keyListener}
												/>
											</Stack>
										) : null}
									</Grid>
								</Stack>
							</Stack>
						);
					})}
			</>
		);
	}

	function renderAppliance(appliance: ConfigAppliances, index: number) {
		const isCurrentAppliance = appliance.agentId === agentId;
		return (
			<Stack mb={4}>
				<Card>
					<CardHeader
						sx={{
							px: 3,
							py: 2,
							alignItems: "center",
						}}
						action={
							<Stack
								direction="row"
								spacing={2}
								justifyContent="flex-end"
								mt={1}
							>
								{index > 0 ? (
									<IconButton onClick={() => handleDeleteAppliance(index)}>
										<DeleteOutlineIcon />
									</IconButton>
								) : null}
								<IconButton
									onClick={() =>
										setExpanded({ ...expanded, [index]: !expanded[index] })
									}
								>
									{expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
								</IconButton>
							</Stack>
						}
						title={<FormSectionHeader header={appliance.hostname} />}
					/>
					<Collapse in={expanded[index]} timeout="auto">
						<Stack px={3} pb={2}>
							<Grid
								container
								columnGap={4}
								rowGap={2}
								sx={{ pl: 0 }}
								display="grid"
								gridTemplateColumns="1fr 1fr"
							>
								<Stack width="100%">
									<HAStandby
										agentId={appliance.agentId === agentId ? "" : agentId}
										peerAppliances={
											appliance.agentId ? [appliance.agentId] : []
										}
										setSelectedPeerAppliance={(value: Appliance) =>
											handleApplianceSelect(value, index)
										}
										disabled={isCurrentAppliance || isFormDisabled}
									/>
								</Stack>
								{isActiveHAMode(HAMode, HAModeType) ||
								networkInterface === NetworkInterfaces.LAN ? null : (
									<Stack width="100%">
										<CTIPInput
											field="wanIPAddress"
											displayName={window.getCTTranslatedText("WANIPAddress")}
											placeholder={IPWithMask_PLACEHOLDER}
											fieldType={IP_FIELD_TYPES.SUBNET}
											value={appliance?.wanIp ?? ""}
											handleUpdate={(event: TextFieldUpdate) =>
												handleVlanFieldChange({
													value: event?.value,
													applianceIndex: index,
													index: 0,
													field: ApplianceVLANFields.WANIP,
													isParentWanIP: true,
												})
											}
											disabled={isFormDisabled}
											onKeyUp={keyListener}
										/>
									</Stack>
								)}
							</Grid>
						</Stack>
						<CardContent
							sx={{
								pt: 0,
							}}
						>
							<Stack spacing={2}>{renderVLANs(appliance, index)}</Stack>
						</CardContent>
					</Collapse>
				</Card>
			</Stack>
		);
	}

	return (
		<Stack>
			<Stack direction="row" spacing={2} justifyContent="flex-end" mb={2}>
				<Button
					startIcon={<AddCircleOutlineIcon />}
					onClick={() => {
						handleAddAppliance();
						scrollIntoView();
					}}
					disabled={
						isStandAloneHAMode(HAMode) ||
						(isStandbyHAMode(HAMode, HAModeType) && appliances.length >= 2)
					}
				>
					{window.getCTTranslatedText("Add Gatekeeper")}
				</Button>
			</Stack>

			{appliances.map((appliance, index) => {
				return renderAppliance(appliance, index);
			})}
		</Stack>
	);
}
