import {
	CableOutlined,
	DeviceHubOutlined,
	GroupOutlined,
	HiveOutlined,
	InstallDesktopOutlined,
	MonitorHeartOutlined,
	SettingsOutlined,
} from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BadgeIcon from "@mui/icons-material/Badge";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import DnsIcon from "@mui/icons-material/DnsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import MonitorIcon from "@mui/icons-material/Monitor";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import StoreIcon from "@mui/icons-material/Store";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
	AIXIcon,
	AppleIcon,
	DockerIcon,
	LinuxIcon,
	SolarisIcon,
	TemplateIcon,
	WindowsIcon,
} from "assets/svgs";
import { IconsProps } from "assets/svgs/common";
import { CrowdStrikeIcon } from "assets/svgs/CrowdStrikeIcon";
import { NavMainItemKey } from "modules/NavSidebar/constants";
import { UnmanagedDeviceCoreTagKey } from "pages/appliance-detail/components/appliance-unmanaged-devices/constants";
import { CoreTagsKeys, OSType } from "pages/assets/types";

export type IconObj = {
	icon:
		| OverridableComponent<SvgIconTypeMap<{}, "svg">>
		| ((...args: IconsProps[]) => JSX.Element);
	defaultProps?: IconsProps;
};

export const DEFAULT_TAG_ICON: IconObj = { icon: LocalOfferOutlinedIcon };
export const NULL_ICON = { icon: () => <span></span> };

const SERIAL_NUMBER_ICON = { icon: NumbersOutlinedIcon };
const VERSION_ICON = { icon: MemoryOutlinedIcon };

export const CORE_TAG_ICONS: Record<CoreTagsKeys, IconObj> = {
	[CoreTagsKeys.Application]: { icon: MonitorIcon },
	[CoreTagsKeys.Location]: { icon: LocationOnIcon },
	[CoreTagsKeys.Role]: { icon: BadgeIcon },
	[CoreTagsKeys.Owner]: { icon: ManageAccountsIcon },
	[CoreTagsKeys.Environment]: { icon: CloudQueueIcon },
	[CoreTagsKeys.UserGroup]: { icon: GroupsOutlinedIcon },
	[CoreTagsKeys.Department]: { icon: Diversity3OutlinedIcon },
	[CoreTagsKeys.PrimarySubnet]: { icon: LanguageOutlinedIcon },
	[CoreTagsKeys.KernelVersion]: { icon: MemoryOutlinedIcon },
	[CoreTagsKeys.SerialNumber]: SERIAL_NUMBER_ICON,
	[CoreTagsKeys.Model]: DEFAULT_TAG_ICON,
	[CoreTagsKeys.Category]: DEFAULT_TAG_ICON,
	[CoreTagsKeys.SubCategory]: DEFAULT_TAG_ICON,
	[CoreTagsKeys.Manufacturer]: DEFAULT_TAG_ICON,
	[CoreTagsKeys.BusinessValue]: DEFAULT_TAG_ICON,
	[CoreTagsKeys.ClusterIdentifier]: DEFAULT_TAG_ICON,
	[CoreTagsKeys.ContainerNamespace]: DEFAULT_TAG_ICON,
};

export const MISC_TAG_ICONS = {
	businessValue: { icon: AttachMoneyIcon },
	ipAddress: { icon: DnsIcon },
	macAddress: { icon: DnsIcon },
	vendorInfo: { icon: StoreIcon },
};

export const DEFAULT_OS_ICON: IconObj = { icon: ChangeHistoryIcon };

const solarisIcon = SolarisIcon;

export const OS_ICON_MAP: Record<OSType, IconObj> = {
	[OSType.Linux]: {
		icon: LinuxIcon,
		defaultProps: { width: "20px", height: "20px" },
	},
	[OSType.Windows]: {
		icon: WindowsIcon,
		defaultProps: { width: "18px", height: "18px" },
	},
	[OSType.Container]: {
		icon: DockerIcon,
	},
	[OSType.Docker]: {
		icon: DockerIcon,
	},
	[OSType.Darwin]: {
		icon: AppleIcon,
	},
	[OSType.AIX]: {
		icon: AIXIcon,
		defaultProps: { width: "20px", height: "20px" },
	},
	[OSType.Solaris]: {
		icon: solarisIcon,
	},
	[OSType.SunOS]: {
		icon: solarisIcon,
	},
};

export const UNMANAGED_DEVICE_CORE_TAG_ICONS: Record<
	UnmanagedDeviceCoreTagKey,
	IconObj
> = {
	[UnmanagedDeviceCoreTagKey.DEVICE_CATEGORY]: DEFAULT_TAG_ICON,
	[UnmanagedDeviceCoreTagKey.DEVICE_MANUFACTURER]: DEFAULT_TAG_ICON,
	[UnmanagedDeviceCoreTagKey.DEVICE_MODEL]: DEFAULT_TAG_ICON,
	[UnmanagedDeviceCoreTagKey.DEVICE_PRODUCT_VERSION]: VERSION_ICON,
	[UnmanagedDeviceCoreTagKey.DEVICE_SERIAL_NUMBER]: SERIAL_NUMBER_ICON,
	[UnmanagedDeviceCoreTagKey.DEVICE_SUBCATEGORY]: DEFAULT_TAG_ICON,
};

export const NAVBAR_ICONS: Record<NavMainItemKey, IconObj> = {
	[NavMainItemKey.DASHBOARD]: { icon: SpeedOutlinedIcon },
	[NavMainItemKey.VISUALIZER]: { icon: VisibilityOutlinedIcon },
	[NavMainItemKey.ASSETS]: { icon: ListAltOutlinedIcon },
	[NavMainItemKey.SEGMENTS]: { icon: HiveOutlined },
	[NavMainItemKey.USER_GROUPS]: { icon: GroupOutlined },
	[NavMainItemKey.NETWORK_DATA]: { icon: CableOutlined },
	[NavMainItemKey.TEMPLATES]: { icon: TemplateIcon },
	[NavMainItemKey.NAMED_NETWORKS]: { icon: DeviceHubOutlined },
	[NavMainItemKey.TAGS]: { icon: LocalOfferOutlinedIcon },
	[NavMainItemKey.MONITORING]: { icon: MonitorHeartOutlined },
	[NavMainItemKey.HOST_GROUPS]: {
		icon: (...args) =>
			CrowdStrikeIcon({ variant: "minimal", className: args[0].className }),
	},
	[NavMainItemKey.SENSORS]: { icon: InstallDesktopOutlined },
	[NavMainItemKey.SETTINGS]: { icon: SettingsOutlined },
};
