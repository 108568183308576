import { GridRowId } from "@mui/x-data-grid-pro";

export enum TaskStatus {
	Pending = "pending",
	InProgress = "in-progress",
	Completed = "completed",
	Failed = "failed",
}

export interface Task<T> {
	id: GridRowId;
	status: TaskStatus;
	data: T;
}

export class StatusUpdateWorker<T, P> {
	private tasks: Task<T>[] = [];
	private isRunning: boolean = false;
	private updateFunction: (
		id: GridRowId,
		data: T,
		params: P
	) => Promise<void | T>;
	private onUpdate: (tasks: Task<T>[], isCompleted: boolean) => void;
	private onProgress: (progress: number) => void;
	private concurrency: number;

	constructor({
		update,
		onUpdate,
		onProgress,
		concurrency = 1,
	}: {
		update: (id: GridRowId, data: T, params: P) => Promise<void | T>;
		onUpdate: (tasks: Task<T>[], isCompleted: boolean) => void;
		onProgress: (progress: number) => void;
		concurrency?: number;
	}) {
		this.updateFunction = update;
		this.onUpdate = onUpdate;
		this.onProgress = onProgress;
		this.concurrency = concurrency;
	}

	public addTasks(items: { id: GridRowId; data: T }[]) {
		const newTasks = items.map(item => ({
			...item,
			status: TaskStatus.Pending,
		}));
		this.tasks.push(...newTasks);
	}

	private calculateProgress(): number {
		const completedTasks = this.tasks.filter(
			task =>
				task.status === TaskStatus.Completed ||
				task.status === TaskStatus.Failed
		).length;
		return Math.round((completedTasks / this.tasks.length) * 100);
	}

	private async processBatch(batch: Task<T>[], params: P) {
		for (const task of batch) {
			task.status = TaskStatus.InProgress;
			this.onUpdate([...this.tasks], false);

			try {
				await this.updateFunction(task.id, task.data, params);
				task.status = TaskStatus.Completed;
			} catch (error) {
				console.error(`Error updating task ${task.id}:`, error);
				task.status = TaskStatus.Failed;
			}

			this.onUpdate([...this.tasks], false);
			this.onProgress(this.calculateProgress());
		}
	}

	public async run(params: P) {
		if (this.isRunning) {
			return;
		}

		this.isRunning = true;
		this.onProgress(0);

		while (this.tasks.some(task => task.status === TaskStatus.Pending)) {
			const batch = this.tasks
				.filter(task => task.status === TaskStatus.Pending)
				.slice(0, this.concurrency);

			await this.processBatch(batch, params);
		}

		this.isRunning = false;
		this.onUpdate([...this.tasks], true);
		this.onProgress(100);
	}

	public setConcurrency(concurrency: number) {
		this.concurrency = concurrency;
	}
}
