import {
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { ColorModeContext } from "assets/ctTheme/CTThemeProvider";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useContext, useMemo } from "react";
import { ConfigCard } from "../common/components/config-card";
import { Configurations } from "../configurations";

const LANGUAGE_OPTIONS = [
	{ langCode: "en", label: "English" },
	{
		langCode: "ja",
		label: "日本語",
	},
];

export function General() {
	const colorMode = useContext(ColorModeContext);
	const theme = useTheme();

	const { userPermissions, isLoading: isUserPermissionsLoading } =
		useUserPermissionsStore();

	const showConfigurations =
		isUserPermissionsLoading ||
		(userPermissions.has("UPDATE_ALERTRULES") ??
			userPermissions.has("UPDATE_TENANT_AGENT_SECRET"));

	const selectedLang = window.CTLangCode;
	const selectedLanguage = useMemo(
		() => LANGUAGE_OPTIONS.find(opt => opt.langCode === selectedLang)?.label,
		[selectedLang]
	);

	return (
		<>
			<Stack className="content" gap="24px" width="66%">
				{showConfigurations && <Configurations />}
				<ConfigCard title="Theme">
					<Stack
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack>
							<Typography variant="body2">
								{window.getCTTranslatedText(
									"Personalize the appearance to match your style"
								)}
							</Typography>
						</Stack>
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="row-radio-buttons-group"
								value={theme.palette.mode}
								onChange={colorMode.toggleColorMode}
							>
								<FormControlLabel
									value="light"
									control={<Radio />}
									label="Light"
								/>
								<FormControlLabel
									value="dark"
									control={<Radio />}
									label="Dark"
								/>
							</RadioGroup>
						</FormControl>
					</Stack>
				</ConfigCard>
				<ConfigCard title="Language">
					<Stack
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack>
							<Typography variant="body2">
								{window.getCTTranslatedText(
									"Select your preferred language for the product"
								)}
							</Typography>
						</Stack>
						<FormControl
							variant="standard"
							sx={{ width: "fit-content", minWidth: 150, m: 0, mt: 1 }}
						>
							<Select size="medium" value={selectedLanguage}>
								{LANGUAGE_OPTIONS.map(option => {
									return (
										<MenuItem
											key={option.label}
											value={option.label}
											onClick={() => window.changeLanguage(option.langCode)}
										>
											{option.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Stack>
				</ConfigCard>
			</Stack>
		</>
	);
}
