import { useDemoController } from "hooks/useDemoController";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ROUTES } from "routes";
import { create } from "zustand";
import {
	NavMainItemKey,
	NavMonitoringSubItemKey,
	NavNetworkDataSubItemKey,
	NavSensorsSubItemKey,
	NavSettingsSubItemKey,
	NavTagsSubItemKey,
} from "./constants";
import { NavBottomItems, NavTopItems } from "./types";

type NavSidebarStore = {
	subMenuAnchor: HTMLElement | null;
	selectedMainNavItemKey: NavMainItemKey;
	setSubMenuAnchor: (anchorEl: HTMLElement | null) => void;
	setSelectedMainNavItemKey: (selectedMainNavItemKey: NavMainItemKey) => void;
	closeSubMenu: () => void;
	reset: () => void;
};

const useStore = create<NavSidebarStore>(set => {
	const initState = {
		subMenuAnchor: null,
		selectedMainNavItemKey: NavMainItemKey.DASHBOARD,
	};

	return {
		subMenuAnchor: null,
		selectedMainNavItemKey: NavMainItemKey.DASHBOARD,
		setSubMenuAnchor: (anchorEl: HTMLElement | null) =>
			set({
				subMenuAnchor: anchorEl,
			}),
		setSelectedMainNavItemKey: (selectedMainNavItemKey: NavMainItemKey) =>
			set({ selectedMainNavItemKey }),
		closeSubMenu: () => set({ subMenuAnchor: null }),
		reset: () => set(initState),
	};
});

const useNavSidebarStore = () => {
	const storeObj = useStore();
	const { isDemo } = useDemoController();
	const { isFeatureEnabled: isPanopticMapfeatureEnabled } =
		useFeatureFlagControl(FEATURES.PANOPTIC_MAP);
	const { isFeatureEnabled: isScimFeaturesEnabled } = useFeatureFlagControl(
		FEATURES.USER_SEGMENTATION
	);
	const { isFeatureEnabled: isCrowdStrikeFeatureEnabled } =
		useFeatureFlagControl(FEATURES.CROWDSTRIKE_EDR);
	const { userPermissions } = useUserPermissionsStore();

	const topItems: NavTopItems = {
		[NavMainItemKey.DASHBOARD]: {
			label: "Dashboard",
			path: ROUTES.DASHBOARD.pathname,
		},
		[NavMainItemKey.VISUALIZER]: {
			label: "Visualizer",
			path: ROUTES.VISUALIZER.pathname,
			permission: isPanopticMapfeatureEnabled,
		},
		[NavMainItemKey.ASSETS]: {
			label: "Assets",
			path: ROUTES.ASSETS.pathname,
		},
		[NavMainItemKey.SEGMENTS]: {
			label: "Segments",
			path: ROUTES.SEGMENT.pathname,
		},
		[NavMainItemKey.USER_GROUPS]: {
			label: "User_Groups",
			path: ROUTES.USER_SEGMENTATION.pathname,
			permission: isScimFeaturesEnabled,
		},
		[NavMainItemKey.NETWORK_DATA]: {
			label: "Network Data",
			subItems: {
				[NavNetworkDataSubItemKey.PORTS]: {
					label: "Ports",
					path: ROUTES.PORTS.pathname,
				},
				[NavNetworkDataSubItemKey.PATHS]: {
					label: "Paths",
					path: ROUTES.PATHS.pathname,
				},
			},
		},
		[NavMainItemKey.TEMPLATES]: {
			label: "Templates",
			path: ROUTES.TEMPLATES.pathname,
		},
		[NavMainItemKey.NAMED_NETWORKS]: {
			label: "Named Networks",
			path: ROUTES.NETWORKS.pathname,
		},
		[NavMainItemKey.TAGS]: {
			label: "Tags",
			subItems: {
				[NavTagsSubItemKey.TAG_RULES]: {
					label: "Tag Rules",
					path: ROUTES.TAG_LABEL_RULES.pathname,
				},
				[NavTagsSubItemKey.TAG_NAMES]: {
					label: "Tag Names",
					path: ROUTES.TAG_NAMES.pathname,
				},
			},
		},
		[NavMainItemKey.MONITORING]: {
			label: "Monitor",
			subItems: {
				[NavMonitoringSubItemKey.ALERTS]: {
					label: "Alerts",
					path: ROUTES.MONITORING_ALERTS.pathname,
				},
				[NavMonitoringSubItemKey.LOGS]: {
					label: "Logs",
					path: ROUTES.MONITORING_LOGS.pathname,
					permission: userPermissions.has("LIST_EVENT"),
				},
				[NavMonitoringSubItemKey.SYSTEM_TASKS]: {
					label: "System Tasks",
					path: ROUTES.MONITORING_BG_PROCESSES.pathname,
				},
			},
		},
	};

	const bottomItems: NavBottomItems = {
		[NavMainItemKey.HOST_GROUPS]: {
			label: "Host Groups",
			path: ROUTES.CROWDSTRIKE_GROUPS.pathname,
			permission: isCrowdStrikeFeatureEnabled,
		},
		[NavMainItemKey.SENSORS]: {
			label: "Sensors",
			subItems: {
				[NavSensorsSubItemKey.AGENTS]: {
					label: "Agents",
					path: ROUTES.AGENTS.pathname,
				},
				[NavSensorsSubItemKey.GATEKEEPERS]: {
					label: "Gatekeepers",
					path: ROUTES.APPLIANCES.pathname,
				},
				[NavSensorsSubItemKey.CONTAINERS]: {
					label: "Containers",
					path: ROUTES.CLUSTERS.pathname,
				},
			},
		},
		[NavMainItemKey.SETTINGS]: {
			label: "Settings",
			subItems: {
				[NavSettingsSubItemKey.GENERAL]: {
					label: "General",
					path: ROUTES.SETTINGS_GENERAL.pathname,
				},
				[NavSettingsSubItemKey.USER_MANAGEMENT]: {
					label: "User Management",
					path: ROUTES.SETTINGS_ORGANIZATION.pathname,
					permission: !isDemo && userPermissions.has("LIST_SUBJECT"),
				},
				[NavSettingsSubItemKey.INTEGRATIONS]: {
					label: "Integrations",
					path: ROUTES.SETTINGS_INTEGRATIONS.pathname,
					permission: !isDemo && userPermissions.has("LIST_INTEGRATION"),
				},
				[NavSettingsSubItemKey.ALERT_RULES]: {
					label: "Alert Rules",
					path: ROUTES.SETTINGS_ALERTS.pathname,
					permission: userPermissions.has("UPDATE_ALERTRULES"),
				},
				[NavSettingsSubItemKey.API_KEYS]: {
					label: "API Keys",
					path: ROUTES.SETTINGS_APIS.pathname,
					permission: !isDemo && userPermissions.has("LIST_USER_API_KEY"),
				},
			},
		},
	};

	const navItems = { ...topItems, ...bottomItems };

	return { navItems, topItems, bottomItems, ...storeObj };
};

export default useNavSidebarStore;
