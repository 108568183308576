import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Template } from "pages/templates/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TemplateToolBar } from "../template-data-grid-toolbar";
import {
	DOWNLOAD_CSV_COLUMNS,
	ENDPOINT_TEMPLATE_COLUMNS,
	TemplateColumnConfig,
	TEMPLATES_COLUMNS,
} from "./constants";

interface TemplateDataGridProps extends DataGridProps<Template> {
	showProgress?: boolean;
}

export const TemplateDataGrid = (props: TemplateDataGridProps) => {
	const [selection, setSelection] = useState<Array<GridRowId>>(
		props.selectedRawData?.map((row: Template) => row.templateId) ?? []
	);

	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const { isFeatureEnabled: isUserGroupsColumnEnabled } = useFeatureFlagControl(
		FEATURES.USER_GROUP_COLUMN
	);

	const templateColumns = isUserGroupsColumnEnabled
		? ENDPOINT_TEMPLATE_COLUMNS
		: TEMPLATES_COLUMNS;

	const columns = useMemo(() => {
		if (props.showProgress) {
			return DOWNLOAD_CSV_COLUMNS;
		}

		let selectedColumns = userPermissions.has("DELETE_NAMED_NETWORK")
			? templateColumns
			: templateColumns.filter(col => col.field !== "action");

		return selectedColumns;
	}, [props.showProgress, userPermissions, templateColumns]);

	useEffect(() => {
		const tempSelections: GridRowId[] = (props.selectedRawData || []).map(
			item => item.templateId
		);
		setSelection(tempSelections);
	}, [props.selectedRawData]);

	const onClearSelection = () => {};

	const renderToolbar = useCallback(
		() => <TemplateToolBar hideToolbar={onClearSelection} />,
		[]
	);

	const selectedRawData: Array<Template> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: Template) => {
			return selection.indexOf(row.templateId) !== -1;
		});
	}, [props?.rawData, selection]);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={TemplateColumnConfig.PinnedColumns}
					checkboxSelection={!props.showProgress}
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ templateId }: Template) => templateId}
					paginationMode="server"
					sortingMode="server"
					rowSelectionModel={selection}
					onRowSelectionModelChange={
						props.showProgress
							? undefined
							: selectionModel => {
									setSelection(selectionModel);
								}
					}
					{...props}
					selectedRawData={selectedRawData}
					renderToolbar={props.showProgress ? undefined : renderToolbar}
				/>
			</Box>
		</Stack>
	);
};
