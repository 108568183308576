import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { CTGuardrail, CTGuardrailTraffic } from "common/atoms/ct-guardrail";
import { CurrentStatuses } from "common/atoms/ct-guardrail/CTGuardrail";
import { GuardrailCheckbox } from "common/atoms/ct-guardrail/GuardrailCheckbox";
import { useGuardrailsStore } from "common/atoms/ct-guardrail/store";
import {
	Guardrail,
	OutputData,
	SelectedValues,
} from "common/atoms/ct-guardrail/types";
import { parseErrorMessage } from "common/utils";
import { Annotation } from "modules/annotation";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { DiffViewerButton } from "modules/policy-diff-viewer/DiffViewerButton";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDebouncedSnackbar } from "modules/snackbar/store/useDebouncedSnackbar";
import numeral from "numeral";
import { AssetStatus } from "pages/assets/types";
import { usePathsAPI } from "pages/paths/components/path-data-grid/hooks";
import { usePortsAPI } from "pages/ports/components/port-data-grid/hooks";
import { getEnforcementStatus } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/PolicyAutomationDrawer";
import {
	PolicyChangeType,
	PolicyUnreviewedTraffic,
	Traffic,
	UnreviewedTraffic,
} from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";
import {
	buildPathsCriteria,
	buildPortsCriteria,
	combineCriteria,
} from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/utils";
import pluralize from "pluralize";
import { useCallback, useEffect, useState } from "react";
import {
	AlertMessages,
	AssetStatusReverseMap,
	Direction,
	SecurityStatus,
} from "../../constants";
import { useZeroTrustAPI } from "../../hooks/use-update-status";
import {
	ActionBarModes,
	AssetStatusActionProps,
	DeployStatusChangeProps,
	StatusChangeProps,
	StatusChangeReqBodyProps,
} from "./types";

export const AssetStatusAction = ({
	asset,
	direction,
	currentStatus,
	selectedStatus,
	statusChangeCallback,
	disabled,
	criteria,
	mode = ActionBarModes.DEFAULT,
	comment,
	minValue,
	setComment,
	setIsCommentDrawerOpen,
	isCommentDrawerOpen,
	useAssetStore,
	isPreviewMode = false,
	isCommentsVisible = false,
	isTestMode,
	onChangeTestMode,
	hasPermission = true,
}: AssetStatusActionProps) => {
	const theme = useTheme();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const { setDebouncedSnackbar } = useDebouncedSnackbar();
	const updateDeployMutation = useZeroTrustAPI();
	const updateTestModeMutation = useZeroTrustAPI();
	const portsMutation = usePortsAPI();
	const pathsMutation = usePathsAPI();
	const [isDiffViewerDrawerOpen, setIsDiffViewerDrawerOpen] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
	const [trafficReviewCriteria, setTrafficReviewCriteria] = useState<Traffic>();
	const [unreviewedTraffic, setUnreviewedTraffic] =
		useState<PolicyUnreviewedTraffic>();
	const [guardrails, setGuardrails] = useState<Guardrail[]>([]);
	const [acknowledgement, setAcknowledgement] = useState<boolean>(false);

	const setGuardrailTraffic = useGuardrailsStore(
		state => state.setGuardrailTraffic
	);

	let assetStatusValue: AssetStatus = AssetStatusReverseMap[selectedStatus];
	let simulateStatus: boolean = false;

	const enforcementType =
		direction === Direction.Inbound
			? PolicyChangeType.AttackSurfaceEnforcement
			: PolicyChangeType.BlastRadiusEnforcement;

	switch (direction) {
		case Direction.Inbound:
			simulateStatus = Boolean(asset?.simulateInboundAssetStatus);
			break;
		case Direction.Outbound:
			simulateStatus = Boolean(asset?.simulateOutboundAssetStatus);
			break;
	}

	function getUnreviewedEntities() {
		let portCriteria = "";
		let assetsCriteria = `assetname in ('${asset?.assetName}')`;

		if (direction === Direction.Inbound) {
			//get ports criteria
			portCriteria = combineCriteria(
				assetsCriteria,
				buildPortsCriteria(
					enforcementType,
					getEnforcementStatus(selectedStatus, Boolean(isTestMode))
				)
			);
		}

		//get paths criteria
		const pathCriteria = combineCriteria(
			combineCriteria(
				assetsCriteria,
				`'direction' in ('${direction === Direction.Inbound ? "inbound" : "outbound"}')`
			),
			buildPathsCriteria(
				enforcementType,
				getEnforcementStatus(selectedStatus, Boolean(isTestMode))
			)
		);

		return {
			ports: portCriteria,
			paths: pathCriteria,
			assets: assetsCriteria,
		};
	}

	const triggerDeployMode = ({ status }: DeployStatusChangeProps) => {
		setUnreviewedTraffic(undefined);
		if (
			status !== AssetStatus.Unsecured &&
			status !== AssetStatus.SimulateSecureInternet
		) {
			let trafficReviewCriteria: Traffic = getUnreviewedEntities();
			setTrafficReviewCriteria(trafficReviewCriteria);
			return;
		}
		setShowConfirmDialog(true);
	};

	const buildReqBody = ({
		direction,
		status,
		simulationMode,
	}: StatusChangeReqBodyProps) => {
		if (simulationMode) {
			status = `simulate-${status}` as AssetStatus;
		}

		interface BodyProps {
			criteria: string;
			inboundToState?: AssetStatus;
			outboundToState?: AssetStatus;
			comment: string | null;
		}

		const body: BodyProps = {
			criteria: criteria || `assetId in ('${asset?.assetId}')`,
			comment: comment ?? null,
		};

		if (direction === Direction.Inbound) {
			body["inboundToState"] = status;
		} else if (direction === Direction.Outbound) {
			body["outboundToState"] = status;
		}
		return body;
	};

	const confirmTestMode = async ({ direction, status }: StatusChangeProps) => {
		const body = buildReqBody({ direction, status, simulationMode: true });

		setDebouncedSnackbar(
			true,
			SnackBarSeverity.Info,
			window.getCTTranslatedText("debouncedSnackbarText")
		);

		await updateTestModeMutation.mutateAsync(body, {
			onSuccess: response => {
				setDebouncedSnackbar.cancel();
				setComment?.(undefined);
				statusChangeCallback();
				setIsDiffViewerDrawerOpen(false);
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UpdatedAssetStatusToSimulateStatusSuccessfully",
					{ status: window.getCTTranslatedText(`simulate-${status}`) }
				);
				resetGuardrails();
			},
			onError: error => {
				setDebouncedSnackbar.cancel();
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
		setShowConfirmDialog(false);
	};

	const confirmDeployMode = async ({
		direction,
		status,
	}: StatusChangeProps) => {
		const body = buildReqBody({ direction, status, simulationMode: false });

		setDebouncedSnackbar(
			true,
			SnackBarSeverity.Info,
			window.getCTTranslatedText("debouncedSnackbarText")
		);

		await updateDeployMutation.mutateAsync(body, {
			onSuccess: response => {
				setDebouncedSnackbar.cancel();
				setComment?.(undefined);
				statusChangeCallback();
				setIsDiffViewerDrawerOpen(false);
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UpdatedAssetStatusSuccessfully",
					{ status: window.getCTTranslatedText(status) }
				);
				resetGuardrails();
			},
			onError: error => {
				setDebouncedSnackbar.cancel();
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
		setShowConfirmDialog(false);
		setGuardrails([]);
		setUnreviewedTraffic(undefined);
	};

	const updateUnreviewedTraffic = (
		policyChangeId: PolicyChangeType,
		traffic: UnreviewedTraffic
	) => {
		traffic.assets ??= 0;
		if (unreviewedTraffic?.[policyChangeId]?.assets !== traffic?.assets) {
			setUnreviewedTraffic(prev => ({ ...prev, [policyChangeId]: traffic }));
		}
	};

	useEffect(() => {
		if (
			unreviewedTraffic &&
			unreviewedTraffic[enforcementType]?.assets &&
			trafficReviewCriteria
		) {
			let guardrails: Guardrail[] = [];
			guardrails.push({
				id: enforcementType,
				baseCriteria: trafficReviewCriteria,
			});
			setGuardrails(guardrails);
		} else if (unreviewedTraffic && trafficReviewCriteria) {
			setShowConfirmDialog(true);
		}
	}, [unreviewedTraffic, trafficReviewCriteria, enforcementType]);

	const resetGuardrails = useCallback(() => {
		setGuardrails([]);
		setTrafficReviewCriteria(undefined);
		setGuardrailTraffic({});
		setUnreviewedTraffic(undefined);
		setAcknowledgement(false);
	}, [
		setGuardrails,
		setTrafficReviewCriteria,
		setGuardrailTraffic,
		setUnreviewedTraffic,
		setAcknowledgement,
	]);

	useEffect(() => {
		resetGuardrails();
	}, [selectedStatus, isTestMode, resetGuardrails]);

	const handleConfirmDialog = (dialogVisibility: boolean) => {
		setShowConfirmDialog(dialogVisibility);
	};

	const totalComments =
		direction === Direction.Inbound
			? asset?.totalInboundComments
			: asset?.totalOutboundComments;

	const params = `securityStatus=${
		direction === Direction.Inbound ? "attacksurface" : "blastradius"
	}`;

	const isStatusUnchanged = () => {
		return (
			getEnforcementStatus(selectedStatus, Boolean(isTestMode)) ===
			(direction === Direction.Inbound
				? asset?.inboundAssetStatus
				: asset?.outboundAssetStatus)
		);
	};

	const renderTestModeButton = () => {
		return (
			<LoadingButton
				loading={updateTestModeMutation.isLoading}
				disabled={
					mode === ActionBarModes.DEFAULT &&
					(disabled || (simulateStatus && isStatusUnchanged()))
				}
				sx={{ color: theme.palette.primary.contrastText }}
				color="warning"
				variant="contained"
				onClick={() => {
					triggerDeployMode({
						direction,
						status: assetStatusValue,
						force: false,
					});
				}}
			>
				{window.getCTTranslatedText("Deploy")}
			</LoadingButton>
		);
	};

	const renderDeployButton = () => {
		return (
			<LoadingButton
				loading={
					updateDeployMutation.isLoading ||
					portsMutation.isLoading ||
					pathsMutation.isLoading
				}
				disabled={
					mode === ActionBarModes.DEFAULT && (disabled || isStatusUnchanged())
				}
				color="primary"
				variant="contained"
				onClick={() => {
					triggerDeployMode({
						direction,
						status: assetStatusValue,
						force: false,
					});
				}}
			>
				{window.getCTTranslatedText("Deploy")}
			</LoadingButton>
		);
	};

	const renderComments = () => {
		return (
			<Stack width="100%" display={"block"}>
				<Stack
					sx={{
						maxWidth: "100%",
						cursor: "pointer",
						mt: 1.5,
					}}
					alignItems="center"
					justifyContent="flex-start"
					direction="row"
					onClick={() => setIsCommentDrawerOpen?.(true)}
				>
					<CommentOutlinedIcon fontSize="small" />
					<Typography sx={{ mx: 1 }} variant="subtitle1">
						{totalComments
							? `${numeral(totalComments).format("0a")} ${pluralize(
									window.getCTTranslatedText("Comment"),
									totalComments || 0
								)}`
							: window.getCTTranslatedText("Comment")}
					</Typography>
				</Stack>
				{isCommentDrawerOpen && useAssetStore && (
					<AnnotationDrawer
						isOpen={isCommentDrawerOpen}
						onClose={() => setIsCommentDrawerOpen?.(false)}
						entity={EntityType.Assets}
						entityId={asset?.assetId ?? ""}
						useStore={useAssetStore}
						params={params}
						hasPermission={hasPermission}
					/>
				)}
				{hasPermission && setComment && (
					<Annotation
						hideTitle={true}
						comment={comment ?? ""}
						setComment={setComment}
					/>
				)}
			</Stack>
		);
	};

	const renderTestAction = () => {
		return (
			selectedStatus !== SecurityStatus.Unsecure &&
			selectedStatus >= (minValue ?? 0) &&
			(isPreviewMode ? (
				<>
					<DiffViewerButton
						buttonProps={{
							color: "warning",
							sx: { px: 2 },
							size: "medium",
							disabled:
								mode === ActionBarModes.DEFAULT &&
								(disabled || (simulateStatus && isStatusUnchanged())),
						}}
						handlerCloseMenu={() => {
							setIsDiffViewerDrawerOpen(false);
						}}
						isOpen={isDiffViewerDrawerOpen}
						handlerDiffViewDrawerOpen={() => setIsDiffViewerDrawerOpen(true)}
						buttonTitle="Preview"
						asset={asset}
						direction={direction === Direction.Inbound ? "inbound" : "outbound"}
						variant="outlined"
						isPreviewMode={isPreviewMode}
						inboundToState={
							direction === Direction.Inbound
								? `simulate-${assetStatusValue}`
								: undefined
						}
						outboundToState={
							direction === Direction.Outbound
								? `simulate-${assetStatusValue}`
								: undefined
						}
					>
						<>
							{renderComments()}
							<Stack
								pt={2}
								width={"100%"}
								direction={"row"}
								justifyContent={"flex-end"}
							>
								{renderTestModeButton()}
							</Stack>
						</>
					</DiffViewerButton>
					{renderTestModeButton()}
				</>
			) : (
				renderTestModeButton()
			))
		);
	};

	const renderDeployAction = () => {
		return isPreviewMode ? (
			<>
				<DiffViewerButton
					buttonProps={{
						sx: { px: 2 },
						size: "medium",
						disabled:
							mode === ActionBarModes.DEFAULT &&
							(disabled || isStatusUnchanged()),
					}}
					handlerCloseMenu={() => {
						setIsDiffViewerDrawerOpen(false);
					}}
					isOpen={isDiffViewerDrawerOpen}
					buttonTitle="Preview"
					asset={asset}
					handlerDiffViewDrawerOpen={() => setIsDiffViewerDrawerOpen(true)}
					direction={direction === Direction.Inbound ? "inbound" : "outbound"}
					variant="outlined"
					isPreviewMode={isPreviewMode}
					inboundToState={
						direction === Direction.Inbound ? `${assetStatusValue}` : undefined
					}
					outboundToState={
						direction === Direction.Outbound ? `${assetStatusValue}` : undefined
					}
				>
					<>
						{renderComments()}
						<Stack
							pt={2}
							width={"100%"}
							direction={"row"}
							justifyContent={"flex-end"}
						>
							{renderDeployButton()}
						</Stack>
					</>
				</DiffViewerButton>
				{renderDeployButton()}
			</>
		) : (
			renderDeployButton()
		);
	};

	const handleChange = (isTestChecked: boolean) => {
		onChangeTestMode?.(isTestChecked);
	};

	const guardrailSelectedStatus: SelectedValues = {
		[direction === Direction.Inbound
			? PolicyChangeType.AttackSurfaceEnforcement
			: PolicyChangeType.BlastRadiusEnforcement]:
			AssetStatusReverseMap[selectedStatus],
	};

	const currentStatuses: CurrentStatuses = {
		[enforcementType]: asset?.inboundAssetStatus,
	};

	const renderSecondaryText = () => {
		return (
			<GuardrailCheckbox
				acknowledgement={acknowledgement}
				setAcknowledgement={setAcknowledgement}
			/>
		);
	};

	return (
		<>
			{isCommentsVisible && renderComments()}

			{hasPermission && (
				<Stack
					justifyContent={"space-between"}
					sx={{ width: "100%" }}
					alignContent={"flex-end"}
					alignItems="center"
					direction="row"
					spacing={3}
					pt={4}
				>
					<Stack>
						{selectedStatus !== SecurityStatus.Unsecure && (
							<FormGroup sx={{ mr: 2 }}>
								<FormControl
									sx={{
										margin: 0,
										"&.MuiFormControlLabel-root": { marginRight: 1 },
										"& .MuiFormControlLabel-label": {
											fontSize: theme => theme.typography.body2.fontSize,
											color: theme => theme.palette.text.secondary,
										},
									}}
								>
									<RadioGroup
										row
										aria-labelledby="enforcement mode"
										value={isTestMode ? "test" : "enforce"}
										onChange={e => {
											handleChange(e.target.value === "test");
										}}
										name="enforcement-mode"
									>
										<FormControlLabel
											value="test"
											control={<Radio />}
											label={window.getCTTranslatedText("Test")}
										/>
										<FormControlLabel
											value="enforce"
											control={<Radio />}
											label={window.getCTTranslatedText("Enforce")}
										/>
									</RadioGroup>
								</FormControl>
							</FormGroup>
						)}
					</Stack>

					<Stack direction={"row"} spacing={1}>
						{isTestMode ? renderTestAction() : renderDeployAction()}
					</Stack>
				</Stack>
			)}

			{trafficReviewCriteria?.paths || trafficReviewCriteria?.ports ? (
				<CTGuardrailTraffic
					id={enforcementType}
					showWarning={false}
					baseCriteria={trafficReviewCriteria}
					showAssetCount={false}
					updateTraffic={updateUnreviewedTraffic}
					currentStatus={currentStatuses[enforcementType]}
					selectedStatus={getEnforcementStatus(
						selectedStatus,
						Boolean(isTestMode)
					)}
					updateAggregate={(id: PolicyChangeType, traffic?: OutputData) => {
						if (traffic) {
							setGuardrailTraffic({ [id]: traffic });
						}
					}}
				/>
			) : null}

			<CTGuardrail
				open={Boolean(guardrails?.length)}
				loading={
					updateDeployMutation.isLoading ||
					portsMutation.isLoading ||
					pathsMutation.isLoading
				}
				guardrails={guardrails}
				onClose={() => {
					resetGuardrails();
				}}
				showAssetCount={false}
				currentStatuses={currentStatuses}
				onProceed={() => {
					if (isTestMode) {
						confirmTestMode({ direction, status: assetStatusValue });
					} else {
						confirmDeployMode({ direction, status: assetStatusValue });
					}
					resetGuardrails();
				}}
				selectedValues={guardrailSelectedStatus}
			/>

			<CTConfirmation
				open={showConfirmDialog}
				onClose={() => {
					handleConfirmDialog(false);
					setAcknowledgement(false);
				}}
				isLoading={
					updateDeployMutation.isLoading ||
					portsMutation.isLoading ||
					pathsMutation.isLoading
				}
				title={window.getCTTranslatedText("Deploy Policies")}
				primaryText={AlertMessages.deploy}
				primaryButtonText={window.getCTTranslatedText("Deploy")}
				secondaryButtonText={window.getCTTranslatedText("Cancel")}
				primaryButtonDisabled={
					updateDeployMutation.isLoading ||
					portsMutation.isLoading ||
					pathsMutation.isLoading ||
					!acknowledgement
				}
				secondaryText={renderSecondaryText()}
				onSuccess={() => {
					if (isTestMode) {
						confirmTestMode({ direction, status: assetStatusValue });
					} else {
						confirmDeployMode({ direction, status: assetStatusValue });
					}
				}}
			/>
		</>
	);
};
