export enum VulnerabilityTypes {
	Vulnerabilities = "Vulnerabilities",
	KnownExploit = "deviceKnownExploitVulnerability",
	LateralMovement = "deviceLateralMovementVulnerability",
}

export enum VulnerabilityTypeFacets {
	deviceKnownExploitVulnerability = "deviceknownexploitvulnerability",
	deviceLateralMovementVulnerability = "devicelateralmovementvulnerability",
}
