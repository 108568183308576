import { create } from "zustand";
import { UserPermissionsInterface } from "./types";

export const useUserPermissionsStore = create<UserPermissionsInterface>(
	set => ({
		isLoading: true,
		userPermissions: new Map(),
		setPermissions: (userPermissions: Array<string>) => {
			set(state => {
				let newPermissions = new Map(state.userPermissions);

				userPermissions.forEach((permission: string) => {
					newPermissions.set(permission, true);
				});

				return {
					isLoading: false,
					userPermissions: newPermissions,
				};
			});
		},
	})
);
