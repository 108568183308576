import { Stack, Typography } from "@mui/material";
import { CustomCommonStoreType } from "common/types/types";

import { CoreResponse } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { SavedQueryList } from "modules/saved-query/components/saved-query-list";
import { Scope } from "modules/scope-metadata/types";
import { AddCriteria } from "pages/create-tag-rule/components/add-criteria";
import { AddCriteriaProps } from "pages/create-tag-rule/components/add-criteria/AddCriteria";
import { DisplayAppliedRule } from "pages/create-tag-rule/components/display-applied-rule";
import { SEGMENTS_READ_ONLY_DIMENSIONS } from "pages/tags/components/tag-policy-list/constants";

export function PolicySelectionForm({
	coreResponse,
	useFacetStore,
	sortDisplayByName,
	viewOnly,
	header,
	addCriteriaProps,
	hideSavedQuery,
	title = "Policy Criteria",
	subTitle,
	addCriteriaBtnTitle = "Add policy criteria",
	addCriteriaDialogTitle = "Create Policy Criteria",
}: {
	header?: React.ReactElement;
	coreResponse:
		| CoreResponse<Scope.TagPolicy>
		| CoreResponse<Scope.CrowdStrikeFirewallHostGroup>;
	useFacetStore: CustomCommonStoreType;
	sortDisplayByName?: boolean;
	viewOnly?: boolean;
	addCriteriaProps?: Partial<AddCriteriaProps>;
	hideSavedQuery?: boolean;
	title?: string;
	subTitle?: string;
	addCriteriaDialogTitle?: string;
	addCriteriaBtnTitle?: string;
}) {
	return (
		<>
			<Stack>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent={"space-between"}
					sx={{
						width: "100%",
					}}
				>
					<Stack alignItems="flex-start">
						{header ?? (
							<Typography variant="subtitle1">
								{window.getCTTranslatedText(title)}
							</Typography>
						)}
						{subTitle && (
							<Typography
								variant="caption"
								color={theme => theme.palette.text.secondary}
							>
								{window.getCTTranslatedText(subTitle)}
							</Typography>
						)}
					</Stack>

					<Stack alignItems="center" direction="row" spacing={2}>
						<AddCriteria
							{...addCriteriaProps}
							useFacetStore={useFacetStore}
							facetsOpen={coreResponse.facetsOpen}
							setFacetsOpen={coreResponse.setFacetsOpen}
							dialogTitle={addCriteriaDialogTitle}
							btnTitle={addCriteriaBtnTitle}
							viewOnly={viewOnly}
							readOnlyDimensions={SEGMENTS_READ_ONLY_DIMENSIONS}
						>
							<FacetControllerGroup
								useFacetStore={useFacetStore}
								config={coreResponse.facetConfig}
								value={coreResponse.facetState}
								onChange={coreResponse.updateFacet}
								isSelection={true}
								readOnlyDimensions={SEGMENTS_READ_ONLY_DIMENSIONS}
								showSelectionToggle={true}
							/>
						</AddCriteria>
						{!viewOnly && hideSavedQuery !== true && (
							<SavedQueryList
								useFacetStore={useFacetStore}
								showButtonTrigger={true}
								disableSearch={true}
							/>
						)}
					</Stack>
				</Stack>

				<DisplayAppliedRule
					useFacetStore={useFacetStore}
					minRequired={1}
					sortByName={sortDisplayByName}
					viewOnly={viewOnly}
					readOnlyDimensions={SEGMENTS_READ_ONLY_DIMENSIONS}
				/>
			</Stack>
		</>
	);
}
