import CloseIcon from "@mui/icons-material/Close";
import {
	Alert,
	Box,
	Checkbox,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { DataGrid } from "modules/data-grid/components/data-grid";
import {
	AlertNotificationStatus,
	AlertRuleConfig,
	AlertRuleStatus,
} from "../../types";
import { BASE_ALERTCONFIG_COLUMNS } from "../alert-config-data-grid/constants";
import { AlertRuleStatusActionBar } from "../alert-rule-status-action-bar";

interface AlertRuleChangeStatusDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	selectedData: Array<AlertRuleConfig> | undefined;
	selectedStatus: AlertNotificationStatus | undefined;
	confirm: () => void;
	title?: string;
}

export const AlertRuleChangeStatusDrawer = ({
	isOpen,
	onClose,
	selectedStatus,
	selectedData,
	title,
	confirm,
}: AlertRuleChangeStatusDrawerProps) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const [isSnoozedWarningChecked, setIsSnoozedWarningChecked] = useState(false);

	const snoozedRows = useMemo(() => {
		return selectedData?.filter(
			(row: AlertRuleConfig) =>
				row.alertRuleStatus === AlertRuleStatus.Snoozed &&
				selectedStatus === AlertNotificationStatus.Enabled
		);
	}, [selectedData, selectedStatus]);

	const isCheckboxDisabled = useMemo(() => {
		return (
			(snoozedRows?.filter(row => selection.includes(row.alertRuleId)).length ??
				0) <= 0
		);
	}, [snoozedRows, selection]);

	const hasSnoozedRows = (snoozedRows?.length ?? 0) > 0;

	useEffect(() => {
		if (selectedData?.length) {
			const selectedGroupMap: string[] = selectedData
				.filter((row: AlertRuleConfig) =>
					selectedStatus === AlertNotificationStatus.Enabled
						? row.alertRuleStatus !== AlertRuleStatus.Enabled
						: row.alertRuleStatus !== AlertRuleStatus.Disabled
				)
				.map((row: AlertRuleConfig) => row.alertRuleId);

			setSelection(selectedGroupMap);
		}
	}, [selectedData, selectedStatus]);

	const confirmAction = () => {
		confirm();
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
				<Typography variant="h5">
					<b>{window.getCTTranslatedText(title ?? "")}</b>
				</Typography>
				<Typography variant="h5">{`: ${window.getCTTranslatedText(
					selectedStatus ?? ""
				)}`}</Typography>
			</Stack>
			<DialogContent sx={{ width: "100%", height: "100%" }}>
				<Stack sx={{ height: "100%", overflow: "hidden" }}>
					<Box sx={{ height: "100%", overflow: "hidden" }}>
						<DataGrid
							rowHeight={64}
							columns={BASE_ALERTCONFIG_COLUMNS}
							pagination={true}
							getRowId={(alert: AlertRuleConfig) => `${alert.alertRuleId}`}
							paginationMode="client"
							sortingMode="client"
							checkboxSelection
							isRowSelectable={data =>
								selectedStatus === AlertNotificationStatus.Enabled
									? data.row.alertRuleStatus !== AlertRuleStatus.Enabled
									: data.row.alertRuleStatus !== AlertRuleStatus.Disabled
							}
							rowSelectionModel={selection}
							onRowSelectionModelChange={selectionModel => {
								setSelection(selectionModel);
							}}
							rows={selectedData}
							rowCount={selectedData?.length ?? 0}
							selectedRawData={selectedData}
							initialState={{
								sorting: {
									sortModel: [{ field: "groupName", sort: "desc" }],
								},
							}}
						/>
					</Box>

					{hasSnoozedRows && (
						<Alert
							sx={{ width: "100%", mt: 3, alignItems: "center" }}
							severity="warning"
							icon={
								<Checkbox
									checked={isSnoozedWarningChecked}
									onChange={() => setIsSnoozedWarningChecked(prev => !prev)}
									inputProps={{ "aria-label": "controlled" }}
									color="warning"
									disabled={isCheckboxDisabled}
								/>
							}
						>
							<Typography
								variant="body2"
								onClick={() => {
									if (isCheckboxDisabled) {
										return;
									}
									setIsSnoozedWarningChecked(prev => !prev);
								}}
								sx={{ cursor: isCheckboxDisabled ? undefined : "pointer" }}
							>
								{window.getCTTranslatedText("SnoozedAlertReEnableWarning")}
							</Typography>
						</Alert>
					)}
				</Stack>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<AlertRuleStatusActionBar
					selectedStatus={selectedStatus}
					selection={selection}
					isValid={isCheckboxDisabled ? true : isSnoozedWarningChecked}
					update={confirmAction}
					cancel={onClose}
				/>
			</DialogActions>
		</Drawer>
	);
};
