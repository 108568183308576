import { Paper, useTheme } from "@mui/material";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useCore } from "modules/core";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { useCoreTagsFacetOrder } from "pages/create-tag-policy/CreateTagPolicy";
import { CrowdStrikeEditPrecedence } from "pages/crowdstrike-groups/firewall-host-groups/edit-precedence";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { useEffect, useState } from "react";
import { ROUTES } from "routes";
import { SEGMENTS_TABS, tabBasedPolicyColumnConfig } from "../../constants";
import {
	useCrowdStrikeTagPolicyFacetStore,
	useCrowdStrikeTagPolicyStore,
} from "../../store";
import { useTagPolicyListAPI } from "../../TagPolicyList";
import { CrowdStrikeTagPolicy } from "../../types";
import { TagPolicyDataGrid } from "../TagPolicyDataGrid";

function CrowdStrikeSegmentsEditPrecedence() {
	const theme = useTheme();

	const [orderedRows, setOrderedRows] = useState<CrowdStrikeTagPolicy[]>([]);

	const facetOrder = useCoreTagsFacetOrder();

	const coreResponse = useCore<CrowdStrikeTagPolicy>({
		useStore: useCrowdStrikeTagPolicyStore,
		useFacetStore: useCrowdStrikeTagPolicyFacetStore,
		facetGroupInfo: facetOrder,
		scope: Scope.TagBasedPolicy,
		useApi: () => useTagPolicyListAPI(ManagedByValue.CrowdStrike),
		pageSize: 10000,
		additionalCriteria:
			SEGMENTS_TABS[ManagedByValue.CrowdStrike].additionalCriteria,
		dataMapper: (obj, i) => ({
			...obj,
			__reorder__:
				obj.crowdstrikeFirewallHostGroup?.crowdstrikeFirewallHostgroupName,
			managedBy: ManagedByValue.CrowdStrike,
		}),
	});

	useEffect(() => {
		if (coreResponse?.rows) {
			const rows =
				coreResponse.rows === undefined
					? []
					: coreResponse.rows
							.filter(obj => "crowdstrikeFirewallHostGroup" in obj)
							.sort(
								(a, b) =>
									a.crowdstrikeFirewallHostGroup!.precedence -
									b.crowdstrikeFirewallHostGroup!.precedence
							)
							.map((obj, i) => ({
								...obj,
								crowdstrikeFirewallHostGroup: {
									...obj.crowdstrikeFirewallHostGroup,
									precedence: i + 1,
								},
							}));

			setOrderedRows(rows);
		}
	}, [coreResponse.rows]);

	const onRowOrderChange = ({
		oldIndex,
		targetIndex,
	}: GridRowOrderChangeParams) => {
		const updatedRows = [...orderedRows];
		const [movedRow] = updatedRows.splice(oldIndex, 1);
		updatedRows.splice(targetIndex, 0, movedRow);

		const updatedPrecedenceRows = updatedRows.map((row, index) => ({
			...row,
			crowdstrikeFirewallHostGroup: {
				...row.crowdstrikeFirewallHostGroup,
				precedence: index + 1,
			},
		}));

		setOrderedRows(updatedPrecedenceRows);
	};

	return (
		<CrowdStrikeEditPrecedence
			title="Edit CrowdStrike Segment Precedence"
			orderedRows={orderedRows.map(o => o.crowdstrikeFirewallHostGroup)}
			navigatePath={`${ROUTES.SEGMENT.pathname}?tab=${ManagedByValue.CrowdStrike}`}
		>
			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
			>
				<TagPolicyDataGrid
					defaultPinnedColumns={tabBasedPolicyColumnConfig.pinnedColumns}
					rowCount={orderedRows.length}
					rows={getUniqueListBy(orderedRows ?? [], "tagBasedPolicyId")}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					hasNoData={
						coreResponse.mutation.isSuccess && coreResponse.rowCount === 0
					}
					metadata={
						{
							...coreResponse.metadata,
							scope: ManagedByValue.CrowdStrike,
						} as ScopeMetadata
					}
					getExportStatus={coreResponse?.getExportStatus}
					getUrlToDownload={coreResponse?.getUrlToDownload}
					resetDownloadUrl={coreResponse?.resetDownloadUrl}
					rawData={coreResponse?.rawData}
					managedBy={ManagedByValue.CrowdStrike}
					checkboxSelection={false}
					initialState={{
						sorting: {
							sortModel: [{ field: "precedence", sort: "asc" }],
						},
					}}
					rowReordering
					onRowOrderChange={onRowOrderChange}
				/>
			</Paper>
		</CrowdStrikeEditPrecedence>
	);
}

export default CrowdStrikeSegmentsEditPrecedence;
