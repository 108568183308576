import { Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import DisabledTooltipButton from "pages/crowdstrike-groups/firewall-host-groups/components/DisabledTooltipButton";
import { FIREWALL_HOST_GROUPS_PERMISSION } from "pages/crowdstrike-groups/firewall-host-groups/constants";
import { ROUTES } from "routes";

function CrowdStrikeSegmentsHeader() {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const isCreateDisabled = !userPermissions.has(
		FIREWALL_HOST_GROUPS_PERMISSION.CREATE
	);
	const isEditDisabled = !userPermissions.has(
		FIREWALL_HOST_GROUPS_PERMISSION.EDIT
	);

	return (
		<Stack
			direction="row"
			justifyContent="flex-end"
			alignItems="center"
			gap={2}
			ml="auto"
		>
			<DisabledTooltipButton
				link={ROUTES.EDIT_CROWDSTRIKE_SEGMENT_PRECEDENCE.pathname}
				disabled={isEditDisabled}
				variant="outlined"
				text="Edit Precedence"
			/>
			<DisabledTooltipButton
				link={ROUTES.CREATE_CROWDSTRIKE_SEGMENT.pathname}
				disabled={isCreateDisabled}
				variant="contained"
				text="CreateSegment"
			/>
		</Stack>
	);
}

export default CrowdStrikeSegmentsHeader;
