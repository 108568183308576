import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { VulnerabilityTypes } from "pages/assets/components/asset-data-grid/types";
import { VulnerabilityDataGrid } from "./components/vulnerability-data-grid";
import { VulnerabilityTypeQuery } from "./types";

interface VulnerabilityDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	assetId: string;
	vulnerabilityType?: string;
	assetName: string;
	assetType?: string;
}

export const VulnerabilityDrawer = ({
	isOpen,
	onClose,
	assetId,
	assetName,
	vulnerabilityType,
	assetType = "assets",
}: VulnerabilityDrawerProps) => {
	let subHeader = assetName;
	let vulnTypeParam = "";
	switch (vulnerabilityType) {
		case VulnerabilityTypes.LateralMovement:
			vulnTypeParam = VulnerabilityTypeQuery.LateralMovement;
			break;

		case VulnerabilityTypes.KnownExploit:
			vulnTypeParam = VulnerabilityTypeQuery.KnownExploit;
			break;

		default:
			break;
	}
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: 3,
					width: "70%",
					overflow: "hidden",
				},
			}}
		>
			<Toolbar />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<Stack direction={"row"} alignContent="center">
					<Stack direction={"column"} justifyContent="center">
						<Typography variant="h6">
							{window.getCTTranslatedText(vulnerabilityType ?? "")}
						</Typography>
						<Typography variant="body2" color="GrayText">
							{subHeader}
						</Typography>
					</Stack>
				</Stack>

				<IconButton size="small" aria-label="close drawer" onClick={onClose}>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Stack>
			<Stack
				justifyContent="space-between"
				style={{ flex: 1, position: "relative", overflow: "hidden" }}
			>
				<VulnerabilityDataGrid
					assetId={assetId}
					assetType={assetType}
					vulnerabilityType={vulnTypeParam}
				/>
			</Stack>
		</Drawer>
	);
};
