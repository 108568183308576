import { Box, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import {
	getAbsoluteLastObserved,
	getFacetGroupInfo,
	getRelativeLastObserved,
} from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useCore } from "modules/core";
import { SortOrder } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { HierarchyVisController } from "modules/hierarchy-vis";
import { RecommendationsButton } from "modules/recommendation-workflows";
import { PathRecommendationPageType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { Scope } from "modules/scope-metadata/types";
import {
	getAssetInterfaceIPs,
	getAssetInterfaceMACs,
} from "pages/asset/components/metadata-item-wrapper/helpers/AssetInterfaces";
import { formatCoreTagsAsset } from "pages/paths/components/path-data-grid/helpers/columnHelpers";
import prettyBytes from "pretty-bytes";
import { useCallback, useMemo } from "react";
import { PortDataGrid } from "./components/port-data-grid";
import { usePortsAPI } from "./components/port-data-grid/hooks";
import { PORT_TIME_FILTER_OPTIONS, usePortsFacetsOrder } from "./constants";
import { usePortStore } from "./store";
import { Port } from "./types";

const readOnlyDimensions = ["listenportlastobserved"];

export const Ports = () => {
	const theme = useTheme();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const portsFacetOrder = usePortsFacetsOrder();
	const PORTS_FACET_GROUP_INFO_WITHOUT_READ_ONLY_DIMENSIONS = useMemo(
		() => getFacetGroupInfo(portsFacetOrder, readOnlyDimensions),
		[portsFacetOrder]
	);

	const coreResponse = useCore<Port>({
		useStore: usePortStore,
		facetGroupInfo: PORTS_FACET_GROUP_INFO_WITHOUT_READ_ONLY_DIMENSIONS,
		scope: Scope.Port,
		dataMapper: port => {
			port.rawBandwidthInBytes = port.bandwidthInBytes;
			port.rawListenPortLastObserved = port.listenPortLastObserved;
			port.bandwidthInBytes = prettyBytes(Number(port.bandwidthInBytes));
			port.listenPortLastObservedAbsolute = getAbsoluteLastObserved(
				port.listenPortLastObserved
			);
			port.listenPortLastObserved = getRelativeLastObserved(
				port.listenPortLastObserved
			);
			if (port?.listenAsset) {
				port.listenAsset.coreTags = formatCoreTagsAsset(port?.listenAsset);
				if (port.listenAsset.interfaces) {
					port.ipAddresses = `"${getAssetInterfaceIPs(port.listenAsset.interfaces ?? [])}"`;
					port.macAddresses = `"${getAssetInterfaceMACs(port.listenAsset.interfaces ?? [])}"`;
				}
			}
			return port;
		},
		defaultSortOrder: [{ field: "listenportlastobserved", order: "desc" }],
		useApi: usePortsAPI,
		pageSize: 100,
	});

	const onSortChange = useCallback(
		(sortOrder: SortOrder[]) => {
			if (sortOrder?.length > 0) {
				sortOrder = sortOrder.map(item => {
					item.field = item.field.toLowerCase();
					return item;
				});

				const newSortOrder = sortOrder.map(sort => {
					if (sort.field === "listenportlastobservedtimestamp") {
						return {
							field: "listenportlastobserved",
							order: sort.order,
						};
					}

					return sort;
				});

				coreResponse.onSortChange(newSortOrder);
			}
		},
		[coreResponse]
	);

	const metadata = useCommonStore(state => state.metadata);
	const criteria = useCommonStore(state => state.currentSearchCriteria);

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction={"row"} alignItems={"center"}>
				<FacetOpenCloseController
					facetsOpen={coreResponse.facetsOpen}
					setFacetsOpen={coreResponse.setFacetsOpen}
					readOnlyDimensions={readOnlyDimensions}
					scope={Scope.Port}
					timeFilterConfig={{
						shouldShowTimeFilter: true,
						options: PORT_TIME_FILTER_OPTIONS,
					}}
				>
					<FacetControllerGroup
						config={coreResponse.facetConfig}
						value={coreResponse.facetState}
						onChange={coreResponse.updateFacet}
					/>
				</FacetOpenCloseController>
				{userPermissions.has("UPDATE_TEMPLATE") && (
					<Box sx={{ ml: 2 }}>
						<RecommendationsButton
							page={PathRecommendationPageType.Port}
							criteria={criteria}
							disabled={!Boolean(coreResponse?.facetState?.size)}
						/>
					</Box>
				)}
			</Stack>

			<Box
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					overflowY: "auto",
					flex: 1,
					height: "100%",
				}}
			>
				<HierarchyVisController
					scope={Scope.Port}
					defaultDimensionName="assetrisk"
				/>

				<Paper sx={{ height: "100%" }}>
					<PortDataGrid
						initialState={{
							sorting: {
								sortModel: [{ field: "listenportlastobserved", sort: "desc" }],
							},
						}}
						rows={coreResponse.rows}
						rowCount={coreResponse.rowCount}
						mutation={coreResponse.mutation}
						onPageChange={coreResponse.onPageChange}
						page={coreResponse.page}
						pageSize={coreResponse.pageSize}
						onPageSizeChange={coreResponse.onPageSizeChange}
						onSortChange={onSortChange}
						metadata={metadata}
						triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
						getExportStatus={coreResponse?.getExportStatus}
						getUrlToDownload={coreResponse?.getUrlToDownload}
						resetDownloadUrl={coreResponse?.resetDownloadUrl}
						rawData={coreResponse?.rawData}
					/>
				</Paper>
			</Box>
		</Stack>
	);
};
