import { useCriteriaBuilder } from "modules/core/Core";
import { nil } from "modules/facets/FacetUtils";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { useTagsAPI } from "pages/tags/hooks";
import { useMemo } from "react";

export const useTBPCriteriaBuilder = (
	facets: FacetState,
	metadata: ScopeMetadata | undefined
) => {
	const coreTagsAPI = useTagsAPI();

	const allCoreTags = useMemo(() => {
		if (!coreTagsAPI?.tagFields) {
			return undefined;
		}
		return [
			...(coreTagsAPI.tagFields?.coreTags ?? []),
			...(coreTagsAPI.tagFields?.userDefinedTags ?? []),
		];
	}, [coreTagsAPI.tagFields]);

	const tbpSearchFacets = useMemo(() => {
		if (!facets || !allCoreTags) {
			return undefined;
		}

		const searchFacets: FacetState = new Map(facets);

		if (facets?.size) {
			allCoreTags.forEach(tag => {
				if (
					!searchFacets?.has(tag.internalName) &&
					!searchFacets?.has(tag.name!) &&
					!searchFacets?.has(tag.displayName)
				) {
					const nilOptions: FacetOptionState = new Map();
					const value =
						tag.internalName === "managedby" ? ManagedByValue.ColorTokens : nil;
					nilOptions.set(value, {
						isSelected: true,
						operator: Operator.EQUAL,
					});
					searchFacets?.set(tag.internalName, nilOptions);
				}
			});
		}

		return searchFacets;
	}, [facets, allCoreTags]);

	return {
		criteria: useCriteriaBuilder("", facets, metadata),
		exhaustiveCriteria: useCriteriaBuilder("", tbpSearchFacets, metadata),
	};
};
