import { Button, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
	AlertFilter,
	AlertStatus,
} from "modules/NavigationLayout/components/notifications/types";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { ALL_TIME } from "modules/facets/constants";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import numeral from "numeral";
import { formatCreationTime } from "pages/monitoring/AuditLogUtils";
import {
	ALERT_TIME_FILTER_OPTIONS,
	FACET_ORDER,
} from "pages/monitoring/constants";
import { useAlertsStore } from "pages/monitoring/store";
import { CTAlert } from "pages/monitoring/types";
import { formatCoreTagsAsset } from "pages/paths/components/path-data-grid/helpers/columnHelpers";
import { useAlertConfigAPI } from "pages/settings/components/alert-config/hooks/useAlertConfig";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import { AlertDataGrid } from "./components/alert-data-grid";
import { ALERT_DATA_GRID_ID } from "./components/alert-data-grid/AlertDataGrid";
import { useAlertsAPI } from "./components/alerts-action/hooks";
import { EmptyAlertPage } from "./components/empty-alert-page/EmptyAlertPage";

interface CreationTimeFilterType {
	startTime: string;
	endTime: string;
}

export function Alerts() {
	const theme = useTheme();

	const navigate = useNavigate();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const globalSelectedTime = useUserPreferencesStore(state => state.timeFilter);
	const requestAPIRefresh = useAlertsStore(store => store.requestAPIRefresh);
	const defaultCreationFilterType: CreationTimeFilterType = {
		startTime: "",
		endTime: "",
	};
	const [creationFilter, setCreationFilter] = useState<CreationTimeFilterType>(
		defaultCreationFilterType
	);
	const [configuredRules, setConfiguredRules] = useState<number>(0);
	const [refreshConfig, setRefreshConfig] = useState(false);

	const selectedTime = useUserPreferencesStore(state => state.timeFilter);
	const [showEmptyPage, setShowEmptyPage] = useState<boolean>(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const additionalCriteria =
		creationFilter.startTime &&
		creationFilter.endTime &&
		selectedTime !== ALL_TIME
			? `alertlastseen > '${creationFilter.startTime}' AND alertlastseen < '${creationFilter.endTime}'`
			: "";

	const updateFacetOption = useCommonStore(state => state.updateFacetOption);
	const setExternalCriteria = useCommonStore(
		state => state.setExternalCriteria
	);

	const setTime = useCallback(() => {
		const formatedTime = formatCreationTime(globalSelectedTime);
		setCreationFilter({
			startTime: formatedTime?.startTime,
			endTime: formatedTime?.endTime,
		});
	}, [globalSelectedTime]);

	useEffect(() => {
		setTime();
	}, [setTime]);

	useEffect(() => {
		updateFacetOption({
			facetName: AlertFilter.filters,
			optionName: AlertStatus.PENDING,
			value: true,
		});

		return () =>
			updateFacetOption({
				facetName: AlertFilter.filters,
				optionName: AlertStatus.PENDING,
				value: false,
			});
	}, [updateFacetOption]);

	const coreResponse = useCore<CTAlert>({
		useStore: useAlertsStore,
		facetGroupInfo: FACET_ORDER,
		scope: Scope.Alert,
		dataMapper: alert => {
			if (alert?.asset) {
				alert.asset.coreTags = formatCoreTagsAsset(alert.asset);
			}
			alert.message = alert.details;
			alert.creationTime = alert.lastSeen;
			return alert;
		},
		useApi: useAlertsAPI,
		pageSize: 100,
		defaultSortOrder: [
			{ field: "alertseverity", order: "desc" },
			{ field: "alertlastseen", order: "desc" },
		],
		additionalCriteria: additionalCriteria,
		isNewCsvAPIVersion: true,
	});

	useEffect(() => {
		setExternalCriteria(additionalCriteria);

		return () => setExternalCriteria("*");
	}, [additionalCriteria, setExternalCriteria]);

	const { mutate: getAlertMutate } = useAlertConfigAPI();
	useEffect(() => {
		const body = {
			criteria: `'alertrulestatus' in ('enabled', 'snoozed')`,
			pagination: {
				offset: 0,
				limit: 1,
			},
		};

		getAlertMutate(body, {
			onSuccess: response => {
				setConfiguredRules(response?.metadata?.total ?? 0);
				if (coreResponse?.rows?.length !== undefined) {
					const hasData = coreResponse?.rows?.length > 0;
					const hasMetadata = response?.metadata?.total > 0;

					setShowEmptyPage(!(hasData || hasMetadata));
				}
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	}, [
		getAlertMutate,
		setSnackbar,
		coreResponse?.rows?.length,
		setConfiguredRules,
		refreshConfig,
	]);

	const rowCount =
		coreResponse.rowCount > 0 &&
		coreResponse.rowCount === coreResponse.maxRowCount
			? coreResponse.rowCount - 1
			: coreResponse.rowCount;

	const handleRefresh = useCallback(() => {
		requestAPIRefresh();
		setRefreshConfig(prev => !prev);
	}, [requestAPIRefresh]);

	return (
		<>
			<Stack direction={"column"} sx={{ height: "100%", width: "100%" }}>
				<Stack direction={"row"} mb={2}>
					<Stack sx={{ flex: 1 }}>
						<FacetOpenCloseController
							facetsOpen={coreResponse.facetsOpen}
							setFacetsOpen={coreResponse.setFacetsOpen}
							timeFilterConfig={{
								shouldShowTimeFilter: true,
								options: ALERT_TIME_FILTER_OPTIONS,
							}}
						>
							<FacetControllerGroup
								config={coreResponse?.facetConfig}
								value={coreResponse.facetState}
								onChange={coreResponse.updateFacet}
							/>
						</FacetOpenCloseController>
					</Stack>
					{userPermissions.has("UPDATE_ALERTRULES") && (
						<Button
							color="primary"
							variant="outlined"
							onClick={() => navigate(ROUTES.SETTINGS_ALERTS.pathname)}
						>
							{Number(configuredRules) > 0
								? window.getCTTranslatedText("Alert Config") +
									` (${numeral(Number(configuredRules)).format("0a")})`
								: window.getCTTranslatedText("Alert Config")}
						</Button>
					)}
				</Stack>
				{showEmptyPage ? (
					<EmptyAlertPage
						onClick={() => {
							handleRefresh();
						}}
					/>
				) : (
					<Paper
						sx={{
							transition: theme.transitions.create(["width", "margin"], {
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
							flex: 1,
							overflow: "hidden",
						}}
						id={ALERT_DATA_GRID_ID}
					>
						<AlertDataGrid
							initialState={{
								sorting: {
									sortModel: [
										{ field: "alertseverity", sort: "desc" },
										{ field: "alertlastseen", sort: "desc" },
									],
								},
							}}
							rows={coreResponse.rows?.map((obj, index) => ({
								...obj,
								id: obj.lastSeen + index,
							}))}
							originalRowCount={coreResponse.rowCount}
							checkboxSelection
							metadata={coreResponse.metadata}
							rowCount={rowCount}
							maxRowCount={coreResponse.maxRowCount}
							mutation={coreResponse.mutation}
							onPageChange={coreResponse.onPageChange}
							page={coreResponse.page}
							pageSize={coreResponse.pageSize}
							onPageSizeChange={coreResponse.onPageSizeChange}
							onSortChange={coreResponse.onSortChange}
							triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
							getExportStatus={coreResponse?.getExportStatus}
							getUrlToDownload={coreResponse?.getUrlToDownload}
							resetDownloadUrl={coreResponse?.resetDownloadUrl}
							rawData={coreResponse?.rawData}
							setTime={setTime}
						/>
					</Paper>
				)}
			</Stack>
		</>
	);
}
