import { LineChartDatum, StackedTrendChart } from "@colortokens/ng-ui";
import { useTheme } from "@mui/material";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { BarStackChart } from "modules/charts";
import { ChartDatum } from "modules/charts/bar-group/BarGroupChart";
import React from "react";

const LABELS: { [key: string]: string } = {
	ports: "Ports Reviewed",
	paths: "Paths Reviewed",
	assets: "Assets Secured",
};

export function getLabel(key: string) {
	return DisplayTextMap[key] || LABELS[key];
}

const linePosition = {
	x1: "0",
	y1: "12",
	x2: "100",
	y2: "12",
};

interface BarChartStackGraphProps {
	title: string;
	preChartComponent?: React.ReactNode;
	data: Array<ChartDatum> | undefined;
	dataPoint?: string;
	lineChartData?: Array<LineChartDatum> | undefined;
	lineChartDataPoint?: string;
	priorityMap: { [key: string]: number };
	category?: string;
	showAllLabels?: boolean;
	total?: number;
	isDialogWidget?: boolean;
	tooltipTitle?: string;
	animated?: boolean;
}

export function BarStackChartGraph(props: BarChartStackGraphProps) {
	const theme = useTheme();

	const strokeStyles = [
		{
			stroke: theme.palette.text.primary,
			strokeWidth: 3,
		},
	];

	const chartLabel = `${window.getCTTranslatedText("BarStackChartLabel", {
		lineChartDataPoint: window.getCTTranslatedText(
			props.lineChartDataPoint ?? ""
		),
	})}`;

	return (
		<StackedTrendChart
			{...props}
			strokeStyles={strokeStyles}
			linePosition={linePosition}
			chartLabel={chartLabel}
			renderChart={({ width, height, ...props }) => (
				<BarStackChart width={width} height={height} {...props} />
			)}
		/>
	);
}
