import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import { BooleanFlags, ProbeProtocolLabels, ProbeProtocols } from "../types";
import { DevicesToProtect } from "./DevicesToProtect";

interface SecurityProps {
	deviceProbingDisabled: boolean;
	setDeviceProbingDisabled: (value: boolean) => void;
	probeProtocols: Array<string>;
	setProbeProtocols: (value: Array<string>) => void;
	vulnerabilitiesDisabled: boolean;
	setVulnerabilitiesDisabled: (value: boolean) => void;
	optIn: string;
	setOptIn: (value: string) => void;
	isFormDisabled: boolean;
}

export function Security({
	deviceProbingDisabled,
	setDeviceProbingDisabled,
	probeProtocols,
	setProbeProtocols,
	vulnerabilitiesDisabled,
	setVulnerabilitiesDisabled,
	optIn,
	setOptIn,
	isFormDisabled,
}: SecurityProps) {
	const handleProtocolChange = (protocol: keyof typeof ProbeProtocols) => {
		const currentProbeProtocols = [...(probeProtocols ?? [])];
		const protocolValue = ProbeProtocols[protocol];
		const index = currentProbeProtocols.indexOf(protocolValue);
		if (index > -1) {
			const updatedProtocols = currentProbeProtocols.filter(
				p => p !== protocolValue
			);
			setProbeProtocols(updatedProtocols);
		} else {
			setProbeProtocols([...currentProbeProtocols, protocolValue]);
		}
	};

	function renderDeviceManagement() {
		return (
			<DevicesToProtect
				optIn={optIn}
				setOptIn={setOptIn}
				isFormDisabled={isFormDisabled}
			/>
		);
	}

	function renderDeviceIdentification() {
		return (
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Typography variant="subtitle1">
							{window.getCTTranslatedText("deviceIdentification")}:
						</Typography>
						<RadioGroup
							value={
								!deviceProbingDisabled ? BooleanFlags.True : BooleanFlags.False
							}
							onChange={e => {
								setDeviceProbingDisabled(e.target.value === BooleanFlags.False);
							}}
						>
							<FormControlLabel
								value={BooleanFlags.True}
								control={<Radio />}
								label={window.getCTTranslatedText("Enable")}
							/>
							<FormControlLabel
								value={BooleanFlags.False}
								control={<Radio />}
								label={window.getCTTranslatedText("Disable")}
							/>
						</RadioGroup>
					</Stack>
				</FormControl>
			</Stack>
		);
	}

	function renderVulnerabilityDetection() {
		return (
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Typography variant="subtitle1">
							{window.getCTTranslatedText("vulnerabilityDetection")}:
						</Typography>
						<RadioGroup
							value={
								!vulnerabilitiesDisabled
									? BooleanFlags.True
									: BooleanFlags.False
							}
							onChange={e => {
								setVulnerabilitiesDisabled(
									e.target.value === BooleanFlags.False
								);
							}}
						>
							<FormControlLabel
								value={BooleanFlags.True}
								control={<Radio />}
								label={window.getCTTranslatedText("Enable")}
							/>
							<FormControlLabel
								value={BooleanFlags.False}
								control={<Radio />}
								label={window.getCTTranslatedText("Disable")}
							/>
						</RadioGroup>
					</Stack>
				</FormControl>
			</Stack>
		);
	}

	function renderProtocols() {
		return (
			<Stack>
				<FormControl>
					<Stack justifyContent={"center"} spacing={2}>
						<Typography variant="body1">
							{window.getCTTranslatedText("Protocols to support")}:
						</Typography>
						<Stack>
							{Object.keys(ProbeProtocols).map(protocol => (
								<Stack justifyContent={"center"} key={protocol}>
									<FormControlLabel
										control={
											<Checkbox
												checked={probeProtocols?.includes(
													ProbeProtocols[
														protocol as keyof typeof ProbeProtocols
													]
												)}
												onChange={() =>
													handleProtocolChange(
														protocol as keyof typeof ProbeProtocols
													)
												}
											/>
										}
										label={
											ProbeProtocolLabels[
												protocol as keyof typeof ProbeProtocolLabels
											]
										}
									/>
								</Stack>
							))}
						</Stack>
					</Stack>
				</FormControl>
			</Stack>
		);
	}

	return (
		<Box sx={{ minWidth: "100%" }}>
			<Grid
				container
				columnGap={4}
				rowGap={4}
				sx={{ pl: 0 }}
				display="grid"
				gridTemplateColumns="1fr 1fr"
			>
				{renderDeviceManagement()}
				{renderDeviceIdentification()}
				{!deviceProbingDisabled ? renderProtocols() : null}
				{!deviceProbingDisabled ? renderVulnerabilityDetection() : null}
			</Grid>
		</Box>
	);
}
