import CircleIcon from "@mui/icons-material/Circle";
import {
	Button,
	Divider,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ServiceRestartIcon } from "assets/svgs";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { CTWidget } from "common/molecules/widget";
import { WidgetListItem } from "common/molecules/widget/components/widget-list-item";
import { dayjsWithPlugins, parseErrorMessage } from "common/utils";
import { API_URL } from "hooks/useNetworking/constants";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { LogsFetcher } from "modules/agent-logs/LogsFetcher";
import { LogsDrawer } from "modules/logs-drawer";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useRestartAgents } from "pages/agents/components/agent-data-grid/hooks";
import { AgentStatusType, Logs } from "pages/agents/types";
import {
	ApplianceWidgetProps,
	FirewallStatus,
} from "pages/appliance-detail/types";
import { useApplianceStore } from "pages/appliances/store";
import { Appliance } from "pages/appliances/types";
import { getPendingActionsTooltip } from "pages/appliances/utils";
import {
	FirewallLogType,
	ViewFirewallLogsButton,
} from "pages/asset/components/asset-metadata-wrapper/components/asset-firewall-status/ViewFirewallLogsButton";
import { LogOptions } from "pages/asset/types";
import prettyBytes from "pretty-bytes";
import { useMemo, useState } from "react";

interface WidgetProps {
	appliance?: Appliance;
	isParent?: boolean;
}

const iconStyle = {
	fontSize: 18,
};

const StatusWidgetContent = ({ appliance, isParent }: WidgetProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [optionsData, setOptionsData] = useState<Logs>();
	const [areOptionsLoading, setAreOptionsLoading] = useState(true);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const onViewLogsClick = () => {
		if (appliance?.agentId) {
			setIsDrawerOpen(true);
		}
	};

	const onDrawerClick = () => setIsDrawerOpen(false);

	const logOptions: LogOptions = useMemo(
		() =>
			optionsData?.items.map((item, index) => ({
				value: item.lastModified,
				label:
					index === 0
						? window.getCTTranslatedText("latestAgentLogDownload", {
								date: dayjsWithPlugins(item.lastModified).format(
									"DD-MMM-YY HH:mm:ss"
								),
							})
						: dayjsWithPlugins(item.lastModified).format("DD-MMM-YY HH:mm:ss"),
				id: item.name,
				fileSize: prettyBytes(item.sizeInBytes),
			})),
		[optionsData]
	);

	const renderAgentStatus = (status?: string) => {
		return (
			<Stack
				direction="row"
				alignItems="center"
				justifyContent={"space-between"}
			>
				<Stack direction="row" alignItems="center">
					<CircleIcon
						color={status === AgentStatusType.Active ? "success" : "disabled"}
						sx={{ mr: 1, ...iconStyle }}
					/>
					<Typography variant="body2" sx={{ mr: 1 }}>
						{window.getCTTranslatedText(
							status === AgentStatusType.Active ? "Active" : "Inactive"
						)}
					</Typography>
				</Stack>
				{!isParent ? (
					<>
						<Tooltip title={window.getCTTranslatedText("viewLogs")}>
							<Button variant={"text"} onClick={onViewLogsClick}>
								{window.getCTTranslatedText("Logs")}
							</Button>
						</Tooltip>
						<>
							{isDrawerOpen && (
								<LogsFetcher
									agentId={appliance?.agentId ?? ""}
									setOptionsData={setOptionsData}
									setAreOptionsLoading={setAreOptionsLoading}
								/>
							)}
							<LogsDrawer
								isOpen={isDrawerOpen}
								onClose={onDrawerClick}
								label="Logs"
								areOptionsLoading={areOptionsLoading}
								logOptions={logOptions}
								downloadPrefix={`${API_URL}/agents/${appliance?.agentId}/logs/`}
								hasPermission={userPermissions.has("READ_AGENT_LOG")}
							/>
						</>
					</>
				) : null}
			</Stack>
		);
	};

	const renderFirewallStatus = (status?: string) => {
		return (
			<Stack
				direction="row"
				alignItems="center"
				justifyContent={"space-between"}
				flexWrap={"wrap"}
			>
				<Stack direction="row" alignItems="center" mr={2}>
					<CircleIcon
						color={
							status === FirewallStatus.Synchronized ? "success" : "disabled"
						}
						fontSize="small"
						sx={{ mr: 1, ...iconStyle }}
					/>
					<Typography variant="body2" sx={{ mr: 1 }}>
						{window.getCTTranslatedText(
							status === FirewallStatus.Synchronized
								? "Synchronized"
								: "Pending"
						)}
					</Typography>
				</Stack>
				{!isParent ? (
					<ViewFirewallLogsButton
						agentId={appliance?.agentId ?? ""}
						type={FirewallLogType.APPLIANCE}
					/>
				) : null}
			</Stack>
		);
	};

	return (
		<Stack width="100%" spacing={3}>
			<Stack spacing={1}>
				<WidgetListItem
					keyText={window.getCTTranslatedText("gatekeeperStatus")}
					valueElement={renderAgentStatus(appliance?.agentStatus)}
				/>
				<WidgetListItem
					keyText={window.getCTTranslatedText("gatekeeperVersion")}
					valueText={appliance?.currentVersion}
				/>
			</Stack>
			<Divider />
			<WidgetListItem
				keyText={window.getCTTranslatedText("firewallStatus")}
				valueElement={renderFirewallStatus(appliance?.policySynced)}
			/>
		</Stack>
	);
};

export const ApplianceStatus = ({
	appliance,
	isLoading,
	canUpdate,
	isParent,
}: ApplianceWidgetProps) => {
	const [showConfirmation, setShowConfirmation] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAPIRefresh = useApplianceStore(store => store.requestAPIRefresh);
	const restartMutation = useRestartAgents();
	const disabledActionsTooltip = getPendingActionsTooltip(appliance);
	const theme = useTheme();

	const restartAppliance = async () => {
		if (appliance?.agentId) {
			let selectedAgentIds = [appliance.agentId]
				.map((element: string) => `'${element}'`)
				.join(", ")
				.replace(/"/g, "");
			const body = {
				criteria: `agentId in (${selectedAgentIds})`,
				version: "@latest",
			};
			await restartMutation.mutateAsync(body, {
				onSuccess: response => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"GatekeeperRestartRequestSubmittedSuccessfully"
					);
					setShowConfirmation(false);
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const renderActions = () => {
		let tooltipMessage = Boolean(disabledActionsTooltip)
			? disabledActionsTooltip
			: window.getCTTranslatedText("restartGatekeeper");

		return (
			<>
				{!isParent ? (
					<Tooltip title={tooltipMessage}>
						<Stack>
							<IconButton
								onClick={() => setShowConfirmation(true)}
								disabled={Boolean(disabledActionsTooltip)}
							>
								<ServiceRestartIcon
									color={
										Boolean(disabledActionsTooltip)
											? theme.palette.text.disabled
											: theme.palette.primary.main
									}
								/>
							</IconButton>
						</Stack>
					</Tooltip>
				) : null}
				<CTConfirmation
					open={showConfirmation}
					onClose={() => setShowConfirmation(false)}
					title={window.getCTTranslatedText("restartGatekeeper")}
					primaryText={window.getCTTranslatedText(
						"applianceServiceRestartConfirmation",
						{ service: window.getCTTranslatedText("gatekeeper") }
					)}
					secondaryText={window.getCTTranslatedText("applianceRestartNote")}
					primaryButtonText={window.getCTTranslatedText("restartButton")}
					isLoading={restartMutation.isLoading}
					onSuccess={restartAppliance}
				/>
			</>
		);
	};

	return (
		<CTWidget
			title={window.getCTTranslatedText("agent&firewall")}
			isLoading={isLoading}
			actions={canUpdate ? renderActions() : null}
			children={
				<StatusWidgetContent appliance={appliance} isParent={isParent} />
			}
		/>
	);
};
