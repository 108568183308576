import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useTheme } from "@mui/material";
import {
	MarkerDef,
	MarkerDefAllow,
	MarkerDefAllowedByTest,
	MarkerDefCombined,
	MarkerDefDeny,
} from "assets/svgs/Marker";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
	Background,
	BackgroundVariant,
	ControlButton,
	Controls,
	Edge,
	EdgeChange,
	Node,
	NodeChange,
	NodeDimensionChange,
	NodePositionChange,
	ReactFlowInstance,
	ReactFlowProps,
	applyEdgeChanges,
	applyNodeChanges,
} from "reactflow";
import { usePrevious } from "../../../common/hooks/usePrevious";
import { findDirectAncestorsAndChildren } from "../hooks/useNodesAndEdges";
import { useVisxStore } from "../store";
import { PathReviewStatus, PositionStore } from "../types";
import { EdgeDataType } from "./EdgeWithButton";

const fitVisualizer = (instance: ReactFlowInstance) => {
	setTimeout(() => {
		instance.fitView({ duration: 500 });
	}, 50);
};

const customControlStyle = {
	maxHeight: "100% !important",
	maxWidth: "100% !important",
};

export function Visualizer(props: ReactFlowProps) {
	const visxInstanceRef = useRef<ReactFlowInstance | undefined>();

	const { nodes, edges } = props;

	const [nodeDragging, setDragging] = useState(false);
	const [stateNodes, setStateNodes] = useState(nodes);
	const [stateEdges, setStateEdges] = useState(edges);

	const storedPositions = useVisxStore(state => state.positions);
	const setIsToolbarHidden = useVisxStore(state => state.setIsToolbarHidden);
	const isToolbarHidden = useVisxStore(state => state.isToolbarHidden);
	const setPositions = useVisxStore(state => state.setPositions);
	const positionsRef = useRef<PositionStore | undefined>(storedPositions);

	const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);
	const selectedDirection = useVisxStore(state => state.selectedDirection);
	const previousDirection = usePrevious(selectedDirection);

	useEffect(() => {
		if (previousDirection && previousDirection !== selectedDirection) {
			positionsRef.current = undefined;
		}
	}, [selectedDirection, previousDirection]);

	useEffect(() => {
		if (!storedPositions && positionsRef.current) {
			positionsRef.current = undefined;
		}
	}, [storedPositions]);

	const isPublicNodeExpanded = useVisxStore(
		state => state.isPublicNodeExpanded
	);
	const previousPublicNodeExpanded = usePrevious(isPublicNodeExpanded);

	const isPrivateNodeExpanded = useVisxStore(
		state => state.isPrivateNodeExpanded
	);
	const previousPrivateNodeExpanded = usePrevious(isPrivateNodeExpanded);

	const isOthersNodeExpanded = useVisxStore(
		state => state.isOthersNodeExpanded
	);

	useEffect(() => {
		if (previousPublicNodeExpanded && !isPublicNodeExpanded) {
			positionsRef.current = undefined;
		}
	}, [isPublicNodeExpanded, previousPublicNodeExpanded]);

	useEffect(() => {
		if (previousPrivateNodeExpanded && !isPrivateNodeExpanded) {
			positionsRef.current = undefined;
		}
	}, [isPrivateNodeExpanded, previousPrivateNodeExpanded]);

	const theme = useTheme();

	useEffect(() => {
		setStateNodes(nodes);
	}, [nodes]);

	const highlightedChildren = useVisxStore(state => state.highlightedChildren);
	const highlightedChildrenRef = useRef(highlightedChildren);
	highlightedChildrenRef.current = highlightedChildren;

	const interactiveHandler = useCallback(
		(
			highlightedId: string | undefined,
			nodes: Node[] | undefined,
			edges: Edge<EdgeDataType>[]
		) => {
			let highlightedIds: Set<string> | undefined;

			const handleConnections = (id: string) => {
				const { ancestors, children } = findDirectAncestorsAndChildren(
					edges,
					id
				);

				if (selectedPathStatus !== PathReviewStatus.Enforced) {
					if (selectedDirection === Direction.Inbound) {
						highlightedIds = new Set([...(highlightedIds ?? []), ...ancestors]);
					} else {
						highlightedIds = new Set([...(highlightedIds ?? []), ...children]);
					}
				} else {
					highlightedIds = new Set([
						...(highlightedIds ?? []),
						...ancestors,
						...children,
					]);
				}
			};

			if (highlightedId) {
				handleConnections(highlightedId);
			}

			highlightedIds = new Set([
				...(highlightedIds ?? []),
				...(highlightedChildrenRef.current ?? []),
			]);

			highlightedChildrenRef.current?.forEach(cId => {
				handleConnections(cId);
			});

			const isNodeHighlighted = (nodeId: string) => {
				return highlightedIds?.has(nodeId) || nodeId === highlightedId;
			};

			nodes = nodes?.map(node => {
				node.className = node.id === highlightedId ? "ctSelected" : undefined;
				node.style = {
					...node.style,
					opacity: isNodeHighlighted(node.id) || !highlightedId ? 1 : 0.2,
				};
				return node;
			});

			edges = edges.map(e => {
				e.style = { ...e.style, opacity: 1 };
				// e.animated = false;
				if (e.data) {
					e.data = { ...e.data };
					e.data.interactive = true;
				}
				let isEdgeHighlighted =
					e.source === highlightedId ||
					e.target === highlightedId ||
					highlightedChildrenRef.current?.has(e.source) ||
					highlightedChildrenRef.current?.has(e.target);

				if (
					isEdgeHighlighted &&
					selectedPathStatus !== PathReviewStatus.Enforced
				) {
					let targetSide =
						selectedDirection === Direction.Inbound ? e.target : e.source;
					isEdgeHighlighted =
						targetSide === highlightedId ||
						highlightedChildrenRef.current?.has(targetSide);
				}
				if (isEdgeHighlighted) {
					// e.animated = true;
				} else {
					if (highlightedId) {
						e.style = { ...e.style, opacity: 0.2 };
						if (e.data) {
							e.data.interactive = false;
						}
					}
				}

				// e.animated = false;
				return e;
			});

			return { interactiveNodes: nodes, interactiveEdges: edges };
		},
		[selectedPathStatus, selectedDirection]
	);

	useEffect(() => {
		if (!edges || !lastHovered.current) {
			setStateEdges(edges);
			return;
		}

		const highlightedId = lastHovered.current;

		setStateNodes(nodes => {
			const { interactiveNodes } = interactiveHandler(
				highlightedId,
				nodes,
				edges
			);
			return interactiveNodes;
		});

		setStateEdges(() => {
			const { interactiveEdges } = interactiveHandler(
				highlightedId,
				undefined,
				edges
			);
			return interactiveEdges;
		});
	}, [edges, interactiveHandler]);

	const onNodesChange = useCallback(
		(changes: NodeChange[]) => {
			setStateNodes(nds => {
				if (!nds) {
					return nds;
				}

				if (!positionsRef.current) {
					positionsRef.current = new Map();
				}

				let selectedChanges: NodeChange[] = [];
				let dragging = false;

				changes.forEach(c => {
					if (c.type === "position") {
						selectedChanges.push(c);

						let change = c as NodePositionChange;
						let oldData = positionsRef.current?.get(change.id) || {};

						if (c.dragging) {
							dragging = true;
						}

						positionsRef.current &&
							change.position &&
							positionsRef.current.set(change.id, {
								...oldData,
								position: change.position,
							});
					}
					if (
						c.type === "dimensions" &&
						c.dimensions?.width !== c.dimensions?.height
					) {
						let max = Math.max(
							c.dimensions?.height || 0,
							c.dimensions?.width || 0
						);
						if (max && c.dimensions) {
							c.dimensions.width = max;
							c.dimensions.height = max;
						}
						c.updateStyle = true;

						selectedChanges.push(c);

						let change = c as NodeDimensionChange;
						let oldData = positionsRef.current?.get(change.id) || {};

						positionsRef.current &&
							change.dimensions &&
							positionsRef.current.set(change.id, {
								...oldData,
								styles: {
									...change.dimensions,
								},
							});
					}
				});

				if (!selectedChanges.length) {
					return nds;
				}

				let newNodes = applyNodeChanges(selectedChanges, nds);

				setDragging(dragging);
				setPositions(positionsRef.current);
				return newNodes;
			});
		},
		[setPositions]
	);

	const onEdgesChange = useCallback((changes: EdgeChange[]) => {
		setStateEdges(eds => eds && applyEdgeChanges(changes, eds));
	}, []);

	useEffect(() => {
		if (
			visxInstanceRef.current &&
			(isPrivateNodeExpanded || isPublicNodeExpanded || isOthersNodeExpanded)
		) {
			fitVisualizer(visxInstanceRef.current);
		}
	}, [isPrivateNodeExpanded, isPublicNodeExpanded, isOthersNodeExpanded]);

	const hoveredId = useVisxStore(state => state.hoveredId);
	const lastHovered = useRef(hoveredId);

	useEffect(() => {
		if (lastHovered.current === hoveredId || !stateEdges || !stateNodes) {
			return;
		}

		setStateNodes(nodes => {
			const { interactiveNodes } = interactiveHandler(
				hoveredId,
				nodes,
				stateEdges
			);
			return interactiveNodes;
		});

		setStateEdges(() => {
			const { interactiveEdges } = interactiveHandler(
				hoveredId,
				undefined,
				stateEdges
			);
			return interactiveEdges;
		});

		lastHovered.current = hoveredId;
	}, [stateNodes, stateEdges, hoveredId, interactiveHandler]);

	useEffect(() => {
		return () => {
			setPositions(positionsRef.current);
		};
	}, [setPositions]);

	return (
		<>
			<MarkerDef />
			<MarkerDefCombined />
			<MarkerDefDeny />
			<MarkerDefAllow />
			<MarkerDefAllowedByTest />
			<ReactFlow
				className={nodeDragging ? "nodeDragging" : ""}
				{...props}
				onInit={i => {
					visxInstanceRef.current = i;
					// fitVisualizer(i);
				}}
				fitView
				nodeTypes={props.nodeTypes}
				edgeTypes={props.edgeTypes}
				nodes={stateNodes}
				edges={stateEdges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onEdgeClick={props.onEdgeClick}
				nodesDraggable={true}
				nodesConnectable={false}
				proOptions={props.proOptions}
			>
				<Background
					color={
						theme.palette.mode === "dark"
							? theme.palette.grey[700]
							: theme.palette.grey[800]
					}
					size={1}
					variant={BackgroundVariant.Dots}
				/>
				<Controls showInteractive={false} showFitView={false}>
					<ControlButton
						onClick={() => setIsToolbarHidden(!isToolbarHidden)}
						title={isToolbarHidden ? "exit full screen" : "full screen"}
					>
						{isToolbarHidden ? (
							<FullscreenExitIcon style={customControlStyle} fontSize="large" />
						) : (
							<FullscreenIcon style={customControlStyle} fontSize="large" />
						)}
					</ControlButton>
				</Controls>
			</ReactFlow>
		</>
	);
}
