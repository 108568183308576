import { MenuItem, Select, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { CTRole } from "pages/settings/components/organization/types";
import { useEffect, useState } from "react";

interface RolesDropdownProps {
	onSelect: (role: CTRole) => void;
	rolesList: CTRole[] | undefined;
	selectedRole?: CTRole;
	invite?: boolean;
	typographyVariant?: Variant | "inherit";
	width?: string;
	showRoleDescriptions?: boolean;
}

const ROLES_DESCRIPTION_MAP: Record<string, string> = {
	admin: "AdminRoleDesc",
	"Read-Only": "ReadOnlyRoleDesc",
	"Read-Write": "ReadWriteRoleDesc",
	"Agent-Role": "Agent-Role",
	"Security-Policy-Admin": "SecurityPolicyAdminRoleDesc",
	"Asset-Owner": "AssetOwnerRoleDesc",
};

export const RolesDropdown = ({
	onSelect,
	rolesList,
	selectedRole,
	invite = false,
	typographyVariant = "inherit",
	width = "100%",
	showRoleDescriptions = false,
}: RolesDropdownProps) => {
	const [selectedOperation, setSelectedOperation] = useState<
		CTRole | undefined
	>(selectedRole ?? undefined);

	useEffect(() => {
		if (!invite) {
			setSelectedOperation(selectedRole);
		}
	}, [selectedRole, setSelectedOperation, invite]);

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		role: CTRole
	) => {
		if (
			!selectedOperation ||
			(selectedOperation && role.roleID !== selectedOperation?.roleID)
		) {
			onSelect(role);
			setSelectedOperation(role);
		}
	};

	return (
		<Select
			value={selectedOperation?.displayName ?? ""}
			variant="standard"
			sx={{ width, display: "flex" }}
			renderValue={value => (
				<Typography
					variant={typographyVariant}
					sx={{ opacity: value ? 1 : 0.5 }}
				>
					{window.getCTTranslatedText(value || "Select Role")}
				</Typography>
			)}
			name="role-dropdown"
			displayEmpty
		>
			<MenuItem disabled hidden value={""} sx={{ display: "none" }}>
				{window.getCTTranslatedText("Select Role")}
			</MenuItem>
			{rolesList &&
				rolesList?.length > 0 &&
				((rolesList as Array<CTRole>) || [])
					.filter(role => {
						return role.roleName !== "Agent-Role";
					})
					.map(option => (
						<MenuItem
							key={option?.roleID}
							value={option?.displayName}
							onClick={e => handleMenuItemClick(e, option)}
							sx={{
								flexDirection: "column",
								alignItems: "flex-start",
								maxWidth: 552,
								whiteSpace: "initial",
								lineHeight: "24px",
							}}
						>
							{window.getCTTranslatedText(option?.displayName)}
							{showRoleDescriptions && (
								<Typography
									variant="caption"
									component={"p"}
									fontWeight={400}
									sx={{ color: theme => theme.palette.text.secondary }}
								>
									{window.getCTTranslatedText(
										ROLES_DESCRIPTION_MAP[option?.roleName] ?? ""
									)}
								</Typography>
							)}
						</MenuItem>
					))}
		</Select>
	);
};
