import { GridRowId } from "@mui/x-data-grid";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { PathStoreType } from "pages/paths/store/types";
import { PathStatus } from "pages/paths/types";

interface PathStatusActionbarProps {
	selectedStatus: PathStatus | undefined;
	selection: GridRowId[];
	update: () => void;
	cancel: () => void;
	useStore?: PathStoreType;
	comment?: string | null;
}

export const PathStatusActionBar = ({
	selectedStatus,
	selection,
	update,
	cancel,
}: PathStatusActionbarProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	return (
		<>
			{userPermissions.has("UPDATE_PATH") && (
				<ToolbarAction
					isValid={selection?.length > 0 && !!selectedStatus}
					actionBtnText={"Confirm"}
					save={update}
					hidePrimaryBtn
					cancel={cancel}
					hasPermission={userPermissions.has("UPDATE_PATH")}
				></ToolbarAction>
			)}
		</>
	);
};
