import {
	CircularProgress,
	lighten,
	Stack,
	TextField,
	TextFieldProps,
	Typography,
	useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ParentSize } from "@visx/responsive";
import { scaleOrdinal } from "@visx/scale";
import { getColor } from "common/constants/colors";
import {
	AnalyticsAPIReq,
	AnalyticsResponse,
	StatContainer,
} from "common/types/types";
import debounce from "lodash/debounce";
import { TreeMapChart } from "modules/charts/tree-map/TreeMapChart";
import { ChartDatum } from "modules/charts/tree-map/types";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import React, { useEffect, useMemo, useState } from "react";
import { useCommonStore } from "../../common/store/useCommonStore";
import { useCriteriaBuilder } from "../core/Core";
import { useSearchStore } from "../search/store/useSearchStore";
import { Hierarchy } from "./Hierarchy";
import {
	Dimension,
	HierarchyVisProps,
	MultiDimensionHierarchyDataType,
	SingleDimensionHierarchyDataType,
} from "./types";

const createHierarchyData = (
	aggData: Array<SingleDimensionHierarchyDataType>
) => {
	const hierarchyData: Array<ChartDatum> = [];

	const data = aggData;
	data.forEach(stat => {
		hierarchyData.push({
			id: stat.name,
			datum: {
				count: stat.count,
				label: `${capitalizeFirstLetter(stat.name)}`,
				name: stat.name,
			},
			parent: "root",
		});
	});

	hierarchyData.push({
		id: "root",
		datum: {
			count: 0,
			label: "",
			name: "",
		},
		parent: "",
	});

	return hierarchyData;
};

const color1 = "#607d8b";

export const SelectTextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
	(props: TextFieldProps, ref) => {
		const theme = useTheme();
		return (
			<TextField
				ref={ref}
				{...props}
				select
				variant="standard"
				SelectProps={{
					...props.SelectProps,
				}}
				sx={{
					minWidth: 20,
					p: 0,
					m: 0,
					"& .MuiSelect-select": {
						...theme.typography.body2,
					},
					...props.sx,
				}}
			/>
		);
	}
);

function useHierarchyAPI() {
	return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>([
		"hierarchy",
		"aggregate",
	]);
}

const StatFunction = {
	Count: "count",
	Mean: "avg",
	Distinct: "distinct",
	Min: "min",
	Max: "max",
};

const StatFunctionMapping: { [key: string]: string } = {
	avg: "mean",
};

export function HierarchyVis(props: HierarchyVisProps) {
	const {
		dimensionOptions,
		dimensionDefaultOption,
		onClick: onChartElementClick,
		scope,
	} = props;

	const [statFunction] = useState(StatFunction.Count);

	useEffect(() => {
		setSelectedDimensionOption(dimensionDefaultOption);
	}, [dimensionDefaultOption]);

	const [selectedDimensionOption, setSelectedDimensionOption] =
		useState<Dimension>(dimensionDefaultOption);

	const facetValue = useCommonStore(state => state.facets);
	const metadata = useCommonStore(state => state.metadata);

	const { selectedDimensionValues } = useMemo(() => {
		let selectedDimensionValues: Array<string> = [];

		if (!metadata) {
			return {
				selectedDimensionValues,
			};
		}

		if (selectedDimensionOption?.name) {
			const dimensionFacetValue = facetValue?.get(
				selectedDimensionOption?.name
			);
			selectedDimensionValues = Array.from(dimensionFacetValue?.keys() || []);
		}

		return {
			selectedDimensionValues,
		};
	}, [metadata, selectedDimensionOption, facetValue]);

	const hierarchyAPIMutation = useHierarchyAPI();
	const hierarchyAPIMutator = useMemo(
		() => debounce(hierarchyAPIMutation.mutate, 500),
		[hierarchyAPIMutation.mutate]
	);

	const reset = hierarchyAPIMutation.reset;

	const statKey = useMemo(() => {
		return `${statFunction}(${selectedDimensionOption?.name})`;
	}, [selectedDimensionOption?.name, statFunction]);

	const searchQuery = useSearchStore(state => state.search);
	const facetState = useCommonStore(state => state.facets);

	const facetStateWithoutSelection = useMemo(() => {
		const state = new Map(facetState);

		state.delete(selectedDimensionOption?.name);

		return state;
	}, [facetState, selectedDimensionOption]);

	const criteria = useCriteriaBuilder(
		searchQuery,
		facetStateWithoutSelection,
		metadata
	);

	useEffect(() => {
		reset();
		if (!criteria) {
			return;
		}

		hierarchyAPIMutator({
			criteria,
			groupBy: [selectedDimensionOption.name],
			scope,
			statistics: [statKey], // TODO: Allow changing the aggregator
		});
	}, [
		criteria,
		scope,
		statKey,
		selectedDimensionOption,
		hierarchyAPIMutator,
		reset,
	]);

	const treeData = useMemo(() => {
		const items = hierarchyAPIMutation.data?.items;
		if (hierarchyAPIMutation?.isLoading) {
			return undefined;
		}
		if (!items) {
			return undefined;
		}

		let metricKeys = Object.keys(items);
		let aggData:
			| Array<MultiDimensionHierarchyDataType>
			| Array<SingleDimensionHierarchyDataType> = [];

		const statName = `${selectedDimensionOption.name}${
			StatFunctionMapping[statFunction] || statFunction
		}`;

		let singleDimensionData = [];
		for (let metricKey of metricKeys) {
			singleDimensionData.push({
				name: nilRemover(metricKey),
				count: (items[metricKey] as any as StatContainer)?.["statistics"]?.[
					statName
				],
			});
		}

		aggData = singleDimensionData;

		return createHierarchyData(aggData);
	}, [
		hierarchyAPIMutation.data,
		selectedDimensionOption,
		hierarchyAPIMutation?.isLoading,
		statFunction,
	]);

	// TODO: move to chart
	const categories = useMemo(() => {
		if (!treeData) {
			return [];
		}
		return treeData.filter(v => {
			return v.parent === "root";
		});
	}, [treeData]);

	const colors = categories
		.sort((c1, c2) => c2.datum.count - c1.datum.count)
		.map((c, i) => lighten(color1, i / categories.length));

	const colorScale = scaleOrdinal({
		domain: categories.map(v => v.datum.name),
		range: colors,
	});

	const renderEmptyView = () => {
		return (
			<Stack
				sx={{ minHeight: 330 }}
				alignItems={"center"}
				justifyContent="center"
			>
				<Typography variant="body2" color="info.main">
					It might take some time for analytics to be computed for a new account
				</Typography>
			</Stack>
		);
	};

	return (
		<>
			<Hierarchy
				selectedDimensionOption={selectedDimensionOption}
				facetState={facetState}
				dimensionOptions={dimensionOptions}
				onChangeDimensionOption={onChartElementClick}
				setSelectedDimensionOption={setSelectedDimensionOption}
				// selectedDimensionValues={selectedDimensionValues}
			/>

			<ParentSize>
				{parent => {
					return hierarchyAPIMutation.isLoading ||
						hierarchyAPIMutation.isIdle ? (
						<Stack
							sx={{ minHeight: 330 }}
							alignItems={"center"}
							justifyContent="center"
						>
							<CircularProgress size={24} />
						</Stack>
					) : !treeData || treeData?.length === 0 ? (
						renderEmptyView()
					) : (
						<TreeMapChart
							width={parent.width}
							height={300}
							selected={selectedDimensionValues?.map(optionName => {
								return {
									name: optionName,
									label: optionName,
								};
							})}
							data={treeData}
							onClick={(value, source) => {
								if (!onChartElementClick) {
									return;
								}
								if (value) {
									onChartElementClick({
										dimension: selectedDimensionOption,
										selectedDimensionOption: value,
										source,
									});
								}
							}}
							getColor={chartData => {
								let color = getColor(chartData.id, colorScale(chartData.id));
								return color;
							}}
						/>
					);
				}}
			</ParentSize>
		</>
	);
}

const nilRemover = (value: string) =>
	value === "<nil>" || !value ? nilPlaceHolderValue : value;

export const nilPlaceHolderValue = "NULL";
