import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Divider,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { AutoUpgradeStatus } from "pages/agents/components/agent-drawers/helpers/constants";
import { useRef, useState } from "react";
import { applianceDiagnosticSupportedMinVersion } from "../appliance-data-grid/constants";
import { AgentActionType } from "./Toolbar";

interface IRestartActionsProps {
	isRestartButtonDisabled: boolean;
	handleButtonChange: (btnAction: string, btnActionType: string) => void;
}

interface IUpgradeActionsProps {
	isUpgradeButtonDisabled: boolean;
	handleButtonChange: (btnAction: string, btnActionType: string) => void;
}

interface IAutoUpgradeActionsProps {
	handleButtonChange: (btnAction: string, btnActionType: string) => void;
}
interface IDiagnosticActionsProps {
	isDiagnosticsButtonDisabled: boolean;

	onClickDiagnostics: (btnAction: string, btnActionType: string) => void;
}

export const RestartActions = ({
	isRestartButtonDisabled,
	handleButtonChange,
}: IRestartActionsProps) => {
	return (
		<Stack direction="row">
			<Tooltip
				title={window.getCTTranslatedText(
					isRestartButtonDisabled
						? "A restart request is already in progress for selected agents."
						: ""
				)}
			>
				<span>
					<Button
						disabled={isRestartButtonDisabled}
						color="primary"
						variant="outlined"
						onClick={() => handleButtonChange(AgentActionType.RESTART, "")}
					>
						{window.getCTTranslatedText("restartGatekeeper")}
					</Button>
				</span>
			</Tooltip>
		</Stack>
	);
};

export const UpgradeActions = ({
	isUpgradeButtonDisabled,
	handleButtonChange,
}: IUpgradeActionsProps) => {
	return (
		<Stack direction="row">
			<Tooltip
				title={window.getCTTranslatedText(
					isUpgradeButtonDisabled
						? "The selected gatekeeper is already at the latest version or it has an ongoing process."
						: ""
				)}
			>
				<span>
					<Button
						disabled={isUpgradeButtonDisabled}
						color="primary"
						variant="outlined"
						onClick={() => handleButtonChange(AgentActionType.UPGRADE, "")}
					>
						{window.getCTTranslatedText("upgradeGatekeeper")}
					</Button>
				</span>
			</Tooltip>
		</Stack>
	);
};

export const AutoUpgradeActions = ({
	handleButtonChange,
}: IAutoUpgradeActionsProps) => {
	const autoUpgradeAnchorRef = useRef<HTMLDivElement>(null);
	const [openAutoUpgradeMenu, setAutoUpgradeMenuOpen] = useState(false);
	const handleMenuClose = (event: Event) => {
		if (
			autoUpgradeAnchorRef.current &&
			autoUpgradeAnchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}
		setAutoUpgradeMenuOpen(false);
	};

	const handleMenuToggle = (
		ref: React.RefObject<HTMLDivElement> | undefined
	) => {
		if (ref) {
			setAutoUpgradeMenuOpen(prevOpen => !prevOpen);
		}
	};

	const popperMenuContent = (id: string, options: AutoUpgradeStatus[]) => {
		return (
			<Paper elevation={8}>
				<ClickAwayListener onClickAway={handleMenuClose}>
					<MenuList id={id} autoFocusItem>
						{options.map((option, index, options) => (
							<Stack key={option} sx={{ mx: 2 }}>
								<MenuItem
									onClick={() =>
										handleButtonChange(AgentActionType.AUTOUPGRADE, option)
									}
								>
									<Stack
										alignItems="flex-start"
										justifyContent="flex-start"
										justifyItems="flex-start"
										sx={{ width: "100%" }}
									>
										<Typography
											variant="body2"
											sx={{
												textAlign: "left",
											}}
										>
											{window.getCTTranslatedText(option)}
										</Typography>
									</Stack>
								</MenuItem>
								{index < options.length - 1 && <Divider />}
							</Stack>
						))}
					</MenuList>
				</ClickAwayListener>
			</Paper>
		);
	};

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems={"center"}
			sx={{ height: "100%" }}
		>
			<ButtonGroup
				variant="outlined"
				ref={autoUpgradeAnchorRef}
				aria-label="split button"
			>
				<Button
					aria-controls={openAutoUpgradeMenu ? "auto-upgrade-menu" : undefined}
					aria-expanded={openAutoUpgradeMenu ? "true" : undefined}
					aria-label="auto upgrade"
					aria-haspopup="menu"
					onClick={() => handleMenuToggle(autoUpgradeAnchorRef)}
					endIcon={<ArrowDropDownIcon />}
				>
					{window.getCTTranslatedText("autoUpgradeButton")}
				</Button>
			</ButtonGroup>

			<Popper
				key="blast-radius-menu"
				sx={{
					zIndex: 1,
					minWidth: "230px",
				}}
				open={openAutoUpgradeMenu}
				anchorEl={autoUpgradeAnchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						{popperMenuContent(
							"auto-upgrade-menu",
							Object.values(AutoUpgradeStatus)
						)}
					</Grow>
				)}
			</Popper>
		</Stack>
	);
};

export const DiagnosticActions = ({
	onClickDiagnostics,
	isDiagnosticsButtonDisabled,
}: IDiagnosticActionsProps) => {
	return (
		<Stack direction="row">
			<Tooltip
				title={
					isDiagnosticsButtonDisabled
						? window.getCTTranslatedText(
								"agentDiagnosticSupportedMinVersionTooltipText",
								{
									AgentDiagnosticSupportedMinVersion:
										applianceDiagnosticSupportedMinVersion,
								}
							)
						: ""
				}
			>
				<span>
					<Button
						disabled={isDiagnosticsButtonDisabled}
						color="primary"
						variant="outlined"
						onClick={() => onClickDiagnostics(AgentActionType.DIAGNOSTIC, "")}
					>
						{window.getCTTranslatedText("diagnosticsButton")}
					</Button>
				</span>
			</Tooltip>
		</Stack>
	);
};
