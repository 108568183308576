import { Stack } from "@mui/material";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { GuardrailCheckbox } from "common/atoms/ct-guardrail/GuardrailCheckbox";
import { useState } from "react";
import { TagPolicy } from "../../types";
import { MatchedAssetCount } from "../matched-asset-count";

type Props = {
	policy: TagPolicy;
	isOpen: boolean;
	onClose: () => void;
	title: string;
	primaryText: string;
	isLoading: boolean;
	onSuccess: () => void;
	secondaryTextWarning: string;
};

export function TagPolicyCOnfirmationDialog({
	policy,
	isOpen,
	onClose,
	title,
	primaryText,
	isLoading,
	onSuccess,
	secondaryTextWarning,
}: Props) {
	const hasAssets = (policy?.matchingAssets ?? 0) > 0;
	const [acknowledgement, setAcknowledgement] = useState<boolean>(false);

	function renderSecondaryText() {
		return (
			<Stack>
				{hasAssets ? window.getCTTranslatedText(secondaryTextWarning) : null}
				{hasAssets ? (
					<MatchedAssetCount
						policy={policy}
						addText={true}
						openInNewTab={true}
					/>
				) : null}
				<GuardrailCheckbox
					acknowledgement={acknowledgement}
					setAcknowledgement={setAcknowledgement}
				/>
			</Stack>
		);
	}
	return (
		<>
			<CTConfirmation
				open={isOpen}
				onClose={() => {
					onClose();
					setAcknowledgement(false);
				}}
				title={title}
				primaryText={primaryText}
				secondaryText={renderSecondaryText()}
				primaryButtonText={"Confirm"}
				primaryButtonDisabled={!acknowledgement}
				isLoading={isLoading}
				onSuccess={onSuccess}
			/>
		</>
	);
}
