export const buildAssetCriteria = (
	assetIds: string[],
	areAllAssetsSelected: boolean,
	criteria?: string
): string => {
	if (areAllAssetsSelected && criteria) {
		return criteria;
	}

	return `assetId in (${(assetIds ?? []).map(id => `'${id}'`).join(",")})`;
};
