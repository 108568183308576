import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { SearchBar } from "modules/add-to-templates/components/search-bar";
import { Annotation } from "modules/annotation";
import { ListTable } from "modules/assign-networks-to-assets/components/list-table";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { PathRecommendationPageType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import {
	ButtonVariant,
	RecommendationsButton,
} from "modules/recommendation-workflows/RecommendationsButton";
import {
	ASSET_NETWORK_COLUMNS,
	ASSIGNED_NETWORKS_TAG_COLUMNS,
	NETWORK_LIST_COLUMNS,
} from "pages/networks/components/network-data-grid/constants";
import { RemoveNetworksDialog } from "pages/networks/components/remove-networks-dialog";
import { Network } from "pages/networks/types";
import { MatchedAssetCount } from "pages/tags/components/tag-policy-list/components/matched-asset-count";
import { useCallback, useMemo, useState } from "react";
import {
	AssignedNetworksViewDrawerProps,
	NamedNetworkDrawerType,
} from "./types";

export const AssignedNetworksViewDrawer = ({
	isOpen,
	onClose,
	networkList,
	networksSuggestMutation,
	isLoading,
	title = "Assigned Named Networks",
	AssignNetworksOption,
	page = "assets",
	policyId,
	onSortChange,
	canDelete = true,
	multiselect = false,
	criteria,
	hasPermission,
	setPage,
	setPageSize,
	pageCount,
	pageSize,
	policy,
	totalCount,
	showSearch = true,
	onSearchCriteriaChange,
}: AssignedNetworksViewDrawerProps) => {
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [searchText, setSearchText] = useState("");
	const [selectedNetworkList, setSelectedNetworkList] = useState<
		Array<Network>
	>([]);

	const handleConfirmationVisibility = (value: boolean) => {
		setShowConfirmation(value);
	};

	const columns = useMemo(() => {
		let COLUMNS: GridColDef[] = NETWORK_LIST_COLUMNS;

		if (page === "tags") {
			COLUMNS = [
				...ASSIGNED_NETWORKS_TAG_COLUMNS.filter(
					column =>
						column.field !== "namedNetworkAssignments" &&
						column.field !== "namednetworkTagBasedPolicyAssignments" &&
						column.field !== "ugnamedNetworkAssignments"
				),
			];
		} else if (page === "assets") {
			COLUMNS = NETWORK_LIST_COLUMNS.filter(
				column =>
					column.field !== "namedNetworkAssignments" &&
					column.field !== "namednetworkTagBasedPolicyAssignments" &&
					column.field !== "ugnamedNetworkAssignments"
			);
		} else {
			COLUMNS = ASSET_NETWORK_COLUMNS;
		}
		return COLUMNS;
	}, [page]);

	const triggerUnassign = () => {
		handleConfirmationVisibility(true);
	};

	const onSelectCallback = useCallback((networks: Array<Network>) => {
		setSelectedNetworkList(networks);
	}, []);

	const isValid = () => {
		if (multiselect) {
			return Boolean(selectedNetworkList?.length > 0);
		}
	};

	const onSearchTextChange = (searchText: string) => {
		onSearchCriteriaChange?.(searchText ?? "");
		setSearchText(searchText ?? "");
	};

	const handlerOnClose = () => {
		onSearchCriteriaChange?.("");
		setSearchText("");
		onClose();
	};

	const hasAssets = (policy?.matchingAssets ?? 0) > 0;

	function confirmationSecondaryText() {
		return (
			<Stack>
				{hasAssets ? window.getCTTranslatedText("RemoveNNWarning") : null}
				{hasAssets ? (
					<MatchedAssetCount
						policy={policy}
						addText={true}
						openInNewTab={true}
					/>
				) : null}
			</Stack>
		);
	}

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={handlerOnClose}
			PaperProps={{
				sx: {
					width: "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
			}}
		>
			<Toolbar />
			<DialogContent sx={{ width: "100%", height: "100%" }}>
				<Stack
					alignItems="flex-start"
					sx={{ position: "relative", width: "100%" }}
				>
					<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
						<IconButton
							size="large"
							aria-label="close drawer"
							onClick={handlerOnClose}
							sx={{
								position: "absolute",
								right: "0px",
								top: "0px",
								zIndex: 2,
							}}
						>
							<CloseIcon fontSize="medium" />
						</IconButton>
					</Tooltip>
				</Stack>
				<Stack sx={{ height: "100%", width: "100%" }} alignItems="flex-start">
					<Stack alignItems="flex-start">
						<Typography variant="h6" sx={{ mt: 2, width: "100%" }}>
							{window.getCTTranslatedText(title)}
						</Typography>
					</Stack>
					<Stack
						alignItems="flex-start"
						sx={{ pt: 4, width: "100%", flex: 1, overflow: "hidden" }}
					>
						<Stack
							direction="row"
							sx={{ width: "100%", justifyContent: "flex-end" }}
							spacing={3}
						>
							<SearchBar
								id="named-network-list-searchbar"
								label={window.getCTTranslatedText("Search Named Networks")}
								placeholder={"Search Named Networks"}
								selectedValue={searchText}
								onChange={onSearchTextChange}
								onFocus={() => {}}
							/>
							{AssignNetworksOption && (
								<Stack
									justifyContent="flex-end"
									alignItems="center"
									direction={"row"}
									sx={{ pb: 2 }}
								>
									{page === "tags" && (
										<Box sx={{ mr: 2 }}>
											<RecommendationsButton
												variant={ButtonVariant.OUTLINED}
												page={PathRecommendationPageType.Tags}
												criteria={criteria}
												policyId={policyId}
											/>
										</Box>
									)}

									{AssignNetworksOption()}
								</Stack>
							)}
						</Stack>

						<Stack sx={{ flex: 1, overflow: "hidden", width: "100%" }}>
							<ListTable
								list={networkList}
								columns={columns}
								mutation={networksSuggestMutation}
								isLoading={isLoading}
								rowClickCallback={() => {}}
								onSortChange={onSortChange}
								multiselect={multiselect}
								onSelectCallback={onSelectCallback}
								hasPermission={hasPermission}
								drawerType={NamedNetworkDrawerType.Assigned}
								setPage={setPage}
								setPageSize={setPageSize}
								page={pageCount}
								pageSize={pageSize}
								totalCount={totalCount ?? networkList.length}
								paginationMode={page === "tags" ? "client" : "server"}
								sorting={page === "tags" ? false : true}
							/>
							{selectedNetworkList?.length > 0 && (
								<Annotation setComment={setComment} comment={comment} />
							)}
						</Stack>
					</Stack>
					{showConfirmation && (
						<RemoveNetworksDialog
							criteria={criteria ?? ""}
							networks={selectedNetworkList}
							allowRemove={showConfirmation}
							handleAllowRemove={handleConfirmationVisibility}
							confirmationSecondaryText={confirmationSecondaryText()}
							page={page}
							policyId={policyId}
							onConfirm={handlerOnClose}
							comment={comment}
						/>
					)}
				</Stack>
			</DialogContent>
			{multiselect && (
				<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
					<ToolbarAction
						loading={false}
						save={triggerUnassign}
						cancel={handlerOnClose}
						isValid={isValid()}
						actionBtnText={"Remove"}
						secondaryBtnText={"cancel"}
					/>
				</DialogActions>
			)}
		</Drawer>
	);
};
