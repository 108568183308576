import { Box, Stack, Typography } from "@mui/material";
import { AppliedFacetChipsLocation } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { CustomCommonStoreType } from "common/types/types";
import { useAntlrParser } from "hooks/useAntlrParser/useAntlrParser";
import { OPERATORS_LIST } from "modules/facets/constants";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { CriteriaVisitor, NodeValue } from "parser/CriteriaVisitor";
import { ClauseContext } from "parser/ctqlParser";
import { AppliedFacetsChips } from "../applied-facets-chips";

export function transformVisitorObject(
	input: Record<string, NodeValue>,
	metadata: ScopeMetadata | undefined
): FacetState {
	const facetState: FacetState = new Map();

	for (const [key, node] of Object.entries(input)) {
		const { values, operator } = node;
		const optionState: FacetOptionState = new Map();
		values.forEach(value => {
			optionState.set(value, {
				isSelected: true,
				operator: OPERATORS_LIST.find(op => op === operator) ?? Operator.EQUAL,
			});
		});

		const facetName =
			key.startsWith("'") && key.endsWith("'") ? key.slice(1, -1) : key;
		facetState.set(
			metadata?.namesToColumn[facetName]?.internalName ?? facetName,
			optionState
		);
	}

	return facetState;
}

export const getParsedCriteria = (
	criteria: ClauseContext,
	metadata: ScopeMetadata | undefined
) => {
	const visitor = new CriteriaVisitor();
	return transformVisitorObject(visitor.visit(criteria), metadata);
};

type CriteriaData = {
	[key: string]: any;
	criteriaAsParams?: string | undefined;
	criteria?: string | undefined;
};

interface Props {
	data: CriteriaData;
	useFacetStore: CustomCommonStoreType;
	isValid: boolean;
	readOnlyDimensions?: Array<string>;
}

function CriteriaChips({
	data,
	useFacetStore,
	isValid,
	readOnlyDimensions,
}: Props) {
	const parseCriteria = useAntlrParser();
	const metadata = useFacetStore(state => state.metadata);

	if (!isValid) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	let urlParams = new URLSearchParams();

	let search = urlParams.get("search");

	const getFacetState = () => {
		const parseResult = parseCriteria(data?.criteria ?? "");
		if (parseResult) {
			return getParsedCriteria(parseResult, metadata);
		}
	};

	let facetState = getFacetState();

	if (!facetState && search) {
		facetState = new Map();
	}

	if (search && facetState) {
		let options = new Map();
		options.set(search, {
			isSelected: true,
		});
		facetState?.set("Query", options);
	}

	const facetHasKey = Array.from(facetState?.keys() || [])?.[0]?.length > 0;

	return (
		<Stack
			sx={{
				ml: facetHasKey ? -1 : 0,
				width: "100%",
				"& .facet-chips": {
					flexWrap: "nowrap",
				},
			}}
		>
			{facetHasKey ? (
				<>
					<AppliedFacetsChips
						useFacetStore={useFacetStore}
						appliedChipsLocation={AppliedFacetChipsLocation.SAVED_QUERY}
						facetState={facetState}
						sortByName={true}
						facetChipProps={{
							size: "small",
							sx: {
								fontSize: "12px",
							},
						}}
						readOnlyDimensions={readOnlyDimensions}
					/>
				</>
			) : (
				<>
					<Typography variant="body2">{data?.criteria}</Typography>
				</>
			)}
		</Stack>
	);
}

export default CriteriaChips;
