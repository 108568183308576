import { Box, Link, Stack, Tooltip } from "@mui/material";
import { useAntlrParser } from "hooks/useAntlrParser/useAntlrParser";
import { FacetUtils } from "modules/facets";
import { getParsedCriteria } from "modules/facets/components/criteria-chips/CriteriaChips";
import { OVERWRITE_FACETS } from "modules/facets/constants";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import numeral from "numeral";
import { Link as RouterLink } from "react-router-dom";
import { TagPolicy } from "../../types";

export function MatchedAssetCount({
	policy,
	addText,
	openInNewTab,
}: {
	policy?: TagPolicy;
	addText?: boolean;
	openInNewTab?: boolean;
}) {
	const parseCriteria = useAntlrParser();
	const { data } = useScopeMetadata({ scope: Scope.TagBasedPolicy });

	if (!policy?.tagBasedPolicyId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	const getCriteria = (policy?: TagPolicy) => {
		const parseResult = parseCriteria(policy?.criteria ?? "");
		if (parseResult) {
			return `filters=${encodeURIComponent(FacetUtils.getURLQueryFromFacets(getParsedCriteria(parseResult, data)))}`;
		}

		return "";
	};

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			<Stack alignItems="flex-start">
				<Tooltip title={window.getCTTranslatedText("View assets")}>
					<Link
						typography={"body2"}
						component={RouterLink}
						to={`/assets?${getCriteria(policy)}&${OVERWRITE_FACETS}`}
						sx={{
							color: "primary.main",
							textDecoration: "none",
						}}
						target={openInNewTab ? "_blank" : undefined}
						rel={openInNewTab ? "noopener noreferrer" : undefined}
					>
						{numeral(policy?.matchingAssets ?? 0).format("0a")}{" "}
						{addText ? window.getCTTranslatedText("Assets") : ""}
					</Link>
				</Tooltip>
			</Stack>
		</Stack>
	);
}
