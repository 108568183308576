import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadataColumn } from "modules/scope-metadata/types";
import { useMemo, useRef } from "react";

export interface Tags {
	coreTags: ScopeMetadataColumn[];
	userDefinedTags: ScopeMetadataColumn[];
}

export function useTagsAPI(scope: Scope = Scope.Asset) {
	const { data: metaData, isLoading } = useScopeMetadata({
		scope,
	});

	const colList = Object.keys(metaData?.columns || {});
	const metaDataRef = useRef(metaData);
	metaDataRef.current = metaData;

	const tagFields = useMemo<Tags | undefined>(() => {
		if (!metaDataRef.current || !colList.length) {
			return;
		}

		const fields = Object.keys(metaDataRef.current?.columns);

		let coreTags: Array<ScopeMetadataColumn> = [];
		let userDefinedTags: Array<ScopeMetadataColumn> = [];

		(fields ?? []).forEach((field: string) => {
			let column = metaDataRef.current?.columns?.[field];
			if (!column) {
				return;
			}

			column = { ...column };
			column.name = field;

			if (column.coreTag && !column.userDefined) {
				coreTags.push(column);
			} else if (column?.userDefined) {
				userDefinedTags.push(column);
			}
		});

		return {
			coreTags: coreTags,
			userDefinedTags: userDefinedTags,
		};
	}, [colList.length]);

	return { tagFields, metaData, isLoading };
}
