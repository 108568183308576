import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { dayjsWithPlugins } from "common/utils";
import { useAgentOtpAPI } from "pages/agents/components/agent-data-grid-toolbar/hooks";
import { ConfigCard } from "../common/components/config-card";

export function AgentFirewallResetPassword() {
	const { data: agentOtpData, isLoading } = useAgentOtpAPI({
		enabled: true,
	});
	const expiresOnDisplay = dayjsWithPlugins(
		agentOtpData?.expirationTime ?? 0
	).format("MMM DD, YYYY h:mm A");

	if (isLoading) {
		return (
			<Skeleton
				variant="rectangular"
				width={"100%"}
				height={"245px"}
				animation="wave"
			/>
		);
	}

	return (
		<ConfigCard title="AgentFirewallResetPassword">
			<Stack>
				<Typography variant="body2">
					{window.getCTTranslatedText("ReconnectAgentPasswordInstructions")}
				</Typography>
				<Stack
					variant="outlined"
					component={Paper}
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
					p={2}
					my={3}
				>
					<Typography variant="body2">{agentOtpData?.otp}</Typography>
					<CopyButton text={agentOtpData?.otp} />
				</Stack>
				<Typography variant="body2">
					{`${window.getCTTranslatedText("ExpiresOn")} ${expiresOnDisplay}`}
				</Typography>
			</Stack>
		</ConfigCard>
	);
}
