import { AssetSecurityLevelIcons, SecurityLevels } from "@colortokens/ng-ui";
import { Box } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";

type VulnerabilitySeverity = SecurityLevels | "no value";

export function UnmanagedDeviceVulnerabilitySeverity({
	device,
	viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
	const severity = device.deviceVulnerabilitySeverity as VulnerabilitySeverity;

	if (!device.deviceId || severity === "no value") {
		return <Box style={{ width: 150 }} />;
	}

	if (severity) {
		return (
			<CTDatagridButton
				isDisabled={viewOnly === true}
				asText={viewOnly === true}
				facetName={"devicevulnerabilityseverity"}
				facetOption={device?.deviceVulnerabilitySeverity ?? ""}
				startIcon={
					<AssetSecurityLevelIcons
						securityLevel={
							device?.deviceVulnerabilitySeverity as SecurityLevels
						}
					/>
				}
			>
				{window.getCTTranslatedText(severity)}
			</CTDatagridButton>
		);
	}
	return null;
}
