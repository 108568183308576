import { styled } from "@mui/material/styles";
export const ReactFlowContainer = styled("div")(({ theme }) => ({
	flex: 1,
	position: "relative",

	".react-flow": {
		"--node-box-shadow":
			"5px 0 7px rgba(42, 138, 246, 0.3), -5px 0 7px rgba(233, 42, 103, 0.3)",
	},

	"& .react-flow__background > pattern": {
		stroke:
			theme.palette.mode === "dark" ? "rgba(238, 238, 238, 0.05)" : "initial",
	},

	"& .react-flow__node-ctNode": {
		borderRadius: "0 !important",
		background: "transparent !important",
		border: "none !important",
		boxShadow: "initial !important",
		pointerEvents: "none !important",
		cursor: "pointer !important",
		transition: "all ease-in-out 250ms",
	},

	"& .react-flow__edge-edgeWithButton": {
		transition: "all ease-in 350ms",
	},

	".nodeDragging .react-flow__node-ctNode, .nodeDragging .react-flow__edge-edgeWithButton":
		{
			transition: "none",
		},

	"& .react-flow__edge-path-selector": {
		fill: "none",
		stroke: "transparent",
		strokeWidth: 18,
	},

	"& .react-flow__node": {
		borderWidth: "0 !important",
	},

	"& .react-flow__node.react-flow__node-default": {
		textOverflow: "ellipsis",
		wordBreak: "break-word",
		whiteSpace: "nowrap",
		padding: "0px !important",

		display: "flex",
		alignItems: "center",
		flexDirection: "column",

		background: "transparent",
		border: `1px dashed ${theme.palette.text.secondary} !important`,
		borderWidth: `1px !important`,
		justifyContent: `flex-start`,
	},

	"& .react-flow__handle": {
		left: "50%",
		top: "50%",
		transform: "translate(-50%,-50%)",
		opacity: 0,
		pointerEvents: "none",
	},

	"& .react-flow__node-ctNode .ctNode-internal": {
		textAlign: "center",
		fontSize: "18px",
		width: "min(100%, 70px)",
		height: "min(100%, 70px)",
		padding: "2px !important",
		background: theme.palette.mode === "dark" ? undefined : "#fff",
		borderRadius: "8px",
		border: `1px solid #FFFFFF1F`,
		boxShadow:
			theme.palette.mode === "dark"
				? "none"
				: `
			0px 1px 10px 0px #0000001F,
			0px 4px 5px 0px #00000024,
			0px 2px 4px -1px #00000033
		`,
	},

	"& .react-flow__node-ctNode .ctNode-internal.others": {
		outline: "1px solid #00C0CA",
		outlineOffset: "-4px",
	},

	"& .react-flow__node-ctNode .ct-drag-handle": {
		pointerEvents: "all",
	},

	"& .react-flow__node-ctNode.ctSelected .ctNode-internal, & .react-flow__node-ctNode.ctSelected .ctNode-container":
		{
			border: `1px solid ${theme.palette.primary.main}`,
		},

	"& .react-flow__node-ctNode.ctSelected .ctNode-internal.others": {
		border: `1px solid ${theme.palette.primary.main}`,
		borderStyle: "double",
		borderWidth: "4px",
	},

	"& .ctNode-container::before": {
		content: `""`,
		position: "absolute",
		inset: "0",
		opacity: 0,
		transition: "opacity ease-in-out 300ms",
		backgroundSize: "cover",
	},

	"& .ctNode-container.isLoading.light::before": {
		background: `url(/assets/images/VisualizerLoader_Light.gif)`,
		backgroundSize: "cover",
		mixBlendMode: "hue",
	},

	"& .ctNode-container.isLoading.dark::before": {
		background: `url(/assets/images/VisualizerLoader_Dark.gif)`,
		backgroundSize: "cover",
		mixBlendMode: "color-dodge",
	},

	"& .ctNode-container.isLoading::before": {
		opacity: 1,
	},

	"& .visx-main-loader": {
		borderRadius: "100%",
	},
}));
