import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { PathRule } from "pages/paths/types";
import { PortRule } from "pages/ports/types";
import {
	TemplatePathRule,
	TemplatePortRule,
} from "pages/templates/components/remove-template-rule-dialog/RemoveTemplateRuleDialog";
import { useTemplateStore } from "pages/templates/store";

export interface TemplateRemoveProps {
	rule: TemplatePortRule | TemplatePathRule | GridRowId[];
	page: string;
	handleAllowRemove: Function;
	allowRemove: boolean;
	templateID?: string;
	secondaryText?: string | JSX.Element;
}

interface TemplateRemoveBodyProps {
	ports: Array<string | GridRowId | undefined>;
	paths: Array<string | GridRowId | undefined>;
}

const useRemoveTemplatesAPI = (templateId: string | undefined) => {
	return useMutation<any, Error, any>([
		"policy",
		`templates/${templateId}/deduct`,
		"delete",
	]);
};

export function TemplateRemoveRule({
	rule,
	page,
	handleAllowRemove,
	allowRemove,
	templateID,
	secondaryText,
}: TemplateRemoveProps) {
	const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
	const urlParams = new URLSearchParams(window.location.search);
	const notify = useEventSubscriptionStore(state => state.notify);
	const templateId =
		urlParams.get("templateId") ??
		(Array.isArray(rule) ? (templateID ?? "") : rule?.templateId);

	const removeTemplatesAPI = useRemoveTemplatesAPI(templateId);

	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	const updateTemplateDetails = () => {
		if (templateId) {
			setLoading(true);

			const body: TemplateRemoveBodyProps = buildRequestBody();

			removeTemplatesAPI.mutate(body, {
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: ["policy"],
					});
					requestAPIRefresh();
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label:
							page === "port"
								? "PortRemovedFromTemplate"
								: "PathRemovedFromTemplate",
					});
					setTimeout(() => {
						handleAllowRemove(false);
						setLoading(false);
					}, 500);
				},
				onError: () => {
					setLoading(false);
					handleAllowRemove(false);
				},
			});
		}
	};

	function buildRequestBody() {
		let body: TemplateRemoveBodyProps = {
			ports: [],
			paths: [],
		};

		if (page === "port") {
			if (Array.isArray(rule)) {
				body.ports = rule;
			} else {
				const portRule: PortRule = rule as PortRule;
				body.ports.push(portRule?.lpId);
			}
		} else {
			if (Array.isArray(rule)) {
				body.paths = rule;
			} else {
				const pathRule: PathRule = rule as PathRule;
				body.paths.push(pathRule?.channelHash);
			}
		}

		return body;
	}

	return (
		<>
			<Dialog
				open={allowRemove}
				onClose={() => handleAllowRemove(false)}
				aria-labelledby="delete-template-rule"
			>
				<DialogTitle id="delete-template">
					{window.getCTTranslatedText(`Delete Rule`)}
				</DialogTitle>
				<DialogContent>
					{window.getCTTranslatedText(
						Array.isArray(rule) && rule?.length !== 1
							? "templateRemovalConfirmationTextPlural"
							: "templateRemovalConfirmationTextSingular"
					)}

					{secondaryText ? (
						<>
							<br />
							<Typography variant="body2">{secondaryText}</Typography>
						</>
					) : null}
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="inherit"
						onClick={() => handleAllowRemove(false)}
					>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<LoadingButton
						loading={removeTemplatesAPI.isLoading || loading}
						onClick={updateTemplateDetails}
						autoFocus
						variant="contained"
						color={"error"}
						type="submit"
					>
						{window.getCTTranslatedText("Remove")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
