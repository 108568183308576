import { AgentFirewallResetPassword } from "./AgentFireWallPassword";
import { AgentPasswordConfig } from "./AgentPasswordConfig";
import { SessionTimeoutConfig } from "./SessionTimeoutConfig";

export function Configurations() {
	return (
		<>
			<AgentPasswordConfig />
			<SessionTimeoutConfig />
			<AgentFirewallResetPassword />
		</>
	);
}
