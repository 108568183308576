import { Box, Link, Stack, Typography } from "@mui/material";

import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useCommonStore } from "common/store";
import { getStatus } from "common/utils/statusUtils";
import { StatusText } from "pages/paths/constants";
import {
	PathColumnRenderProps,
	PathStatus as PathStatusOptions,
} from "pages/paths/types";
import { Link as RouterLink } from "react-router-dom";

const width = 180;

export function PathStatusView({
	path,
	viewOnly,
	useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
	const facetState = useFacetStore(state => state.facets);
	if (!path.channelHash) {
		return <Box style={{ minWidth: width, minHeight: 45 }} />;
	}

	const destAssetName = path.destinationAsset?.assetName || "";

	const status = StatusText[path?.reviewed || PathStatusOptions.Unreviewed];
	const statusDetails = getStatus(status);
	if (statusDetails.status === StatusText[PathStatusOptions.Allow]) {
		statusDetails.description = statusDetails.description || "Default";
	}

	const isSelected =
		!viewOnly && facetState?.get("reviewed")?.get(path.reviewed)?.isSelected;

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				asText={viewOnly === true}
				isDisabled={viewOnly === true}
				useFacetStore={useFacetStore}
				facetName={"reviewed"}
				facetOption={path.reviewed ?? ""}
			>
				{window.getCTTranslatedText(statusDetails.status)}
			</CTDatagridButton>
			{statusDetails.description && (
				<Typography
					variant="caption"
					color={isSelected ? "primary" : "inherit"}
					sx={{ pl: 1 }}
				>
					{window.getCTTranslatedText(statusDetails.description)}
				</Typography>
			)}

			{!path.isRowSelectable && !viewOnly && path.direction === "inbound" && (
				<Link
					typography={"caption"}
					component={RouterLink}
					to={`/ports?filters=${encodeURIComponent(
						`listenport=${path.port}|listenportprotocol=${path.protocol}|assetname=${destAssetName}`
					)}`}
					sx={{ color: "info.main", pl: 1 }}
				>
					{window.getCTTranslatedText("Configure port")}
				</Link>
			)}
		</Stack>
	);
}
