import {
	Button,
	Card,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { UserInfoType } from "modules/analytics/KnowledgeBase";
import { ARCHITECTURE_TYPE } from "pages/agents/components/agent-download-page/constants";
import { PlatformData } from "pages/agents/components/agent-download-page/type";
import { useState } from "react";
import { InstallationLinks, PLATFORMS } from "../../../constants";
import { renderIcons } from "../ApplianceInstall";

export interface VersionInfo {
	version: string;
	downloadLink: string;
	architecture: string;
	fileName: string;
	agentType: string;
	targetPlatform: string;
	recommended: boolean;
}

export interface AgentVersion {
	platform: string;
	platformData: PlatformData;
	agentGatwayVersion: VersionInfo[];
	knowledgeBaseHmac: UserInfoType | null;
}

function ProccessPlatform(platform: string) {
	switch (platform) {
		case PLATFORMS.ISO:
			return `ISO (.iso)`;
		case PLATFORMS.OVA:
			return `OVA (.ova)`;
		case PLATFORMS.DEBIAN:
			return `Debian (.deb)`;
	}
}

export function DownloadItem(props: Readonly<AgentVersion>) {
	const theme = useTheme();
	const architecture = ARCHITECTURE_TYPE.X86_64;
	const [agentVersion, setAgentVersion] = useState(() => {
		const latestVersionArr = props?.agentGatwayVersion.filter(
			arch => architecture === arch.architecture && arch.recommended
		);

		return latestVersionArr.length > 0 ? latestVersionArr[0]?.version : "";
	});

	const handleChange = (event: SelectChangeEvent) => {
		setAgentVersion(event?.target?.value);
	};

	const onDownloadClick = () => {
		let selectedAgent = props?.agentGatwayVersion.find(agent => {
			return agent.version === agentVersion;
		});
		if (selectedAgent?.downloadLink) {
			window.open(selectedAgent.downloadLink, "_blank", "noopener, noreferrer");
		}
	};

	const onInstallInstructionsClick = () => {
		let knowledgeBaseParams = `?hmac=${encodeURIComponent(
			JSON.stringify(props.knowledgeBaseHmac)
		)}`;
		let docLink = `${
			InstallationLinks[props.platform as keyof typeof InstallationLinks]
		}${knowledgeBaseParams}`;
		window.open(docLink, "_blank", "noopener, noreferrer");
	};

	return (
		<Stack
			sx={{
				p: 5,
				width: "100%",
			}}
			component={Card}
			direction="row"
			alignItems={"center"}
			justifyContent="space-between"
			boxShadow={2}
			borderRadius={1}
		>
			<Stack direction="row" spacing={1} minWidth={180}>
				<Stack justifyContent={"center"}>
					{renderIcons(props.platform, theme.palette.text.primary)}
				</Stack>
				<Stack justifyContent={"center"}>
					<Typography variant="subtitle1">
						{ProccessPlatform(props.platform)}
					</Typography>
				</Stack>
			</Stack>
			<Stack>
				<FormControl>
					<Select
						displayEmpty
						sx={{
							display: "flex",
							textAlign: "left",
							fontSize: "small",
							minWidth: 200,
						}}
						value={agentVersion}
						inputProps={{ "aria-label": "Without label" }}
						onChange={handleChange}
					>
						{props.platformData?.versionsInfo?.map((vName: VersionInfo) => (
							<MenuItem
								sx={{
									display: "flex",
									backgroundColor: "initial",
									fontSize: "small",
									justifyContent: "flex-start",
								}}
								key={vName?.version}
								value={vName?.version}
							>
								<Typography variant="body2" sx={{ pointerEvents: "all" }}>
									{vName?.version}
								</Typography>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Stack>
			<Stack direction="row" spacing={2}>
				<Button
					color="primary"
					variant="outlined"
					onClick={() => onInstallInstructionsClick()}
				>
					{window.getCTTranslatedText("installInstructions")}
				</Button>
				<Button
					color="primary"
					variant="outlined"
					onClick={() => onDownloadClick()}
				>
					{window.getCTTranslatedText("download")}
				</Button>
			</Stack>
		</Stack>
	);
}
