import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Stack, Tooltip } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { PortColumnRenderProps, PortStatus } from "pages/ports/types";
import { useState } from "react";
import PortLateralMovementDrawer from "./PortLateralMovementDrawer";

export function PortLateralMovement({ port }: PortColumnRenderProps) {
	const [showLateralMovementDrawer, setShowLateralMovementDrawer] =
		useState(false);
	const lateralMovementCount = (port?.lateralMovementTechniques ?? []).length;
	const showWarningIcon = port?.listenPortEnforced === PortStatus.Unreviewed;

	if (!lateralMovementCount) {
		return <div></div>;
	}
	return (
		<>
			<Stack flexDirection="row">
				<DataGridButton
					variant={"text"}
					color={"primary"}
					onClick={() => setShowLateralMovementDrawer(true)}
				>
					{lateralMovementCount}
				</DataGridButton>
				{showWarningIcon && (
					<Tooltip
						title={window.getCTTranslatedText("LateralMovementWarnTooltip")}
					>
						<ErrorOutlineRoundedIcon
							style={{
								width: "18px",
								height: "18px",
							}}
							color="warning"
							sx={{ mt: "3px" }}
						/>
					</Tooltip>
				)}
			</Stack>
			{showLateralMovementDrawer && (
				<PortLateralMovementDrawer
					lateralMovementTechniques={port.lateralMovementTechniques}
					onClose={() => setShowLateralMovementDrawer(false)}
					isOpen={showLateralMovementDrawer}
				/>
			)}
		</>
	);
}
