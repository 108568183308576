import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";

export interface ToolbarProps {
	leftActions?: React.ReactNode;
	children?: React.ReactNode;
	requestAPIRefresh?: () => void;
}

export default function GridToolbar({
	leftActions,
	children: rightActions,
	requestAPIRefresh,
}: ToolbarProps) {
	const theme = useTheme();

	if (!leftActions && !rightActions && !requestAPIRefresh) {
		return null;
	}

	const handleRefreshChange = () => {
		requestAPIRefresh?.();
	};

	return (
		<Toolbar
			id="grid-toolbar"
			variant="dense"
			sx={{
				zIndex: 2,
				padding: "4px !important",
				boxShadow: "unset !important",
			}}
		>
			<Stack
				width="100%"
				justifyContent="space-between"
				direction="row"
				spacing={2}
			>
				<Stack direction="row" spacing={2} alignItems="center" flexShrink={0}>
					{leftActions}
				</Stack>
				<Stack direction="row" spacing={2} alignItems="center" flexShrink={0}>
					{rightActions}
					{requestAPIRefresh && (
						<Tooltip title="Refresh">
							<IconButton onClick={handleRefreshChange} color="primary">
								<RefreshIcon
									fontSize="small"
									sx={{ color: theme.palette.primary.main }}
								/>
							</IconButton>
						</Tooltip>
					)}
				</Stack>
			</Stack>
		</Toolbar>
	);
}
