import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { TemplateColumnRenderProps } from "pages/templates/types";
import pluralize from "pluralize";
import { Link as RouterLink } from "react-router-dom";

interface GroupCountProps extends TemplateColumnRenderProps {
	openInNewTab?: boolean;
}

export function GroupCount({
	template,
	viewOnly,
	openInNewTab,
}: Readonly<GroupCountProps>) {
	const templatesCount = numeral(
		template?.usergroupTemplateAssignments ?? 0
	).format("0a");
	if (!template.templateId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			{viewOnly || template?.usergroupTemplateAssignments === 0 ? (
				<Typography variant="body2">
					{templatesCount}{" "}
					{window
						.getCTTranslatedText(
							`${pluralize("Group", Number(templatesCount))}`
						)
						.toLowerCase()}
				</Typography>
			) : (
				<Tooltip title={window.getCTTranslatedText("View groups")}>
					<Link
						typography={"body2"}
						component={RouterLink}
						to={`/users?tab=groups&filters=${encodeURIComponent(
							`templatename=${template.templateName}`
						)}`}
						sx={{ color: "primary.main" }}
						target={openInNewTab ? "_blank" : undefined}
						rel={openInNewTab ? "noopener noreferrer" : undefined}
					>
						{templatesCount}{" "}
						{window
							.getCTTranslatedText(
								`${pluralize("Group", Number(templatesCount))}`
							)
							.toLowerCase()}
					</Link>
				</Tooltip>
			)}
		</Stack>
	);
}
