import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box } from "@mui/material";
import { useState } from "react";

export function GenerateAPIKeyButton({
	onGenerate,
}: {
	onGenerate: (publicKey: string) => void;
}) {
	const [generating, setGenerating] = useState(false);
	const [error, setError] = useState<any>(undefined);

	const generate = async () => {
		setGenerating(true);
		setError(undefined);
		try {
			let keys = await generateKey();

			var fileContent = keys.privatePem;
			var myFile = new Blob([fileContent], { type: "text/plain" });

			window.URL = window.URL || window.webkitURL;

			const fileUrl = window.URL.createObjectURL(myFile);
			const link = document.createElement("a");
			link.href = fileUrl;
			link.setAttribute("download", `colortokens_api_key.pem`);
			link.click();

			link?.parentNode?.removeChild(link);
			onGenerate(keys.publicPem);
		} catch (e) {
			console.error(e);
			setError(e);
		}
		setGenerating(false);
	};

	return (
		<>
			{error ? (
				<Alert severity="error" sx={{ mb: 4 }}>
					{error.toString()}
				</Alert>
			) : (
				<Alert severity="info" sx={{ mb: 4 }}>
					{window.getCTTranslatedText(
						`Key will be generated in the browser locally and available for download only once. ColorTokens will have no access to the private key.`
					)}
				</Alert>
			)}

			<Box>
				<LoadingButton
					variant="contained"
					onClick={generate}
					loading={generating}
				>
					{window.getCTTranslatedText("Generate & Download Key")}
				</LoadingButton>
			</Box>
		</>
	);
}

/*
Convert an ArrayBuffer into a string
from https://developer.chrome.com/blog/how-to-convert-arraybuffer-to-and-from-string/
*/
function ab2str(buf: ArrayBuffer) {
	return String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));
}

/*
  Export the given key and write it into the "exported-key" space.
  */
async function exportCryptoKey(key: CryptoKeyPair) {
	const privatePem = await keyToString(key.privateKey, "PRIVATE");
	const publicPem = await keyToString(key.publicKey, "PUBLIC");
	return {
		privatePem,
		publicPem,
	};
}

async function keyToString(key: CryptoKey, type: string) {
	const exported = await window.crypto.subtle.exportKey(
		type === "PRIVATE" ? "pkcs8" : "spki",
		key
	);
	const exportedAsString = ab2str(exported);
	const exportedAsBase64 = window.btoa(exportedAsString);
	const pemExported = `-----BEGIN ${type} KEY-----\n${exportedAsBase64}\n-----END ${type} KEY-----`;
	return pemExported;
}

/*
  Generate a sign/verify key pair,
  then set up an event listener on the "Export" button.
  */

async function generateKey() {
	return window.crypto.subtle
		.generateKey(
			{
				name: "RSA-PSS",
				modulusLength: 4096,
				publicExponent: new Uint8Array([1, 0, 1]),
				hash: "SHA-256",
			},
			true, //exportable
			["sign", "verify"]
		)
		.then(keyPair => {
			return exportCryptoKey(keyPair);
		});
}
