import { Box, Stack, Typography } from "@mui/material";
import { COUNTRY_FLAG_LOOKUP } from "common/constants/countryFlagLookup";
import { NnHitColumnRenderProps } from "../types";

export function NNHitsCountry({ nnHit }: NnHitColumnRenderProps) {
	if (!nnHit.lastHitObserved) {
		return <Box style={{ width: 70 }} />;
	}

	const countryFlag = nnHit?.countryName
		? COUNTRY_FLAG_LOOKUP[nnHit?.countryName.toLowerCase()]
		: "";

	return (
		<Box style={{ width: "fit-content" }}>
			<Stack direction="row" gap={1} alignItems="center">
				{countryFlag}
				<Typography noWrap variant="body2">
					{nnHit?.countryName ?? ""}
				</Typography>
			</Stack>
		</Box>
	);
}
