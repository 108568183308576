import { Button, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { APIKeyCreds } from "./APIKeyCreds";
import APIsDataGrid from "./APIsDataGrid";
import { NewAPIKeyButton } from "./NewAPIKeyButton";

function Actions() {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const hasPermission = userPermissions.has("CREATE_API_KEY");

	return (
		<Stack
			direction="row"
			justifyContent="flex-end"
			flexGrow={1}
			gap="1rem"
			mb={"1.5rem"}
		>
			<Button
				variant="outlined"
				href={`https://api-${window.location.host}/docs`}
				target="_blank"
			>
				{window.getCTTranslatedText("Documentation")}
			</Button>
			<APIKeyCreds />
			{hasPermission && <NewAPIKeyButton />}
		</Stack>
	);
}

export function APIsTab() {
	return (
		<>
			<Actions />
			<APIsDataGrid />
		</>
	);
}
