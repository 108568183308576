import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, AlertColor, Button, Stack, useTheme } from "@mui/material";
import { FirewallWarningIcon } from "assets/svgs";
import { FacetState, Operator } from "modules/facets/types";
import { AssetFirewallStatus } from "pages/assets/constants";
import { Asset, AssetSecurityCategory } from "pages/assets/types";
import { CompassDirection } from "pages/paths/types";
import { useEffect, useMemo, useState } from "react";
import { AssetReviewModeType } from "../../AssetDetail";
import { AssetDetailModes, Direction } from "../../constants";
import { AssetReviewDrawer } from "../asset-review-drawer";

const readOnlyDimensions = ["listenportlastobserved", "pathlastobserved"];

interface AssetSynchronizeAlertProps {
	severity: AlertColor;
	clickCallback: () => void;
	loading?: boolean;
	messageComp: JSX.Element | null;
	direction: Direction;
	asset: Asset;
	canViewChanges: boolean;
	showInternetChangesOnly: boolean;
}

export const AssetSynchronizeAlert = ({
	severity = "warning",
	clickCallback,
	loading = false,
	messageComp,
	direction,
	asset,
	canViewChanges,
	showInternetChangesOnly,
}: AssetSynchronizeAlertProps) => {
	const theme = useTheme();
	const [showFwChange, setViewFwChange] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const mode = urlParams.get("mode");
	const openPreviewMode = mode === AssetDetailModes.ViewFirewallPendingChanges;

	useEffect(() => {
		if (openPreviewMode) {
			setViewFwChange(true);
		}
	}, [openPreviewMode]);

	const icon = (
		<Stack
			sx={{ height: "100%", pt: 0.5 }}
			alignItems="center"
			justifyContent="center"
		>
			<FirewallWarningIcon
				color={theme.palette.warning.main}
				width="22px"
				height="22px"
			/>
		</Stack>
	);

	const activeTab = useMemo(() => {
		const directionForPendingChanges =
			direction === Direction.Inbound
				? "attackSurfacePendingChanges"
				: "blastRadiusPendingChanges";

		const isPathTabVisible =
			asset?.[directionForPendingChanges]?.internetPaths ||
			asset?.[directionForPendingChanges]?.intranetPaths;
		const isPortTabVisible =
			asset?.[directionForPendingChanges]?.internetPorts ||
			asset?.[directionForPendingChanges]?.internetPorts;
		const isTemplateTabVisible =
			asset?.[directionForPendingChanges]?.allowTemplates ||
			asset?.[directionForPendingChanges]?.blockTemplates ||
			asset?.[directionForPendingChanges]?.unassignedAllowTemplates ||
			asset?.[directionForPendingChanges]?.unassignedBlockTemplates;
		const isNNTabVisible =
			asset?.[directionForPendingChanges]?.namednetworkChange;

		switch (true) {
			case Boolean(isPortTabVisible):
				return 0;
			case Boolean(isPathTabVisible):
				return 1;
			case Boolean(isTemplateTabVisible):
				return 2;
			case Boolean(isNNTabVisible):
				return 3;
			default:
				return 0;
		}
	}, [asset, direction]);

	const directionForPendingChanges =
		direction === Direction.Inbound
			? "attackSurfacePendingChanges"
			: "blastRadiusPendingChanges";
	const isPreviewMode =
		asset?.[directionForPendingChanges]?.progressiveSyncPending &&
		!asset?.[directionForPendingChanges]?.internetPorts &&
		!asset?.[directionForPendingChanges]?.intranetPorts &&
		!asset?.[directionForPendingChanges]?.internetPaths &&
		!asset?.[directionForPendingChanges]?.intranetPaths &&
		!asset?.[directionForPendingChanges]?.allowTemplates &&
		!asset?.[directionForPendingChanges]?.blockTemplates &&
		!asset?.[directionForPendingChanges]?.unassignedAllowTemplates &&
		!asset?.[directionForPendingChanges]?.unassignedBlockTemplates &&
		!asset?.[directionForPendingChanges]?.namednetworkChange;

	const portFacetState: FacetState = new Map();

	const pathFacetState: FacetState = new Map([
		[
			"direction",
			new Map([
				[
					direction === Direction.Inbound
						? AssetSecurityCategory.INBOUND
						: AssetSecurityCategory.OUTBOUND,
					{ isSelected: true, operator: Operator.EQUAL },
				],
			]),
		],
	]);

	if (showInternetChangesOnly) {
		const compassdirectionOptions = new Map([
			[
				CompassDirection.NorthSouth,
				{ isSelected: true, operator: Operator.EQUAL },
			],
		]);

		const listeningonpublicinterfaceOptions = new Map([
			["true", { isSelected: true, operator: Operator.EQUAL }],
		]);
		pathFacetState.set("compassdirection", compassdirectionOptions);
		portFacetState.set(
			"listeningonpublicinterface",
			listeningonpublicinterfaceOptions
		);
	}

	function renderAlert() {
		return (
			<Alert
				sx={{ width: "100%", alignItems: "center" }}
				severity={severity as AlertColor}
				action={
					<Stack direction={"row"} alignItems={"center"} spacing={1}>
						{canViewChanges && (
							<Button onClick={() => setViewFwChange(true)} variant="outlined">
								{window.getCTTranslatedText("Preview")}
							</Button>
						)}
						<LoadingButton
							loading={loading}
							color="warning"
							variant="outlined"
							onClick={() => {
								clickCallback();
								setViewFwChange(false);
							}}
							sx={{ whiteSpace: "nowrap" }}
						>
							{window.getCTTranslatedText(AssetFirewallStatus["Synchronize"])}
						</LoadingButton>
					</Stack>
				}
				icon={icon}
			>
				{messageComp}
			</Alert>
		);
	}

	return (
		<>
			{renderAlert()}
			{showFwChange && (
				<AssetReviewDrawer
					isOpen={showFwChange}
					onClose={() => setViewFwChange(false)}
					asset={asset}
					portFacetState={portFacetState}
					pathFacetState={pathFacetState}
					direction={direction === Direction.Inbound ? "inbound" : "outbound"}
					mode={AssetReviewModeType.ViewFirewallPendingChanges}
					title={`Review pending ${
						direction === Direction.Inbound ? "attack Surface" : "blast Radius"
					} changes`}
					activeTab={activeTab}
					isPreviewMode={isPreviewMode}
					readOnlyDimensions={readOnlyDimensions}
				/>
			)}
		</>
	);
};
