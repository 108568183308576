import { Theme } from "@mui/material";
import { AssetStatus } from "pages/assets/types";

export enum AssetDetailModes {
	ViewFirewallPendingChanges = "viewFirewallPendingChanges",
}

export enum Direction {
	Inbound = 0,
	Outbound = 1,
}

export enum SecurityStatus {
	None = -1,
	Unsecure = 0,
	SimulateInternet = 50, // review sagar
	Internet = 50,
	SimulateAll = 100,
	All = 100,
}

export enum GuardrailScenarios {
	SkipState = "SkipState",
	UnreviewedTraffic = "UnreviewedTraffic",
	PushToFirewall = "PushToFirewall",
}

export const SecurityStatusLabels = {
	[SecurityStatus.None]: "None",
	[SecurityStatus.Unsecure]: "Unsecure",
	// [SecurityStatus.SimulateInternet]: "Simulate Secure Internet",
	[SecurityStatus.Internet]: "Secure Internet",
	// [SecurityStatus.SimulateAll]: "Simulate Secure All",
	[SecurityStatus.All]: "Secure All",
};

export const AssetStatusMap = {
	[AssetStatus.None]: SecurityStatus.None,
	[AssetStatus.Unsecured]: SecurityStatus.Unsecure,
	[AssetStatus.SimulateSecureInternet]: SecurityStatus.SimulateInternet,
	[AssetStatus.SecureInternet]: SecurityStatus.Internet,
	[AssetStatus.SimulateSecureAll]: SecurityStatus.SimulateAll,
	[AssetStatus.SecureAll]: SecurityStatus.All,
};

export const AssetStatusReverseMap = {
	[SecurityStatus.None]: AssetStatus.None,
	[SecurityStatus.Unsecure]: AssetStatus.Unsecured,
	// [SecurityStatus.SimulateInternet]: AssetStatus.SimulateSecureInternet,
	[SecurityStatus.Internet]: AssetStatus.SecureInternet,
	// [SecurityStatus.SimulateAll]: AssetStatus.SimulateSecureAll,
	[SecurityStatus.All]: AssetStatus.SecureAll,
};

export const AlertMessages = {
	[Direction.Inbound]: {
		[SecurityStatus.Unsecure]: {
			message: {
				info: "Reduce Attack Surface by Securing Internet",
				simulate: "",
				warning: "This asset has unreviewed inbound ports and paths",
				confirmation: "Confirm Unsecure status change",
			},
		},
		[SecurityStatus.Internet]: {
			message: {
				info: "Reduce Attack Surface by Securing All",
				simulateInfo: "Reduce Attack Surface by Securing Internet",
				simulate:
					"Use Test Mode to test configured policies on the host firewall",
				warning: "This asset has unreviewed inbound ports and paths",
				confirmation: "Confirm Secure Internet status change",
			},
		},
		[SecurityStatus.All]: {
			message: {
				info: "Attack Surface reduced to v(a)",
				simulateInfo: "Reduce Attack Surface by Securing All",
				simulate:
					"Use Test Mode to test configured policies on the host firewall",
				warning: "This asset has unreviewed inbound ports and paths",
				confirmation: "Confirm Secure All status change",
			},
		},
		[SecurityStatus.None]: {
			message: {
				warning: "",
				info: "",
				simulateInfo: "",
				simulate: "",
				confirmation: "",
				simulateConfirmation: "",
			},
		},
	},
	[Direction.Outbound]: {
		[SecurityStatus.Unsecure]: {
			message: {
				warning: "This asset has unreviewed outbound paths",
				info: "Reduce Blast Radius by Simulating Secure Internet in Test mode",
				simulateInfo: "",
				simulate: "",
				confirmation: "Confirm Unsecure status change",
			},
		},
		[SecurityStatus.Internet]: {
			message: {
				warning: "This asset has unreviewed outbound paths",
				info: "Reduce Blast Radius by Simulating Secure All in Test mode",
				simulateInfo: "Reduce Blast Radius by Securing Internet",
				simulate:
					"Use Test Mode to test configured policies on the host firewall",
				confirmation: "Confirm Secure Internet status change",
				simulateConfirmation:
					"Use Test Mode to test configured policies on the host firewall",
			},
		},
		[SecurityStatus.All]: {
			message: {
				warning: "This asset has unreviewed outbound paths",
				info: "Blast Radius reduced to v(b)",
				simulateInfo: "Reduce Blast Radius by Securing All",
				simulate:
					"Use Test Mode to test configured policies on the host firewall",
				confirmation: "Confirm Secure All status change",
				simulateConfirmation:
					"Use Test Mode to test configured policies on the host firewall",
			},
		},
		[SecurityStatus.None]: {
			message: {
				warning: "",
				info: "",
				simulateInfo: "",
				simulate: "",
				confirmation: "",
				simulateConfirmation: "",
			},
		},
	},
	deploy: "Are you sure you want to deploy these policy changes?",
};

export const GuardrailWarnings = {
	[Direction.Inbound]: {
		[GuardrailScenarios.UnreviewedTraffic]: [
			{
				title: "Unreviewed Traffic",
				description:
					"This asset has unreviewed inbound ports and paths. You are deploying policies without running Test Mode first.",
				potentialImpacts: [
					"Service outages - Overly restrictive rules can block legitimate traffic and disrupt operations",
					"Security gaps - Untested policies may create inadvertent vulnerabilities or attack vectors",
					"Difficult rollbacks - Fixing problems becomes more complex once changes are deployed",
				],
				recommendations: [
					"Test changes - Always use test mode first to validate policy modifications",
					"Review impacts - Check effects on all ports, paths, and network traffic patterns",
					"Monitor results - Observe system behavior during testing phase",
				],
			},
		],
		[GuardrailScenarios.SkipState]: [
			{
				title: "Skipping Security State",
				description: "You are skipping the security state for this asset.",
				potentialImpacts: [
					"Access lockouts - Complete security lockdown may block critical internal systems",
					"Business disruption - Sudden strict controls can halt essential workflows",
					"False positives - Overly restrictive settings may flag legitimate activities as threats",
				],
				recommendations: [
					"Gradual implementation - Move through security states progressively",
					'Baseline establishment - Use "Secure Internet" to understand normal traffic patterns',
					"Impact assessment - Evaluate each security level's effect using test mode before advancing",
				],
			},
		],
		[GuardrailScenarios.PushToFirewall]: [
			{
				title: "Push to Firewall",
				description:
					"Firewall policy modifications can have significant network-wide impacts. Ensure proper validation before deployment.",
				potentialImpacts: [
					"Irreversible changes - Once pushed to firewall, policy changes cannot be automatically rolled back",
					"Production impact - Direct deployment may affect live services and critical business operations",
					"Cascading failures - Incorrect firewall rules can trigger chain reactions across connected systems",
				],
				recommendations: [
					"Change window - Schedule deployments during approved maintenance windows",
					"Verification plan - Have a clear plan to verify services after deployment",
					"Rollback procedure - Prepare manual rollback steps in case of issues",
				],
			},
		],
	},
	[Direction.Outbound]: {
		[GuardrailScenarios.UnreviewedTraffic]: [
			{
				title: "Unreviewed Traffic",
				description:
					"This asset has unreviewed outbound paths. You are deploying policies without running Test Mode first.",
				potentialImpacts: [
					"Service outages - Overly restrictive rules can block legitimate traffic and disrupt operations",
					"Security gaps - Untested policies may create inadvertent vulnerabilities or attack vectors",
					"Difficult rollbacks - Fixing problems becomes more complex once changes are deployed",
				],
				recommendations: [
					"Test changes - Always use test mode first to validate policy modifications",
					"Review impacts - Check effects on all outbound paths, and network traffic patterns",
					"Monitor results - Observe system behavior during testing phase",
				],
			},
		],
		[GuardrailScenarios.SkipState]: [
			{
				title: "Skipping Security State",
				description: "You are skipping the security state for this asset.",
				potentialImpacts: [
					"Access lockouts - Complete security lockdown may block critical internal systems",
					"Business disruption - Sudden strict controls can halt essential workflows",
					"False positives - Overly restrictive settings may flag legitimate activities as threats",
				],
				recommendations: [
					"Gradual implementation - Move through security states progressively",
					'Baseline establishment - Use "Secure Internet" to understand normal traffic patterns',
					"Impact assessment - Evaluate each security level's effect using test mode before advancing",
				],
			},
		],
		[GuardrailScenarios.PushToFirewall]: [
			{
				title: "Push to Firewall",
				description:
					"Firewall policy modifications can have significant network-wide impacts. Ensure proper validation before deployment.",
				potentialImpacts: [
					"Irreversible changes - Once pushed to firewall, policy changes cannot be automatically rolled back",
					"Production impact - Direct deployment may affect live services and critical business operations",
					"Cascading failures - Incorrect firewall rules can trigger chain reactions across connected systems",
				],
				recommendations: [
					"Change window - Schedule deployments during approved maintenance windows",
					"Verification plan - Have a clear plan to verify services after deployment",
					"Rollback procedure - Prepare manual rollback steps in case of issues",
				],
			},
		],
	},
};

export const assetSliderStyles = ({
	theme,
	minValue,
	selectedStatus,
	isAlpha,
}: {
	theme: Theme;
	minValue?: SecurityStatus;
	selectedStatus?: SecurityStatus;
	isAlpha: boolean;
}) => {
	const DisabledPointerColor = theme.palette.custom.sliderBg;
	let disabledPointer;
	let simulatePointer = {};

	if (minValue === SecurityStatus.SimulateInternet) {
		disabledPointer = {
			"&[data-index='0']": {
				background: `${DisabledPointerColor}`,
				border: `5px solid ${DisabledPointerColor}`,
			},
		};
	} else if (minValue === SecurityStatus.All) {
		disabledPointer = {
			"&[data-index='0']": {
				background: `${DisabledPointerColor}`,
				border: `5px solid ${DisabledPointerColor}`,
			},
			"&[data-index='1']": {
				background: `${DisabledPointerColor}`,
				border: `5px solid ${DisabledPointerColor}`,
			},
		};
	}

	if (selectedStatus === SecurityStatus.SimulateAll && isAlpha) {
		simulatePointer = {
			"&[data-index='1']": {
				background: `${theme.palette.warning.main}`,
				border: `5px solid ${theme.palette.warning.main}`,
			},
		};
	}

	let gradient = `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.main}, ${theme.palette.warning.main})`;
	if (isAlpha) {
		gradient = `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.main}, ${theme.palette.warning.main}, ${theme.palette.warning.main})`;
	}
	return {
		"&.asset-status-slider.simulate .MuiSlider-track": {
			border: "none",
			background: gradient,
		},
		"&.asset-status-slider.simulate .MuiSlider-thumb": {
			backgroundColor: theme.palette.warning.main,
			border: `5px solid ${theme.palette.warning.main}`,
			boxShadow: "0 0 0 7px rgba(255, 160, 0, 0.16)",
			zIndex: 2,
			"&.asset-status-slider.simulate:hover": {
				boxShadow: "0 0 0 7px rgba(255, 160, 0, 0.16)",
			},
		},
		".MuiSlider-mark": {
			zIndex: "2 !important",
			...disabledPointer,
		},
		"&.asset-status-slider.simulate .MuiSlider-mark": {
			zIndex: "2 !important",
			...simulatePointer,
		},
	};
};
