import produce from "immer";
import { FacetGroupInfo } from "modules/core/types";
import { ADVANCED_OPERATORS, ALL_TIME } from "modules/facets/constants";
import {
	Scope,
	ScopeMetadata,
	ScopeMetadataColumn,
} from "modules/scope-metadata/types";
import { GroupInfo } from "./components/group-info";
import {
	ADVANCED_OPERATORS_SUPPORT,
	COMMON_FACETS,
	MINUS,
	PLUS,
} from "./constants";
import {
	Facet,
	FacetChange,
	FacetCommit,
	FacetConfig,
	FacetGroup,
	FacetGroups,
	FacetOption,
	FacetOptionState,
	FacetState,
	FacetType,
	Operator,
	ServerFacet,
} from "./types";
import { getOptionLabel } from "./utils";
import { getLabelFromDisplayValues } from "./utils/getLabelFromDisplayValues";

export const nil = "<nil>";
export class FacetUtils {
	static handleFacetOptionChange(
		facetState: FacetState,
		facetUpdate: FacetChange
	): FacetState {
		if (!facetUpdate) {
			return facetState;
		}

		if (!facetState) {
			facetState = new Map();
		}

		return produce(facetState, draft => {
			let facetOptionValue = draft.get(facetUpdate.facetName);
			if (!facetOptionValue) {
				facetOptionValue = new Map();
			}
			const oldValue = facetOptionValue.get(facetUpdate.optionName);

			let oldState = draft.get(facetUpdate.facetName);
			let operator: Operator = Operator.EQUAL as Operator;
			let oldValues = Array.from(oldState?.values() ?? []);
			oldValues.forEach(v => {
				operator = v.operator;
			});

			facetOptionValue.set(facetUpdate.optionName, {
				isSelected: facetUpdate.value,
				operator:
					facetUpdate.operator ??
					oldValue?.operator ??
					operator ??
					Operator.EQUAL,
			});

			if (!facetUpdate.value) {
				facetOptionValue.delete(facetUpdate.optionName);
			}

			if (facetOptionValue.size > 0) {
				draft.set(facetUpdate.facetName, facetOptionValue);
			} else {
				draft.delete(facetUpdate.facetName);
			}
		});
	}

	static updateFacet(facetState: FacetState, commit: FacetCommit): FacetState {
		if (!commit) {
			return facetState;
		}

		if (!facetState) {
			facetState = new Map();
		}

		const newState = produce(facetState, draft => {
			let removedKeys: Array<string> = [];
			commit.options?.forEach((option, optionName) => {
				if (!option.isSelected) {
					removedKeys.push(optionName);
				}
			});

			draft.set(commit.facetName, new Map(commit.options));
			removedKeys.forEach(removedKey => {
				draft.get(commit.facetName)?.delete(removedKey);
			});

			if (!draft.get(commit.facetName)?.size) {
				draft.delete(commit.facetName);
			}
		});

		return newState;
	}

	static removeFacetFromState(
		facetState: FacetState,
		facetName: string
	): FacetState {
		if (!facetName) {
			return facetState;
		}

		if (!facetState) {
			facetState = new Map();
		}
		return produce(facetState, draft => {
			draft.delete(facetName);
		});
	}

	static transferState(from: FacetState, to: FacetState): FacetState {
		if (!from) {
			return to;
		}
		return produce(to, draft => {
			from.forEach((value, key) => {
				draft?.set(key, value);
			});
			return draft;
		});
	}

	static getServerQueryLanguageFromFacets(
		facetState: FacetState,
		metaData?: ScopeMetadata
	): string {
		if (!metaData) {
			return "";
		}

		if (!facetState) {
			facetState = new Map();
		}
		// "attacksurface in (unknown, minimal)  and businessvalue in (low, medium)
		const queries: Array<string> = [];
		const entries = Array.from(facetState.entries() ?? []).sort((a, b) =>
			a[0].localeCompare(b[0])
		);
		entries.forEach(([key, value]) => {
			let operator: Operator = Operator.EQUAL as Operator;

			const stringTypeFacetValues: Array<string> = [];
			const rangeTypeFacetValues: Array<string> = [];

			const column = metaData?.columns[key] || metaData?.namesToColumn?.[key];

			value.forEach((optionValue, optionKey) => {
				if (optionKey === "<nil>") {
					optionKey = "NULL";
				}
				if (!column) {
					return;
				}

				if (optionValue?.isSelected) {
					const dataType = column?.dataType;
					const facetType =
						dataType === "Numeric" || dataType === "Timestamp"
							? FacetType.Range
							: FacetType.String;
					switch (facetType) {
						case FacetType.String:
							if (optionKey === "NULL") {
								stringTypeFacetValues.push(optionKey);
							} else {
								stringTypeFacetValues.push(`'${optionKey}'`);
							}
							break;
						case FacetType.Range:
							rangeTypeFacetValues.push(optionKey);
							break;
					}
				}
				operator = optionValue.operator;
			});

			if (stringTypeFacetValues.length > 0) {
				if (column) {
					// add check for applicable filter keys
					if (ADVANCED_OPERATORS[operator]) {
						// startswith || endswith || contains
						let prefix = "";
						let suffix = "";
						let query = "";

						if (operator === Operator.CONTAINS) {
							prefix = "%";
							suffix = "%";
						} else if (operator === Operator.STARTS_WITH) {
							suffix = "%";
						} else if (operator === Operator.ENDS_WITH) {
							prefix = "%";
						} else if (operator === Operator.EXCLUDES) {
							prefix = "%";
							suffix = "%";
						} else {
							// invalid operator
						}
						(stringTypeFacetValues ?? []).forEach(
							(value: string, index: number) => {
								// Remove single quotes from the beginning and end of the string
								value = value?.trim().replace(/^'|'$/g, "");
								if (index === 0) {
									query = `${
										query === "" ? "" : `${query}, `
									}'${prefix}${value}${suffix}'`;
									let operatorValue =
										operator === Operator.EXCLUDES ? "NOT ILIKE" : "ILIKE";
									query = `'${key}' ${operatorValue} ${query}`;
									queries.push(query);
								}
							}
						);
					} else {
						let query = `'${key}' ${
							operator === Operator.NOT_EQUAL ? "not " : ""
						}in (${stringTypeFacetValues.join(",")})`;
						queries.push(query);
					}
				} else {
					// console.log('log invalid filter keys', key );
				}
			}

			if (rangeTypeFacetValues.length > 0) {
				const rangeTypeFacetValuesQuery: Array<string> = [];
				rangeTypeFacetValues.forEach(facetValue => {
					if (column) {
						// add check for applicable filter keys
						if (facetValue.includes(MINUS)) {
							const [start, end] = facetValue.split(` ${MINUS} `);
							rangeTypeFacetValuesQuery.push(
								`'${key}' ${
									operator === Operator.NOT_EQUAL ? "NOT " : ""
								}BETWEEN ${start} AND ${end}`
							);
						} else if (facetValue.includes(PLUS)) {
							const [start] = facetValue.split(PLUS);
							const rangeOperator =
								operator === Operator.NOT_EQUAL
									? Operator.LESS_THAN_OR_EQUAL
									: Operator.GREATER_THAN;
							rangeTypeFacetValuesQuery.push(
								`'${key}' ${rangeOperator} ${start}`
							);
						} else if (facetValue !== ALL_TIME) {
							rangeTypeFacetValuesQuery.push(
								`'${key}' ${
									operator === Operator.NOT_EQUAL ? "!" : ""
								}= ${facetValue}`
							);
						}
					} else {
						// console.log('log invalid filter keys', key );
					}
				});

				if (rangeTypeFacetValuesQuery.length === 0) {
					return;
				}

				let query = `(${rangeTypeFacetValuesQuery.join(
					operator === Operator.NOT_EQUAL ? " AND " : " OR "
				)})`;

				queries.push(query);
			}
		});
		return queries.join(" AND ");
	}

	static getURLQueryFromFacets(facetState: FacetState): string {
		const queries: Array<string> = [];

		if (!facetState) {
			facetState = new Map();
		}

		facetState.forEach((value, key) => {
			const stringTypeFacetValues: Array<string> = [];

			let operator = Operator.EQUAL as Operator;
			value.forEach((optionValue, optionKey) => {
				if (optionValue?.isSelected) {
					stringTypeFacetValues.push(optionKey);
				}
				operator = optionValue?.operator;
			});
			if (stringTypeFacetValues.length > 0) {
				let query = `${encodeURIComponent(key)}${operator}${encodeURIComponent(
					stringTypeFacetValues
						.map(facetValue => encodeURIComponent(facetValue))
						.join(",")
				)}`;
				queries.push(query);
			}
		});

		return queries.join("|");
	}

	static updateServerFacetOptions(
		serverFacet: ServerFacet,
		considerHasMore: boolean,
		currentFacetConfig: FacetConfig,
		metadata: ScopeMetadata
	) {
		if (!serverFacet || !currentFacetConfig) {
			return currentFacetConfig;
		}

		let facet: Facet | undefined;

		currentFacetConfig.forEach(facetContainer => {
			if (facet) {
				return;
			}
			let index = facetContainer.facets.findIndex(
				targetFacet => targetFacet.name === serverFacet.field
			);
			if (index >= 0) {
				facet = facetContainer.facets[index];
				facet.options = getOptionsForFacet(serverFacet, metadata);
				if (considerHasMore) {
					facet.searchable = serverFacet.hasMore;
				}
				facetContainer.facets[index] = { ...facet };

				facetContainer.facets = [...facetContainer.facets];
			}
		});

		currentFacetConfig = [...currentFacetConfig];
		return currentFacetConfig;
	}

	static getFacetConfigFromServerValues(
		serverFacets: Array<ServerFacet>,
		currentFacetConfig: FacetConfig,
		lastInteractedFacetName: string | null,
		metadata?: ScopeMetadata,
		facetGroupInfo?: FacetGroupInfo,
		activeFacets?: FacetState
	): FacetConfig {
		if (!serverFacets) {
			serverFacets = [];
		}

		if (!metadata || !facetGroupInfo) {
			return currentFacetConfig;
		}

		const facetGroups = new Map<FacetGroups, { facetGroup: FacetGroup }>();

		const addToGroup = (groupName: FacetGroups, facet: Facet) => {
			if (!facetGroups.has(groupName)) {
				facetGroups.set(groupName, { facetGroup: [] });
			}
			if (metadata?.columns[facet.name]) {
				// add check for applicable filter keys
				facetGroups.get(groupName)?.facetGroup.push(facet);
			}
		};

		const isIncludedInFacet: {
			[key: string]: boolean;
		} = {};

		const desiredFacets = { ...(facetGroupInfo || {}) };

		const makeFacet = (name: string, serverFacet?: ServerFacet) => {
			if (name === lastInteractedFacetName) {
				let lastInteractedFacet;
				currentFacetConfig?.forEach(config => {
					// as last interacted facet can be part of any group
					const currentConfig = config.facets.find(
						existingFacet => existingFacet.name === lastInteractedFacetName
					);
					if (currentConfig) {
						lastInteractedFacet = currentConfig;
					}
				});

				if (lastInteractedFacet) {
					return lastInteractedFacet;
				} else {
					console.warn("Could not find last interacted facet");
				}
			}

			let columninfo = metadata?.columns[name];

			if (!columninfo) {
				return null;
			}
			let options: Array<FacetOption> = [];
			if (serverFacet) {
				options = getOptionsForFacet(serverFacet, metadata);
			} else {
				let column = metadata.columns?.[name];
				let values: any = {};
				const defaultOption = DefaultOptions[name];
				if (defaultOption) {
					values = defaultOption.values;
				}
				Object.values(column?.values ?? {}).forEach(v => {
					values[v.display] = -1;
				});
				options = valuesToOptions(values, column);
			}

			const dataType = columninfo?.dataType;
			const isNumericType = dataType === "Numeric" || dataType === "Timestamp";

			let suggestions = metadata.columns[name]?.values?.map(optionValue => {
				return {
					label: optionValue.display,
					name: optionValue.internal + "",
				};
			});

			let isSearchable = columninfo.searchable;
			if (serverFacet && !serverFacet.hasMore) {
				isSearchable = false;
			}
			if (suggestions?.length && !columninfo.facetable) {
				isSearchable = false;
			}
			if (columninfo.facetable) {
				suggestions = [];
			}

			let advancedOperators = false;
			if (ADVANCED_OPERATORS_SUPPORT[name]) {
				advancedOperators = true;
			}

			return {
				qualifier: columninfo.qualifier as Scope,
				label: metadata.columns?.[name]?.displayName || "",
				name: name,
				options: options,
				searchable: isSearchable,
				facetable: columninfo?.facetable,
				suggestions,
				type: isNumericType ? FacetType.Range : FacetType.String,
				advancedOperators: advancedOperators,
			};
		};

		serverFacets.forEach((serverFacet, index) => {
			delete desiredFacets[serverFacet.field];
			if (serverFacet.isSearchField) {
				return;
			}

			isIncludedInFacet[serverFacet.field] = true;
			const isCommonFacet = COMMON_FACETS[serverFacet.field];

			let facet: Facet | null = makeFacet(serverFacet.field, serverFacet);
			if (!facet) {
				return;
			}
			if (isCommonFacet) {
				const commonFacetGroup = COMMON_FACETS[
					serverFacet.field
				] as FacetGroups;
				addToGroup(commonFacetGroup || FacetGroups.Tags, facet);
			} else if (facetGroupInfo[facet.name]) {
				addToGroup(facetGroupInfo[facet.name] as FacetGroups, facet);
			}
		});

		Object.entries(desiredFacets).forEach(([key, value]) => {
			isIncludedInFacet[key] = true;
			const isCommonFacet = COMMON_FACETS[key];

			const facet = makeFacet(key);
			if (!facet) {
				return;
			}
			if (isCommonFacet) {
				const commonFacetGroup = COMMON_FACETS[key] as FacetGroups;
				addToGroup(commonFacetGroup || FacetGroups.Tags, facet);
			} else {
				addToGroup(facetGroupInfo[key] as FacetGroups, facet);
			}
		});

		const sorter = (a: Facet, b: Facet) => {
			return a?.label && b?.label ? a.label.localeCompare(b.label) : -1;
		};

		const config: FacetConfig = [];

		facetGroups.forEach((group, name) => {
			config.push({
				displayName: name,
				facets: group.facetGroup.sort(sorter),
				Icon: GroupInfo[name]?.icon,
				isCustomIcon: GroupInfo[name]?.isCustomIcon,
			});
		});

		config.sort((a, b) => {
			return (
				GroupInfo[a.displayName as FacetGroups]?.order -
				GroupInfo[b.displayName as FacetGroups]?.order
			);
		});
		return config;
	}
}

function getOptionsForFacet(serverFacet: ServerFacet, metadata: ScopeMetadata) {
	return valuesToOptions(
		serverFacet.values,
		metadata.columns?.[serverFacet.field]
	);
}

function valuesToOptions(
	values?: { [key: string]: any },
	fieldDescriptor?: ScopeMetadataColumn
) {
	let options: Array<FacetOption> = [];

	let optionKeys = Object.keys(values || {});
	if (!optionKeys.length) {
		return options;
	}
	const unit = fieldDescriptor?.unit;

	const dataType = fieldDescriptor?.dataType;
	const isNumericType = dataType === "Numeric" || dataType === "Timestamp";
	const valuesDisplayOptions = fieldDescriptor?.valuesDisplayOptions;

	optionKeys.forEach(key => {
		const label = valuesDisplayOptions
			? getLabelFromDisplayValues(key, valuesDisplayOptions)
			: getOptionLabel(key, unit);
		options.push({
			count: values?.[key],
			label: label,
			name: key,
			isCoreTagEditable: fieldDescriptor?.isCoreTagEditable ?? true,
		});
	});

	try {
		options.sort((opA, opB) => {
			if (isNumericType) {
				return parseInt(opA.name, 10) - parseInt(opB.name, 10);
			}
			const dbValues = fieldDescriptor?.values;

			if (dbValues) {
				const orderMap: { [key: string]: number } = {};
				dbValues?.forEach(value => {
					orderMap[value.display] = value.internal;
				});
				return orderMap[opA.name] - orderMap[opB.name];
			}

			return opA.name.toLowerCase() > opB.name.toLowerCase() ? 1 : -1;
		});
	} catch (e) {
		console.error("Failure in sorting", e);
	}
	return options;
}

const DefaultOptions: { [key: string]: any } = {
	bandwidthinbytes: {
		values: { "0": -1 },
	},
	connectioncount: {
		values: { "0": -1 },
	},
};

function mergeFacetOptionStates(
	state1: FacetOptionState,
	state2: FacetOptionState
): FacetOptionState {
	const result = new Map(state1);

	for (const [key, value] of state2.entries()) {
		if (result.has(key)) {
			const existingValue = result.get(key);
			if (
				existingValue &&
				(existingValue.isSelected !== value.isSelected ||
					existingValue.operator !== value.operator)
			) {
				result.set(key, {
					isSelected: existingValue.isSelected || value.isSelected,
					operator: value.operator,
				});
			}
		} else {
			result.set(key, value);
		}
	}

	return result;
}

export function mergeFacetStates(
	state1: FacetState,
	state2: FacetState
): FacetState {
	if (!state1) return state2;
	if (!state2) return state1;

	const result = new Map(state1);

	for (const [key, value] of state2.entries()) {
		if (result.has(key)) {
			const existingValue = result.get(key);
			if (existingValue) {
				result.set(key, mergeFacetOptionStates(existingValue, value));
			}
		} else {
			result.set(key, value);
		}
	}

	return result;
}
