import { Box, Stack } from "@mui/material";

import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useNetworkStore } from "pages/networks/store";
import { Network } from "pages/networks/types";
import { AssetCount } from "../asset-count";
import { GroupCount } from "../group-count";
import { UsedByTagBasedPolicy } from "../used-by-tag-based-policy";

export interface NetworkDeleteProps {
	network: Network | undefined;
	handleAllowDelete: Function;
	allowDelete: boolean;
}

const useDeleteNetworksAPI = (namedNetworkId: string | undefined) => {
	return useMutation<any, Error, any>([
		"networks",
		`namednetworks/${namedNetworkId}`,
		"delete",
	]);
};

export function NetworkDelete({
	network,
	handleAllowDelete,
	allowDelete,
}: NetworkDeleteProps) {
	const notify = useEventSubscriptionStore(state => state.notify);
	const deleteNetworksAPI = useDeleteNetworksAPI(network?.namedNetworkId);
	const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
	const [loading, setLoading] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const queryClient = useQueryClient();
	const hasAssets =
		network?.namedNetworkAssignments && network.namedNetworkAssignments > 0;
	const hasSegments =
		network?.namednetworkTagBasedPolicyAssignments &&
		network.namednetworkTagBasedPolicyAssignments > 0;
	const hasUGs =
		network?.usergroupNamedNetworkAssignments &&
		network.usergroupNamedNetworkAssignments > 0;

	const updateNetworkDetails = () => {
		setLoading(true);

		deleteNetworksAPI.mutate(
			{},
			{
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: ["networks"],
					});
					setTimeout(() => {
						requestAPIRefresh();
						handleAllowDelete(false);
						setLoading(false);
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "DeleteNamedNetworkRequestSubmittedSuccessfully",
						});
					}, 500);
				},
				onError: error => {
					setLoading(false);
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};

	function renderSecondaryText() {
		return (
			<Stack>
				{hasAssets || hasSegments || hasUGs
					? window.getCTTranslatedText("DeleteNetworkWarning")
					: null}
				{hasAssets ? (
					<AssetCount network={network} openInNewTab={true} />
				) : null}
				{hasSegments ? (
					<UsedByTagBasedPolicy network={network} openInNewTab={true} />
				) : null}
				{hasUGs ? <GroupCount network={network} openInNewTab={true} /> : null}
			</Stack>
		);
	}

	return (
		<Box onClick={e => e.stopPropagation()}>
			<CTConfirmation
				open={allowDelete}
				onClose={() => handleAllowDelete(false)}
				title={window.getCTTranslatedText("Delete Named Network")}
				primaryText={window.getCTTranslatedText(
					"AreYouSureYouWantToDeleteNamedNetwork",
					{ namedNetworkName: network?.namedNetworkName }
				)}
				secondaryText={renderSecondaryText()}
				primaryButtonText={window.getCTTranslatedText("Delete")}
				isLoading={deleteNetworksAPI.isLoading || loading}
				onSuccess={updateNetworkDetails}
			/>
		</Box>
	);
}
