import { getWRAdditionalCriteria } from "modules/AppBar/helpers";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store";
import { useWorkRequestFacetStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";
import { useRef } from "react";

export interface PendingWorkRequestToolbarProps extends ToolbarProps {}

export function PendingWorkRequestToolbar(
	props: PendingWorkRequestToolbarProps
) {
	const requestAPIRefresh = useWorkRequestStore(
		state => state.requestAPIRefresh
	);
	const setExternalCriteria = useWorkRequestFacetStore(
		state => state.setExternalCriteria
	);
	const externalCriteria = useWorkRequestFacetStore(
		state => state.externalCriteria
	);
	const additionalCriteria = useRef(externalCriteria);
	const onRefresh = () => {
		additionalCriteria.current = getWRAdditionalCriteria();
		setExternalCriteria(additionalCriteria.current);
		requestAPIRefresh();
	};
	return <GridToolbar {...props} requestAPIRefresh={onRefresh}></GridToolbar>;
}
