export const getChartDates = (timeline = 29) => {
	const currentDate = new Date();
	currentDate.setUTCHours(23, 59, 59, 999);

	const startDate = new Date();
	startDate.setDate(currentDate.getDate() - timeline);
	startDate.setUTCHours(0, 0, 0, 0);

	return {
		timeStart: startDate.toISOString(),
		timeEnd: currentDate.toISOString(),
	};
};
