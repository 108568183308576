import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { SecurityPatchesDataGrid } from "./components/security-patches-data-grid";

interface SecurityPatchesDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	assetId: string;
	assetName: string;
}

export const SecurityPatchesDrawer = ({
	isOpen,
	onClose,
	assetId,
	assetName,
}: SecurityPatchesDrawerProps) => {
	let headerText = "Security Patches";
	let subHeader = assetName;

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: 3,
					width: "70%",
					overflow: "hidden",
				},
			}}
		>
			<Toolbar />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<Stack direction={"row"} alignContent="center">
					<Stack direction={"column"} justifyContent="center">
						<Typography variant="h6">{headerText}</Typography>
						<Typography variant="body2" color="GrayText">
							{subHeader}
						</Typography>
					</Stack>
				</Stack>

				<IconButton size="small" aria-label="close drawer" onClick={onClose}>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Stack>
			<Stack
				justifyContent="space-between"
				style={{ flex: 1, position: "relative", overflow: "hidden" }}
			>
				<>
					<SecurityPatchesDataGrid assetId={assetId} />
				</>
			</Stack>
		</Drawer>
	);
};
