import { CustomCommonStoreType } from "common/types/types";
import { CoreStoreBuilder } from "modules/core/store";
import { CoreStore } from "modules/core/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { create } from "zustand";

export interface SegmentStore extends CoreStore {
	selectedCriteria: string[];
	setSelectedCriteria: (selectedCriteria: string[]) => void;
}

export const useTagPolicyStore = create<SegmentStore>()(set => ({
	...CoreStoreBuilder(set),
	selectedCriteria: [],
	setSelectedCriteria: (selectedCriteria: string[]) =>
		set({ selectedCriteria }),
	requestAPIRefresh: () => {
		set({ apiRefreshRequest: Date.now() });
		useCrowdStrikeTagPolicyStore.getState().requestAPIRefresh();
	},
}));

export const useCrowdStrikeTagPolicyStore = create<SegmentStore>()(set => ({
	...CoreStoreBuilder(set),
	selectedCriteria: [],
	setSelectedCriteria: (selectedCriteria: string[]) =>
		set({ selectedCriteria }),
}));

export const createTagPolicyFacetStore = () => {
	return create<FacetStore>()(set => ({
		...FacetStoreBuilder(set),
	}));
};

export const useTagPolicyFacetStore: CustomCommonStoreType =
	createTagPolicyFacetStore();

export const useCrowdStrikeTagPolicyFacetStore: CustomCommonStoreType =
	createTagPolicyFacetStore();
