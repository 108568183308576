import { capitalizeFirstLetter } from "@colortokens/ng-ui";
import { TAG_CAPITALIZE_BLACKLIST } from "common/constants/tags";
import { FacetOption } from "../types";

export const getFacetOptionDisplayText = (
	option: Pick<FacetOption, "label" | "isCoreTagEditable">,
	facetName: string
) => {
	const { label, isCoreTagEditable } = option;
	const isExcluded = label.startsWith("!_") && label.endsWith("_!");

	if (!isExcluded) {
		return isCoreTagEditable || TAG_CAPITALIZE_BLACKLIST.includes(facetName)
			? window.getCTTranslatedText(label)
			: capitalizeFirstLetter(window.getCTTranslatedText(label));
	}

	const optionLabel = label.slice(2, -2);

	if (optionLabel === "null") {
		return window.getCTTranslatedText("No Value (Excluded)");
	}

	return window.getCTTranslatedText(label.slice(2, -2) + " (Excluded)");
};
