import { Box, Stack, Typography } from "@mui/material";
import { TagPolicy } from "../../types";

export function TagPolicyName({ policy }: { policy: TagPolicy }) {
	if (!policy.tagBasedPolicyId || !policy.tagBasedPolicyName) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack
			direction={"row"}
			justifyContent={"space-between"}
			alignItems={"center"}
			sx={{ width: "100%" }}
		>
			<Box sx={{ width: "100%", overflow: "hidden" }}>
				<Typography
					sx={{
						width: "100%",
						textOverflow: "ellipsis",
						overflow: "hidden",
					}}
					variant="body2"
				>
					{policy?.tagBasedPolicyName}
				</Typography>
			</Box>
		</Stack>
	);
}
