import { InfoOutlined } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { getDate } from "common/utils";
import { AssetMetaDataReadonlyProps, PolicyStatus } from "pages/assets/types";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { ViewFirewallDecisionLogsButton } from "./ViewFirewallDecisionLogsButton";
import {
	FirewallLogType,
	ViewFirewallLogsButton,
} from "./ViewFirewallLogsButton";

export function AssetFirewallStatus({ asset }: AssetMetaDataReadonlyProps) {
	if (!asset?.assetId) {
		return <Box style={{ width: 42 }} />;
	}

	const status = asset.policyStatus;
	const autoSyncEnabled = asset.autoSynchronizeEnabled;
	const showCTAs = asset.managedBy !== ManagedByValue.CrowdStrike;

	let statusIcon;
	let text;

	switch (status) {
		case PolicyStatus.Synchronized:
			if (autoSyncEnabled) {
				statusIcon = <CircleIcon color={"success"} sx={{ fontSize: 12 }} />;
				text = window.getCTTranslatedText("Auto Synchronized");
			} else {
				statusIcon = <CircleIcon color={"success"} sx={{ fontSize: 12 }} />;
				text = window.getCTTranslatedText("Synchronized");
			}
			break;
		default:
			statusIcon = <CircleIcon color={"disabled"} sx={{ fontSize: 12 }} />;
			text = "Pending";
	}

	return (
		<Stack
			direction={"row"}
			spacing={3}
			alignItems="center"
			justifyContent="flex-start"
			sx={{ width: "100%" }}
		>
			<Typography variant="subtitle1">
				<b>{window.getCTTranslatedText("Firewall")}</b>
			</Typography>
			<Stack
				sx={{ mr: 1, flex: 1 }}
				direction="row"
				spacing={1}
				alignItems="center"
			>
				{statusIcon}
				<Typography variant="body2">
					{window.getCTTranslatedText(text)}
				</Typography>

				{status === PolicyStatus.Synchronized && (
					<Tooltip
						title={`${window.getCTTranslatedText("LastSyncedTime")} : ${getDate(
							asset?.lastPolicyDeploymentTriggeredAt ?? ""
						)}`}
					>
						<IconButton>
							<InfoOutlined fontSize="small" />
						</IconButton>
					</Tooltip>
				)}
			</Stack>
			{showCTAs && (
				<>
					<ViewFirewallLogsButton
						asset={asset}
						agentId={asset.agentId}
						type={FirewallLogType.ASSET}
					/>
					<ViewFirewallDecisionLogsButton
						asset={asset}
						agentId={asset.agentId}
					/>
				</>
			)}
		</Stack>
	);
}
