import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Card, Divider, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
	children: React.ReactElement;
	title: string;
	showSaveCta?: boolean;
	isLoading?: boolean;
	isSaveBtnDisabled?: boolean;
	onSaveBtnClick?: () => void;
	saveBtnTooltip?: string;
};

export function ConfigCard({
	children,
	title,
	showSaveCta = false,
	isLoading = false,
	isSaveBtnDisabled,
	onSaveBtnClick,
	saveBtnTooltip = "",
}: Props) {
	return (
		<Card
			sx={{
				width: "100%",
			}}
		>
			<Stack sx={{ padding: "12px 16px 12px 24px" }}>
				<Typography variant="body1">
					{window.getCTTranslatedText(title)}
				</Typography>
			</Stack>
			<Divider />
			<Stack p="24px 24px 20px">{children}</Stack>
			{showSaveCta && (
				<Stack>
					<Stack direction={"row"} justifyContent={"flex-end"} px={3} pb={3}>
						<Tooltip title={window.getCTTranslatedText(saveBtnTooltip)}>
							<LoadingButton
								loading={isLoading}
								disabled={isSaveBtnDisabled}
								color="primary"
								variant="contained"
								onClick={onSaveBtnClick}
							>
								{window.getCTTranslatedText("Save")}
							</LoadingButton>
						</Tooltip>
					</Stack>
				</Stack>
			)}
		</Card>
	);
}
