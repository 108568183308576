import useIcons from "common/hooks/useIcons";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { IconDictionary } from "common/types/types";
import { parseErrorMessage } from "common/utils";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	UNMANAGED_DEVICES_CORE_TAG_LABELS,
	UNMANAGED_DEVICES_DEVICE_AVAILABILITY,
	UnmanagedDeviceCoreTagKey,
} from "pages/appliance-detail/components/appliance-unmanaged-devices/constants";
import {
	UnmanagedDevice,
	UnmanagedDeviceEditBody,
} from "pages/appliance-detail/components/appliance-unmanaged-devices/types";
import { useUnmanagedDevicesStore } from "pages/appliance-detail/store";
import { useUpdateUnmanagedDevice } from "pages/asset/components/asset-detail/hooks/use-update-asset-annotations/useUpdateAssetAnnotations";
import { NO_VALUE } from "pages/asset/constants";
import { MetadataItemType, MetadataTag } from "../../types";
import MetadataEdit from "./MetadataEdit";
import { FormBuilderElement } from "./types";

interface Props {
	unmanagedDevice: UnmanagedDevice;
}

function UnmanagedDeviceEdit({ unmanagedDevice }: Props) {
	const { allowEdit, handleEdit, requestAPIRefresh } =
		useUnmanagedDevicesStore();
	const { mutate, isLoading: isEditSubmitLoading } = useUpdateUnmanagedDevice(
		unmanagedDevice?.deviceId
	);
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const { getIcon } = useIcons();

	const onEditSubmit = (data: Partial<UnmanagedDevice>) => {
		const handleNoValueString = (body: UnmanagedDeviceEditBody) => {
			for (const key in body?.deviceCoreTags) {
				if (
					body.deviceCoreTags[key] === NO_VALUE ||
					body.deviceCoreTags[key] === ""
				) {
					body.deviceCoreTags[key] = null;
				}
			}
			return body;
		};

		const body: UnmanagedDeviceEditBody = {
			...unmanagedDevice!,
			deviceAlias: data.deviceName ?? "",
			deviceCoreTags:
				data.deviceCoreTags ?? unmanagedDevice?.deviceCoreTags ?? {},
			deviceAvailability:
				UNMANAGED_DEVICES_DEVICE_AVAILABILITY[
					(unmanagedDevice as any)?.deviceAvailability?.toLowerCase()
				] ?? null,
		};

		delete body.deviceVulnerabilities;
		delete body.deviceLateralMovementVulnerability;
		delete body.deviceKnownExploitVulnerability;
		delete body.deviceVulnerabilitySeverity;

		handleNoValueString(body);

		mutate(body, {
			onSuccess: () => {
				requestAPIRefresh();
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "AssetDetailsSubmittedSuccessfully",
				});
				setTimeout(() => {
					handleEdit(undefined);
				}, 500);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const generateFormElements = () => {
		const nameObj: FormBuilderElement = {
			key: "deviceName",
			rhfKey: "deviceName",
			label: "Name",
			value: unmanagedDevice?.deviceName ?? "",
			type: MetadataItemType.Text,
			columns: 12,
		};

		const UNMANAGED_DEVICE_TAGS = Object.values(UnmanagedDeviceCoreTagKey);

		const tags: MetadataTag[] = UNMANAGED_DEVICE_TAGS.map(tagKey => {
			// Using . notation to indicate to RHF that this is an entry in the deviceCoreTags object
			const rhfKey = `deviceCoreTags.${tagKey}`;
			const Icon = getIcon(IconDictionary.UNMANAGED_DEVICE, tagKey);

			return {
				key: tagKey,
				rhfKey,
				label: UNMANAGED_DEVICES_CORE_TAG_LABELS[tagKey],
				value: unmanagedDevice?.deviceCoreTags?.[tagKey] ?? "",
				type: MetadataItemType.Tag,
				icon: <Icon />,
				isCoreTagEditable: true,
			};
		});

		const sortedTags = tags.sort((a, b) => {
			return a.label.localeCompare(b.label);
		});

		const formElementTags = sortedTags.map(tag => ({ ...tag, columns: 6 }));

		return [nameObj, ...formElementTags];
	};
	return (
		<MetadataEdit
			title={window.getCTTranslatedText("EditTags")}
			data={generateFormElements()}
			open={allowEdit}
			onClose={() => handleEdit(undefined)}
			onSubmit={onEditSubmit}
			isSubmitLoading={isEditSubmitLoading}
			scope={Scope.UnmanagedDevice}
		/>
	);
}

export default UnmanagedDeviceEdit;
