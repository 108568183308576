import { LineChartDatum } from "@colortokens/ng-ui";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { IconButton, Stack, debounce } from "@mui/material";
import { useCommonStore } from "common/store";
import { ChartDatum as BarStackChartDatum } from "modules/charts/bar-stack/BarStackChart";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { SecurityLevels } from "pages/assets/types";
import { DashboardWidgetDialog } from "pages/dashboard/components/dashboard-widget-dialog";
import { DashboardTimeFilter } from "pages/dashboard/components/dashboard-widget-dialog/components/dashboard-time-filter";
import { BarStackChartGraph } from "pages/dashboard/components/security-progress/SecurityProgress";
import {
	barChartDataMapper,
	barChartRequestBody,
	getChartDates,
} from "pages/dashboard/components/security-progress/helpers";
import { useTimeSeriesAPI } from "pages/dashboard/components/security-progress/hooks";
import { ASSET_FIELDS_PRIORITY } from "pages/dashboard/constants";
import { SecurityChartDialogProps, TIMELINES } from "pages/dashboard/types";
import { useEffect, useMemo, useState } from "react";

export const SecurityChartDialog = ({
	type,
	totalAssetsCount,
}: SecurityChartDialogProps) => {
	const [open, setOpen] = useState(false);
	const [selectedTimeline, setSelectedTimeline] = useState(TIMELINES.WEEK);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	const timeSeriesMutation = useTimeSeriesAPI();
	const timeSeriesTrendMutation = useTimeSeriesAPI();

	const timeSeriesMutationObj = useMemo(
		() => debounce(timeSeriesMutation.mutate, 500),
		[timeSeriesMutation.mutate]
	);

	const timeSeriesTrendMutationObj = useMemo(
		() => debounce(timeSeriesTrendMutation.mutate, 500),
		[timeSeriesTrendMutation.mutate]
	);

	const [securityData, setSecurityDataData] = useState<BarStackChartDatum[]>(
		[]
	);

	const [securityTrendData, setSecurityTrendData] = useState<LineChartDatum[]>(
		[]
	);

	useEffect(() => {
		if (open && type) {
			let criteria = searchCriteria ?? "*";
			let timeFilter = Number(selectedTimeline) - 1;
			const date = getChartDates(timeFilter ?? 29);

			const requestObj = barChartRequestBody({
				criteria,
				scope: Scope.Asset,
				groupBy: [`metric${type}`],
				statistics: ["distinctcount(metricassetid)"],
				timeStart: date.timeStart,
				timeEnd: date.timeEnd,
			});

			timeSeriesMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const items = responseData?.items ?? {};

						const securityLevels: SecurityLevels[] =
							Object.values(SecurityLevels);

						const barStackChartData = barChartDataMapper({
							items,
							metricId: `metric${type}`,
							valueKeys: securityLevels,
							statisticsId: "metricassetiddistinctcount",
						});

						if (barStackChartData?.length > 0) {
							setSecurityDataData(barStackChartData);
						}
					}
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"ErrorMessageForAssetOverTimeData",
						{ type: window.getCTTranslatedText(type) }
					);
				},
			});
		}
	}, [
		timeSeriesMutationObj,
		searchCriteria,
		setSnackbar,
		open,
		type,
		selectedTimeline,
	]);

	useEffect(() => {
		if (open && type) {
			let criteria = searchCriteria ?? "*";
			let timeFilter = Number(selectedTimeline) - 1;
			const date = getChartDates(timeFilter ?? 29);

			const requestObj = barChartRequestBody({
				criteria,
				scope: Scope.Asset,
				groupBy: [],
				statistics: [`mean(metric${type}score)`],
				timeStart: date.timeStart,
				timeEnd: date.timeEnd,
			});

			timeSeriesTrendMutationObj(requestObj, {
				onSuccess(data) {
					if (data) {
						const responseData = data;
						const items = responseData?.items || {};

						const riskKeys: Array<string> = ["risk"];

						const barStackChartData = barChartDataMapper({
							items,
							valueKeys: riskKeys,
							statisticsId: `metric${type}scoremean`,
						});

						if (barStackChartData?.length > 0) {
							setSecurityTrendData(barStackChartData);
						}
					}
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"ErrorMessageForAssetOverTimeData",
						{ type: window.getCTTranslatedText(type) }
					);
				},
			});
		}
	}, [
		timeSeriesTrendMutationObj,
		searchCriteria,
		setSnackbar,
		open,
		type,
		selectedTimeline,
	]);

	const handleTimeLineUpdate = (timeline: TIMELINES) => {
		setSelectedTimeline(timeline);
	};

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<IconButton aria-label="trend" onClick={handleClick} size="medium">
				<TrendingUpIcon />
			</IconButton>
			<DashboardWidgetDialog
				title={`${window.getCTTranslatedText("SecurityChartTitle", {
					type: window.getCTTranslatedText(type),
				})}`}
				isLoading={timeSeriesMutation?.isLoading}
				children={
					timeSeriesMutation?.isLoading || !securityData ? null : (
						<BarStackChartGraph
							title={`${window.getCTTranslatedText("SecurityChartTitle", {
								type: window.getCTTranslatedText(type),
							})}`}
							data={securityData}
							dataPoint="Assets"
							category={type}
							showAllLabels={selectedTimeline === TIMELINES.WEEK}
							total={totalAssetsCount ?? 0}
							isDialogWidget={true}
							priorityMap={ASSET_FIELDS_PRIORITY}
							lineChartData={securityTrendData}
							lineChartDataPoint={`${window.getCTTranslatedText(type)}`}
							animated
						/>
					)
				}
				open={open}
				handleClose={handleClose}
				timeFilter={
					<DashboardTimeFilter
						selectedTimeline={selectedTimeline}
						handleTimeLineUpdate={handleTimeLineUpdate}
					/>
				}
			/>
		</Stack>
	);
};
