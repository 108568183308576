import { capitalize } from "@mui/material";
import { IconDictionary } from "common/types/types";
import {
	MetadataItemType,
	MetadataName,
	MetadataTag,
} from "pages/asset/components/asset-metadata-wrapper/types";
import { getAssetInterfaceIPs } from "pages/asset/components/metadata-item-wrapper/helpers";
import {
	Asset,
	AssetType,
	CoreTagsKeys,
	CoreTagsLabels,
	OSType,
} from "pages/assets/types";
import { useTagsAPI } from "pages/tags/hooks";
import { Tags } from "pages/tags/hooks/useTagsAPI";
import useIcons from "./useIcons";

interface ReturnValue {
	name: MetadataName | undefined;
	tags: Array<MetadataTag>;
}

type UseAssetMetadata = (asset?: Asset) => ReturnValue;

/**
 * Accepts an asset and extracts the name and tags from it.
 * The tags are extracted from the asset object and the /fields API and are merged
 * to provide an array of unique tags, avoiding duplicates.
 * If the asset is not provided, name is undefined, and tags are extracted from /fields API only.
 * @returns {{name: MetadataName | undefined, tags: Array<MetadataTag>}}
 */

const useAssetMetadata: UseAssetMetadata = asset => {
	const { tagFields } = useTagsAPI();
	const { getIcon } = useIcons();

	const checkIsCoreTagEditable = (key: string) => {
		return Boolean(
			tagFields?.coreTags.some(
				tag => tag.internalName === key && (tag.isCoreTagEditable ?? true)
			)
		);
	};

	const getDefaultTags = (): Array<MetadataTag> => {
		const ipAddressObj: MetadataTag = {
			key: "ipAddress",
			rhfKey: "ipAddress",
			label: "IP Address",
			value: getAssetInterfaceIPs(asset?.interfaces ?? []),
			type: MetadataItemType.Tag,
			icon: getIcon(IconDictionary.MISC, "ipAddress")(),
			isCoreTagEditable: checkIsCoreTagEditable("addresses"),
		};

		const businessValueObj: MetadataTag = {
			label: "Business Value",
			value: asset?.businessValue ?? "",
			icon: getIcon(IconDictionary.MISC, "businessValue")(),
			type: MetadataItemType.BusinessValue,
			key: "businessValue",
			rhfKey: "businessValue",
			isCoreTagEditable: checkIsCoreTagEditable(CoreTagsKeys.BusinessValue),
		};

		const defaultTags: Array<MetadataTag> = [businessValueObj, ipAddressObj];

		if (!asset) {
			return [businessValueObj];
		}

		switch (asset?.type) {
			case AssetType.Device: {
				const macAddressObj: MetadataTag = {
					key: "macAddress",
					rhfKey: "macAddress",
					label: "MAC Address",
					value: (asset?.interfaces && asset?.interfaces[0]?.macaddress) ?? "",
					type: MetadataItemType.Tag,
					icon: getIcon(IconDictionary.MISC, "macAddress")(),
					isCoreTagEditable: checkIsCoreTagEditable("macaddress"),
				};

				const vendorInfoObj: MetadataTag = {
					key: "vendorInfo",
					rhfKey: "vendorInfo",
					label: "Vendor",
					value: asset?.vendorInfo ?? "",
					type: MetadataItemType.Tag,
					icon: getIcon(IconDictionary.MISC, "vendorInfo")(),
					isCoreTagEditable: true,
				};
				return [...defaultTags, macAddressObj, vendorInfoObj];
			}
			case AssetType.Service:
				const serviceCoreTags = {
					[CoreTagsKeys.ClusterIdentifier]: asset?.containerNamespace,
					[CoreTagsKeys.ContainerNamespace]: asset?.clusterIdentifier,
				};

				const clusterIdentifierObj: MetadataTag = {
					key: CoreTagsKeys.ClusterIdentifier,
					rhfKey: CoreTagsKeys.ClusterIdentifier,
					label:
						CoreTagsLabels[CoreTagsKeys.ClusterIdentifier] ??
						CoreTagsKeys.ClusterIdentifier,
					value: serviceCoreTags[CoreTagsKeys.ClusterIdentifier] ?? "",
					type: MetadataItemType.Tag,
					icon: getIcon(
						IconDictionary.CORE_TAG,
						CoreTagsKeys.ClusterIdentifier
					)(),
					isCoreTagEditable: checkIsCoreTagEditable(
						CoreTagsKeys.ClusterIdentifier
					),
				};

				const containerNamespaceObj: MetadataTag = {
					key: CoreTagsKeys.ContainerNamespace,
					rhfKey: CoreTagsKeys.ContainerNamespace,
					label:
						CoreTagsLabels[CoreTagsKeys.ContainerNamespace] ??
						CoreTagsKeys.ContainerNamespace,
					value: serviceCoreTags[CoreTagsKeys.ContainerNamespace] ?? "",
					type: MetadataItemType.Tag,
					icon: getIcon(
						IconDictionary.CORE_TAG,
						CoreTagsKeys.ContainerNamespace
					)(),
					isCoreTagEditable: checkIsCoreTagEditable(
						CoreTagsKeys.ContainerNamespace
					),
				};

				return [...defaultTags, clusterIdentifierObj, containerNamespaceObj];
			case AssetType.Server:
			case AssetType.User: {
				const operatingSystemObj: MetadataTag = {
					label: "Operating System",
					value: asset?.osName ?? "",
					icon: getIcon(IconDictionary.OS, asset?.osName as OSType)(),
					type: MetadataItemType.Tag,
					key: "osName",
					rhfKey: "osName",
					isCoreTagEditable: checkIsCoreTagEditable("osname"),
				};
				return [...defaultTags, operatingSystemObj];
			}
			default:
				return [];
		}
	};

	const extractCoreTagsFromAsset = (coreTags: Asset["coreTags"] = {}) => {
		const tags: MetadataTag[] = [];

		Object.entries(coreTags ?? {}).forEach(([key, value]) => {
			// Using . notation to indicate to RHF that this is an entry in the coreTags object
			const rhfKey = `coreTags.${key}`;
			const label = CoreTagsLabels[key as CoreTagsKeys] ?? key;

			// If value is falsy, ignore
			if (!value) {
				return;
			}

			tags.push({
				key,
				rhfKey,
				label,
				value: value ?? "",
				type: MetadataItemType.Tag,
				icon: getIcon(IconDictionary.CORE_TAG, key as CoreTagsKeys)(),
				isCoreTagEditable: checkIsCoreTagEditable(key as CoreTagsKeys),
			});
		});

		return tags;
	};

	const extractCoreTagsFromTagsApi = (
		coreTags: Tags["coreTags"] | undefined,
		assetType: Asset["type"] | undefined,
		assetCoreTags: Asset["coreTags"] = {}
	) => {
		const tags: MetadataTag[] = [];

		const DEFAULT_TAGS: Array<string> = [
			CoreTagsKeys.Application,
			CoreTagsKeys.Environment,
			CoreTagsKeys.Location,
			CoreTagsKeys.Owner,
			CoreTagsKeys.Role,
			CoreTagsKeys.Department,
			CoreTagsKeys.PrimarySubnet,
		];

		const DEVICE_TAGS: Array<string> = [
			...DEFAULT_TAGS,
			CoreTagsKeys.Model,
			CoreTagsKeys.Category,
			CoreTagsKeys.SubCategory,
			CoreTagsKeys.Manufacturer,
			CoreTagsKeys.KernelVersion,
			CoreTagsKeys.SerialNumber,
		];

		const SERVICE_CORE_TAGS: Array<string> = [
			CoreTagsKeys.Application,
			CoreTagsKeys.Environment,
			CoreTagsKeys.Location,
			CoreTagsKeys.Role,
			CoreTagsKeys.Owner,
		];

		coreTags?.forEach(tag => {
			const key = tag.name ?? "";
			// Using . notation to indicate to RHF that this is an entry in the coreTags object
			const rhfKey = `coreTags.${key}`;

			const tagObj: MetadataTag = {
				key,
				rhfKey,
				label: CoreTagsLabels[key as CoreTagsKeys],
				value: assetCoreTags?.[key] ?? "",
				type: MetadataItemType.Tag,
				icon: getIcon(IconDictionary.CORE_TAG, key as CoreTagsKeys)(),
				isCoreTagEditable: tag.isCoreTagEditable ?? true,
			};

			switch (assetType) {
				case AssetType.Device:
					DEVICE_TAGS.includes(key) && tags.push(tagObj);
					break;
				case AssetType.Service:
					SERVICE_CORE_TAGS.includes(key) && tags.push(tagObj);
					break;
				default:
					DEFAULT_TAGS.includes(key) && tags.push(tagObj);
					break;
			}
		});

		return tags;
	};

	const extractUserDefinedTags = (
		userDefinedTags: Tags["userDefinedTags"] | undefined
	) => {
		const tags: MetadataTag[] = [];

		userDefinedTags?.forEach(tag => {
			const key = tag.displayName ?? "";
			// Using . notation to indicate to RHF that this is an entry in the coreTags object
			const rhfKey = `coreTags.${key}`;

			const label = tag.displayName;
			const capitalizedLabel = capitalize(label);

			tags.push({
				key,
				rhfKey,
				label: capitalizedLabel,
				value: asset?.coreTags[key] ?? "",
				type: MetadataItemType.Tag,
				isUserDefinedTag: true,
				icon: getIcon(IconDictionary.CORE_TAG, key as CoreTagsKeys)(),
				isCoreTagEditable: tag.isCoreTagEditable ?? true,
			});
		});

		return tags;
	};

	const tags: ReturnValue["tags"] = [
		...getDefaultTags(),
		...extractCoreTagsFromAsset(asset?.coreTags),
		...extractCoreTagsFromTagsApi(
			tagFields?.coreTags,
			asset?.type,
			asset?.coreTags
		),
		...extractUserDefinedTags(tagFields?.userDefinedTags),
	];

	const uniqueTags = Array.from(
		new Map(tags.map(ele => [ele.key, ele])).values()
	);

	const sortedTags = uniqueTags.sort((a, b) => {
		if (a.isUserDefinedTag === b.isUserDefinedTag) {
			return a.label.localeCompare(b.label);
		}
		return a.isUserDefinedTag ? 1 : -1;
	});

	const assetNameObj: MetadataName = {
		key: "assetName",
		rhfKey: "assetName",
		label: "Name",
		value: asset?.assetName ?? "",
		type: MetadataItemType.Text,
	};

	return { tags: sortedTags, name: asset ? assetNameObj : undefined };
};

export default useAssetMetadata;
