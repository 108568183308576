import { Box, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useTemplateStore } from "pages/templates/store";
import { Template } from "pages/templates/types";
import { useState } from "react";
import { AssetCount } from "../asset-count";
import { GroupCount } from "../group-count";
import { UsedByPolicies } from "../used-by-policies";

export interface TemplateDeleteProps {
	template: Template;
	handleAllowDelete: (value: boolean) => void;
	allowDelete: boolean;
}

const useDeleteTemplatesAPI = (templateId: string | undefined) => {
	return useMutation<any, Error, any>([
		"policy",
		`templates/${templateId}`,
		"delete",
	]);
};

export function TemplateDelete({
	template,
	handleAllowDelete,
	allowDelete,
}: TemplateDeleteProps) {
	const notify = useEventSubscriptionStore(state => state.notify);
	const deleteTemplatesAPI = useDeleteTemplatesAPI(template?.templateId);
	const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
	const [loading, setLoading] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const queryClient = useQueryClient();

	const hasAssets =
		template.templateAssignments && template.templateAssignments > 0;
	const hasSegments =
		template.templateTagBasedPolicyAssignments &&
		template.templateTagBasedPolicyAssignments > 0;
	const hasUGs =
		template.usergroupTemplateAssignments &&
		template.usergroupTemplateAssignments > 0;

	const updateTemplateDetails = () => {
		setLoading(true);

		deleteTemplatesAPI.mutate(
			{},
			{
				onSuccess: response => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "DeleteTemplatesRequestSubmittedSuccessfully",
					});
					queryClient.invalidateQueries({
						queryKey: ["policy"],
					});
					setTimeout(() => {
						requestAPIRefresh();
						handleAllowDelete(false);
						setLoading(false);
					}, 500);
				},
				onError: error => {
					setLoading(false);
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};

	function renderSecondaryText() {
		return (
			<Stack>
				{hasAssets || hasSegments || hasUGs
					? window.getCTTranslatedText("DeleteTemplateWarning")
					: null}
				{hasAssets ? (
					<AssetCount template={template} openInNewTab={true} />
				) : null}
				{hasSegments ? (
					<UsedByPolicies template={template} openInNewTab={true} />
				) : null}
				{hasUGs ? <GroupCount template={template} openInNewTab={true} /> : null}
			</Stack>
		);
	}

	return (
		<Box onClick={e => e.stopPropagation()}>
			<CTConfirmation
				open={allowDelete}
				onClose={() => handleAllowDelete(false)}
				title={window.getCTTranslatedText("Delete Template")}
				primaryText={window.getCTTranslatedText(
					"AreYouSureYouWantToDeleteTemplate",
					{
						templateName: template?.templateName,
					}
				)}
				secondaryText={renderSecondaryText()}
				primaryButtonText={window.getCTTranslatedText("Delete")}
				isLoading={deleteTemplatesAPI.isLoading || loading}
				onSuccess={updateTemplateDetails}
			/>
		</Box>
	);
}
