import { Box, capitalize, Typography } from "@mui/material";

interface Props {
	isUpdatePending: boolean;
	currentStatus: "enabled" | "disabled" | undefined;
}

function PendingFirewallCoexistence({ isUpdatePending, currentStatus }: Props) {
	const currentStatusCapitalized = currentStatus
		? capitalize(currentStatus)
		: "Disabled";
	const heading = isUpdatePending ? "Status Updated:" : "No Status Change:";
	const description = isUpdatePending
		? "The Firewall Coexistence status has been updated."
		: "No recent updates to the Firewall Coexistence status.";
	const currentStatusText = `Current status${isUpdatePending ? ": " : " remains "}`;

	return (
		<Box py={2}>
			<Typography variant="subtitle1" mb={1}>
				{window.getCTTranslatedText(heading)}
			</Typography>
			<Typography variant="body2">
				{window.getCTTranslatedText(description)}
				<br />
				{window.getCTTranslatedText(currentStatusText)}
				<strong>{window.getCTTranslatedText(currentStatusCapitalized)}</strong>
			</Typography>
		</Box>
	);
}

export default PendingFirewallCoexistence;
