import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GRID_TREE_DATA_GROUPING_FIELD,
	GridCellCheckboxRenderer,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AgentLogs } from "modules/agent-logs";
import { CustomDetailPanelToggle } from "modules/data-grid/components/data-grid";
import { AgentLanIp } from "../agent-lanip";
import { AgentLocation } from "../agent-location";
import { AgentMode } from "../agent-mode";
import { AgentStatus } from "../agent-status";
import { AgentVersion } from "../agent-version";
import { AgentWanIp } from "../agent-wanip";
import { ApplianceActionsMenu } from "../appliance-actions-menu";
import { ApplianceName } from "../appliance-name";
import { DeviceCount } from "../device-count";
import { UnmanagedDeviceCount } from "../unmanaged-device-count";
import { isParentRow } from "./helpers/columnHelpers";

export const applianceDiagnosticSupportedMinVersion = "3.0.625";
export const applianceDiagnosticSupportedDevVersion = "1.0.1";

export const BASE_APPLIANCE_COLUMNS: GridColDef[] = [
	{
		...GRID_CHECKBOX_SELECTION_COL_DEF,
		renderCell: params => {
			return <GridCellCheckboxRenderer {...params} />;
		},
		type: "singleSelect",
	},
	{
		field: "agentName",
		headerName: "gatekeeperName",
		minWidth: 160,
		width: 160,
		flex: 0.8,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <ApplianceName agent={params.row} />;
		},
	},
	{
		field: "agentStatus",
		headerName: "gatekeeperStatus",
		width: 160,
		minWidth: 160,
		flex: 0.4,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentStatus agent={params.row} />;
		},
	},
	{
		field: "currentVersion",
		headerName: "gatekeeperVersion",
		width: 160,
		minWidth: 160,
		flex: 0.25,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentVersion agent={params.row} />;
		},
	},
	{
		field: "agentLocation",
		headerName: "Location",
		width: 160,
		minWidth: 100,
		flex: 0.4,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentLocation agent={params.row} />;
		},
	},
	{
		field: "managedDeviceCount",
		headerName: "managedDevices",
		width: 120,
		minWidth: 100,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <DeviceCount agent={params.row} />;
		},
	},
	{
		field: "unmanagedDeviceCount",
		headerName: "UnmanagedDevices",
		width: 120,
		minWidth: 100,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceCount agent={params.row} />;
		},
	},
	{
		field: "mode",
		headerName: "DHCP Mode",
		width: 120,
		minWidth: 120,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentMode agent={params.row} viewOnly={true} />;
		},
	},
	{
		field: "wanIp",
		headerName: "WAN IP",
		width: 140,
		minWidth: 140,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentWanIp agent={params.row} viewOnly={true} />;
		},
	},
	{
		field: "lanIp",
		headerName: "LAN/VLAN IP",
		width: 140,
		minWidth: 140,
		flex: 0.3,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AgentLanIp agent={params.row} viewOnly={true} />;
		},
	},
];

export const LOG_COLUMN: GridColDef[] = [
	{
		field: "logs",
		headerName: "Logs",
		width: 90,
		minWidth: 90,
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		headerAlign: "left",
		headerClassName: "hideRightSeparator",
		align: "center",
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			if (isParentRow(params.row)) {
				return null;
			}
			return <AgentLogs agent={params.row} />;
		},
	},
];

export const ACTION_COLUMN: GridColDef[] = [
	{
		field: "action",
		headerName: "",
		sortable: false,
		width: 50,
		minWidth: 50,
		headerClassName: "hideRightSeparator",
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			const isChild = params.row?.agentId?.includes("-child");
			const isParent = isParentRow(params.row);

			const agent = {
				...params.row,
				agentId: params.row?.agentId?.replace("-child", ""),
			};

			return (
				<ApplianceActionsMenu
					agent={agent}
					isParent={isParent}
					isChild={isChild}
				/>
			);
		},
	},
];

export const EXPANSION_TOGGLE_COLUMN: GridColDef[] = [
	{
		...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
		renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<CustomDetailPanelToggle id={params.id} value={params.value} />
		),
	},
];

export const GROUP_COLUMN: GridColDef[] = [
	{
		field: GRID_TREE_DATA_GROUPING_FIELD,
		minWidth: 30,
		width: 30,
	},
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
	// Hide columns from the display
};

const PinnedColumns = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "agentName"],
	right: ["logs", "diagnostics", "action", GRID_TREE_DATA_GROUPING_FIELD],
};

export const ApplianceColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};
