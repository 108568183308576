import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { CircularCenteredLoader } from "common/atoms/loader";
import { Toolbar } from "common/atoms/toolbar";
import { NoDataFound } from "common/molecules/no-data-found";
import { CoreStore } from "modules/core/types";
import { useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import { AnnotationSubmission } from "./components/annotation-submission";
import { AnnotationsList } from "./components/annotations-list";
import { useAnnotationsListAPI } from "./hooks";

interface AnnotationDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	entity: string;
	entityId: string;
	params?: string;
	useStore: UseBoundStore<StoreApi<CoreStore>>;
	queryKey?: string;
	hasPermission?: boolean;
}

export const AnnotationDrawer = ({
	isOpen,
	onClose,
	entity,
	entityId,
	params,
	useStore,
	queryKey,
	hasPermission = true,
}: AnnotationDrawerProps) => {
	const { data, isLoading } = useAnnotationsListAPI(entity, entityId, params);
	const [hasComments, setHasComments] = useState<boolean>(false);
	const requestAPIRefresh = useStore(state => state.requestAPIRefresh);
	const queryClient = useQueryClient();

	const handlerOnClose = () => {
		if (hasComments) {
			requestAPIRefresh();
			queryClient.invalidateQueries({ queryKey: [queryKey] });
		}
		onClose();
	};

	return (
		<Box onClick={e => e.stopPropagation()}>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={handlerOnClose}
				PaperProps={{
					sx: {
						padding: 3,
						width: "40%",
						overflow: "hidden",
					},
				}}
			>
				<Toolbar />
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{ mb: 1 }}
				>
					<Stack direction={"row"} alignItems="center">
						<Typography variant="h6">
							{window.getCTTranslatedText("Comments")}
						</Typography>
					</Stack>
					<IconButton
						size="small"
						aria-label="close drawer"
						onClick={handlerOnClose}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Stack>
				{hasPermission && (
					<AnnotationSubmission
						entity={entity}
						entityId={entityId}
						setHasComments={setHasComments}
					/>
				)}
				<Stack
					justifyContent="space-between"
					style={{ flex: 1, position: "relative" }}
				>
					{isLoading ? (
						<CircularCenteredLoader />
					) : (
						<>
							{data && data?.items?.length ? (
								<AnnotationsList annotationsList={data} />
							) : (
								<NoDataFound text={window.getCTTranslatedText("No comments")} />
							)}
						</>
					)}
				</Stack>
			</Drawer>
		</Box>
	);
};
