import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { useSearchParams } from "react-router-dom";
import { NotificationConfig } from "./NotificationConfig";

interface NotificationConfigDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	alertRules: string[];
}

export const NotificationConfigDrawer = ({
	isOpen,
	onClose,
	alertRules,
}: NotificationConfigDrawerProps) => {
	const [urlParams, setURLParams] = useSearchParams();

	const handlerOnClose = () => {
		let criteriaAsParams = new URLSearchParams(urlParams);
		criteriaAsParams.delete("filters");
		setURLParams(criteriaAsParams?.toString());
		onClose();
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={handlerOnClose}
			PaperProps={{
				sx: { width: "70%", overflow: "hidden" },
			}}
		>
			<Toolbar />
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 1, p: 3 }}
			>
				<Stack direction={"row"} alignItems="center">
					<Typography variant="h6">
						{window.getCTTranslatedText("ConfigureAlerts")}
					</Typography>
				</Stack>
				<IconButton
					size="small"
					aria-label="close drawer"
					onClick={handlerOnClose}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Stack>

			<NotificationConfig alertRules={alertRules} onClose={handlerOnClose} />
		</Drawer>
	);
};
