import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppBar } from "modules/AppBar/components/AppBar";
import { WorkRequestToast } from "modules/AppBar/components/WorkRequestToast";
import { NavSidebar } from "modules/NavSidebar/components";
import React from "react";
import { useLocation } from "react-router-dom";

const noPaddingRoutes: { [key: string]: boolean } = {
	visualizer: true,
};

interface SidebarProps {
	onLogout: Function;
	children: React.ReactNode;
}

export const NavigationLayout = ({ onLogout, children }: SidebarProps) => {
	const theme = useTheme();
	const location = useLocation();

	const routesPaths = location?.pathname
		?.split("/")
		?.filter(route => route !== "");

	const activeSidebarTab = routesPaths?.[0]?.toLowerCase();
	const subRoute = routesPaths?.[1]?.toLowerCase() || undefined;

	const contentPaddingStyle = {
		padding:
			noPaddingRoutes[activeSidebarTab] ||
			(subRoute && noPaddingRoutes[subRoute])
				? 0
				: 3,
		pt:
			noPaddingRoutes[activeSidebarTab] ||
			(subRoute && noPaddingRoutes[subRoute])
				? 0
				: 4,
	};

	return (
		<Box sx={{ display: "flex" }}>
			<AppBar onLogout={onLogout} />
			<NavSidebar />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					minWidth: 0,
					height: "100vh",
					pt: `54px`,
				}}
			>
				<Box
					sx={{
						...contentPaddingStyle,
						height: "100%",
						overflowY: "auto",
					}}
					bgcolor={theme.palette.background.default}
				>
					{children}
				</Box>
			</Box>
			<WorkRequestToast />
		</Box>
	);
};
