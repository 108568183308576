import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { DataLoaderBody } from "modules/core/Core";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useState } from "react";
import { MAX_DATA_LIMIT, PAGE_SIZE } from "../constants";
import { ExportCSVParams, ExportCSVResponse } from "../types";
import { convertJsonToCsv } from "../utils";
import { downloadBlob } from "../utils/convertJsonToCsv";

export function useExportCSV<DataType>({
	useApi,
	page,
	sort,
	searchCriteria,
	sourceCriteria,
	destinationCriteria,
	scope,
	frontendOnly,
	isNewCsvAPIVersion = false,
	maxRowCount,
	dataMapper,
	removeSearchFields,
}: ExportCSVParams<DataType>): ExportCSVResponse<DataType> {
	let exportState = false;
	const [exportStatus, setExportGlobalStatus] = useState<boolean>(false);
	const [urlToDownload, setUrlToDownload] = useState<string | undefined>(
		undefined
	);
	const exportMutation = useApi();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const { isFeatureEnabled: isExportCsvEnabled } = useFeatureFlagControl(
		FEATURES.EXPORT_CSV
	);
	const triggerExportAsCsv = async (selectedData: DataType[] | undefined) => {
		const exportPromises = [];
		let pgSize = PAGE_SIZE;
		let jsonData: Array<DataType> = [];
		let maxIterationLimit = MAX_DATA_LIMIT / PAGE_SIZE;
		let currentPage = 0;
		setUrlToDownload(undefined);
		setExportStatus(true);

		const addData = (newData: Array<DataType> = []) => {
			if (dataMapper) {
				jsonData = jsonData.concat(newData.map(dataMapper));
			} else {
				jsonData = jsonData.concat(newData);
			}
		};

		setSnackbar(true, SnackBarSeverity.Info, "CSVGenerationStarted");

		if (isExportCsvEnabled && !frontendOnly && isNewCsvAPIVersion) {
			let idsCriteria = getIdsCriteria(selectedData, scope);
			const search: DataLoaderBody = {
				criteria: idsCriteria ?? searchCriteria ?? "*",
				pagination: {
					offset: 0,
					limit: maxRowCount ?? 10000,
					sort,
				},
				facetFields: [],
				_download: true,
			};

			if (sourceCriteria !== "") {
				search["sourceCriteria"] = sourceCriteria ?? "";
			}

			if (destinationCriteria !== "") {
				search["destinationCriteria"] = destinationCriteria ?? "";
			}

			search._csvDownload = true;

			exportMutation.mutateAsync(search, {
				onSuccess(data) {
					const responseData = data;
					if (responseData?.items && responseData?.items?.length > 0) {
						addData(responseData?.items);
						if (responseData?.items?.length < pgSize) {
							setExportStatus(false);
						} else {
							setExportStatus(true);
						}
					} else {
						setExportStatus(false);
					}

					const blob = responseData as any as Blob;
					const url = downloadBlob(blob, false);
					setUrlToDownload(url);
					setSnackbar(true, SnackBarSeverity.Success, "CSVGenerationCompleted");
				},
				onError(error) {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					setExportStatus(false);
				},
			});

			return;
		}

		if (selectedData) {
			addData(selectedData);
		} else {
			for (let i = 0; i < maxIterationLimit; i++) {
				const search: DataLoaderBody = {
					criteria: searchCriteria ?? "*",
					pagination: {
						offset: currentPage * pgSize,
						limit: pgSize,
						sort,
					},
					facetFields: [],
					_download: true,
				};

				if (sourceCriteria !== "") {
					search["sourceCriteria"] = sourceCriteria ?? "";
				}

				if (destinationCriteria !== "") {
					search["destinationCriteria"] = destinationCriteria ?? "";
				}

				if (removeSearchFields?.length) {
					removeSearchFields.forEach(field => {
						delete search[field];
					});
				}

				const promise = await exportMutation.mutateAsync(search, {
					onSuccess(data) {
						const responseData = data;
						if ((responseData?.items?.length ?? 0) > 0) {
							addData(responseData.items);
							if (responseData.items.length < pgSize) {
								setExportStatus(false);
							} else {
								setExportStatus(true);
							}

							const cleanedCsvContent = convertJsonToCsv(responseData.items);
							const blob = new Blob([cleanedCsvContent], { type: "text/csv" });
							const url = downloadBlob(blob, false);

							return url;
						} else {
							setExportStatus(false);
						}
					},
					onError(error) {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						setExportStatus(false);
					},
				});

				exportPromises.push(promise);

				if (i === maxIterationLimit - 1 || !exportState) {
					break;
				}
				currentPage = currentPage + 1;
			}
		}

		const results = await Promise.all(exportPromises);

		const lastUrl = results[results.length - 1];
		if (lastUrl) {
			setUrlToDownload(lastUrl as unknown as string);
			setSnackbar(true, SnackBarSeverity.Success, "CSVGenerationCompleted");
		}

		if (jsonData) {
			try {
				setExportStatus(false);
				const url = convertJsonToCsv(jsonData ?? [], false);
				setUrlToDownload(url);
				setSnackbar(true, SnackBarSeverity.Success, "CSVGenerationCompleted");
			} catch (error) {
				setExportStatus(false);
				setUrlToDownload(undefined);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			}
		}
	};

	const getExportStatus = (): boolean => {
		return exportStatus;
	};

	const setExportStatus = (status: boolean) => {
		exportState = status;
		setExportGlobalStatus(exportState);
	};

	const getUrlToDownload = (): string | undefined => {
		return urlToDownload;
	};

	const resetDownloadUrl = () => {
		setUrlToDownload(undefined);
	};

	return {
		triggerExportAsCsv,
		getExportStatus,
		getUrlToDownload,
		resetDownloadUrl,
	};
}

function getIdsCriteria(data: Array<any> | undefined, scope: Scope) {
	if (!data) {
		return undefined;
	}
	let field = "";
	let key = "";
	switch (scope) {
		case Scope.Asset:
			key = "assetId";
			field = key;
			break;
		case Scope.Path:
			key = "channelHash";
			field = key;
			break;
		case Scope.Port:
			key = "lpId";
			field = key;
			break;
		case Scope.Template:
			key = "templateId";
			field = key;
			break;
		case Scope.Network:
			key = "namedNetworkId";
			field = key;
			break;
		case Scope.Agent:
			key = "agentId";
			field = key;
			break;
		case Scope.Appliance:
			key = "agentId";
			field = key;
			break;
		case Scope.AuditLog:
			key = "agentId";
			field = key;
			break;
		case Scope.TagRule:
			key = "ruleId";
			field = key;
			break;
		case Scope.TagBasedPolicy:
			key = "tagBasedPolicyId";
			field = key;
			break;
		default:
			break;
	}
	return `${field} in (${(data ?? [])
		.map(value => `'${value[key]}'`)
		.join(",")})`;
}
