import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Chip, Stack, Tooltip, useTheme } from "@mui/material";
import { DEFAULT_TIME_FILTER } from "common/molecules/TimeFilter/TimeFilter";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { nil } from "modules/facets/FacetUtils";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useMemo } from "react";
import { CompassDirection } from "../../paths/types";
import { useDirectionChange } from "../hooks/useDirectionChange";
import { computeNodeStats, useVisxStore } from "../store";
import {
	CTNodeType,
	Dimensions,
	GroupNodeDataType,
	PathReviewStatus,
	StatisticType,
	TrafficData,
	UserGroupDimension,
} from "../types";
import {
	DEFAULT_PRIVATE_NETWORK_NODE_NAME,
	DEFAULT_PUBLIC_NETWORK_NODE_NAME,
	OTHERS,
	getNodeDisplayName,
	isSubnet,
	removeSubnetNodePrefix,
} from "../visx-utils";

export function CTGroup({
	data,
	id,
	isContainer,
	isLoading,
}: {
	data: GroupNodeDataType;
	isContainer?: boolean;
	id: string;
	isLoading: boolean;
}) {
	const filterDimension = data?.dimension;

	const originalTrafficData = useVisxStore(state => state.trafficData);
	const setTrafficData = useVisxStore(state => state.setTrafficData);

	const setExpansions = useVisxStore(state => state.setExpansions);
	const originalExpansions = useVisxStore(state => state.expansions);

	const childNodeIdsMap = useVisxStore(state => state.childNodeIds);
	const changeParentChildRelationship = useVisxStore(
		state => state.changeParentChildRelationship
	);

	const setIsPublicNodeExpanded = useVisxStore(
		state => state.setIsPublicNodeExpanded
	);
	const setIsPrivateNodeExpanded = useVisxStore(
		state => state.setIsPrivateNodeExpanded
	);
	const theme = useTheme();
	const positions = useVisxStore(state => state.positions);
	const setPositions = useVisxStore(state => state.setPositions);
	const { onDirectionChange } = useDirectionChange();

	const setIsDrawerOpen = useVisxStore(state => state.setIsDrawerOpen);
	const setSelectedNode = useVisxStore(state => state.setSelectedNode);
	const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);
	const setSelectedPathStatus = useVisxStore(
		state => state.setSelectedPathStatus
	);
	const selectedDimension = useVisxStore(state => state.selectedDimension);
	const setSelectedDimension = useVisxStore(
		state => state.setSelectedDimension
	);

	const restore = useVisxStore(state => state.restore);

	const setSavedTimeState = useUserPreferencesStore(
		state => state.setSavedTimeState
	);
	const setTimeFilter = useUserPreferencesStore(state => state.setTimeFilter);

	const setSelected = () => {
		if (selectedDimension?.name === UserGroupDimension.name) {
			return;
		}
		const isAll = data.label === "Selected Assets";
		if ((data.label === OTHERS || data.type !== CTNodeType.HUB) && !isAll) {
			return;
		}

		if (selectedPathStatus === PathReviewStatus.Enforced) {
			setSelectedPathStatus(PathReviewStatus.WIP);
		}
		setIsDrawerOpen(true);

		if (!isAll) {
			setSelectedNode(data);
		}
	};

	const removeGroup = () => {
		let expansions = originalExpansions;

		const cleanup = (trafficData: TrafficData, cleanupNodeId: string) => {
			positions?.delete(cleanupNodeId);
			positions?.delete(trafficData[cleanupNodeId]?.parent?.name || "");

			if (cleanupNodeId === DEFAULT_PRIVATE_NETWORK_NODE_NAME) {
				setIsPrivateNodeExpanded(false);
				return;
			}

			if (cleanupNodeId === DEFAULT_PUBLIC_NETWORK_NODE_NAME) {
				setIsPublicNodeExpanded(false);
				return;
			}

			if (!trafficData) {
				return trafficData;
			}

			let nodesToDelete: Set<string> = new Set();
			if (trafficData[cleanupNodeId]?.children?.length) {
				trafficData[cleanupNodeId]?.children?.forEach(childTrafficData => {
					let childTrafficDataKeys = Object.keys(childTrafficData);

					childTrafficDataKeys.forEach(name => {
						nodesToDelete.add(name);
					});
				});
			} else {
				nodesToDelete = new Set(
					childNodeIdsMap.get(cleanupNodeId)?.keys() || []
				);
			}

			let trafficNodes = Object.keys(trafficData);
			trafficNodes.forEach(nodeId => {
				nodesToDelete.forEach(nodeToRemoveId => {
					if (
						trafficData[nodeToRemoveId]?.children ||
						childNodeIdsMap.has(nodeToRemoveId)
					) {
						cleanup(trafficData, nodeToRemoveId);
					}
					delete trafficData[nodeToRemoveId];
					let nodeData = trafficData[nodeId];

					if (!nodeData) {
						return;
					}
					const deleteFromNetwork = (
						stat: StatisticType,
						direction: CompassDirection
					) => {
						let statsHolder = nodeData?.data?.[stat];
						let networkTraffic = statsHolder?.stats?.[direction];
						if (!networkTraffic) {
							return;
						}

						let keys = Object.keys(networkTraffic);
						keys.forEach(nwKey => {
							delete networkTraffic![nwKey][nodeToRemoveId];
						});
						if (nodeToRemoveId !== nil && isSubnet(nodeToRemoveId)) {
							delete networkTraffic[nodeToRemoveId];
						}
					};

					let hasDataInNodes = false;
					Object.values(StatisticType).forEach(type => {
						let hasData =
							nodeData?.data?.[type]?.dimensionStats?.has(nodeToRemoveId) ||
							nodeData?.data?.[type]?.eastWestNetworkStats?.has(
								nodeToRemoveId
							) ||
							false;
						hasDataInNodes = hasData || hasDataInNodes;

						if (hasData) {
							deleteFromNetwork(type, CompassDirection.EastWest);
							deleteFromNetwork(type, CompassDirection.NorthSouth);
						}
					});

					if (hasDataInNodes) {
						nodeData = {
							...nodeData,
							...computeNodeStats(nodeData),
						};

						trafficData[nodeId] = { ...nodeData };
					}
				});
			});

			if (trafficData[cleanupNodeId]) {
				delete trafficData[cleanupNodeId]?.children;
				trafficData[cleanupNodeId] = { ...trafficData[cleanupNodeId] };
			} else {
				changeParentChildRelationship(
					Array.from(nodesToDelete),
					cleanupNodeId,
					true
				);
			}

			expansions = expansions?.filter(
				e =>
					e.parent?.name !== cleanupNodeId &&
					e.parent?.name !== removeSubnetNodePrefix(cleanupNodeId)
			);
			return { ...trafficData };
		};

		if (!originalTrafficData) {
			return;
		}

		let newTrafficData = cleanup(
			originalTrafficData,
			isSubnet(data.id) ? data.id : data.label
		);
		if (newTrafficData) {
			setTrafficData(newTrafficData);
		}
		setExpansions(expansions);
		setPositions(positions);
	};

	const restoreState = () => {
		let savedState = useVisxStore.getState().savedState;
		const savedTime = useUserPreferencesStore.getState().savedTimeState;
		if (savedState) {
			savedState.savedState = undefined;
			restore(savedState);
			onDirectionChange(Number(savedState?.selectedDirection) as Direction);
		} else {
			setSelectedDimension(Dimensions[0]);
		}
		if (savedTime) {
			setSavedTimeState(DEFAULT_TIME_FILTER);
			setTimeFilter(savedTime);
		}
	};

	const exitUGMode = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e?.stopPropagation?.();
		restoreState();
	};

	let background =
		theme.palette.mode === "dark"
			? `radial-gradient(50% 50% at 50% 50%, rgba(0, 192, 202, 0.02) 0%, rgba(0, 192, 202, 0.20) 100%)`
			: `radial-gradient(50% 50% at 50% 50%, rgba(0, 192, 202, 0.015) 0%, rgba(0, 192, 202, 0.15) 100%)`;

	const isNetworkNode =
		data.id === DEFAULT_PUBLIC_NETWORK_NODE_NAME ||
		data.id === DEFAULT_PRIVATE_NETWORK_NODE_NAME;

	let displayLabel = useMemo(
		() => getNodeDisplayName(data.label),
		[data.label]
	);

	const showUGExitButton =
		displayLabel === "Selected Assets" &&
		selectedDimension?.name === UserGroupDimension.name;

	displayLabel = showUGExitButton
		? window.getCTTranslatedText("userGroupView")
		: displayLabel;

	const chipStyles = {
		cursor: "move",
		backgroundColor: theme.palette.mode === "dark" ? "#313436" : "#fff",
		border: "1px solid",
		borderColor: theme.palette.mode === "dark" ? "#FFFFFF1F" : "#0000001F",
		color: theme.palette.mode === "dark" ? "#FFF" : "#000000DE",
	};

	return (
		<Stack
			className={`ctNode-container ${isLoading ? "isLoading" : ""} ${theme.palette.mode ?? ""}`}
			alignItems={"center"}
			sx={{
				width: "100%",
				height: "100%",
				borderRadius: "50%",
				background,
				pointerEvents: "none !important",
				position: "relative",
			}}
		>
			<Stack
				direction={"row"}
				spacing={1}
				className="ct-drag-handle"
				alignItems="center"
				justifyContent="center"
				sx={{
					top: -12,
					minWidth: "max-content",
					position: "relative",
				}}
			>
				{isContainer ? (
					<Chip
						onClick={setSelected}
						label={removeSubnetNodePrefix(displayLabel)}
						sx={chipStyles}
						icon={
							showUGExitButton ? (
								<ArrowBackIcon
									sx={{
										cursor: "pointer",
									}}
									onClick={e => exitUGMode(e)}
								/>
							) : (
								<></>
							)
						}
					/>
				) : (
					<Tooltip
						title={
							isNetworkNode
								? ""
								: `${getNodeDisplayName(
										removeSubnetNodePrefix(data.id)
									)} ${getNodeDisplayName(
										filterDimension?.label ?? ""
									)?.toLocaleLowerCase()} ${getNodeDisplayName(
										"expanded by"
									)} ${displayLabel?.toLocaleLowerCase()}`
						}
					>
						<Chip
							label={getNodeDisplayName(removeSubnetNodePrefix(data.id))}
							onDelete={removeGroup}
							onClick={setSelected}
							sx={chipStyles}
							deleteIcon={<RemoveCircleIcon />}
						/>
					</Tooltip>
				)}
			</Stack>
		</Stack>
	);
}
