import { useQuery } from "@tanstack/react-query";
import { Template } from "pages/templates/types";

interface UseTemplateAPIProps {
	templateId: string | undefined;
	enabled?: boolean;
}

export function useTemplateAPI({ templateId, enabled }: UseTemplateAPIProps) {
	return useQuery<Template, Error>(["policy", `templates/${templateId}`], {
		refetchOnMount: false,
		enabled: enabled ?? Boolean(templateId),
		refetchOnWindowFocus: false,
	});
}
