import { Button, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { parseErrorMessage } from "common/utils";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAlertsStore } from "pages/monitoring/store";
import { CTAlert } from "pages/monitoring/types";
import { useAlertStatusUpdateAPI } from "../alerts-action/hooks";

export interface AlertToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<CTAlert> | undefined;
	hideToolbar: () => void;
	selection?: Array<GridRowId>;
	setTime: () => void;
}

export function AlertToolbar(props: Readonly<AlertToolbarProps>) {
	const requestAPIRefresh = useAlertsStore(store => store.requestAPIRefresh);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const assignTemplateToTags = useAlertStatusUpdateAPI();

	const handleDismissAction = (event: any) => {
		if (event && props.selectedData) {
			event.stopPropagation();
			const getAllAlertHash = props.selectedData.map(obj => obj.alertHash);
			const body = {
				criteria: `alerthash in (${getAllAlertHash.join(", ")})`,
			};
			assignTemplateToTags.mutateAsync(body, {
				onSuccess: response => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"Alerts dismissed successfully"
					);
					requestAPIRefresh();
					props.hideToolbar();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const callApi = () => {
		props.setTime();
		requestAPIRefresh();
	};

	return (
		<GridToolbar {...props} requestAPIRefresh={callApi}>
			{props.selectedData && props.selectedData.length > 0 && (
				<Stack
					direction="row"
					spacing={2}
					sx={{ width: "100%" }}
					alignItems={"center"}
					justifyContent="flex-end"
				>
					<Stack direction="row" alignItems={"center"}>
						<Stack direction="row">
							<Button
								variant="outlined"
								aria-label="dismiss"
								onClick={handleDismissAction}
							>
								{window.getCTTranslatedText("Dismiss")}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			)}
		</GridToolbar>
	);
}
