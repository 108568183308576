import { Box, Paper, Stack, useTheme } from "@mui/material";
import { TagList } from "./components/tag-list";
import { TagValueList } from "./components/tag-value-list";

const TAG_LIST_MIN_WIDTH = "330px";

export function TagNames() {
	const theme = useTheme();

	return (
		<Stack
			direction={"row"}
			alignItems="flex-start"
			spacing={3}
			sx={{
				width: "100%",
				height: "100%",
			}}
		>
			<Stack
				sx={{
					height: "100%",
					overflow: "scroll",
					flexShrink: 0,
					flexBasis: TAG_LIST_MIN_WIDTH,
					minWidth: TAG_LIST_MIN_WIDTH,
				}}
			>
				<TagList />
			</Stack>
			<Box
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					overflowY: "auto",
					flex: 1,
					height: "100%",
				}}
			>
				<Paper sx={{ height: "100%" }}>
					<TagValueList />
				</Paper>
			</Box>
		</Stack>
	);
}
