import { BreachImpactChart } from "@colortokens/ng-ui";
import { SecurityLevels } from "pages/assets/types";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { useNavigate } from "react-router-dom";

export const RiskGaugeChart = ({
	value,
	viewOnly,
}: {
	value: number;
	viewOnly?: boolean;
}) => {
	value = Math.round(value ?? 0);
	return <GaugeChart value={value} viewOnly={viewOnly} />;
};

interface GaugeChartProps {
	value: number;
	width?: number;
	height?: number;
	viewOnly?: boolean;
}

const GaugeChart = ({
	value,
	width = 400,
	height = 165,
	viewOnly,
}: GaugeChartProps) => {
	const navigate = useNavigate();
	const processBtnClickCallback = (text: SecurityLevels) => {
		const link = getBtnLink(text, "assetrisk");
		if (link) {
			navigate(link);
		}
	};

	const text = getSecurityLevelText(value ?? 0);

	return (
		<BreachImpactChart
			viewOnly={viewOnly}
			width={width}
			height={height}
			value={value}
			onClick={() => processBtnClickCallback(text)}
			animated
		/>
	);
};

function getBtnLink(text: SecurityLevels, type: string) {
	let link = "";
	let queryParam = encodeURI(`${type}=${text}`);
	link = `/assets?filters=${queryParam}`;

	return link;
}
