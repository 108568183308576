import { AssetRiskDistribution } from "@colortokens/ng-ui";
import { SecurityLevels } from "pages/assets/types";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export interface ListDatum {
	label: string;
	name: string;
	count: number;
}

export interface ClassificationData {
	loading?: boolean;
	total: number;
	data: Array<ListDatum>;
	totalAssets?: number;
}

export interface AssetClassificationByLevelsProps {
	data: ClassificationData;
	type: string;
	category: string;
}

function getLink(
	data: { name: string; label: string; count: number },
	type: string
) {
	let link = "";
	let queryParam = encodeURI(`${type}=${data.name}`);
	link = `/assets?filters=${queryParam}`;

	return link;
}

function getBtnLink(text: SecurityLevels, type: string) {
	let link = "";
	let queryParam = encodeURI(`${type}=${text}`);
	link = `/assets?filters=${queryParam}`;

	return link;
}

export const AssetClassificationByLevels = ({
	data: config,
	type,
	category,
}: AssetClassificationByLevelsProps) => {
	const navigate = useNavigate();

	const processBtnClickCallback = useCallback(
		(text: SecurityLevels) => {
			const link = getBtnLink(text, type);
			if (link) {
				navigate(link);
			}
		},
		[navigate, type]
	);

	const processLinkCallback = (data: ListDatum) => {
		const link = getLink(data, type);
		if (link) {
			navigate(link);
		}
	};

	const text = getSecurityLevelText(config?.total ?? 0);

	const onClickCountButton = useCallback(() => {
		navigate("/assets");
	}, [navigate]);

	const onClickChartLabelButton = useCallback(
		() => processBtnClickCallback(text),
		[processBtnClickCallback, text]
	);
	if (config?.loading) {
		return null;
	}

	return (
		<AssetRiskDistribution
			data={config}
			type={type}
			category={category}
			onCountClick={onClickCountButton}
			onClickChartLabelButton={onClickChartLabelButton}
			onLinearProgressClick={processLinkCallback}
			animated
		/>
	);
};
