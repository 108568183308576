import { Tooltip } from "@mui/material";
import { TagPolicy } from "../../types";

interface Props
	extends Pick<
		TagPolicy,
		"baselineBreachImpactScore" | "targetBreachImpactScore"
	> {}

function BaselineTargetScore({
	baselineBreachImpactScore: baseline,
	targetBreachImpactScore: target,
}: Props) {
	const baselineValue = baseline ?? "--";
	const targetValue = target ?? "--";

	return (
		<Tooltip
			placement="top"
			title={
				<>
					{window.getCTTranslatedText("Baseline Score")}: {baselineValue} <br />
					{window.getCTTranslatedText("Target Score")}: {targetValue}
				</>
			}
		>
			<div>
				{baselineValue} / {targetValue}
			</div>
		</Tooltip>
	);
}

export default BaselineTargetScore;
