import { IconsProps } from "./common";

export const NozomiIcon = ({
	width = "34px",
	height = "32px",
	color = "black",
}: IconsProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		fill="none"
		viewBox="0 0 34 32"
	>
		<path
			fill="#009FDB"
			d="M24.867 7.297 17.57 0v5.7l7.297 7.298zM30.72 13.074v5.777l2.888-2.888z"
		></path>
		<path
			fill="#009FDB"
			d="M26.083 8.437v7.449L17.57 7.373V32l3.497-3.497V16.039l6.232 6.233 2.205-2.28v-8.057z"
		></path>
		<path
			fill="#009FDB"
			d="m26.463 23.106-4.257-4.257v8.513zM3.28 13.074.392 15.963 3.28 18.85zM9.057 24.626l7.373 7.373v-5.777l-7.373-7.373zM7.537 8.818l4.257 4.18v-8.36z"
		></path>
		<path
			fill="#009FDB"
			d="M12.934 3.42v12.466L6.701 9.653l-2.28 2.28v8.057l3.497 3.497v-7.45l8.513 8.514V0z"
		></path>
	</svg>
);
