import { Scope } from "modules/scope-metadata/types";

export type SuggestionType = Scope | "IP" | "Domain" | "Default";
export interface Suggestion {
	type: SuggestionType;
	value: string;
	displayName?: string;
	rawData?: any;
	_group?: string;
}

export enum AllowedField {
	PathPort = "port",
	Port = "listenPort",
	PathWithDomain = "pathwithdomain",
	PathWithoutDomain = "pathwithoutdomain",
	Process = "process",
	TemplateCategory = "templatecategory",
}
