import { Box } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { Asset } from "pages/assets/types";
import { VulnerabilityTypeFacets } from "../asset-data-grid/types";

interface AssetVulnerabilityAvailabilityProps {
	asset?: Asset;
	vulnerabilityType: keyof Asset;
	viewOnly?: boolean;
}

export function AssetVulnerabilityAvailability({
	asset,
	vulnerabilityType,
	viewOnly,
}: AssetVulnerabilityAvailabilityProps) {
	if (
		asset?.[vulnerabilityType] === null ||
		asset?.[vulnerabilityType] === undefined ||
		asset?.[vulnerabilityType] === "no value"
	) {
		return <Box style={{ width: 120 }} />;
	}
	const facetOption = String(asset?.[vulnerabilityType] ?? "");
	return (
		<CTDatagridButton
			asText={viewOnly === true}
			isDisabled={viewOnly === true}
			facetName={
				VulnerabilityTypeFacets[
					vulnerabilityType as keyof typeof VulnerabilityTypeFacets
				]
			}
			facetOption={facetOption}
		>
			{window.getCTTranslatedText(facetOption)}
		</CTDatagridButton>
	);
}
