import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { ApplianceTabs } from "pages/appliance-detail/types";
import { ApplianceColumnRenderProps } from "pages/appliances/types";
import { Link as RouterLink } from "react-router-dom";
import { isParentRow } from "../appliance-data-grid/helpers/columnHelpers";

export function UnmanagedDeviceCount({
	agent,
	viewOnly,
}: ApplianceColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}
	const isParent = isParentRow(agent);
	if (isParent) {
		viewOnly = true;
	}
	return (
		<Stack
			sx={{ maxWidth: "100%" }}
			alignItems="center"
			justifyContent="center"
		>
			<Stack justifyContent="center" alignItems="center">
				{viewOnly ? (
					<Typography variant="body2" noWrap sx={{ ml: 6 }}>
						{numeral(agent?.unmanagedDeviceCount || 0).format("0a")}
					</Typography>
				) : (
					<Tooltip title={window.getCTTranslatedText("viewDevices")}>
						<Link
							typography={"body2"}
							component={RouterLink}
							to={`/appliances/${agent?.agentName}?tab=${
								ApplianceTabs.UnmanagedDevices
							}&filters=${encodeURIComponent(
								`agentname=${agent?.agentName}|type=device`
							)}`}
							sx={{ color: "info.main", ml: 6 }}
						>
							{numeral(agent?.unmanagedDeviceCount || 0).format("0a")}
						</Link>
					</Tooltip>
				)}
			</Stack>
		</Stack>
	);
}
