import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, Stack, Typography, TypographyProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface TrafficCountLinkProps {
	count?: number;
	link: string;
	criteria?: string;
	textVariant?: TypographyProps["variant"];
}

export function TrafficCountLink({
	count,
	link,
	textVariant = "body2",
}: TrafficCountLinkProps) {
	if (!count) return <Typography variant={textVariant}>-</Typography>;
	return (
		<Link
			component={RouterLink}
			to={link}
			underline="hover"
			target="_blank"
			rel="noopener noreferrer"
		>
			<Stack direction="row" alignItems="center" spacing={0.6} px={0.5}>
				<Typography variant={textVariant}>{count}</Typography>
				<OpenInNewIcon sx={{ fontSize: 15 }} />
			</Stack>
		</Link>
	);
}
