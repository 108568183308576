import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { Template } from "pages/templates/types";
import { TemplateDetail } from "../template-detail/TemplateDetail";

interface TemplateDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	templateId: string | undefined;
	template?: Template;
}

export const TemplateViewDrawer = ({
	isOpen,
	onClose,
	templateId,
	template,
}: TemplateDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "20px", top: "26px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<TemplateDetail templateId={templateId} template={template} />
		</Drawer>
	);
};
