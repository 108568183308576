import {
	Alert,
	DialogContent,
	Drawer,
	Stack,
	Toolbar,
	Typography,
} from "@mui/material";
import { GridRowId, useGridApiRef } from "@mui/x-data-grid-pro";
import { parseErrorMessage } from "common/utils";
import noop from "lodash/noop";
import { scrollToDataGridRow } from "modules/data-grid/components/data-grid/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { convertJsonToCsv } from "modules/export-csv/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	StatusUpdateWorker,
	Task,
	TaskStatus,
} from "pages/assets/components/asset-data-grid-toolbar/AssetStatusUpdateWorker";
import { CircularProgressWithLabel } from "pages/assets/components/asset-data-grid-toolbar/Toolbar";
import { TemplateDataGrid } from "pages/templates/components/template-data-grid";
import { useTemplateAPI } from "pages/templates/components/template-detail/hooks";
import { Template, TemplatePath, TemplatePort } from "pages/templates/types";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface DownloadTemplateDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	rows: Template[];
}

interface DownloadTemplateDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	rows: Template[];
}

export const DownloadTemplateDrawer = ({
	isOpen,
	onClose,
	rows,
}: DownloadTemplateDrawerProps) => {
	const templateDataGridRef = useGridApiRef();
	const [isProcessingInitiated, setIsProcessingInitiated] = useState(false);
	const [isProcessingComplete, setIsProcessingComplete] = useState(false);
	const [progress, setProgress] = useState(0);
	const [tasks, setTasks] = useState<Task<Template>[]>([]);
	const currentTemplateIdRef = useRef<string>();
	const templateResponseResolveRef = useRef<((value: Template) => void) | null>(
		null
	);
	const downloadDataRef = useRef<{ [key: string]: Template }>({});
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const {
		data: templateData,
		isFetching,
		error: templateError,
	} = useTemplateAPI({
		templateId: currentTemplateIdRef.current ?? undefined,
		enabled: Boolean(currentTemplateIdRef.current),
	});

	useEffect(() => {
		if (!isFetching && templateData && currentTemplateIdRef.current) {
			const currentId = currentTemplateIdRef.current;

			if (templateError || !templateData) {
				setTasks(prevTasks =>
					prevTasks.map(task =>
						task.id.toString() === currentId
							? { ...task, status: TaskStatus.Failed }
							: task
					)
				);
			} else {
				downloadDataRef.current = {
					...downloadDataRef.current,
					[currentId]: templateData,
				};

				setTasks(prevTasks =>
					prevTasks.map(task =>
						task.id.toString() === currentId
							? { ...task, status: TaskStatus.Completed }
							: task
					)
				);

				if (templateResponseResolveRef.current) {
					templateResponseResolveRef.current(templateData);
					templateResponseResolveRef.current = null;
				}
			}

			currentTemplateIdRef.current = undefined;
		}
	}, [templateData, isFetching, templateError]);

	const handleDownloadCSV = useCallback(() => {
		const flattenedData: any[] = [];

		rows.forEach(template => {
			const templateData = downloadDataRef.current[template.templateId];
			if (!templateData) return;

			const baseTemplateData = {
				templateId: template.templateId,
				templateName: template.templateName,
				templateDescription: template.templateDescription,
				Port: "",
				"Port Status": "",
				"Inbound Path": "",
				Source: "",
				"Outbound Path": "",
				Destination: "",
			};

			const templatePorts = Array.isArray(templateData.templatePorts)
				? templateData.templatePorts
				: [];
			templatePorts.forEach((port: TemplatePort) => {
				flattenedData.push({
					...baseTemplateData,
					Port: `${port.listenPortProtocol} ${port.listenPort}`,
					"Port Status": port.listenPortReviewed || "",
				});
			});

			const templatePaths = Array.isArray(templateData.templatePaths)
				? templateData.templatePaths
				: [];

			const inboundPaths = templatePaths.filter(
				(path: TemplatePath) => path.direction === "inbound"
			);
			inboundPaths.forEach((path: TemplatePath) => {
				flattenedData.push({
					...baseTemplateData,
					"Inbound Path": `${path.protocol} ${path.port}`,
					Source: path.sourceAsset?.assetName || "",
				});
			});

			const outboundPaths = templatePaths.filter(
				(path: TemplatePath) => path.direction === "outbound"
			);
			outboundPaths.forEach((path: TemplatePath) => {
				flattenedData.push({
					...baseTemplateData,
					"Outbound Path": `${path.protocol} ${path.port}`,
					Destination: path.destinationAsset?.assetName || "",
				});
			});

			// If no specific data exists, add at least the base template data
			if (templatePorts.length === 0 && templatePaths.length === 0) {
				flattenedData.push(baseTemplateData);
			}
		});

		try {
			convertJsonToCsv(flattenedData, true);
			setSnackbar(true, SnackBarSeverity.Success, "CSVDownloadedSuccessfully");
		} catch (error) {
			setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
		}
	}, [rows, setSnackbar]);

	const updateWorker = useMemo(
		() =>
			new StatusUpdateWorker<Template, void>({
				update: async templateId => {
					scrollToDataGridRow(templateDataGridRef, templateId);

					return new Promise<Template>(resolve => {
						currentTemplateIdRef.current = templateId.toString();
						templateResponseResolveRef.current = resolve;
					});
				},
				onUpdate: (updatedTasks, isCompleted) => {
					setTasks(updatedTasks);
					if (isCompleted) {
						setIsProcessingComplete(true);
						handleDownloadCSV();
					}
				},
				onProgress: currentProgress => {
					setProgress(currentProgress);
				},
				concurrency: 1,
			}),
		[handleDownloadCSV, templateDataGridRef]
	);

	useEffect(() => {
		if (!isProcessingInitiated && rows.length > 0) {
			const templates = rows.map(template => ({
				id: template.templateId as GridRowId,
				data: template,
			}));
			updateWorker.addTasks(templates);
			setIsProcessingInitiated(true);
			setProgress(0);
			updateWorker.run();
		}
	}, [isProcessingInitiated, rows, updateWorker]);

	const processedRows = useMemo(() => {
		return rows.map(template => ({
			...template,
			...(tasks.find(task => task.id === template.templateId) ?? {}),
			...(downloadDataRef.current[template.templateId] ?? {}),
		}));
	}, [rows, tasks]);

	const renderContent = useCallback(() => {
		if (isProcessingInitiated) {
			const failedTasks = tasks.filter(
				task => task.status === TaskStatus.Failed
			);

			return (
				<Stack sx={{ width: "100%", height: "100%" }}>
					<Stack sx={{ mb: 2, width: "100%" }} spacing={1}>
						{!isProcessingComplete && (
							<Alert
								severity="warning"
								icon={<CircularProgressWithLabel value={progress} />}
								sx={{
									".MuiAlert-message": {
										display: "flex",
										alignItems: "center",
									},
								}}
							>
								{window.getCTTranslatedText("csvDownloadInProgress")}
							</Alert>
						)}
						{isProcessingComplete && (
							<Alert severity={failedTasks.length ? "error" : "success"}>
								{window.getCTTranslatedText(
									failedTasks.length
										? "failedCSVDownload"
										: "successfulCSVDownload",
									{
										template: window.getCTTranslatedText(
											pluralize(
												"template",
												failedTasks.length ? failedTasks.length : tasks.length
											)
										),
										count: failedTasks.length
											? failedTasks.length
											: tasks.length,
									}
								)}
							</Alert>
						)}
					</Stack>
					<Stack sx={{ flex: 1, width: "100%", overflow: "hidden" }}>
						<TemplateDataGrid
							showProgress
							rows={processedRows}
							rowCount={processedRows.length}
							viewOnly
							isLoading={false}
							apiRef={templateDataGridRef}
						/>
					</Stack>
				</Stack>
			);
		}

		return (
			<Stack sx={{ width: "100%", height: "100%" }}>
				<Stack sx={{ flex: 1, width: "100%", overflow: "hidden" }}>
					<TemplateDataGrid
						showProgress
						rows={rows}
						rowCount={rows.length}
						viewOnly
						isLoading={false}
					/>
				</Stack>
			</Stack>
		);
	}, [
		isProcessingComplete,
		isProcessingInitiated,
		processedRows,
		progress,
		rows,
		tasks,
		templateDataGridRef,
	]);

	return (
		<Drawer
			open={isOpen}
			onClose={(_, reason) => {
				if (
					isProcessingInitiated &&
					!isProcessingComplete &&
					reason === "backdropClick"
				) {
					return;
				}
				onClose();
			}}
			anchor="right"
			PaperProps={{
				sx: {
					padding: "0px",
					width: "50%",
					minWidth: "800px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack direction="column" spacing={2} sx={{ mt: 2, mb: 0, mx: 4 }}>
				<Typography variant="h5">
					<strong>{window.getCTTranslatedText("downloadTemplates")}</strong>
				</Typography>
			</Stack>
			<DialogContent sx={{ minWidth: 800, flex: 1 }}>
				{renderContent()}
			</DialogContent>
			<ToolbarAction
				save={noop}
				hidePrimaryBtn
				cancel={onClose}
				hideSecondaryBtn={isProcessingInitiated && !isProcessingComplete}
			/>
		</Drawer>
	);
};
