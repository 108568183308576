enum NavMainItemKey {
	DASHBOARD = "dashboard",
	VISUALIZER = "visualizer",
	ASSETS = "assets",
	SEGMENTS = "segments",
	USER_GROUPS = "user_groups",
	NETWORK_DATA = "network_data",
	TEMPLATES = "templates",
	NAMED_NETWORKS = "named_networks",
	TAGS = "tags",
	MONITORING = "monitoring",
	HOST_GROUPS = "host_groups",
	SENSORS = "sensors",
	SETTINGS = "settings",
}

enum NavNetworkDataSubItemKey {
	PORTS = "ports",
	PATHS = "paths",
}

enum NavMonitoringSubItemKey {
	ALERTS = "alerts",
	LOGS = "logs",
	SYSTEM_TASKS = "system_tasks",
}

enum NavSensorsSubItemKey {
	AGENTS = "agents",
	GATEKEEPERS = "gatekeepers",
	CONTAINERS = "containers",
}

enum NavTagsSubItemKey {
	TAG_NAMES = "tag_names",
	TAG_RULES = "tag_rules",
}

enum NavSettingsSubItemKey {
	GENERAL = "general",
	USER_MANAGEMENT = "user_management",
	API_KEYS = "api_keys",
	INTEGRATIONS = "integrations",
	ALERT_RULES = "alert_rules",
}

const NAV_SIDEBAR_COLLAPSED_WIDTH = 80;
const NAV_SIDEBAR_EXPANDED_WIDTH = 240;

export {
	NAV_SIDEBAR_COLLAPSED_WIDTH,
	NAV_SIDEBAR_EXPANDED_WIDTH,
	NavMainItemKey,
	NavMonitoringSubItemKey,
	NavNetworkDataSubItemKey,
	NavSensorsSubItemKey,
	NavSettingsSubItemKey,
	NavTagsSubItemKey,
};
