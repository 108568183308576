import { Grid, Stack } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import {
	ApplianceVLANUpdate,
	ConfigAppliances,
	HAModes,
	HAModeTypes,
	InputListType,
	IPRangeInt,
	MTUOptions,
	NetworkInterfaces,
} from "../types";
import { IP_PLACEHOLDER } from "../utils";
import { AddMultipleItemInput } from "./components";

export interface WANProps {
	HAMode: string;
	appliances: ConfigAppliances[];
	gatewayAddress: string;
	setGatewayAddress: (value: string) => void;
	wanVirtualIP: string;
	setWanVirtualIP: (value: string) => void;
	DNSServers: IPRangeInt[];
	setDNSServers: (ipRanges: IPRangeInt[]) => void;
	wanMTU: string;
	setWanMTU: (value: string) => void;
	HAModeType: string;
	networkInterface: string;
	handleVlanChange: (value: ApplianceVLANUpdate) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
}

export function WAN({
	HAMode,
	appliances,
	HAModeType,
	networkInterface,
	handleVlanChange,
	gatewayAddress,
	setGatewayAddress,
	wanVirtualIP,
	setWanVirtualIP,
	DNSServers,
	setDNSServers,
	wanMTU,
	setWanMTU,
	keyListener,
	isFormDisabled,
}: WANProps) {
	const handleListChange = (
		e: TextFieldUpdate,
		index: number,
		IPList: InputListType
	) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			let newIPRanges = [...IPList] as IPRangeInt[];
			newIPRanges[index]["value"] = value;
			setDNSServers(newIPRanges);
		}
	};

	const addNewItem = (IPList: InputListType) => {
		const newIPRanges = [...IPList] as IPRangeInt[];
		newIPRanges.push({ value: "" });
		setDNSServers(newIPRanges);
	};

	const removeItem = (index: number, IPList: InputListType) => {
		const newIPRanges = [...IPList] as IPRangeInt[];
		newIPRanges.splice(index, 1);
		setDNSServers(newIPRanges);
	};

	return (
		<Stack>
			<Stack spacing={2}>
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					<Stack>
						<CTIPInput
							field="gatewayAddress"
							displayName={window.getCTTranslatedText("gatewayAddress")}
							placeholder={IP_PLACEHOLDER}
							value={gatewayAddress}
							handleUpdate={(event: TextFieldUpdate) =>
								setGatewayAddress(event?.value)
							}
							onKeyUp={keyListener}
							disabled={isFormDisabled}
						/>
					</Stack>
					<Stack>
						<CTSelectDropDown
							field={"wanMTU"}
							displayName={window.getCTTranslatedText("MTU")}
							selectedValue={wanMTU}
							handleUpdate={(event: TextFieldUpdate) => setWanMTU(event.value)}
							data={MTUOptions}
							showLabel={true}
						/>
					</Stack>
					{HAMode !== HAModes.StandAlone ? (
						<Stack>
							{HAModeType === HAModeTypes.Active &&
							networkInterface === NetworkInterfaces.VLAN ? null : (
								<CTIPInput
									field="virtualIP"
									displayName={window.getCTTranslatedText("virtualIP")}
									placeholder={IP_PLACEHOLDER}
									value={wanVirtualIP}
									handleUpdate={(event: TextFieldUpdate) =>
										setWanVirtualIP(event?.value)
									}
									onKeyUp={keyListener}
									disabled={isFormDisabled}
								/>
							)}
						</Stack>
					) : null}
					<Stack>
						<AddMultipleItemInput
							list={DNSServers}
							isFormDisabled={isFormDisabled}
							handleListChange={handleListChange}
							addNewItem={addNewItem}
							removeItem={removeItem}
							addButtonText="Add DNS Server"
							title="DNS Servers"
						/>
					</Stack>
				</Grid>
			</Stack>
		</Stack>
	);
}
