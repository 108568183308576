import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Stack, Tooltip } from "@mui/material";

export interface ToolbarActionProps {
	loading?: boolean;
	isValid?: boolean;
	actionBtnText?: string;
	secondaryBtnText?: string;
	actionBtnTooltip?: string;
	save: (_: React.SyntheticEvent, buttonSelection?: string) => void;
	cancel?: () => void;
	href?: string;
	hidePrimaryBtn?: boolean;
	hideSecondaryBtn?: boolean;
	hasPermission?: boolean;
	background?: boolean;
	size?: "small" | "medium" | "large";
	renderActionButtons?: () => React.ReactNode;
}

const toolbarstyles = {
	px: 3,
	py: 2,
	width: "100%",
};

export const ToolbarAction = ({
	loading,
	isValid = true,
	actionBtnText = "save",
	secondaryBtnText = "Cancel",
	actionBtnTooltip = "",
	save,
	href,
	cancel,
	hidePrimaryBtn = false,
	hideSecondaryBtn = false,
	hasPermission = true,
	background = true,
	size = "medium",
	renderActionButtons,
}: ToolbarActionProps) => {
	const renderButton = () => {
		if (!hasPermission) {
			return null;
		}

		if (!renderActionButtons) {
			return (
				<Tooltip title={window.getCTTranslatedText(actionBtnTooltip)}>
					<Box>
						<LoadingButton
							loading={loading}
							href={href}
							onClick={save}
							autoFocus
							variant="contained"
							type="submit"
							size={size}
							disabled={!isValid}
						>
							{window.getCTTranslatedText(actionBtnText)}
						</LoadingButton>
					</Box>
				</Tooltip>
			);
		}

		return renderActionButtons();
	};

	return (
		<Stack
			sx={{
				borderRadius: 0,
				width: "100%",
			}}
		>
			<Stack
				direction="row"
				justifyItems="flex-end"
				alignItems={"flex-end"}
				justifyContent="flex-end"
				spacing={3}
				sx={{
					...toolbarstyles,
				}}
			>
				{!hideSecondaryBtn && (
					<Button variant="outlined" size={size} onClick={cancel} type="button">
						{window.getCTTranslatedText(secondaryBtnText)}
					</Button>
				)}
				{!hidePrimaryBtn && renderButton()}
			</Stack>
		</Stack>
	);
};
