import { Box, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { FacetOptionState, Operator } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { ASSET_COLUMNS } from "pages/appliance-detail/constants";
import {
	ApplianceDevicesFilterKeys,
	ApplianceDevicesFilterValues,
} from "pages/appliance-detail/types";
import { Appliance, PairedGateway } from "pages/appliances/types";
import {
	getAssetInterfaceIPs,
	getAssetInterfaceMACs,
} from "pages/asset/components/metadata-item-wrapper/helpers";
import { AssetDataGrid } from "pages/assets/components/asset-data-grid";
import {
	calculateReviewCoverage,
	getFilterNameFromColumnName,
} from "pages/assets/components/asset-data-grid/helpers";
import { useAssetsAPI } from "pages/assets/components/asset-data-grid/hooks";
import { AssetVisualizeButton } from "pages/assets/components/asset-visualize-button";
import { useAssetsFacetsOrder } from "pages/assets/constants";
import { useAssetDevicesStore, useAssetStore } from "pages/assets/store";
import { Asset } from "pages/assets/types";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { useEffect } from "react";

interface ApplianceDevicesProps {
	applianceName?: string;
	deviceType?: string;
	applianceDetails?: Appliance;
	isParent: boolean;
}

export const ApplianceDevices = ({
	applianceName,
	deviceType,
	isParent,
	applianceDetails,
}: ApplianceDevicesProps) => {
	const theme = useTheme();
	const setFacets = useCommonStore(state => state.setFacets);
	const apiRefreshRequest = useAssetStore(state => state.apiRefreshRequest);
	const requestRefresh = useAssetDevicesStore(state => state.requestAPIRefresh);

	useEffect(() => {
		if (applianceDetails) {
			const applianceFilter: FacetOptionState = new Map([
				[
					applianceDetails.agentName,
					{ isSelected: true, operator: Operator.EQUAL },
				],
			]);
			const deviceFilter: FacetOptionState = new Map([
				[
					ApplianceDevicesFilterValues.Device,
					{ isSelected: true, operator: Operator.EQUAL },
				],
			]);
			let pairedGatewaysFilter: FacetOptionState = new Map();
			if (isParent) {
				pairedGatewaysFilter = new Map(
					((applianceDetails?.pairedGateways as PairedGateway[]) || [])?.map(
						(
							gateway: PairedGateway
						): [string, { isSelected: boolean; operator: Operator }] => [
							gateway.agentName,
							{ isSelected: true, operator: Operator.EQUAL },
						]
					)
				);
			}
			let facets = new Map([
				[
					ApplianceDevicesFilterKeys.ApplianceName,
					isParent ? pairedGatewaysFilter : applianceFilter,
				],
				[ApplianceDevicesFilterKeys.Type, deviceFilter],
			]);
			if (deviceType) {
				const deviceTypeFilter = new Map([
					[deviceType, { isSelected: true, operator: Operator.EQUAL }],
				]);
				facets.set(ApplianceDevicesFilterKeys.Status, deviceTypeFilter);
			}
			setFacets(facets);
		}

		return () => setFacets(undefined);
	}, [applianceDetails, setFacets, deviceType, isParent]);

	useEffect(() => {
		requestRefresh();
	}, [apiRefreshRequest, requestRefresh]);

	const dataMapper = (asset: Asset) => {
		asset.reviewCoverage = calculateReviewCoverage(asset);
		if (asset?.containerNamespace || asset?.clusterIdentifier) {
			if (!asset?.coreTags) {
				asset.coreTags = {};
			}
			if (asset?.containerNamespace) {
				asset.coreTags.containerNamespace = asset?.containerNamespace;
			}
			if (asset?.clusterIdentifier) {
				asset.coreTags.clusterIdentifier = asset?.clusterIdentifier;
			}
		}
		asset.ipAddresses = `"${getAssetInterfaceIPs(asset.interfaces ?? [])}"`;
		asset.macAddresses = `"${getAssetInterfaceMACs(asset?.interfaces ?? [])}"`;

		return asset;
	};

	const coreResponse = useCore<Asset>({
		useStore: useAssetDevicesStore,
		facetGroupInfo: useAssetsFacetsOrder(),
		scope: Scope.Asset,
		facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
		defaultSortOrder: [{ field: "assetrisk", order: "desc" }],
		dataMapper: dataMapper,
		skipSearch: true,
		useApi: useAssetsAPI,
		pageSize: 100,
	});

	let metadata = useCommonStore(state => state.metadata);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	if (metadata?.columns?.assetavailability) {
		metadata.columns.assetavailability.displayName = "Status";
	}

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction={"row"} alignItems="center">
				<Stack sx={{ flex: 1 }}>
					<FacetOpenCloseController
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							config={coreResponse.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
						/>
					</FacetOpenCloseController>
				</Stack>
				<AssetVisualizeButton />
			</Stack>
			<Box
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					overflowY: "auto",
					flex: 1,
					height: "100%",
				}}
			>
				<Paper sx={{ height: "100%" }}>
					<AssetDataGrid
						initialState={{
							sorting: {
								sortModel: [{ field: "assetrisk", sort: "desc" }],
							},
						}}
						metadata={metadata}
						rows={coreResponse.rows}
						rowCount={coreResponse.rowCount}
						mutation={coreResponse.mutation}
						onPageChange={coreResponse.onPageChange}
						page={coreResponse.page}
						pageSize={coreResponse.pageSize}
						onPageSizeChange={coreResponse.onPageSizeChange}
						onSortChange={sortModel => {
							if (sortModel?.length > 0) {
								sortModel = sortModel.map(item => {
									item.field = getFilterNameFromColumnName(item.field);
									return item;
								});
								coreResponse.onSortChange(sortModel);
							}
						}}
						hasNoData={
							coreResponse.mutation.isSuccess &&
							coreResponse.rowCount === 0 &&
							searchCriteria === "*"
						}
						triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
						getExportStatus={coreResponse?.getExportStatus}
						getUrlToDownload={coreResponse?.getUrlToDownload}
						resetDownloadUrl={coreResponse?.resetDownloadUrl}
						rawData={coreResponse?.rawData}
						columns={ASSET_COLUMNS(false)}
						showDeviceActions={true}
					/>
				</Paper>
			</Box>
		</Stack>
	);
};
