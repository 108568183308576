import Grid from "@mui/material/Unstable_Grid2";
import { MetaDataItem } from "../asset-metadata-wrapper/components/metadata-item";
import { MetadataTag } from "../asset-metadata-wrapper/types";

export function MetaDataItemWrapper({ tags }: { tags: MetadataTag[] }) {
	return (
		<Grid container sx={{ width: "100%" }} columnSpacing={3}>
			{tags.map(({ key, label, value, icon, isCoreTagEditable }) => {
				return (
					<Grid xs={12} sm={6} md={4} lg={4} key={`${key}`}>
						<MetaDataItem
							itemKey={key}
							label={label}
							value={value}
							icon={icon}
							isCoreTagEditable={isCoreTagEditable}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
}
