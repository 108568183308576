import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Button,
	Paper,
	Stack,
	Tab,
	Tabs,
	TextField,
	useTheme,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import { TabPanel } from "common/atoms/ct-tabs";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { Referral } from "pages/referral";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { InviteMemberDrawer } from "./components/invite-member-drawer";
import { TenantProfileEditor } from "./components/tenant-profile-editor";
import { UpdateUserRole } from "./components/update-user-role";
import { UserActionsMenu } from "./components/user-actions";
import { INVITE_COLUMNS, MEMBERS_COLUMNS, QUERY_ACTIONS } from "./constants";
import {
	CTRole,
	CTUser,
	Invite,
	InviteResult,
	TeamMembersResult,
} from "./types";

const iconStyle = "rgba(0, 0, 0, 0.38)";
const IconStyleDarkMode = "rgba(255, 255, 255, 0.38)";

interface ReferralSettings {
	referralAllowed: boolean;
}

function useTeamMembers({
	setMemberListRows,
}: {
	setMemberListRows: (users: Array<CTUser>) => void;
}) {
	return useQuery<TeamMembersResult, Error>(
		["teamMembers", "auth/admin/tenant/user"],
		{
			onSuccess: response => {
				setMemberListRows(response.Users);
			},
		}
	);
}

export function useRoles() {
	return useQuery<Array<CTRole> | undefined, Error>([
		"teamMembers",
		"auth/roles",
	]);
}

function useInviteList() {
	return useQuery<InviteResult, Error>(["invites", "auth/admin/invite/user"]);
}

export function OrgManagement() {
	const [memberListRows, setMemberListRows] = useState<CTUser[]>([]);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	// TODO: add error handling and loading states
	const { data: membersList, isLoading } = useTeamMembers({
		setMemberListRows,
	});
	const { data: rolesList } = useRoles();
	const [showInviteDialog, setShowInviteDialog] = useState(false);

	const [selectedTab, setTab] = useState(0);
	const urlParams = new URLSearchParams(window.location.search);
	const action = urlParams.get("action") || "";
	const redirectURL = urlParams.get("redirectURL") || "";
	const theme = useTheme();
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		if (action === QUERY_ACTIONS.INVITE) {
			handleInviteDialogOpen();
		}
	}, [action]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	const handleInviteDialogOpen = () => {
		setShowInviteDialog(true);
	};

	const handleClose = () => {
		setShowInviteDialog(false);
		if (action === QUERY_ACTIONS.INVITE && redirectURL) {
			window.location.href = redirectURL;
		}
	};

	const onSuccess = () => {
		handleClose();
		setTab(1);
	};

	const { data: referralSettings } = useQuery<ReferralSettings>([
		"canRefer",
		"auth/referralSettings",
	]);

	const handleUpdate = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const value = e.target.value;
		setSearchText(value);
		const filteredUsers = (membersList?.Users ?? []).filter(
			user =>
				user.name.toLowerCase().includes(value.toLowerCase()) ||
				user.email.toLowerCase().includes(value.toLowerCase())
		);
		setMemberListRows(filteredUsers);
	};

	const onClear = () => {
		setSearchText("");
		setMemberListRows(membersList?.Users ?? []);
	};

	return (
		<Stack
			direction={"row"}
			spacing={2}
			alignItems={"flex-start"}
			sx={{ height: "100%" }}
		>
			<TenantProfileEditor />
			<Stack spacing={3} sx={{ flex: 1, height: "100%" }}>
				<Box>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Tabs value={selectedTab} onChange={handleTabChange}>
							<Tab label={window.getCTTranslatedText("Members")} />
							{userPermissions.has("CREATE_SUBJECT") && (
								<Tab label={window.getCTTranslatedText("Invites")} />
							)}
							{referralSettings && referralSettings.referralAllowed && (
								<Tab label="Refer and earn" />
							)}
						</Tabs>
						<Stack direction="row" alignItems="flex-end">
							{selectedTab === 0 && (
								<TextField
									variant="standard"
									value={searchText}
									type="text"
									placeholder={window.getCTTranslatedText("membersSearch")}
									onChange={handleUpdate}
									sx={{
										width: "240px",
										m: 0,
									}}
									InputProps={{
										startAdornment: (
											<SearchIcon
												sx={{
													color:
														theme.palette.mode === "dark"
															? IconStyleDarkMode
															: iconStyle,
													mb: 1,
													mr: 1,
												}}
											/>
										),
										endAdornment: searchText && (
											<CloseIcon
												onClick={onClear}
												sx={{
													color:
														theme.palette.mode === "dark"
															? IconStyleDarkMode
															: iconStyle,
													cursor: "pointer",
													mb: 1,
												}}
											/>
										),
									}}
								/>
							)}
							{userPermissions.has("CREATE_SUBJECT") && (
								<Button
									variant="contained"
									onClick={handleInviteDialogOpen}
									color="primary"
									sx={{ ml: 2 }}
								>
									{window.getCTTranslatedText("Invite Member")}
								</Button>
							)}
						</Stack>
					</Stack>
				</Box>
				<TabPanel
					value={selectedTab}
					index={0}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<>
						<Stack
							direction={"column"}
							spacing={2}
							sx={{ height: "100%", width: "100%" }}
						>
							<Stack sx={{ flex: 1, width: "100%", overflow: "hidden" }}>
								<MembersList
									users={memberListRows}
									loading={isLoading}
									rolesList={rolesList}
								/>
							</Stack>
						</Stack>
					</>
				</TabPanel>
				<TabPanel
					value={selectedTab}
					index={1}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<>
						<InviteList />
					</>
				</TabPanel>
				<TabPanel
					value={selectedTab}
					index={2}
					style={{ flex: 1, overflow: "hidden" }}
				>
					<Referral />
				</TabPanel>
				{showInviteDialog && userPermissions.has("CREATE_SUBJECT") && (
					<InviteMemberDrawer
						isOpen={showInviteDialog}
						onClose={handleClose}
						onSuccess={() => {
							onSuccess();
						}}
						rolesList={rolesList}
					/>
				)}
			</Stack>
		</Stack>
	);
}

interface MembersListProps {
	users?: Array<CTUser>;
	loading: boolean;
	rolesList: Array<CTRole> | undefined;
}

function MembersList({ users, loading, rolesList }: MembersListProps) {
	const columns = useMemo(() => {
		if (!users || !users.length) {
			return MEMBERS_COLUMNS;
		}

		const MEMBERS_LIST_COLUMNS: GridColDef[] = [
			...MEMBERS_COLUMNS,
			{
				field: "role",
				headerName: "Role",
				headerAlign: "left",
				align: "left",
				flex: 1,
				sortingOrder: ["asc", "desc"],
				renderCell: (params: GridRenderCellParams<any, any, any>) => {
					return <UpdateUserRole rolesList={rolesList} user={params?.row} />;
				},
			},
			{
				field: "action",
				headerName: "",
				sortable: false,
				width: 60,
				resizable: false,
				align: "right",
				renderCell: (params: GridRenderCellParams<any, any, any>) => {
					return <UserActionsMenu user={params?.row} />;
				},
			},
		];

		return MEMBERS_LIST_COLUMNS;
	}, [rolesList, users]);

	return (
		<Paper sx={{ flex: 1, overflow: "hidden" }}>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: [{ field: "name", sort: "asc" }],
					},
				}}
				isLoading={loading}
				rows={getUniqueListBy(users || [], "userId")}
				rowCount={users?.length ?? 0}
				columns={columns}
				getRowId={({ userId }: CTUser) => userId}
			/>
		</Paper>
	);
}

function InviteList() {
	const invites = useInviteList();

	const columns = INVITE_COLUMNS;

	return (
		<Paper sx={{ flex: 1, overflow: "hidden" }}>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: [{ field: "name", sort: "asc" }],
					},
				}}
				isLoading={invites.isLoading}
				rows={getUniqueListBy(invites.data || [], "inviteCode")}
				rowCount={invites.data?.length ?? 0}
				columns={columns}
				getRowId={({ inviteCode }: Invite) => inviteCode}
			/>
		</Paper>
	);
}
