import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import useNavSidebarStore from "../useNavSidebarStore";
import NavList from "./NavList";
import { NavSubMenu } from "./NavSubMenu";
import { StyledNavSidebar } from "./styles";

export const NavSidebar = () => {
	const theme = useTheme();
	const {
		topItems,
		bottomItems,
		navItems,
		selectedMainNavItemKey,
		closeSubMenu,
	} = useNavSidebarStore();

	const {
		isSidebarExpanded: isMenuExpanded,
		setIsSidebarExpanded: setIsMenuExpanded,
	} = useUserPreferencesStore();

	const handleMenuExpander = () => {
		const value = !isMenuExpanded;
		setIsMenuExpanded(value);
		closeSubMenu();
	};

	return (
		<StyledNavSidebar
			isMenuExpanded={isMenuExpanded}
			as="nav"
			elevation={theme.palette.mode === "dark" ? 1 : 0}
		>
			<IconButton
				aria-label="toggle menu expander"
				className="menuExpandButton"
				onClick={handleMenuExpander}
			>
				<NavigateNextIcon
					sx={{
						fontSize: theme.typography.body2.fontSize,
					}}
				/>
			</IconButton>
			<NavList variant="main" items={topItems} />
			<NavList variant="main" items={bottomItems} />
			<NavSubMenu>
				<Typography variant="overline">
					{window.getCTTranslatedText(navItems[selectedMainNavItemKey].label)}
				</Typography>
				<NavList
					variant="sub"
					items={navItems[selectedMainNavItemKey].subItems ?? {}}
				/>
			</NavSubMenu>
		</StyledNavSidebar>
	);
};
