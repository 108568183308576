import { LoadingButton } from "@mui/lab";
import { FormLabel, Stack, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CTDrawer } from "common/molecules/drawer";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { CTRole } from "pages/settings/components/organization/types";
import { useState } from "react";
import { RolesDropdown } from "./components/roles-dropdown";

export interface InviteFormProps {
	name: string;
	email: string;
	role?: number | string;
}

interface InviteMemberDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	rolesList: Array<CTRole> | undefined;
}

const useInviteMembers = () => {
	return useMutation<any, Error, any>([
		"userInvites",
		"auth/admin/invite/user",
	]);
};

export const InviteMemberDrawer = ({
	isOpen,
	onClose,
	onSuccess,
	rolesList,
}: InviteMemberDrawerProps) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [role, setRole] = useState<CTRole | undefined>(undefined);
	const inviteMembersAPI = useInviteMembers();
	const [loading, setLoading] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const queryClient = useQueryClient();

	const isValidMember = () => {
		let isValid = false;

		const isNameValid = name?.trim().length > 0;
		const isEmailValid = email?.trim().length > 0;
		const isRoleValid = !!role?.roleID;

		if (isNameValid && isEmailValid && isRoleValid) {
			isValid = true;
		} else {
			isValid = false;
		}

		return isValid;
	};

	const updateMemberDetails = () => {
		if (isValidMember()) {
			setLoading(true);

			const body: Array<InviteFormProps> = [
				{
					name: name,
					email: email?.trim(),
					role: role?.roleID,
				},
			];

			inviteMembers(body);
		}
	};

	const inviteMembers = (body: Array<InviteFormProps>) => {
		inviteMembersAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["teamMembers"],
				});
				onSuccess();
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"MemberInvitedSuccessfully"
				);
				setTimeout(() => {
					onClose();
					setLoading(false);
				}, 300);
			},
			onError: error => {
				setLoading(false);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const handleNameChange = (e: any) => {
		if (e?.target?.value) {
			setName(e.target.value);
		} else {
			setName("");
		}
	};

	const handleEmailChange = (e: any) => {
		if (e?.target?.value) {
			setEmail(e.target.value.toLowerCase());
		} else {
			setEmail("");
		}
	};

	const handleRoleChange = (role: CTRole) => {
		if (role) {
			setRole(role);
		} else {
			setRole(undefined);
		}
	};

	const keyListener = (event: any) => {
		if (event?.key === "Enter" || event?.keyCode === 13) {
			updateMemberDetails();
		}
	};

	return (
		<CTDrawer
			title="Invite New Member"
			open={isOpen}
			onClose={onClose}
			actions={
				<LoadingButton
					variant="contained"
					loading={loading}
					onClick={updateMemberDetails}
				>
					{window.getCTTranslatedText("Invite")}
				</LoadingButton>
			}
			drawerWidth="560px"
		>
			<Stack spacing={"2rem"}>
				<Typography variant="body2">
					{window.getCTTranslatedText("InviteMemberDesc")}
				</Typography>
				<FormLabel htmlFor="name" sx={{ display: "block" }}>
					<Typography variant="caption">
						{window.getCTTranslatedText("Name")}
					</Typography>
					<TextField
						required
						name="name"
						value={name}
						type="text"
						placeholder={window.getCTTranslatedText("Enter name")}
						fullWidth
						onChange={handleNameChange}
						inputProps={{ autoComplete: "off" }}
						onKeyUp={keyListener}
						variant="standard"
						InputLabelProps={{ shrink: true }}
						sx={{ my: 0 }}
					/>
				</FormLabel>

				<FormLabel htmlFor="email" sx={{ display: "block" }}>
					<Typography variant="caption">
						{window.getCTTranslatedText("Email")}
					</Typography>
					<TextField
						required
						name="email"
						value={email}
						type="text"
						placeholder={window.getCTTranslatedText("Enter email")}
						fullWidth
						onChange={handleEmailChange}
						inputProps={{ autoComplete: "off" }}
						onKeyUp={keyListener}
						variant="standard"
						InputLabelProps={{ shrink: true }}
						sx={{ my: 0 }}
					/>
				</FormLabel>

				<FormLabel htmlFor="role-dropdown" sx={{ display: "block" }}>
					<Typography variant="caption">
						{window.getCTTranslatedText("Role")}
					</Typography>
					<RolesDropdown
						rolesList={rolesList as Array<CTRole> | undefined}
						onSelect={handleRoleChange}
						invite={true}
						showRoleDescriptions
					/>
				</FormLabel>
			</Stack>
		</CTDrawer>
	);
};
