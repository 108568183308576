import { PortStatus } from "pages/ports/types";
import { TemplateViewTab } from "pages/templates/components/template-detail/TemplateDetail";
import { PORT_STATE_ORDER_MAP } from "pages/templates/components/template-detail/constant";
import { Template, TemplatePath, TemplatePort } from "pages/templates/types";
import {
	RuleEngineTemplate,
	RuleEngineTemplatePath,
	RuleEngineTemplatePort,
} from "../types";

interface MatchedByTemplateRuleEngineDataMapperProps {
	data: Template[];
	pathRuleHashList: string[];
	portRuleHashList: string[];
	winningTemplates: string[];
	isAnyMatch?: boolean;
}

export const matchedByTemplateRuleEngineDataMapper = ({
	data,
	pathRuleHashList,
	portRuleHashList,
	winningTemplates,
	isAnyMatch,
}: MatchedByTemplateRuleEngineDataMapperProps) => {
	let portWinningRule: RuleEngineTemplatePort;

	const combinedTemplateData: RuleEngineTemplate = {
		templatePorts: [],
		inboundPaths: [],
		outboundPaths: [],
	};

	const filteredData = data.map((template: Template) => {
		template.templatePaths = (
			template?.templatePaths as TemplatePath[]
		)?.filter(path => pathRuleHashList.includes(path.channelHash));

		template.templatePorts = (
			template?.templatePorts as TemplatePort[]
		)?.filter(port => portRuleHashList.includes(port.lpId));

		return template;
	});

	filteredData.forEach(template => {
		((template?.templatePorts ?? []) as TemplatePort[]).forEach(
			(port: RuleEngineTemplatePort, index: number) => {
				if (template?.oobTemplate) {
					port["oobTemplate"] = template.oobTemplate;
				}

				port["templateId"] = template.templateId;
				port["templateName"] = template.templateName;
				port["lpId"] = port.lpId + index + template.templateId;
				port["isWinningRule"] =
					portRuleHashList?.some(portId => port.lpId.includes(portId)) &&
					winningTemplates?.includes(template.templateId);

				if (port["isWinningRule"]) {
					portWinningRule = port;
				}

				combinedTemplateData.templatePorts = [
					...(combinedTemplateData?.templatePorts ?? []),
					port,
				];
			}
		);

		((template?.templatePaths ?? []) as TemplatePath[]).forEach(
			(path: RuleEngineTemplatePath, index) => {
				if (template.oobTemplate) {
					path["oobTemplate"] = template.oobTemplate;
				}

				path["templateId"] = template.templateId;
				path["templateName"] = template.templateName;
				path.channelHash = path.channelHash + index + template.templateId;

				path["isWinningRule"] =
					portWinningRule?.listenPort === path?.port &&
					portWinningRule?.listenPortProtocol === path?.protocol &&
					portWinningRule?.listenPortReviewed === PortStatus.Deny
						? false
						: pathRuleHashList?.some(pathId =>
								path.channelHash.includes(pathId)
							) && isAnyMatch;

				if (path.direction === "inbound") {
					combinedTemplateData.inboundPaths = [
						...(combinedTemplateData?.inboundPaths ?? []),
						path,
					];
				}

				if (path.direction === "outbound") {
					combinedTemplateData.outboundPaths = [
						...(combinedTemplateData?.outboundPaths ?? []),
						path,
					];
				}
			}
		);
	});

	combinedTemplateData?.templatePorts?.sort((a, b) => {
		if (a?.isWinningRule) {
			return a.listenPort === "any" ? 1 : -1;
		}
		if (b?.isWinningRule) {
			return b.listenPort === "any" ? -1 : 1;
		}

		if (
			PORT_STATE_ORDER_MAP[a.listenPortReviewed] !==
			PORT_STATE_ORDER_MAP[b.listenPortReviewed]
		) {
			return (
				PORT_STATE_ORDER_MAP[b.listenPortReviewed] -
				PORT_STATE_ORDER_MAP[a.listenPortReviewed]
			);
		}

		if (a.listenPort !== b.listenPort) {
			return a.listenPort === "any" ? 1 : b.listenPort === "any" ? -1 : 0;
		}
		return 0;
	});

	const sortPaths = (a: any) => (a?.isWinningRule ? 1 : 0);

	combinedTemplateData?.inboundPaths?.sort(sortPaths);
	combinedTemplateData?.outboundPaths?.sort(sortPaths);

	return combinedTemplateData;
};

export const getActiveTab = (ruleEngineTemplate: RuleEngineTemplate) => {
	if (ruleEngineTemplate.templatePorts?.some(port => port.isWinningRule)) {
		return TemplateViewTab.Ports;
	}

	if (ruleEngineTemplate.inboundPaths?.length) {
		return TemplateViewTab.Inbound;
	}

	if (ruleEngineTemplate.outboundPaths?.length) {
		return TemplateViewTab.Outbound;
	}
};
