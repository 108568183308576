export function DiagonalFillPattern({
	opacity,
	strokeColor,
}: {
	opacity: number;
	strokeColor?: string;
}) {
	return (
		<svg
			height="10"
			width="10"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
		>
			<defs>
				<pattern
					id="diagonal-stripe-1"
					patternUnits="userSpaceOnUse"
					width="10"
					height="10"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
						<rect width="10" height="10" fill="transparent" />
						<path
							d="M-1,1 l2,-2            M0,10 l10,-10            M9,11 l2,-2"
							stroke={"#00C0CA"}
							strokeWidth="0.5"
						/>
					</svg>
				</pattern>
			</defs>
		</svg>
	);
}
