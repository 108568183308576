import { Stack, Tab } from "@mui/material";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs";

import { UserGroups } from "pages/users-segmentation/components/user-groups";
import { Users } from "pages/users-segmentation/components/users/Users";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export enum UserTabs {
	Users = 0,
	Groups = 1,
}

export const UserSegmentation = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedTab, setSelectedTab] = useState(UserTabs.Users);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
		let tabName = "";
		switch (newValue) {
			case UserTabs.Users:
				tabName = "users";
				break;
			case UserTabs.Groups:
				tabName = "groups";
				break;
		}
		navigate(`?tab=${tabName}`);
	};

	useLayoutEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const tabParam = urlParams.get("tab");
		let initialTab = UserTabs.Users;

		if (tabParam === "groups") {
			initialTab = UserTabs.Groups;
		}

		setSelectedTab(initialTab);
	}, [location.search, setSelectedTab]);

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack sx={{ width: "100%" }}>
				<Stack direction="row" sx={{ width: "100%" }}>
					<Stack flex={1} sx={{ marginBottom: 2 }}>
						<CtTabs value={selectedTab} onChange={handleTabChange}>
							<Tab label={window.getCTTranslatedText("Users")} />
							<Tab label={window.getCTTranslatedText("Groups")} />
						</CtTabs>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				sx={{ width: "100%", height: "100%", overflow: "hidden" }}
				alignItems="flex-start"
			>
				<TabPanel value={selectedTab} index={0} style={{ height: "100%" }}>
					<Users />
				</TabPanel>
				<TabPanel value={selectedTab} index={1} style={{ height: "100%" }}>
					<UserGroups />
				</TabPanel>
			</Stack>
		</Stack>
	);
};
