import { Button, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { getSegmentsNameFromCriteria } from "common/utils/getSegmentsNameFromCriteria";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { usePortStore } from "pages/ports/store";
import { Port } from "pages/ports/types";
import { useState } from "react";

export interface PortToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<Port> | undefined;
	showPortStatusOption: boolean;
	recommendationId?: string;
	clearSelection: Function;
}

export default function PortToolbar(props: PortToolbarProps) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const requestAPIRefresh = usePortStore(store => store.requestAPIRefresh);
	const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);

	const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
		setShowAddToTemplateDialog(dialogVisibility);
	};

	const handleClose = () => {
		setShowAddToTemplateDialog(false);
	};

	const addToTemplate = () => {
		setShowAddToTemplateDialog(false);
		requestAPIRefresh();
		props.clearSelection();
	};
	const facetState = useCommonStore?.(state => state.facets);
	let { data: portMetadata } = useScopeMetadata({ scope: Scope.Port });
	const autoSuggestTemplateName = getSegmentsNameFromCriteria(
		facetState,
		portMetadata
	);

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			{props.selectedData && props.selectedData.length > 0 && (
				<Stack
					direction="row"
					spacing={3}
					sx={{ width: "100%" }}
					justifyItems="flex-end"
					alignItems={"flex-end"}
					justifyContent="flex-end"
				>
					<AddToTemplateDrawer
						isOpen={showAddToTemplateDialog}
						page={"ports"}
						title="Add to Template"
						rules={props.selectedData}
						showPortStatusOption={props.showPortStatusOption}
						onCancel={handleClose}
						onConfirm={addToTemplate}
						btnTitle={"add"}
						actionType={TemplateActionType.add}
						autoSuggestTemplateName={autoSuggestTemplateName}
					></AddToTemplateDrawer>

					{userPermissions.has("UPDATE_TEMPLATE") && (
						<Stack direction="row" alignItems={"center"}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleAddToTemplateDialogOpen(true)}
							>
								Add to template
							</Button>
						</Stack>
					)}
				</Stack>
			)}
		</GridToolbar>
	);
}
