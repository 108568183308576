import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { TargetingSegmentsDrawer } from "modules/targeting-segments-drawer";
import numeral from "numeral";
import { TemplateColumnRenderProps } from "pages/templates/types";
import pluralize from "pluralize";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

interface UsedByPoliciesProps extends TemplateColumnRenderProps {
	openInNewTab?: boolean;
}

export function UsedByPolicies({
	template,
	viewOnly,
	openInNewTab,
}: UsedByPoliciesProps) {
	const [showTargettedSegmentsDrawer, setShowTargettedSegmentsDrawer] =
		useState(false);

	if (!template.templateId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			<Stack alignItems="flex-start">
				{viewOnly || template?.templateTagBasedPolicyAssignments === 0 ? (
					<Typography variant="body2">
						{numeral(template?.templateTagBasedPolicyAssignments ?? 0).format(
							"0a"
						)}{" "}
						{`${window.getCTTranslatedText(
							pluralize(
								"segment",
								Number(template?.templateTagBasedPolicyAssignments ?? 0)
							)
						)}`}
					</Typography>
				) : (
					<Tooltip title={window.getCTTranslatedText("ViewSegment")}>
						<Link
							typography={"body2"}
							component={RouterLink}
							to={"#"}
							sx={{ color: "primary.main" }}
							target={openInNewTab ? "_blank" : undefined}
							rel={openInNewTab ? "noopener noreferrer" : undefined}
							onClick={() => {
								setShowTargettedSegmentsDrawer(true);
							}}
						>
							{numeral(template?.templateTagBasedPolicyAssignments ?? 0).format(
								"0a"
							)}{" "}
							{`${window.getCTTranslatedText(
								pluralize(
									"segment",
									Number(template?.templateTagBasedPolicyAssignments ?? 0)
								)
							)}`}
						</Link>
					</Tooltip>
				)}
				<TargetingSegmentsDrawer
					open={showTargettedSegmentsDrawer}
					onClose={() => setShowTargettedSegmentsDrawer(false)}
					criteria={`'templateid' in ('${template.templateId}')`}
					subtitle={`${window.getCTTranslatedText("Template")}: ${template.templateName}`}
				/>
			</Stack>
		</Stack>
	);
}
