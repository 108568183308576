import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import useDebouncedValue from "hooks/useDebounce/useDebouncedValue";
import { Scope } from "modules/scope-metadata/types";
import { useState } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import useTagSuggestions from "../../hooks/useTagSuggestions";

interface Props {
	tagKey: string;
	label: string;
	value: string;
	onChange: ControllerRenderProps<FieldValues, string>["onChange"];
	icon?: JSX.Element;
	scope: Scope;
}

function EditTagAutocomplete({
	tagKey,
	label,
	value = "",
	onChange,
	icon,
	scope,
}: Props) {
	const [open, setOpen] = useState<boolean>(false);
	const debouncedValue = useDebouncedValue(value, 500);

	const { data: options = [] } = useTagSuggestions({
		tagKey,
		value: debouncedValue,
		scope,
		open,
	});

	return (
		<Autocomplete
			freeSolo
			id={`autocomplete-${label}`}
			disableClearable
			openOnFocus
			autoHighlight
			options={options}
			value={value}
			onInputChange={e => {
				if (e !== null) {
					onChange(e);
				}
			}}
			onChange={(_, value) => {
				if (value !== null) {
					onChange({ target: { value } });
				}
			}}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			renderInput={params => (
				<TextField
					variant="standard"
					{...params}
					label={label}
					placeholder={window.getCTTranslatedText("EnterCoreTag", {
						coreTagKey: label,
					})}
					InputLabelProps={{ shrink: true }}
					InputProps={
						icon && {
							...params.InputProps,
							type: "search",
							autoComplete: "new-password",
							startAdornment: (
								<InputAdornment position="start">{icon}</InputAdornment>
							),
						}
					}
				/>
			)}
		/>
	);
}

export default EditTagAutocomplete;
