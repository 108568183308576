import { CustomCommonStoreType } from "common/types/types";
import CriteriaChips from "modules/facets/components/criteria-chips/CriteriaChips";
import { SEGMENTS_READ_ONLY_DIMENSIONS } from "pages/tags/components/tag-policy-list/constants";
import { useFirewallHostGroupFacetStore } from "../store";
import { FirewallHostGroupListItem } from "../types";

interface Props {
	data: FirewallHostGroupListItem | undefined;
	useFacetStore: CustomCommonStoreType;
}

function FirewallHostGroupCriteria({
	data,
	useFacetStore = useFirewallHostGroupFacetStore,
}: Props) {
	if (!data) {
		return <></>;
	}
	return (
		<CriteriaChips
			data={data}
			useFacetStore={useFacetStore}
			isValid={!!data.crowdstrikeFirewallHostgroupId}
			readOnlyDimensions={SEGMENTS_READ_ONLY_DIMENSIONS}
		/>
	);
}

export default FirewallHostGroupCriteria;
