import { AssetSecurityLevelIcons, SecurityLevels } from "@colortokens/ng-ui";
import { Box } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { AssetColumnRenderProps } from "pages/assets/types";

export type VulnerabilitySeverity = SecurityLevels | "no value";

export function AssetVulnerabilitySeverity({
	asset,
	viewOnly,
}: AssetColumnRenderProps) {
	const severity = asset.vulnerabilitySeverity as VulnerabilitySeverity;

	if (!asset.assetId || severity === "no value") {
		return <Box style={{ width: 150 }} />;
	}

	if (severity) {
		return (
			<CTDatagridButton
				isDisabled={viewOnly === true}
				asText={viewOnly === true}
				facetName={"vulnerabilityseverity"}
				facetOption={severity}
				startIcon={
					<AssetSecurityLevelIcons securityLevel={severity as SecurityLevels} />
				}
			>
				{window.getCTTranslatedText(severity)}
			</CTDatagridButton>
		);
	}

	return null;
}
