import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { AssetAttackSurface } from "pages/assets/components/asset-attack-surface";
import { AssetBlastRadius } from "pages/assets/components/asset-blast-radius";
import { AssetBusinessRisk } from "pages/assets/components/asset-business-risk";
import { AssetBusinessValue } from "pages/assets/components/asset-business-value";
import { AssetNameAndTags } from "pages/assets/components/asset-name-and-tags";
import { AssetPaths } from "pages/assets/components/asset-paths";
import { AssetPorts } from "pages/assets/components/asset-ports";
import { AssetSecurityPatches } from "pages/assets/components/asset-security-patches";
import { AssetVulnerabilities } from "pages/assets/components/asset-vulnerabilities";
import { AssetMaximumCVSS } from "../asset-maximum-cvss";
import { AssetMetadataIcons } from "../asset-metadata-icons";
import { AssetOS } from "../asset-os";
import { AssetSecurityStatus } from "../asset-security-status";
import { AssetTypeIcon } from "../asset-type-icon";
import { AssetVulnerabilityAvailability } from "../asset-vulnerability-availability";
import { AssetVulnerabilitySeverity } from "../asset-vulnerability-severity";
import { DeviceStatus } from "../device-status";
import { VulnerabilityTypes } from "./types";

export const ASSET_COLUMNS = (viewOnly: boolean): GridColDef[] => [
	{
		field: "type",
		width: 60,
		minWidth: 60,
		headerAlign: "left",
		align: "left",
		sortable: false,
		headerName: "Type",
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetTypeIcon asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "assetName",
		headerName: "Asset Name",
		width: 200,
		minWidth: 200,
		flex: 0.7,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetNameAndTags
					asset={params.row}
					showSyncStatus={true}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "tags",
		minWidth: 180,
		headerAlign: "left",
		align: "left",
		sortingOrder: ["desc", "asc"],
		sortable: false,
		headerName: "Tags",
		flex: window.screen.availWidth > 1200 ? 0.5 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetCoreTags
					viewOnly={false}
					coreTags={params.row.coreTags}
					useAssetCoreTagsStore={useCommonStore}
				/>
			);
		},
	},
	{
		field: "osName",
		headerName: "OS",
		width: 80,
		minWidth: 80,
		headerAlign: "left",
		align: "left",
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetOS asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "assetRisk",
		minWidth: 180,
		headerAlign: "left",
		align: "left",
		sortingOrder: ["desc", "asc"],
		headerName: "BreachImpact",
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetBusinessRisk asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "attackSurface",
		headerName: "Attack Surface Security",
		headerAlign: "left",
		align: "left",
		minWidth: 180,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetAttackSurface asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "inboundassetstatus",
		headerName: "Attack Surface Status",
		headerAlign: "left",
		align: "left",
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetSecurityStatus
					asset={params.row}
					statusKey={"inboundAssetStatus"}
					value={params.row?.inboundAssetStatus}
					facetKey={"assetinboundstatus"}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "blastRadius",
		headerName: "Blast Radius Security",
		headerAlign: "left",
		align: "left",
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		sortingOrder: ["desc", "asc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetBlastRadius asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "outboundassetstatus",
		headerName: "Blast Radius Status",
		headerAlign: "left",
		align: "left",
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetSecurityStatus
					asset={params.row}
					statusKey={"outboundAssetStatus"}
					value={params.row?.outboundAssetStatus}
					facetKey={"assetoutboundstatus"}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "businessValue",
		headerName: "Business Value",
		headerAlign: "left",
		align: "left",
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["desc", "asc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetBusinessValue asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "vulnerabilitySeverity",
		headerName: "vulnerabilitySeverity",
		headerAlign: "left",
		align: "left",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetVulnerabilitySeverity asset={params.row} viewOnly={viewOnly} />
			);
		},
	},
	{
		field: "maximumCVSSScore",
		headerName: "Maximum CVSS Score",
		headerAlign: "left",
		align: "center",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetMaximumCVSS asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "lateralMovementVulnerability",
		headerName: "lateralMovementVulnerability",
		description: "remoteCodeExecutionVulnerability",
		headerAlign: "left",
		align: "left",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetVulnerabilityAvailability
					asset={params.row}
					vulnerabilityType={VulnerabilityTypes.LateralMovement}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "knownExploitVulnerability",
		headerName: "knownExploitVulnerability",
		headerAlign: "left",
		align: "left",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetVulnerabilityAvailability
					asset={params.row}
					vulnerabilityType={VulnerabilityTypes.KnownExploit}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "vulnerabilities",
		headerName: "Vulnerabilites",
		headerAlign: "left",
		align: "center",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetVulnerabilities
					assetId={params.row.assetId}
					assetName={params.row.assetName}
					vulnerabilityType={VulnerabilityTypes.Vulnerabilities}
					vulnerabilities={params.row.vulnerabilities}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "securityPatches",
		headerName: "Security Patches",
		headerAlign: "left",
		align: "center",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetSecurityPatches asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "assetAvailability",
		headerName: "Status",
		headerAlign: "left",
		align: "left",
		width: 160,
		minWidth: 160,
		sortingOrder: ["asc", "desc"],
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <DeviceStatus asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "ports",
		headerName: "Ports",
		headerAlign: "left",
		align: "left",
		width: 120,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetPorts asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "paths",
		headerName: "Paths",
		headerAlign: "left",
		align: "left",
		width: 120,
		minWidth: 120,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["desc", "asc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetPaths asset={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "metadataicons",
		headerName: "",
		headerAlign: "left",
		align: "right",
		width: 130,
		minWidth: 90,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetMetadataIcons asset={params.row} viewOnly={viewOnly} />;
		},
	},
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
	// Hide columns from the display
};

const PinnedColumns = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "type", "assetName", "tags"],
	right: ["metadataicons"],
};

export const AssetColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};
