import { Navigate, useLocation } from "react-router-dom";
import { Route, ROUTES } from "./routes";

const REDIRECT_MAP: Record<string, string> = {
	"/monitoring?tab=alerts": ROUTES.MONITORING_ALERTS.pathname,
	"/monitoring?tab=audit_logs": ROUTES.MONITORING_LOGS.pathname,
	"/tags?tab=tags": ROUTES.TAG_NAMES.pathname,
	"/tags?tab=rules": ROUTES.TAG_LABEL_RULES.pathname,
	"/settings?tab=agentconfig": ROUTES.SETTINGS_GENERAL.pathname,
	"/settings?tab=alertconfig": ROUTES.SETTINGS_ALERTS.pathname,
	"/settings?tab=sessionconfig": ROUTES.SETTINGS_GENERAL.pathname,
};

const OldPathNavigate = ({ pathname, redirect }: Route) => {
	const { pathname: currentPathname, search } = useLocation();
	const redirectPath = redirect ?? pathname;
	const currentFullPath = currentPathname + search;
	const currentPathArr = currentPathname.split("/");
	const idFromOldPath = currentPathArr[currentPathArr.length - 1];

	const getPathFromRedirectMap = () => {
		for (const key in REDIRECT_MAP) {
			if (currentFullPath.includes(key)) return REDIRECT_MAP[key];
		}
		return redirectPath;
	};

	const finalPathname = getPathFromRedirectMap()
		.split("/")
		.map(path => (path.includes(":") ? idFromOldPath : path))
		.join("/");

	return (
		<Navigate
			to={{
				pathname: finalPathname,
				search: window.location.search,
			}}
		/>
	);
};

export default OldPathNavigate;
