import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { InputListType } from "../../types";

const baseRowStyle = {
	pt: 0,
	pb: 1.75,
	px: 3,
};

const rulesSectionWrapper = {
	borderRadius: 1,
};

interface DNSServerInputProps {
	list: InputListType;
	isFormDisabled: boolean;
	handleListChange: (
		e: TextFieldUpdate,
		index: number,
		IPList: InputListType
	) => void;
	addNewItem: (IPList: InputListType) => void;
	removeItem: (index: number, IPList: InputListType) => void;
	addButtonText: string;
	title: string;
}

export function AddMultipleItemInput({
	list,
	isFormDisabled,
	handleListChange,
	addNewItem,
	removeItem,
	addButtonText,
	title,
}: DNSServerInputProps) {
	return (
		<Box sx={{ minWidth: "100%" }}>
			<Stack
				alignItems="flex-start"
				justifyContent="flex-start"
				spacing={0}
				sx={{
					...rulesSectionWrapper,
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent={"space-between"}
					sx={{ width: "100%", my: 2 }}
				>
					<Stack alignItems="center">
						<Typography
							variant="subtitle1"
							sx={{
								color: theme => theme.palette.text.disabled,
							}}
						>
							{window.getCTTranslatedText(title)}
						</Typography>
					</Stack>
					<Stack alignItems="center">
						<Button
							variant="text"
							startIcon={<AddCircleOutlineIcon />}
							color="primary"
							onClick={() => addNewItem(list)}
							disabled={isFormDisabled}
						>
							{window.getCTTranslatedText(addButtonText)}
						</Button>
					</Stack>
				</Stack>
				<Stack sx={{ width: "100%" }}>
					<Stack>
						{list &&
							list.length > 0 &&
							list.map((item, index) => {
								const inputValue =
									typeof item === "string" ? item : item?.value;
								return (
									<Stack
										direction="row"
										key={`ip-${index}`}
										sx={{
											background: theme =>
												theme.palette.mode === "dark"
													? theme.palette.background.paper
													: theme.palette.custom.lightGreyBg,
										}}
									>
										<Stack
											direction="row"
											alignItems={"center"}
											justifyContent="flex-start"
											spacing={2}
											sx={{
												...baseRowStyle,
												width: "70%",
											}}
										>
											<CTIPInput
												field={"listitem"}
												displayName={title}
												value={inputValue}
												handleUpdate={(event: TextFieldUpdate) =>
													handleListChange(event, index, list)
												}
												showLabel={false}
												disabled={isFormDisabled}
											/>
										</Stack>
										<Stack
											justifyContent="center"
											alignItems="center"
											width="30%"
										>
											<IconButton
												size="small"
												aria-label="remove from list"
												onClick={() => removeItem(index, list)}
												disabled={isFormDisabled}
											>
												<RemoveCircleOutlineIcon fontSize="small" />
											</IconButton>
										</Stack>
									</Stack>
								);
							})}
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
}
