import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useCommonStore } from "common/store";
import { AssetNameInternal } from "pages/assets/components/asset-name-internal";
import { AssetType } from "pages/assets/types";
import { NIL } from "pages/paths/constants";
import { PathColumnRenderProps, PathDirection } from "pages/paths/types";
import { PathNnHits } from "../path-nn-hits";
import { PathUserGroup } from "../path-user-groups";

export function PathSource({
	path,
	viewOnly,
	asLink,
	useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
	if (!path.channelHash) {
		return <Box style={{ width: 160, minHeight: 45 }} />;
	}

	const renderContent = () => {
		if (
			path.sourceNamedNetwork &&
			path.sourceNamedNetwork.namedNetworkId &&
			path?.sourceNamedNetwork?.namedNetworkName !== ""
		) {
			return (
				<Stack direction="row" alignItems={"center"}>
					<Tooltip
						title={window.getCTTranslatedText(
							viewOnly ? "" : `${path?.sourceNamedNetwork?.namedNetworkName}`
						)}
					>
						<CTDatagridButton
							linkTo={
								asLink
									? `/networks?networkId=${path.sourceNamedNetwork.namedNetworkId}`
									: undefined
							}
							asText={viewOnly === true}
							isDisabled={viewOnly === true}
							sx={{ width: "fit-content" }}
							useFacetStore={useFacetStore}
							facetName={"namednetworkname"}
							facetOption={path.sourceNamedNetwork?.namedNetworkName ?? ""}
						/>
					</Tooltip>

					{path.destinationAsset && (
						<PathNnHits
							direction={PathDirection.Inbound}
							nn={path.sourceNamedNetwork}
							asset={path.destinationAsset}
							port={path.port}
							protocol={path.protocol}
						/>
					)}
				</Stack>
			);
		} else {
			return (
				<Stack direction="row" alignItems={"center"}>
					{path.sourceAsset &&
						path?.sourceAsset?.type !== AssetType.UserGroup && (
							<Tooltip
								title={
									viewOnly
										? ""
										: window.getCTTranslatedText(
												"TooltipShowOnlyForAssetName",
												{
													name: path?.sourceAsset?.assetName,
												}
											)
								}
							>
								<CTDatagridButton
									linkTo={
										asLink ? `/assets/${path.sourceAsset.assetId}` : undefined
									}
									asText={viewOnly === true}
									isDisabled={viewOnly === true}
									sx={{ width: "fit-content" }}
									useFacetStore={useFacetStore}
									facetName={"assetname"}
									facetOption={path.sourceAsset?.assetName || ""}
								/>
							</Tooltip>
						)}

					{path.sourceAsset &&
						path?.sourceAsset?.type === AssetType.UserGroup && (
							<Tooltip
								title={
									viewOnly
										? ""
										: window.getCTTranslatedText(
												"TooltipShowOnlyForAssetName",
												{
													name: path?.sourceAsset?.assetName,
												}
											)
								}
							>
								<CTDatagridButton
									asText={viewOnly === true}
									isDisabled={viewOnly === true}
									sx={{ width: "fit-content" }}
									useFacetStore={useFacetStore}
									facetName={"groupname"}
									facetOption={path.sourceAsset?.assetName || ""}
								/>
							</Tooltip>
						)}

					{path.sourceAsset && path.srcIp && path.srcIp !== NIL && (
						<Typography pl={1} variant="body2">
							-
						</Typography>
					)}

					{path.srcIp && path.srcIp !== NIL && (
						<Tooltip
							title={window.getCTTranslatedText(
								path?.srcIp === "any" ? "anyIP" : (path?.srcIp ?? "")
							)}
						>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<CTDatagridButton
									asText={viewOnly === true}
									isDisabled={viewOnly === true}
									sx={{ width: "fit-content" }}
									useFacetStore={useFacetStore}
									facetName={"srcip"}
									facetOption={path.srcIp ?? ""}
								/>
							</Box>
						</Tooltip>
					)}

					{path?.sourceAsset?.type === AssetType.UserGroup && (
						<PathUserGroup
							title={`${AssetNameInternal({
								asset: path?.sourceAsset,
							})} usergroup hits`}
							path={path}
							userGroupType={"source"}
							usergrouphitusergroupid={path.sourceAsset.assetId}
						/>
					)}
				</Stack>
			);
		}
	};

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
			{renderContent()}
		</Stack>
	);
}
