export const RING_FENCE_DEFAULT_ROWS = [
	{
		port: "any",
		protocol: "ICMP",
		usedByAssets: 0,
		assetWithObservedActivityTrafficWithLastMonth: 0,
		coverage: 0,
		showDelete: false,
	},
	{
		port: "any",
		protocol: "IGMP",
		usedByAssets: 0,
		assetWithObservedActivityTrafficWithLastMonth: 0,
		coverage: 0,
		showDelete: false,
	},
	{
		port: "any",
		protocol: "IPV6-ICMP",
		usedByAssets: 0,
		assetWithObservedActivityTrafficWithLastMonth: 0,
		coverage: 0,
		showDelete: false,
	},
	{
		port: "any",
		protocol: "TCP",
		usedByAssets: 0,
		assetWithObservedActivityTrafficWithLastMonth: 0,
		coverage: 0,
		showDelete: false,
	},
	{
		port: "any",
		protocol: "UDP",
		usedByAssets: 0,
		assetWithObservedActivityTrafficWithLastMonth: 0,
		coverage: 0,
		showDelete: false,
	},
];
