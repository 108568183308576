import { GridRowId } from "@mui/x-data-grid-pro";
import { Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";

export interface PathRules {
	port: string;
	protocol: string;
	direction: string;
	destinationNamedNetwork?: {
		namedNetworkName: string;
		namedNetworkId: string;
	};
	sourceNamedNetwork?: {
		namedNetworkName: string;
		namedNetworkId: string;
	};
	sourceTagBasedPolicy?: {
		tagBasedPolicyName: string;
		tagBasedPolicyId: string;
	};
	destinationTagBasedPolicy?: {
		tagBasedPolicyName: string;
		tagBasedPolicyId: string;
	};
}

export type Rule = Port | Path | PathRules | string | GridRowId;

export interface RuleType {
	rules: Rule[] | undefined;
}

export interface AddToTemplateDialogProps extends RuleType {
	open: boolean;
	page: string;
	title: string;
	showSrcDestOption?: boolean;
	showPortStatusOption?: boolean;
	onCancel: Function;
	onConfirm: Function;
	btnTitle?: string;
	criteria?: string;
	multiselect?: boolean;
	onSelectCallback?: Function;
	policyId?: string;
	showNonWindows?: boolean;
	hasPermission?: boolean;
	actionType: TemplateActionType;
	policy?: TagPolicy;
	showDialog?: boolean;
	autoSuggestTemplateName?: string;
}

export interface AssignTemplateProps {
	templateId: string | undefined;
	assetId: string | undefined;
}

export interface FormatTemplateRequestBodyProps extends RuleType {
	body: any;
}

export enum TemplateActionType {
	assign = "Assign",
	add = "Add",
}
