import { Box, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { FacetOptionState, Operator } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { useUnmanagedDevicesStore } from "pages/appliance-detail/store";
import { ApplianceDevicesFilterKeys } from "pages/appliance-detail/types";
import { Appliance, PairedGateway } from "pages/appliances/types";
import UnmanagedDeviceEdit from "pages/asset/components/asset-metadata-wrapper/components/metadata-edit/UnmanagedDeviceEdit";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { useEffect } from "react";
import { UnmanagedDevicesDataGrid } from "../unmanaged-device-data-grid";
import { useUnmanagedDevicesAPI } from "../unmanaged-device-data-grid/hooks";
import { FACET_ORDER } from "./constants";
import { UnmanagedDevice } from "./types";

interface ApplianceDevicesProps {
	applianceName?: string;
	applianceId?: string;
	applianceDetails?: Appliance;
	isParent: boolean;
}

const dataMapper = (device: UnmanagedDevice) => {
	return device;
};

export const ApplianceUnmanagedDevices = ({
	applianceName,
	applianceId,
	applianceDetails,
	isParent,
}: ApplianceDevicesProps) => {
	const theme = useTheme();
	const setFacets = useCommonStore(state => state.setFacets);

	useEffect(() => {
		if (applianceDetails) {
			const applianceFilter: FacetOptionState = new Map([
				[
					applianceDetails.agentName,
					{ isSelected: true, operator: Operator.EQUAL },
				],
			]);

			let pairedGatewaysFilter: FacetOptionState = new Map();
			if (isParent) {
				pairedGatewaysFilter = new Map(
					((applianceDetails?.pairedGateways as PairedGateway[]) || [])?.map(
						(
							gateway: PairedGateway
						): [string, { isSelected: boolean; operator: Operator }] => [
							gateway.agentName,
							{ isSelected: true, operator: Operator.EQUAL },
						]
					)
				);
			}

			let facets = new Map([
				[
					ApplianceDevicesFilterKeys.ApplianceName,
					isParent ? pairedGatewaysFilter : applianceFilter,
				],
			]);
			setFacets(facets);
		}

		return () => setFacets(undefined);
	}, [applianceDetails, setFacets, isParent]);

	const coreResponse = useCore<UnmanagedDevice>({
		useStore: useUnmanagedDevicesStore,
		facetGroupInfo: FACET_ORDER,
		scope: Scope.UnmanagedDevice,
		facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
		defaultSortOrder: [{ field: "devicename", order: "desc" }],
		dataMapper: dataMapper,
		skipSearch: true,
		useApi: useUnmanagedDevicesAPI,
		pageSize: 100,
	});

	const metadata = useCommonStore(state => state.metadata);
	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);
	const { editAsset } = useUnmanagedDevicesStore();
	return (
		<>
			<Stack
				direction={"column"}
				spacing={2}
				sx={{ height: "100%", width: "100%" }}
			>
				<Stack direction={"row"} alignItems="center">
					<Stack sx={{ flex: 1 }}>
						<FacetOpenCloseController
							facetsOpen={coreResponse.facetsOpen}
							setFacetsOpen={coreResponse.setFacetsOpen}
						>
							<FacetControllerGroup
								config={coreResponse.facetConfig}
								value={coreResponse.facetState}
								onChange={coreResponse.updateFacet}
							/>
						</FacetOpenCloseController>
					</Stack>
				</Stack>
				<Box
					sx={{
						transition: theme.transitions.create(["width", "margin"], {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
						overflowY: "auto",
						flex: 1,
						height: "100%",
					}}
				>
					<Paper sx={{ height: "100%" }}>
						<UnmanagedDevicesDataGrid
							initialState={{
								sorting: {
									sortModel: [{ field: "assetrisk", sort: "desc" }],
								},
							}}
							checkboxSelection
							metadata={metadata}
							rows={coreResponse.rows}
							rowCount={coreResponse.rowCount}
							mutation={coreResponse.mutation}
							onPageChange={coreResponse.onPageChange}
							page={coreResponse.page}
							pageSize={coreResponse.pageSize}
							onPageSizeChange={coreResponse.onPageSizeChange}
							hasNoData={
								coreResponse.mutation.isSuccess &&
								coreResponse.rowCount === 0 &&
								searchCriteria === "*"
							}
							triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
							getExportStatus={coreResponse?.getExportStatus}
							getUrlToDownload={coreResponse?.getUrlToDownload}
							resetDownloadUrl={coreResponse?.resetDownloadUrl}
							onSortChange={sortModel => {
								if (sortModel?.length > 0) {
									coreResponse.onSortChange(sortModel);
								}
							}}
							rawData={coreResponse?.rawData}
							applianceId={applianceId}
						/>
					</Paper>
				</Box>
			</Stack>

			{editAsset && <UnmanagedDeviceEdit unmanagedDevice={editAsset} />}
		</>
	);
};
