import { Instruction } from "common/molecules/instructions-renderer/type";
import {
	DisplayInstructionContent,
	NetworkInstructionContent,
	RegistrationInstructionContent,
	VerifyInstructionContent,
} from "./components/RegisterIntructionsDrawer";

export const RegisterInstructions: Instruction[] = [
	{
		title: "bootupAndLogin",
		subtitle: "bootupAndLoginSubtitle",
	},
	{
		title: "enterGatekeeperName",
		subtitle: "enterGatekeeperNameSubtitle",
		terminalCommands: ["enterApplianceNameTerminalCommand1"],
	},
	{
		title: "displayGatekeeperInformation",
		subtitle: "displayGatekeeperInformationSubtitle",
		terminalCommands: ["displayApplianceInformationTerminalCommand6"],
		instructionContent: DisplayInstructionContent,
		secondaryTerminalCommands: [
			"displayApplianceInformationTerminalCommand1",
			"displayApplianceInformationTerminalCommand2",
			"displayApplianceInformationTerminalCommand3",
			"displayApplianceInformationTerminalCommand4",
			"displayApplianceInformationTerminalCommand5",
			"displayApplianceInformationTerminalCommand6",
			"displayApplianceInformationTerminalCommand7",
		],
	},
	{
		title: "mainMenuOptions",
		subtitle: "mainMenuOptionsSubtitle",
		terminalCommands: [
			"mainMenuOptionsTerminalCommand1",
			"mainMenuOptionsTerminalCommand2",
			"mainMenuOptionsTerminalCommand3",
			"mainMenuOptionsTerminalCommand4",
			"mainMenuOptionsTerminalCommand5",
			"mainMenuOptionsTerminalCommand6",
			"mainMenuOptionsTerminalCommand7",
			"mainMenuOptionsTerminalCommand8",
		],
	},
	{
		title: "networkSettings",
		subtitle: "networkSettingsSubtitle",
		isOptional: true,
		terminalCommands: [
			"networkSettingsTerminalCommand",
			"networkSettingsTerminalCommand1",
			"networkSettingsTerminalCommand2",
			"networkSettingsTerminalCommand3",
			"networkSettingsTerminalCommand4",
		],
		instructionContent: NetworkInstructionContent,
	},
	{
		title: "registerGatekeeper",
		subtitle: "registerGatekeeperSubtitle",
		terminalCommands: [
			"registerApplianceTerminalCommand1",
			"registerApplianceTerminalCommand2",
			"registerApplianceTerminalCommand3",
			"registerApplianceTerminalCommand4",
		],
		instructionContent: RegistrationInstructionContent,
	},
	{
		title: "verifyAndConfigure",
		instructionContent: VerifyInstructionContent,
	},
];
