import { Alert } from "@mui/material";
import { GridRowId, GridRowParams } from "@mui/x-data-grid-pro";
import { isDiagnosticsAllowed } from "pages/agents/components/agent-data-grid/helpers/columnHelpers";
import {
	AGENTS_FIREWALL_COEXISTENCE_COLUMNS,
	AGENT_AUTOUPGRADE_COLUMNS,
	AGENT_DEBUGLOG_COLUMNS,
	AGENT_DECOMMISSION_COLUMNS,
	AGENT_DIAGNOSTICS_COLUMNS,
	AGENT_NS_TRAFFIC_CONFIG_COLUMNS,
	AGENT_POLICY_TAMPERING_COLUMNS,
	AGENT_RESTART_COLUMNS,
	AGENT_TRAFFIC_CONFIG_COLUMNS,
	AutoUpgradeStatus,
	DebugLogStatus,
	FirewallCoexistenceStatus,
	NsTrafficConfigStatus,
	PolicyTamperingStatus,
} from "pages/agents/components/agent-drawers/helpers/constants";

import {
	ServerAgentTrafficConfigSupportedMinVersion,
	UserAgentTrafficConfigSupportedMinVersion,
} from "pages/agents/components/agent-data-grid/constants";

import { Agent, NsTrafficStatusType } from "pages/agents/types";
import { Appliance } from "pages/appliances/types";
import { AssetType } from "pages/assets/types";
import * as semver from "semver";
import { isDiagnosticsAllowed as isDiagnosticsAllowedAppliance } from "../appliance-data-grid/helpers/columnHelpers";
import {
	APPLIANCE_AUTO_UPGRADE_COLUMNS,
	APPLIANCE_DIAGNOSTICS_COLUMNS,
	APPLIANCE_RESTART_COLUMNS,
	APPLIANCE_UPGRADE_COLUMNS,
} from "../appliance-drawers/helpers/constants";
import { AGENT_BASE_DRAWER_ACTION_LABEL } from "./constants";
import { DRAWER_TYPE } from "./types";

export const AgentNsTrafficConfigSupportedMinVersion = "24.6.0";
export const AgentNsTrafficCollectSupportedMinVersion = "24.10.4";

interface GetRowIDProps {
	agentId: string;
	assetId?: string;
	drawerType: DRAWER_TYPE;
}

export const getRowID = ({ agentId, assetId, drawerType }: GetRowIDProps) => {
	switch (drawerType) {
		case DRAWER_TYPE.APPLIANCE_RESTART:
		case DRAWER_TYPE.APPLIANCE_UPGRADE:
		case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
			return agentId;

		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
		case DRAWER_TYPE.AGENT_RESTART:
		case DRAWER_TYPE.AGENT_DECOMMISSION:
		case DRAWER_TYPE.AGENT_DEBUG_LOG:
		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
		case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return `${agentId}-${assetId}`;

		default:
			return "";
	}
};

export const getUpdatedData = (
	drawerType: DRAWER_TYPE,
	data: Agent[] | Appliance[],
	selectionRowIds: GridRowId[]
) => {
	switch (drawerType) {
		case DRAWER_TYPE.APPLIANCE_RESTART:
		case DRAWER_TYPE.APPLIANCE_UPGRADE:
		case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
			return (data as Appliance[]).filter(
				row => selectionRowIds.indexOf(row.rowId!) !== -1
			);

		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
		case DRAWER_TYPE.AGENT_RESTART:
		case DRAWER_TYPE.AGENT_DECOMMISSION:
		case DRAWER_TYPE.AGENT_DEBUG_LOG:
		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
		case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
		case DRAWER_TYPE.AGENT_UPGRADE:
		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return (data as Agent[]).filter(
				row => selectionRowIds.indexOf(row.rowId!) !== -1
			);

		default:
			return [];
	}
};

export const renderAlert = (drawerType: DRAWER_TYPE, action: string) => {
	switch (drawerType) {
		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
			return (
				<Alert severity="warning" sx={{ mb: 3 }}>
					{window.getCTTranslatedText("AlertTrafficConfigWarningText", {
						UserAgentTrafficConfigSupportedMinVersion,
						ServerAgentTrafficConfigSupportedMinVersion,
					})}
				</Alert>
			);

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
			const text =
				action === NsTrafficConfigStatus.CollectNorthSouthTraffic
					? "nsTrafficCollectTooltip"
					: "AlertNsTrafficConfigWarningText";
			const supportedVersion =
				action === NsTrafficConfigStatus.CollectNorthSouthTraffic
					? AgentNsTrafficCollectSupportedMinVersion
					: AgentNsTrafficConfigSupportedMinVersion;
			return (
				<Alert severity="warning" sx={{ mb: 3 }}>
					{window.getCTTranslatedText(text, {
						supportedVersion,
					})}
				</Alert>
			);

		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return (
				<Alert severity="warning" sx={{ mb: 3 }}>
					{window.getCTTranslatedText("FirewallCoexistenceWraningText")}
				</Alert>
			);

		default:
			return "";
	}
};

export const getTitle = (drawerType: DRAWER_TYPE) => {
	switch (drawerType) {
		case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
			return "Auto Upgrade Agent";

		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
			return "Agent Diagnostics";

		case DRAWER_TYPE.AGENT_DEBUG_LOG:
			return "debugLog";

		case DRAWER_TYPE.AGENT_DECOMMISSION:
			return "agentDecommission";

		case DRAWER_TYPE.AGENT_RESTART:
			return "Restart Agent";

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
			return "trafficConfig";

		case DRAWER_TYPE.AGENT_UPGRADE:
			return "Upgrade Agent";

		case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
			return "autoUpgradeGatekeeper";

		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
			return "agentDiagnostics";

		case DRAWER_TYPE.APPLIANCE_RESTART:
			return "restartGatekeeper";

		case DRAWER_TYPE.APPLIANCE_UPGRADE:
			return "upgradeGatekeeper";

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
			return "nsTrafficConfig";

		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
			return "policyTampering";

		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return "firewallCoexistence";

		default:
			return "";
	}
};

export const getButtonTitle = (drawerType: DRAWER_TYPE, action: string) => {
	switch (drawerType) {
		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
			return "Collect diagnostics";

		case DRAWER_TYPE.AGENT_DECOMMISSION:
			return "decommissionButton";

		case DRAWER_TYPE.AGENT_RESTART:
			return "restartButton";

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
			return "Confirm";

		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
			return "Collect diagnostics";

		case DRAWER_TYPE.APPLIANCE_RESTART:
			return "restartButton";

		case DRAWER_TYPE.APPLIANCE_UPGRADE:
		case DRAWER_TYPE.AGENT_UPGRADE:
			return "Upgrade";

		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
			return AGENT_BASE_DRAWER_ACTION_LABEL[action] ?? "Confirm";

		default:
			return action;
	}
};

export const getColumns = (drawerType: DRAWER_TYPE, status?: string) => {
	switch (drawerType) {
		case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
			return AGENT_AUTOUPGRADE_COLUMNS(status ?? "");

		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
			return AGENT_DIAGNOSTICS_COLUMNS;

		case DRAWER_TYPE.AGENT_DEBUG_LOG:
			return AGENT_DEBUGLOG_COLUMNS(status ?? "");
		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
			return AGENT_POLICY_TAMPERING_COLUMNS(status ?? "");

		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return AGENTS_FIREWALL_COEXISTENCE_COLUMNS(status ?? "");

		case DRAWER_TYPE.AGENT_DECOMMISSION:
			return AGENT_DECOMMISSION_COLUMNS;

		case DRAWER_TYPE.AGENT_RESTART:
			return AGENT_RESTART_COLUMNS;

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
			return AGENT_TRAFFIC_CONFIG_COLUMNS(status ?? "");

		case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
			return APPLIANCE_AUTO_UPGRADE_COLUMNS(status ?? "");

		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
			return APPLIANCE_DIAGNOSTICS_COLUMNS;

		case DRAWER_TYPE.APPLIANCE_RESTART:
			return APPLIANCE_RESTART_COLUMNS;

		case DRAWER_TYPE.APPLIANCE_UPGRADE:
			return APPLIANCE_UPGRADE_COLUMNS;

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
			return AGENT_NS_TRAFFIC_CONFIG_COLUMNS(status ?? "");

		default:
			return [];
	}
};

export const getSelectedRows = (
	drawerType: DRAWER_TYPE,
	data: Agent[] | Appliance[],
	selection?: GridRowId[],
	action?: string
) => {
	if (!selection) {
		return;
	}

	switch (drawerType) {
		case DRAWER_TYPE.AGENT_UPGRADE:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) => obj.rowId === item && obj.isUpgradeEnabled
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find((obj: Agent) =>
					action === AutoUpgradeStatus.Disable
						? obj.rowId === item && obj.autoUpgradeEnabled
						: obj.rowId === item && !obj.autoUpgradeEnabled
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_DEBUG_LOG:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) =>
						obj.rowId === item &&
						(action === DebugLogStatus.Disable
							? obj.isDebugLogEnabled
							: !obj.isDebugLogEnabled) &&
						!obj.isDebugLogRunning &&
						obj.isDebugLogSupported
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) =>
						obj.rowId === item &&
						(action === PolicyTamperingStatus.Disable
							? obj.isPolicyTamperingEnabled
							: !obj.isPolicyTamperingEnabled) &&
						!obj.isPolicyTamperingRunning &&
						obj.isPolicyTamperingSupported
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return selection.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) =>
						obj.rowId === item &&
						(action === FirewallCoexistenceStatus.Disable
							? obj.isFirewallCoexistenceEnabled
							: !obj.isFirewallCoexistenceEnabled) &&
						!obj.isFirewallCoexistenceRunning &&
						obj.isFirewallCoexistenceSupported
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_DECOMMISSION:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) =>
						obj.rowId === item &&
						obj.isDecommissionEnabled &&
						obj.isDecommissionSupported
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_RESTART:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) => obj.rowId === item && obj.isRestartEnabled
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
			return selection?.filter(item => {
				const matchedData = (data as Agent[]).find(
					(obj: Agent) =>
						obj.rowId === item &&
						obj.currentTrafficConfiguration !== action &&
						obj.isTrafficConfigurationSupported
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
			return selection?.filter(item => {
				const matchedData = (data as Agent[])?.find(
					(obj: Agent) =>
						obj.rowId === item &&
						!obj.isDiagnosticsRunning &&
						isDiagnosticsAllowed(obj)
				);
				return matchedData !== undefined;
			});

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
			if (action === NsTrafficConfigStatus.CollectNorthSouthTraffic) {
				return selection?.filter(item => {
					const matchedData = (data as Agent[])?.find(
						(obj: Agent) =>
							obj.rowId === item &&
							obj.asset.type === AssetType.Server &&
							obj.northSouthTrafficConfiguration !==
								NsTrafficStatusType.Disabled &&
							semver.gt(
								obj.currentVersion,
								AgentNsTrafficCollectSupportedMinVersion
							)
					);
					return matchedData !== undefined;
				});
			} else {
				return selection?.filter(item => {
					const matchedData = (data as Agent[])?.find(
						(obj: Agent) =>
							obj.rowId === item &&
							obj.asset.type === AssetType.Server &&
							obj.northSouthTrafficConfiguration !== action &&
							semver.gt(
								obj.currentVersion,
								AgentNsTrafficConfigSupportedMinVersion
							)
					);
					return matchedData !== undefined;
				});
			}
		case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
			return selection?.filter(item => {
				const matchedData = (data as Appliance[])?.find((obj: Appliance) =>
					action === AutoUpgradeStatus.Disable
						? obj.rowId === item && obj.autoUpgradeEnabled
						: obj.rowId === item && !obj.autoUpgradeEnabled
				);

				return matchedData !== undefined;
			});

		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
			return selection?.filter(item => {
				const matchedData = (data as Appliance[])?.find(
					(obj: Appliance) =>
						obj.rowId === item &&
						!obj.isDiagnosticsRunning &&
						isDiagnosticsAllowedAppliance(obj)
				);

				return matchedData !== undefined;
			});

		case DRAWER_TYPE.APPLIANCE_RESTART:
			return selection?.filter(item => {
				const matchedData = (data as Appliance[])?.find(
					(obj: Appliance) => obj.agentId === item && obj.isRestartEnabled
				);

				return matchedData !== undefined;
			});

		case DRAWER_TYPE.APPLIANCE_UPGRADE:
			return selection?.filter(item => {
				const matchedData = (data as Appliance[])?.find(
					(obj: Appliance) => obj.agentId === item && obj.isUpgradeEnabled
				);

				return matchedData !== undefined;
			});

		default:
			return [];
	}
};

export const getIsRowSelectable = (
	data: GridRowParams<any>,
	drawerType: DRAWER_TYPE,
	action?: string
) => {
	switch (drawerType) {
		case DRAWER_TYPE.APPLIANCE_RESTART:
		case DRAWER_TYPE.AGENT_RESTART:
			return data.row.isRestartEnabled;

		case DRAWER_TYPE.APPLIANCE_UPGRADE:
			return data.row.isUpgradeEnabled;

		case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
		case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
			return action === AutoUpgradeStatus.Disable
				? data.row.autoUpgradeEnabled
				: !data.row.autoUpgradeEnabled;

		case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
		case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
			return !data.row.isDiagnosticsRunning && isDiagnosticsAllowed(data?.row);

		case DRAWER_TYPE.AGENT_DECOMMISSION:
			return data.row.isDecommissionEnabled && data.row.isDecommissionSupported;

		case DRAWER_TYPE.AGENT_DEBUG_LOG:
			return (
				(action === DebugLogStatus.Disable
					? data.row.isDebugLogEnabled
					: !data.row.isdebugLogEnabled) &&
				!data.row.isDebugLogRunning &&
				data.row.isDebugLogSupported
			);

		case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
			return (
				(action === PolicyTamperingStatus.Disable
					? data.row.isPolicyTamperingEnabled
					: !data.row.isPolicyTamperingEnabled) &&
				!data.row.isPolicyTamperingRunning &&
				data.row.isPolicyTamperingSupported
			);

		case DRAWER_TYPE.FIREWALL_COEXISTENCE:
			return (
				(action === FirewallCoexistenceStatus.Disable
					? data.row.isFirewallCoexistenceEnabled
					: !data.row.isFirewallCoexistenceEnabled) &&
				!data.row.isFirewallCoexistenceRunning &&
				data.row.isFirewallCoexistenceSupported
			);

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
			return (
				data.row.isTrafficConfigurationSupported &&
				action !== data.row.currentTrafficConfiguration
			);

		case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
			const supportedVersion =
				action === NsTrafficConfigStatus.CollectNorthSouthTraffic
					? AgentNsTrafficCollectSupportedMinVersion
					: AgentNsTrafficConfigSupportedMinVersion;
			return (
				data.row?.asset?.type === AssetType.Server &&
				semver.gt(data.row?.currentVersion, supportedVersion)
			);

		default:
			return data.row.isRestartEnabled;
	}
};

export const getSecondaryButtonText = (drawerType: DRAWER_TYPE) => {
	switch (drawerType) {
		case DRAWER_TYPE.AGENT_OFFLINE_PASSWORD:
			return "Close";

		default:
			return "Cancel";
	}
};
