import { Box, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { FirewallWarningIcon } from "assets/svgs";
import { useCommonStore } from "common/store";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { AssetColumnRenderProps } from "pages/assets/types";

interface AssetSyncStatusProps extends AssetColumnRenderProps {
	direction?: Direction;
	width?: string;
	height?: string;
	iconOnly?: boolean;
}

export function AssetSyncStatus({
	asset,
	viewOnly,
	direction,
	iconOnly,
	width = "20px",
	height = "20px",
}: AssetSyncStatusProps) {
	const theme = useTheme();
	const facetState = useCommonStore(state => state.facets);
	const updateFacetOption = useCommonStore(state => state.updateFacetOption);

	let tooltipText = "";
	let value: boolean =
		Boolean(asset?.pendingAttackSurfaceChanges) ||
		Boolean(asset?.pendingBlastRadiusChanges);
	let directionStr: string = "";

	let icon = null;

	if (Boolean(asset?.pendingAttackSurfaceChanges)) {
		directionStr = "inbound";
	}
	if (Boolean(asset?.pendingBlastRadiusChanges)) {
		directionStr =
			directionStr !== "" ? `${directionStr} and outbound` : "outbound";
	}
	const isButtonDisabled = viewOnly === true || asset.autoSynchronizeEnabled;

	if (value) {
		icon = (
			<FirewallWarningIcon
				color={
					isButtonDisabled
						? theme.palette.text.disabled
						: theme.palette.warning.main
				}
				width={width}
				height={height}
			/>
		);
		tooltipText = asset.autoSynchronizeEnabled
			? window.getCTTranslatedText("Auto sync is enabled")
			: window.getCTTranslatedText("PendingFirewallTooltipText", {
					pendingStatus: window.getCTTranslatedText(directionStr),
				});
	}

	if (asset.autoSynchronizeEnabled) {
		tooltipText = window.getCTTranslatedText("Auto sync is enabled");
	}

	const isInboundSelected =
		!viewOnly &&
		facetState
			?.get("pendingattacksurfacechanges")
			?.get(Boolean(asset?.pendingAttackSurfaceChanges)?.toString())
			?.isSelected;

	const isOutboundSelected =
		!viewOnly &&
		facetState
			?.get("pendingblastradiuschanges")
			?.get(Boolean(asset?.pendingBlastRadiusChanges)?.toString())?.isSelected;

	const updateFacetOptions = () => {
		const newValue = isInboundSelected || isOutboundSelected;

		updateFacetOption({
			facetName: "pendingattacksurfacechanges",
			optionName: Boolean(asset?.pendingAttackSurfaceChanges)?.toString(),
			value: !newValue,
		});
		updateFacetOption({
			facetName: "pendingblastradiuschanges",
			optionName: Boolean(asset?.pendingBlastRadiusChanges)?.toString(),
			value: !newValue,
		});
	};

	if (!asset?.assetId) {
		return <Box style={{ width: 120 }} />;
	}

	const selectedBorder = `1px solid ${theme.palette.primary.main}`;

	return (
		<>
			{value && (
				<Tooltip title={tooltipText}>
					<Stack
						sx={{ height: "100%" }}
						alignItems="center"
						justifyContent="center"
					>
						{iconOnly ? (
							<>{icon}</>
						) : (
							<IconButton
								size="small"
								sx={{
									border:
										!viewOnly &&
										!isButtonDisabled &&
										(isInboundSelected || isOutboundSelected)
											? selectedBorder
											: undefined,
									pl: 1,
									pt: 1,
								}}
								disabled={isButtonDisabled}
								color={
									isInboundSelected || isOutboundSelected
										? "primary"
										: "inherit"
								}
								onClick={() =>
									viewOnly || isButtonDisabled ? null : updateFacetOptions()
								}
							>
								{icon}
							</IconButton>
						)}
					</Stack>
				</Tooltip>
			)}
		</>
	);
}
