import { Box, Button, Paper, Stack, useTheme } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-pro";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useCore } from "modules/core";
import { SortOrder } from "modules/core/types";
import { ColConfig } from "modules/data-grid/components/data-grid/types";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { useSearchStore } from "modules/search/store";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import { NetworkDataGrid } from "./components/network-data-grid";
import { useNetworksAPI } from "./components/network-data-grid/hooks";
import { NetworkFormDrawer } from "./components/network-form-drawer";
import { NetworkViewDrawer } from "./components/network-view-drawer";
import { FACET_GROUP_INFO } from "./constants";
import { useNetworkStore } from "./store";
import { Network } from "./types";

export const Networks = () => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const theme = useTheme();

	const coreResponse = useCore<Network>({
		useStore: useNetworkStore,
		facetGroupInfo: FACET_GROUP_INFO,
		scope: Scope.Network,
		dataMapper: network => {
			return network;
		},
		defaultSortOrder: [
			{ field: "isOOBNetwork", order: "asc" },
			{ field: "namedNetworkName", order: "asc" },
		],
		useApi: useNetworksAPI,
		pageSize: 100,
	});

	const metadata = useCommonStore(state => state.metadata);

	const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
	const setSearch = useSearchStore(state => state.setSearch);
	const [selectedNamedNetwork, setSelectedNamedNetwork] = useState<
		Network | undefined
	>(undefined);
	const [selectedNamedNetworkId, setSelectedNamedNetworkId] = useState<
		string | undefined
	>(undefined);
	const [drawerVisibility, setDrawerVisibility] = useState(false);
	const navigate = useNavigate();
	const [createDrawerVisibility, setCreateDrawerVisibility] = useState(false);

	const individualCellClickCols: ColConfig = {
		isOOBNetwork: true,
		provider: true,
		namedNetworkAssignments: true,
		ugnamedNetworkAssignments: true,
	};

	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const networkId = searchParams.get("networkId");

		if (networkId) {
			setSelectedNamedNetworkId(networkId);
			setDrawerVisibility(true);
		}
	}, [location.search]);

	useEffect(() => {
		setSearch("");
		return () => {
			setSearch("");
		};
	}, [setSearch]);

	const onCloseDrawer = (value?: boolean) => {
		navigate(ROUTES.NETWORKS.pathname);
		setSelectedNamedNetworkId(undefined);
		setDrawerVisibility(false);
	};

	const onCloseCreateDrawer = (value?: boolean) => {
		navigate(ROUTES.NETWORKS.pathname);
		setCreateDrawerVisibility(false);
	};

	const updateNetworkData = () => {
		requestAPIRefresh();
	};

	const handleOnCellClick: GridEventListener<"cellClick"> = params => {
		if (
			!individualCellClickCols[params?.field] &&
			params?.row?.namedNetworkId &&
			params.field !== "namednetworkTagBasedPolicyAssignments" &&
			params.field !== "namednetworkpolicyAssignments" &&
			params.field !== "action" &&
			params.field !== "comment"
		) {
			navigate(
				`${ROUTES.NETWORKS.pathname}?networkId=${params?.row?.namedNetworkId}`
			);
			setSelectedNamedNetworkId(params?.row?.namedNetworkId);
			setSelectedNamedNetwork(params?.row);
			setDrawerVisibility(true);
		}
	};

	const onSortChange = (sortOrder: SortOrder[]) => {
		const newSortOrder = sortOrder.map(sort => {
			if (sort.field === "oobnetwork") {
				return {
					field: "isOOBNetwork",
					order: sort.order,
				};
			}

			return sort;
		});

		coreResponse.onSortChange(newSortOrder);
	};

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction={"row"} alignItems="center">
				<Stack sx={{ flex: 1 }}>
					<FacetOpenCloseController
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							config={coreResponse?.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
						/>
					</FacetOpenCloseController>
				</Stack>

				<Stack>
					{userPermissions.has("CREATE_NAMED_NETWORK") && (
						<Button
							variant="contained"
							color="primary"
							onClick={() => setCreateDrawerVisibility(true)}
							sx={{
								ml: 2,
							}}
						>
							{window.getCTTranslatedText("Create named network")}
						</Button>
					)}
				</Stack>
			</Stack>
			<Box
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					overflowY: "auto",
					flex: 1,
					height: "100%",
				}}
			>
				<Paper sx={{ height: "100%" }}>
					<NetworkDataGrid
						initialState={{
							sorting: {
								sortModel: [
									{ field: "oobnetwork", sort: "asc" },
									{ field: "namedNetworkName", sort: "asc" },
								],
							},
						}}
						rows={coreResponse.rows}
						rowCount={coreResponse.rowCount}
						mutation={coreResponse.mutation}
						onPageChange={coreResponse.onPageChange}
						page={coreResponse.page}
						pageSize={coreResponse.pageSize}
						onPageSizeChange={coreResponse.onPageSizeChange}
						onSortChange={onSortChange}
						metadata={metadata}
						onCellClick={handleOnCellClick}
						triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
						getExportStatus={coreResponse?.getExportStatus}
						getUrlToDownload={coreResponse?.getUrlToDownload}
						resetDownloadUrl={coreResponse?.resetDownloadUrl}
						rawData={coreResponse?.rawData}
					/>
				</Paper>
				{createDrawerVisibility && (
					<NetworkFormDrawer
						isOpen={createDrawerVisibility}
						onClose={onCloseCreateDrawer}
						updateNetworkData={updateNetworkData}
						title="Create New Network"
						mode={"create"}
						btnTitle={"create"}
					/>
				)}
				{drawerVisibility && (
					<NetworkViewDrawer
						isOpen={drawerVisibility}
						onClose={onCloseDrawer}
						namedNetworkId={selectedNamedNetworkId}
						namedNetwork={selectedNamedNetwork}
					/>
				)}
			</Box>
		</Stack>
	);
};
