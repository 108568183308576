import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
	ASSET_GUARDRAILS_ENFORCEMENT_BREAKDOWN_COLUMNS,
	ASSET_GUARDRAILS_PROGRESSIVE_BREAKDOWN_COLUMNS,
} from "common/atoms/ct-guardrail/constants";
import { CTGuardrailBreakdownDateGrid } from "common/atoms/ct-guardrail/CTGuardrailBreakdownDateGrid";
import {
	PolicyChangeType,
	UnreviewedTraffic,
} from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";
import {
	BreakdownDialogFields,
	ChangeType,
	Guardrail,
	OutputData,
} from "./types";

interface BreakdownDialogProps {
	isOpen: boolean;
	onClose: () => void;
	type?: ChangeType;
	baseCriteria?: Guardrail[];
	traffic?: OutputData;
}

export const BreakdownDialog = ({
	isOpen,
	onClose,
	type,
	baseCriteria,
	traffic,
}: BreakdownDialogProps) => {
	const rowData = traffic
		? Object.entries(traffic).map(([key, data]) => ({
				[key]: data,
			}))
		: [];

	return (
		<Dialog fullWidth maxWidth="lg" open={isOpen} onClose={onClose}>
			<DialogTitle sx={{ mt: 1 }}>
				{window.getCTTranslatedText("assetWithNetworkDisruptionRisk")}
			</DialogTitle>
			<IconButton
				aria-label="close dialog"
				onClick={onClose}
				size="medium"
				sx={{
					position: "absolute",
					right: "16px",
					top: "16px",
					zIndex: 2,
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent
				sx={{
					width: "100%",
					minHeight: "600px",
					maxHeight: "80vh",
					display: "flex",
					overflow: "hidden",
					pt: 1,
				}}
			>
				<CTGuardrailBreakdownDateGrid
					columns={getFilteredColumns(type, baseCriteria, rowData)}
					rows={rowData}
					rowCount={rowData.length}
				/>
			</DialogContent>
		</Dialog>
	);
};

const hasDataForColumn = (
	rowData: { [key: string]: UnreviewedTraffic }[],
	field: string
): boolean => {
	return rowData.some(row => {
		const assetData = row[Object.keys(row)[0]];

		switch (field) {
			case BreakdownDialogFields.ProgressivePaths:
				if (assetData?.progressivePaths && assetData?.progressivePaths > 0) {
					return true;
				}
				return false;

			case BreakdownDialogFields.ProgressivePorts:
				if (assetData?.progressivePorts && assetData?.progressivePorts > 0) {
					return true;
				}
				return false;

			case BreakdownDialogFields.UnreviewedPorts:
				if (typeof assetData.ports === "object") {
					return (assetData?.ports?.unreviewed || 0) > 0;
				}
				return false;

			case BreakdownDialogFields.UnreviewedPaths:
				if (typeof assetData.paths === "object") {
					return (assetData?.paths?.unreviewed || 0) > 0;
				}
				return false;

			case BreakdownDialogFields.DeniedPorts:
				if (typeof assetData.ports === "object") {
					const { denied = 0, deniedByTemplate = 0 } = assetData.ports;
					return denied + deniedByTemplate > 0;
				}
				return false;

			case BreakdownDialogFields.DeniedPaths:
				if (typeof assetData.paths === "object") {
					const { denied = 0, deniedByTemplate = 0 } = assetData.paths;
					return denied + deniedByTemplate > 0;
				}
				return false;

			case BreakdownDialogFields.TestViolationPaths:
				if (typeof assetData.paths === "object") {
					return (assetData?.paths?.violations || 0) > 0;
				}
				return false;

			case BreakdownDialogFields.SegmentOverlap:
				if (assetData?.status) {
					return Boolean(assetData?.status);
				}
				return false;

			case BreakdownDialogFields.StateSkipped:
				if (assetData?.stateSkipped) {
					return Boolean(assetData?.stateSkipped);
				}
				return false;

			// Always keep asset name column
			case BreakdownDialogFields.AssetName:
				return true;

			default:
				return false;
		}
	});
};

const getFilteredColumns = (
	type: ChangeType | undefined,
	baseCriteria: Guardrail[] | undefined,
	rowData: { [key: string]: UnreviewedTraffic }[]
): GridColDef[] => {
	let enforcementCriteria = baseCriteria?.find(
		guardrail =>
			guardrail.id === PolicyChangeType.AttackSurfaceEnforcement ||
			guardrail.id === PolicyChangeType.BlastRadiusEnforcement
	);
	let progressiveCriteria = baseCriteria?.find(
		guardrail =>
			guardrail.id === PolicyChangeType.AttackSurfaceProgressive ||
			guardrail.id === PolicyChangeType.BlastRadiusProgressive
	);

	const columns = ASSET_GUARDRAILS_PROGRESSIVE_BREAKDOWN_COLUMNS(
		progressiveCriteria
	).concat(ASSET_GUARDRAILS_ENFORCEMENT_BREAKDOWN_COLUMNS(enforcementCriteria));

	const filteredColumns = columns.filter(column =>
		hasDataForColumn(rowData, column.field)
	);
	return filteredColumns;
};
