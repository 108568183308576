import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

import { DataGridButton } from "common/atoms/data-grid-button";
import { MoreOptionsMenu } from "common/molecules/more-options-menu/MoreOptionsMenu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { NotificationConfigDrawer } from "pages/settings/components/notification-config";
import { useState } from "react";
import { useAlertConfigStore } from "../../store";
import {
	AlertNotificationStatus,
	AlertRuleConfig,
	AlertRuleStatus,
} from "../../types";
import { AlertRuleChangeStatusDrawer } from "../alert-rule-change-status-drawer";
import { NotificationStatusChangeDrawer } from "../notification-status-change-drawer/NotificationStatusChangeDrawer";

export function AlertRuleActions({
	alertRule,
}: {
	readonly alertRule: AlertRuleConfig;
}) {
	const [showConfigureDialog, setShowConfigureDialog] = useState(false);
	const [showConfigureNotificationDrawer, setShowConfigureNotificationDrawer] =
		useState(false);
	const [selectedOperation, setSelectedOperation] = useState<
		AlertNotificationStatus | undefined
	>();
	const [showConfigureAlertRuleDrawer, setShowConfigureAlertRuleDrawer] =
		useState(false);
	const [selectedAlertRuleOperation, setSelectedAlertRuleOperation] = useState<
		AlertNotificationStatus | undefined
	>();
	const requestAPIRefresh = useAlertConfigStore(
		store => store.requestAPIRefresh
	);
	const moreActionMenuOptions: Array<MenuOption> = [];
	const [selectedEnabledAlertRuleIDs, setSelectedEnabledAlertRuleIDs] =
		useState<string[]>([]);
	const handleNotificationStatusChange = () => {
		alertRule?.notificationStatus === AlertNotificationStatus.On
			? setSelectedOperation(AlertNotificationStatus.Off)
			: setSelectedOperation(AlertNotificationStatus.On);
		setShowConfigureNotificationDrawer(true);
	};
	const handleAlertRuleStatusChange = () => {
		alertRule.alertRuleStatus === AlertRuleStatus.Enabled
			? setSelectedAlertRuleOperation(AlertNotificationStatus.Disabled)
			: setSelectedAlertRuleOperation(AlertNotificationStatus.Enabled);
		setShowConfigureAlertRuleDrawer(true);
	};
	const handleConfirm = () => {
		requestAPIRefresh();
		setShowConfigureNotificationDrawer(false);
		if (selectedOperation === AlertNotificationStatus.On)
			setShowConfigureDialog(true);
	};
	const handleAlertConfirm = () => {
		requestAPIRefresh();
		setShowConfigureAlertRuleDrawer(false);
		setSelectedAlertRuleOperation(undefined);
	};
	const redirectionHandler = () => {
		setSelectedEnabledAlertRuleIDs([alertRule.alertRuleId]);
	};

	// add buttons of more action menu
	const configureNotificationOption = {
		label: "Configure",
		handler: redirectionHandler,
	};

	if (alertRule.alertRuleStatus === AlertRuleStatus.Snoozed) {
		moreActionMenuOptions.push({
			label: "enableAlertRule",
			handler: () => {
				setSelectedAlertRuleOperation(AlertNotificationStatus.Enabled);
				setShowConfigureAlertRuleDrawer(true);
			},
		});
	}

	if (alertRule.alertRuleStatus === AlertRuleStatus.Disabled) {
		moreActionMenuOptions.push(
			{
				label: "enableAlertRule",
				handler: () => {
					handleAlertRuleStatusChange();
				},
			},
			{
				...configureNotificationOption,
				disabled: true,
				tooltipMessage: window.getCTTranslatedText("alertStepsToFollow"),
			}
		);
	} else if (alertRule.notificationStatus === AlertNotificationStatus.On) {
		moreActionMenuOptions.push(
			{
				label: "turnOffNotification",
				handler: () => {
					handleNotificationStatusChange();
				},
			},
			configureNotificationOption
		);
	} else {
		moreActionMenuOptions.push(
			{
				label: "turnOnNotification",
				handler: () => {
					handleNotificationStatusChange();
				},
			},
			configureNotificationOption
		);
	}

	if (alertRule.alertRuleStatus === AlertRuleStatus.Enabled) {
		moreActionMenuOptions.push({
			label: "disableAlertRule",
			handler: () => {
				handleAlertRuleStatusChange();
			},
		});
	}

	if (alertRule.alertRuleStatus === AlertRuleStatus.Snoozed) {
		moreActionMenuOptions.push({
			label: "disableAlertRule",
			handler: () => {
				setSelectedAlertRuleOperation(AlertNotificationStatus.Disabled);
				setShowConfigureAlertRuleDrawer(true);
			},
		});
	}

	return (
		<>
			<MoreOptionsMenu
				menuOptions={moreActionMenuOptions}
				toolTipPlacement="left"
			/>
			{showConfigureDialog && (
				<ConfigureRuleDialog
					onConfirm={redirectionHandler}
					onClose={() => {
						setShowConfigureDialog(false);
					}}
				/>
			)}
			{showConfigureNotificationDrawer && (
				<NotificationStatusChangeDrawer
					isOpen={showConfigureNotificationDrawer}
					selectedStatus={selectedOperation}
					selectedData={[alertRule]}
					title={`${window.getCTTranslatedText("changeNotificationStatus")}`}
					confirm={handleConfirm}
					onClose={() => {
						setShowConfigureNotificationDrawer(false);
						setSelectedOperation(undefined);
					}}
				/>
			)}
			{showConfigureAlertRuleDrawer && (
				<AlertRuleChangeStatusDrawer
					isOpen={showConfigureAlertRuleDrawer}
					selectedStatus={selectedAlertRuleOperation}
					selectedData={[alertRule]}
					title={`${window.getCTTranslatedText("changeAlertRuleStatus")}`}
					confirm={handleAlertConfirm}
					onClose={() => {
						setShowConfigureAlertRuleDrawer(false);
						setSelectedOperation(undefined);
					}}
				/>
			)}

			<NotificationConfigDrawer
				isOpen={Boolean(selectedEnabledAlertRuleIDs.length)}
				onClose={() => setSelectedEnabledAlertRuleIDs([])}
				alertRules={selectedEnabledAlertRuleIDs}
			/>
		</>
	);
}

interface ConfigureRuleDialogProps {
	onClose: VoidFunction;
	onConfirm: (data: string[]) => void;
	data?: AlertRuleConfig[];
}
export function ConfigureRuleDialog({
	onClose,
	onConfirm,
	data = [],
}: Readonly<ConfigureRuleDialogProps>) {
	return (
		<Dialog
			open={true}
			onClose={() => {
				onClose();
			}}
			aria-labelledby="configure-notificaiton-mediums"
		>
			<DialogTitle id="configure-notificaiton-mediums">
				{window.getCTTranslatedText("Configure")}
			</DialogTitle>
			<DialogContent>
				{window.getCTTranslatedText("notificationTurnedOnForAlert") ?? ""}
				{window.getCTTranslatedText("configureMediumsForAlert") ?? ""}
			</DialogContent>
			<DialogActions>
				<DataGridButton
					variant="text"
					onClick={() => {
						onClose();
					}}
				>
					{window.getCTTranslatedText("Cancel")}
				</DataGridButton>
				<DataGridButton
					variant="text"
					color="primary"
					onClick={() => {
						onConfirm(data?.map(d => d.alertRuleId));
						onClose();
					}}
				>
					{window.getCTTranslatedText("Configure")}
				</DataGridButton>
			</DialogActions>
		</Dialog>
	);
}
