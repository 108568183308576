import { Box } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { UnmanagedDevice } from "../../appliance-unmanaged-devices/types";
import { VulnerabilityTypeFacets } from "../types";

interface UnmanagedDeviceVulnerabilityAvailabilityProps {
	device?: UnmanagedDevice;
	vulnerabilityType: keyof UnmanagedDevice;
	viewOnly?: boolean;
}

export function UnmanagedDeviceVulnerabilityAvailability({
	device,
	vulnerabilityType,
	viewOnly,
}: UnmanagedDeviceVulnerabilityAvailabilityProps) {
	if (
		device?.[vulnerabilityType] === null ||
		device?.[vulnerabilityType] === undefined ||
		device?.[vulnerabilityType] === "no value"
	) {
		return <Box style={{ width: 120 }} />;
	}
	const facetOption = String(device?.[vulnerabilityType] ?? "");
	return (
		<CTDatagridButton
			asText={viewOnly === true}
			isDisabled={viewOnly === true}
			facetName={
				VulnerabilityTypeFacets[
					vulnerabilityType as keyof typeof VulnerabilityTypeFacets
				]
			}
			facetOption={facetOption}
		>
			{window.getCTTranslatedText(facetOption)}
		</CTDatagridButton>
	);
}
