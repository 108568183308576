import { getProductTenants } from "../../helpers";
import { useTenantListAPI } from "../../hooks";
import { TenantSwitchButton } from "./TenantSwitchButton";

function OrganizationsMenuItems() {
	const { data } = useTenantListAPI();
	const tenants = getProductTenants(data?.tenants || []);

	return (
		data &&
		[...tenants]
			?.sort((a, b) => {
				const current = data.current.tenantID;
				if (a.tenantID === current) {
					return -1;
				} else if (b.tenantID === current) {
					return 1;
				}
				return a?.name && b?.name ? a.name.localeCompare(b.name) : -1;
			})
			.map(tenant => (
				<TenantSwitchButton
					key={tenant.tenantID}
					active={tenant.tenantID === data.current.tenantID}
					tenant={tenant}
				/>
			))
	);
}

export default OrganizationsMenuItems;
