import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";

export function GuardrailCheckbox({
	acknowledgement,
	setAcknowledgement,
}: {
	acknowledgement: boolean;
	setAcknowledgement: (value: boolean) => void;
}) {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={acknowledgement}
					onChange={e => setAcknowledgement(!acknowledgement)}
				/>
			}
			label={
				<Stack spacing={0.5}>
					<Typography variant="body2" sx={{ mt: 1, textDecoration: "italic" }}>
						{window.getCTTranslatedText(
							"I understand the potential impacts and risks of proceeding. I have consulted with an Xshield expert."
						)}
					</Typography>
					<Typography variant="caption" sx={{ mt: 1 }}>
						{window.getCTTranslatedText("This change will be audited")}
					</Typography>
				</Stack>
			}
			sx={{ mt: 2, gap: 1 }}
		/>
	);
}
