import { Stack, Typography } from "@mui/material";
import React from "react";

export type TextORElement = string | React.ReactElement;

export function CTJoinedText({ items }: { items: TextORElement[] }) {
	return (
		<Stack
			direction="row"
			spacing={0.6}
			alignItems="center"
			sx={{
				flexWrap: "wrap",
				"& > *": { whiteSpace: "normal" },
			}}
		>
			{items.map((item, index) => (
				<React.Fragment key={index}>
					{typeof item === "string" ? (
						<Typography variant="body2" sx={{ display: "inline" }}>
							{index > 0 ? ` ${item}` : item}
						</Typography>
					) : (
						item
					)}
				</React.Fragment>
			))}
		</Stack>
	);
}
