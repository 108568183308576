export enum MetadataItemType {
	Tag = "tag",
	Text = "text",
	BusinessValue = "businessValue",
}

export type MetadataItem<T extends MetadataItemType> = {
	key: string;
	rhfKey: string;
	label: string;
	value: string;
	type: T;
};

export type MetadataTag = MetadataItem<
	MetadataItemType.Tag | MetadataItemType.BusinessValue
> & {
	isCoreTagEditable: boolean;
	isUserDefinedTag?: boolean;
	icon: JSX.Element;
};

export type MetadataName = MetadataItem<MetadataItemType.Text> & {
	icon?: JSX.Element;
};
