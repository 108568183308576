export enum VulnerabilityTypes {
	Vulnerabilities = "Vulnerabilities",
	KnownExploit = "knownExploitVulnerability",
	LateralMovement = "lateralMovementVulnerability",
}

export enum VulnerabilityTypeFacets {
	lateralMovementVulnerability = "lateralmovementvulnerability",
	knownExploitVulnerability = "knownexploitvulnerability",
}
