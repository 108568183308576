import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { CTUser } from "pages/settings/components/organization/types";
import { useDeleteUser } from "./hooks/use-update-role";

const dialogMinWidth = 300;

interface DeleteUserProps {
	user: CTUser;
	handleClose: () => void;
	allowDelete: boolean;
}

export function DeleteUser({
	user,
	allowDelete,
	handleClose,
}: DeleteUserProps) {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const queryClient = useQueryClient();
	const deleteRoleMutation = useDeleteUser(user);

	const deleteUser = async () => {
		if (user?.userId) {
			const body = {};

			await deleteRoleMutation.mutateAsync(body, {
				onSuccess: response => {
					queryClient.invalidateQueries({
						queryKey: ["auth"],
					});
					queryClient.invalidateQueries({
						queryKey: ["teamMembers"],
					});
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"UserDeletedSuccessfully",
						{ name: user?.name }
					);
					handleClose();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	return (
		<>
			<Dialog open={allowDelete} onClose={handleClose}>
				<DialogTitle>{`Confirm`}</DialogTitle>
				<DialogContent>
					<Box sx={{ minWidth: dialogMinWidth }}>
						<Stack alignItems="center" justifyContent="center">
							<Typography variant="body1">
								{`Are you sure you want to delete user "${user?.name}"?`}
							</Typography>
						</Stack>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" onClick={handleClose}>
						Cancel
					</Button>
					<LoadingButton
						loading={deleteRoleMutation.isLoading}
						onClick={deleteUser}
						autoFocus
						color={"error"}
						type="submit"
						variant="contained"
					>
						Delete
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
