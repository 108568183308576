import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { TargetingSegmentsDrawer } from "modules/targeting-segments-drawer";
import numeral from "numeral";
import { NetworkColumnRenderProps } from "pages/networks/types";
import pluralize from "pluralize";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

interface UsedByPoliciesProps extends NetworkColumnRenderProps {
	openInNewTab?: boolean;
}

export function UsedByTagBasedPolicy({
	network,
	viewOnly,
	openInNewTab,
}: UsedByPoliciesProps) {
	const [showTargettedSegmentsDrawer, setShowTargettedSegmentsDrawer] =
		useState(false);

	if (!network.namedNetworkId) {
		return <Box style={{ minWidth: 120, minHeight: 45 }} />;
	}

	return (
		<Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
			<Stack alignItems="flex-start">
				{network?.namednetworkTagBasedPolicyAssignments === 0 ? (
					<Typography variant="body2">
						{numeral(
							network?.namednetworkTagBasedPolicyAssignments || 0
						).format("0a")}{" "}
						{`${window.getCTTranslatedText(
							pluralize(
								"segment",
								Number(network?.namednetworkTagBasedPolicyAssignments)
							)
						)}`}
					</Typography>
				) : (
					<Tooltip title={window.getCTTranslatedText("ViewSegment")}>
						<Link
							typography={"body2"}
							component={RouterLink}
							to={"#"}
							sx={{ color: "primary.main" }}
							target={openInNewTab ? "_blank" : undefined}
							rel={openInNewTab ? "noopener noreferrer" : undefined}
							onClick={() => {
								setShowTargettedSegmentsDrawer(true);
							}}
						>
							{numeral(
								network?.namednetworkTagBasedPolicyAssignments || 0
							).format("0a")}{" "}
							{`${window.getCTTranslatedText(
								pluralize(
									"segment",
									Number(network?.namednetworkTagBasedPolicyAssignments)
								)
							)}`}
						</Link>
					</Tooltip>
				)}

				<TargetingSegmentsDrawer
					open={showTargettedSegmentsDrawer}
					onClose={() => setShowTargettedSegmentsDrawer(false)}
					criteria={`'namednetworkid' in ('${network.namedNetworkId}')`}
					subtitle={`${window.getCTTranslatedText("Named Network")}: ${network.namedNetworkName}`}
				/>
			</Stack>
		</Stack>
	);
}
