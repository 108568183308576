import { Box, Drawer } from "@mui/material";
import { PendingWorkRequestsDataGrid } from "./components/PendingWorkRequestDataGrid";
import { PendingWorkRequestLocationType } from "./constants";

interface PendingWorkRequestsDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	resourceID?: string;
	componentType?: PendingWorkRequestLocationType;
}

export const PendingWorkRequestsDrawer = ({
	isOpen,
	onClose,
	resourceID,
	componentType = PendingWorkRequestLocationType.DEFAULT,
}: PendingWorkRequestsDrawerProps) => {
	return (
		<Box onClick={e => e.stopPropagation()}>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={onClose}
				PaperProps={{
					sx: {
						padding: 3,
						width: "70%",
						overflow: "hidden",
					},
				}}
			>
				<PendingWorkRequestsDataGrid
					onClose={onClose}
					resourceID={resourceID}
					componentType={componentType}
				/>
			</Drawer>
		</Box>
	);
};
