import { convertCriteriaToUrlParam } from "pages/asset/components/asset-detail/helpers/criteriaBuilder";
import { PathStatus } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";

const getAssetCriteria = (criteria: string, assetname?: string) => {
	if (assetname === undefined) {
		return criteria;
	}

	const baseParams = `assetname IN ('${assetname}')`;

	if (criteria) {
		return `${baseParams} AND ${criteria}`;
	}

	return baseParams;
};

export const getAssetBreakdownLinks = ({
	type,
	status,
	baseCriteria,
	assetName,
}: {
	type: "ports" | "paths";
	status?: PortStatus | PathStatus;
	baseCriteria?: string;
	assetName?: string;
}) => {
	let criteria = baseCriteria ? baseCriteria + " AND " : "";

	if (type === "ports") {
		switch (status) {
			case PortStatus.Unreviewed:
				criteria += `listenportreviewed IN ('${PortStatus.Unreviewed}') AND 'listenportenforced' not in ('${PortStatus.Deny}','${PortStatus.DeniedByTemplate}')`;
				break;
			case PortStatus.Deny:
				criteria += `listenportreviewed IN ('${PortStatus.Deny}','${PortStatus.DeniedByTemplate}') AND 'listenportenforced' not in ('${PortStatus.Deny}','${PortStatus.DeniedByTemplate}')`;
				break;
			default:
				break;
		}
	} else {
		switch (status) {
			case PathStatus.Unreviewed:
				criteria += `reviewed IN ('${PathStatus.Unreviewed}') AND 'enforced' not in ('${PathStatus.Deny}','${PathStatus.DeniedByTemplate}')`;
				break;
			case PathStatus.Deny:
				criteria += `reviewed IN ('${PathStatus.Deny}','${PathStatus.DeniedByTemplate}') AND 'enforced' not in ('${PathStatus.Deny}','${PathStatus.DeniedByTemplate}')`;
				break;
			case PathStatus.AllowTestDeniedViolation:
				criteria += `enforced IN ('${PathStatus.AllowTestDeniedViolation}')`;
				break;
			default:
				break;
		}
	}

	const updatedCriteria = getAssetCriteria(criteria, assetName);

	return `/${type}?filters=${encodeURIComponent(convertCriteriaToUrlParam(updatedCriteria, type))}`;
};
