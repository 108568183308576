import { PORTLESS_PROTOCOLS, Path, PathDirection } from "pages/paths/types";
import { Port, PortStatus } from "pages/ports/types";
import { PortList } from "pages/templates/components/template-form-drawer/components/template-form/types";
import {
	TemplatePath,
	TemplatePort,
	TemplateType,
} from "pages/templates/types";

export const shouldSuggestRestrictPortToAllowedPathsForPorts = (
	port: Port,
	templateType?: TemplateType,
	pathList?: Array<TemplatePath>
) => {
	if (
		!port ||
		templateType === TemplateType.BlockTemplate ||
		port?.listenPort?.toLowerCase() === PortList.ANY ||
		PORTLESS_PROTOCOLS.includes(port?.listenPortProtocol.toUpperCase()) ||
		port?.listenPortReviewed === PortStatus.PathRestricted
	) {
		return false;
	}

	if (
		port &&
		pathList?.find?.(
			(path: TemplatePath) =>
				`${port?.listenPort} ${port?.listenPortProtocol}` ===
				`${path?.port} ${path?.protocol}`
		)
	) {
		return true;
	}

	return false;
};

export const shouldSuggestRestrictPortToAllowedPathsForPaths = (
	path: Path,
	templateType?: TemplateType,
	portList?: Array<TemplatePort>
) => {
	if (
		templateType === TemplateType.BlockTemplate ||
		path?.port?.toLowerCase() === PortList.ANY ||
		PORTLESS_PROTOCOLS.includes(path?.protocol?.toUpperCase()) ||
		path?.direction !== PathDirection.Inbound
	) {
		return false;
	}

	if (
		portList?.every?.(
			(port: TemplatePort) =>
				`${port?.listenPort} ${port?.listenPortProtocol}` !==
				`${path?.port} ${path?.protocol}`
		)
	) {
		return true;
	}

	if (
		portList?.find?.(
			(port: TemplatePort) =>
				`${port?.listenPort} ${port?.listenPortProtocol}` ===
					`${path?.port} ${path?.protocol}` &&
				port?.listenPortReviewed !== PortStatus.PathRestricted
		)
	) {
		return true;
	}

	return false;
};

export function getDestinationSortingName(row: TemplatePath): string {
	let name = "";

	if (row?.destinationTagBasedPolicy) {
		return (name = row?.destinationTagBasedPolicy?.criteria ?? "");
	}
	if (row?.destinationAsset?.assetName) {
		return (name = row.destinationAsset.assetName);
	}
	if (row.dstIp?.length && row.dstIp?.[0] !== "") {
		return (name = row.dstIp?.[0]);
	}
	if (row?.destinationNamedNetwork?.namedNetworkName) {
		return (name = row.destinationNamedNetwork.namedNetworkName);
	}
	if (row.domain) {
		return (name = row.domain);
	}

	return name?.toLowerCase();
}

export function getSourceSortingName(row: TemplatePath): string {
	let name = "";

	if (row?.sourceTagBasedPolicy) {
		return (name = row?.sourceTagBasedPolicy?.criteria ?? "");
	}
	if (row?.sourceAsset?.assetName) {
		return (name = row.sourceAsset.assetName);
	}
	if (row?.srcIp && row.srcIp !== "NIL") {
		return (name = row.srcIp);
	}
	if (row?.sourceNamedNetwork?.namedNetworkName) {
		return (name = row.sourceNamedNetwork.namedNetworkName);
	}

	return name?.toLowerCase();
}

export const parsePortValue = (port: string) => {
	if (port.toLowerCase() === "any") {
		return -1;
	} else if (!isNaN(Number(port))) {
		return Number(port);
	} else if (port.includes("-")) {
		return Number(port.split("-")[0]);
	}
	return Infinity;
};
