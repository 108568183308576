import CloseIcon from "@mui/icons-material/Close";
import {
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { CTGuardrail } from "common/atoms/ct-guardrail";
import { useGuardrailsStore } from "common/atoms/ct-guardrail/store";
import {
	Guardrail,
	OutputData,
	SelectedValues,
} from "common/atoms/ct-guardrail/types";
import { Toolbar } from "common/atoms/toolbar";
import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	AssetStatusMap,
	AssetStatusReverseMap,
	Direction,
	SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { AssetStatus } from "pages/assets/types";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { PathStatus } from "pages/paths/types";
import {
	PortStatus,
	ProgressiveEnforcementLevel,
	ProgressiveEnforcementStatus,
	ProgressiveEnforcementStatusMap,
	ProgressiveEnforcementStatusReverseMap,
	ProgressiveOutboundPortEnforcementLevel,
	ProgressiveOutboundPortEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatusMap,
} from "pages/ports/types";
import { useCallback, useEffect, useState } from "react";
import { TagPolicy } from "../../types";
import { TagPolicyCOnfirmationDialog } from "../tag-policy-confirmation-dialog";
import { PolicyAutomationActions } from "./components/policy-automation-actions";
import { PolicyAutomationGenerator } from "./components/policy-automation-generator";
import {
	useAddAutoPolicyConfigAPI,
	useTagBasedPolicyAutoPushAPI,
} from "./hooks";
import {
	PolicyChangeType,
	PolicyChangeValues,
	PolicyUnreviewedTraffic,
	Traffic,
	TrafficCriteria,
	TrafficType,
	UnreviewedTraffic,
} from "./types";
import {
	buildPathsCriteria,
	buildPortsCriteria,
	combineCriteria,
} from "./utils";

interface PolicyAutomationDrawerProps {
	isOpen: boolean;
	onClose: (refresh: boolean) => void;
	id: string;
	criteria?: string;
	isZeroTrustAutomationEditable?: boolean;
	policy?: TagPolicy;
	showDialog?: boolean;
}

interface CurrentStatus {
	[key: string]:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel
		| SecurityStatus;
	progressiveInbound: ProgressiveEnforcementLevel;
	progressiveOutbound: ProgressiveOutboundPortEnforcementLevel;
	attackSurface: SecurityStatus;
	blastRadius: SecurityStatus;
}

export const getEnforcementStatus = (
	selectedStatus: SecurityStatus,
	isTest: boolean
) => {
	const status = AssetStatusReverseMap[selectedStatus];
	if (!isTest || selectedStatus === SecurityStatus.Unsecure) {
		return status;
	}
	return `simulate-${status}` as AssetStatus;
};

const getProgressiveEnforcementStatus = (
	selectedStatus:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel
) => {
	return ProgressiveEnforcementStatusReverseMap[selectedStatus];
};

const isTestMode = (status?: string) => {
	return !status || status.includes("simulate-") || status.includes("unsecure");
};

export const PolicyAutomationDrawer = ({
	isOpen,
	onClose,
	id,
	criteria,
	isZeroTrustAutomationEditable = true,
	policy,
	showDialog = false,
}: PolicyAutomationDrawerProps) => {
	const { isFeatureEnabled: isProgressiveEnabled } = useFeatureFlagControl(
		FEATURES.PROGRESSIVE
	);
	const { isFeatureEnabled: isProgressiveOutboundV1Enabled } =
		useFeatureFlagControl(FEATURES.PROGRESSIVE_OUTBOUND_V1);
	const { data, isLoading } = useTagBasedPolicyAutoPushAPI(id);
	const updateDeployMutation = useAddAutoPolicyConfigAPI(id);

	const managedBy = policy?.managedBy ?? ManagedByValue.ColorTokens;

	const [selectedAttackSurfaceStatus, setSelectedAttackSurfaceStatus] =
		useState<SecurityStatus>(SecurityStatus.Unsecure);
	const [
		selectedProgressiveInboundEnforcementLevel,
		setSelectedProgressiveInboundEnforcementLevel,
	] = useState<ProgressiveEnforcementLevel>(
		ProgressiveEnforcementLevel.ZeroTrust
	);
	const [
		selectedProgressiveOutboundEnforcementLevel,
		setSelectedProgressiveOutboundEnforcementLevel,
	] = useState<ProgressiveOutboundPortEnforcementLevel>(
		ProgressiveOutboundPortEnforcementLevel.ZeroTrust
	);
	const [selectedBlastRadiusStatus, setSelectedBlastRadiusStatus] =
		useState<SecurityStatus>(SecurityStatus.Unsecure);
	const [autoPush, setAutoPush] = useState<boolean>(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const [attackSurfaceTestMode, setAttackSurfaceTestMode] = useState(
		isTestMode(data?.lowestInboundPolicyStatus)
	);
	const [blastRadiusTestMode, setBlastRadiusTestMode] = useState(
		isTestMode(data?.lowestOutboundPolicyStatus)
	);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [guardrails, setGuardrails] = useState<Guardrail[]>([]);
	const [unreviewedTraffic, setUnreviewedTraffic] =
		useState<PolicyUnreviewedTraffic>();
	const [trafficCriteria, setTrafficCriteria] = useState<TrafficCriteria>();
	const [trafficReviewed, setTrafficReviewed] = useState<boolean>(false);
	const [isTrafficLoading, setIsTrafficLoading] = useState<boolean>(false);

	const setGuardrailTraffic = useGuardrailsStore(
		state => state.setGuardrailTraffic
	);
	const guardrailTraffic = useGuardrailsStore(state => state.guardrailTraffic);

	const shouldShowProgressiveStatus = (
		direction: Direction,
		selectedProgressiveEnforcementLevel?:
			| ProgressiveOutboundPortEnforcementLevel
			| ProgressiveEnforcementLevel
	) => {
		return (
			isProgressiveEnabled &&
			(direction === Direction.Inbound || isProgressiveOutboundV1Enabled) &&
			selectedProgressiveEnforcementLevel !== undefined
		);
	};

	const getCurrentStatus = useCallback(
		(): CurrentStatus => ({
			progressiveInbound:
				ProgressiveEnforcementStatusMap[
					data?.lowestProgressiveInboundPolicyStatus as ProgressiveEnforcementStatus
				] ?? ProgressiveEnforcementStatus.ZeroTrust,
			progressiveOutbound:
				ProgressiveOutboundPortEnforcementStatusMap[
					data?.lowestProgressiveOutboundPolicyStatus as ProgressiveOutboundPortEnforcementStatus
				] ?? ProgressiveOutboundPortEnforcementStatus.ZeroTrust,
			attackSurface:
				AssetStatusMap[
					(data?.lowestInboundPolicyStatus as AssetStatus) ??
						AssetStatus.Unsecured
				],
			blastRadius:
				AssetStatusMap[
					(data?.lowestOutboundPolicyStatus as AssetStatus) ??
						AssetStatus.Unsecured
				],
		}),
		[
			data?.lowestProgressiveInboundPolicyStatus,
			data?.lowestProgressiveOutboundPolicyStatus,
			data?.lowestInboundPolicyStatus,
			data?.lowestOutboundPolicyStatus,
		]
	);

	useEffect(() => {
		const currentStatus = getCurrentStatus();

		setAttackSurfaceTestMode(isTestMode(data?.lowestInboundPolicyStatus));
		setBlastRadiusTestMode(isTestMode(data?.lowestOutboundPolicyStatus));

		setSelectedAttackSurfaceStatus(currentStatus.attackSurface);
		setSelectedBlastRadiusStatus(currentStatus.blastRadius);
		setSelectedProgressiveInboundEnforcementLevel(
			currentStatus.progressiveInbound
		);
		setSelectedProgressiveOutboundEnforcementLevel(
			currentStatus.progressiveOutbound
		);
		setAutoPush(data?.autoSynchronizeEnabled ?? false);
	}, [
		data?.lowestInboundPolicyStatus,
		data?.autoSynchronizeEnabled,
		data?.lowestOutboundPolicyStatus,
		data?.lowestProgressiveInboundPolicyStatus,
		data?.lowestProgressiveOutboundPolicyStatus,
		getCurrentStatus,
	]);

	useEffect(() => {
		setTrafficReviewed(false);
		setUnreviewedTraffic(undefined);
	}, [
		selectedAttackSurfaceStatus,
		selectedBlastRadiusStatus,
		attackSurfaceTestMode,
		blastRadiusTestMode,
		selectedProgressiveInboundEnforcementLevel,
		selectedProgressiveOutboundEnforcementLevel,
		autoPush,
	]);

	useEffect(() => {
		const trafficCriteriaLength = Object.keys(trafficCriteria ?? {}).length;
		if (
			trafficCriteriaLength > 0 &&
			Object.keys(unreviewedTraffic ?? {}).length === trafficCriteriaLength
		) {
			const guardrails: Guardrail[] = [];
			Object.keys(unreviewedTraffic ?? {}).forEach(key => {
				if (
					trafficCriteria?.[key as PolicyChangeType] &&
					unreviewedTraffic?.[key as PolicyChangeType]?.assets
				) {
					guardrails.push({
						id: key as PolicyChangeType,
						baseCriteria: trafficCriteria[key as PolicyChangeType]!,
					});
				}
			});
			setIsTrafficLoading(false);
			if (guardrails.length > 0) {
				setGuardrails(guardrails);
				return;
			}
			setShowConfirmationModal(true);
		}
	}, [unreviewedTraffic, trafficCriteria]);

	const resetGuardrails = () => {
		setGuardrails([]);
		setUnreviewedTraffic(undefined);
		setTrafficReviewed(false);
		setTrafficCriteria(undefined);
		setGuardrailTraffic({});
	};

	const updateUnreviewedTraffic = (
		policyChangeId: PolicyChangeType,
		traffic: UnreviewedTraffic
	) => {
		if (unreviewedTraffic?.[policyChangeId]?.assets !== traffic?.assets) {
			setUnreviewedTraffic(prev => ({ ...prev, [policyChangeId]: traffic }));
		}
	};

	const submitConfigForDeployMode = () => {
		const isZeroTrustProgressive =
			selectedProgressiveInboundEnforcementLevel ===
			ProgressiveEnforcementLevel.ZeroTrust;
		// const isAnyProgressive =
		// 	selectedProgressiveInboundEnforcementLevel ===
		// 	ProgressiveEnforcementLevel.Any;

		const attackSurfaceStatus =
			!isZeroTrustAutomationEditable && isZeroTrustProgressive
				? SecurityStatus.Unsecure
				: selectedAttackSurfaceStatus;

		const body = {
			lowestInboundPolicyStatus: getEnforcementStatus(
				attackSurfaceStatus,
				attackSurfaceTestMode
			),
			lowestOutboundPolicyStatus: getEnforcementStatus(
				selectedBlastRadiusStatus,
				blastRadiusTestMode
			),
			autoSynchronizeEnabled: autoPush,
			lowestProgressiveInboundPolicyStatus: isProgressiveEnabled
				? getProgressiveEnforcementStatus(
						selectedProgressiveInboundEnforcementLevel
					)
				: undefined,
			lowestProgressiveOutboundPolicyStatus: isProgressiveOutboundV1Enabled
				? getProgressiveEnforcementStatus(
						selectedProgressiveOutboundEnforcementLevel
					)
				: undefined,
		};

		updateDeployMutation.mutateAsync(body, {
			onSuccess: () => {
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"ConfigurationSavedSuccessfully"
				);
				onClose(true);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const onSubmit = async () => {
		if (!trafficReviewed) {
			getPolicyTraffic();
			setTrafficReviewed(true);
			return;
		}
	};

	const getPolicyTraffic = async () => {
		let criteria: TrafficCriteria = {};
		if (
			shouldShowProgressiveStatus(
				Direction.Inbound,
				selectedProgressiveInboundEnforcementLevel
			) &&
			selectedProgressiveInboundEnforcementLevel !==
				ProgressiveEnforcementLevel.AllowAll &&
			selectedProgressiveInboundEnforcementLevel !==
				ProgressiveEnforcementLevel.Any
		) {
			criteria[PolicyChangeType.AttackSurfaceProgressive] = getCriteria(
				PolicyChangeType.AttackSurfaceProgressive,
				selectedProgressiveInboundEnforcementLevel
			);
		}

		const attackSurfaceStatus = getEnforcementStatus(
			selectedAttackSurfaceStatus,
			attackSurfaceTestMode
		);
		if (
			selectedProgressiveInboundEnforcementLevel !==
				ProgressiveEnforcementLevel.ZeroTrust &&
			attackSurfaceStatus !== AssetStatus.Unsecured &&
			attackSurfaceStatus !== AssetStatus.SimulateSecureInternet
		) {
			criteria[PolicyChangeType.AttackSurfaceEnforcement] = getCriteria(
				PolicyChangeType.AttackSurfaceEnforcement,
				data?.lowestInboundPolicyStatus ??
					(AssetStatus.Unsecured as AssetStatus)
			);
		}

		if (
			shouldShowProgressiveStatus(
				Direction.Outbound,
				selectedProgressiveOutboundEnforcementLevel
			) &&
			selectedProgressiveOutboundEnforcementLevel !==
				ProgressiveOutboundPortEnforcementLevel.Any
		) {
			criteria[PolicyChangeType.BlastRadiusProgressive] = getCriteria(
				PolicyChangeType.BlastRadiusProgressive,
				selectedProgressiveOutboundEnforcementLevel
			);
		}

		const blastRadiusStatus = getEnforcementStatus(
			selectedBlastRadiusStatus,
			blastRadiusTestMode
		);
		if (
			blastRadiusStatus !== AssetStatus.Unsecured &&
			blastRadiusStatus !== AssetStatus.SimulateSecureInternet
		) {
			criteria[PolicyChangeType.BlastRadiusEnforcement] = getCriteria(
				PolicyChangeType.BlastRadiusEnforcement,
				data?.lowestOutboundPolicyStatus ??
					(AssetStatus.Unsecured as AssetStatus)
			);
		}
		if (Object.keys(criteria).length > 0) {
			setTrafficCriteria(criteria);
			setIsTrafficLoading(true);
		} else {
			setShowConfirmationModal(true);
		}
	};

	const getCriteria = (
		changeType: PolicyChangeType,
		selectedLevel: PolicyChangeValues
	) => {
		let trafficCriteria: Traffic = {};

		if (changeType !== PolicyChangeType.AttackSurfaceProgressive) {
			const pathsCriteria = getTrafficCriteria(
				changeType,
				selectedLevel,
				TrafficType.Path
			);
			trafficCriteria.paths = pathsCriteria;
		}

		if (
			[
				PolicyChangeType.AttackSurfaceProgressive,
				PolicyChangeType.AttackSurfaceEnforcement,
			].includes(changeType)
		) {
			const portsCriteria = getTrafficCriteria(
				changeType,
				selectedLevel,
				TrafficType.Port
			);

			trafficCriteria.ports = portsCriteria;
		}

		trafficCriteria.assets = data?.criteria;
		return trafficCriteria;
	};

	const updateAggregateTraffic = (
		id: PolicyChangeType,
		traffic?: OutputData
	) => {
		if (!guardrailTraffic?.[id] && traffic) {
			const updatedTraffic = { ...guardrailTraffic, [id]: traffic };
			setGuardrailTraffic(updatedTraffic);
		}
	};

	const getTrafficCriteria = (
		changeType: PolicyChangeType,
		selectedLevel: PolicyChangeValues,
		trafficType: TrafficType,
		statusType?: PortStatus | PathStatus
	) => {
		const baseCriteria = `${data?.criteria}`;
		const direction = [
			PolicyChangeType.AttackSurfaceProgressive,
			PolicyChangeType.AttackSurfaceEnforcement,
		].includes(changeType)
			? "inbound"
			: "outbound";

		if (trafficType === TrafficType.Port) {
			return combineCriteria(
				baseCriteria,
				buildPortsCriteria(changeType, selectedLevel)
			);
		}

		const directionCriteria = `direction in ('${direction}')`;
		return combineCriteria(
			combineCriteria(baseCriteria, directionCriteria),
			buildPathsCriteria(changeType, selectedLevel)
		);
	};

	const selectedValues: SelectedValues = {
		[PolicyChangeType.AttackSurfaceProgressive]:
			getProgressiveEnforcementStatus(
				selectedProgressiveInboundEnforcementLevel
			),
		[PolicyChangeType.AttackSurfaceEnforcement]:
			AssetStatusReverseMap[selectedAttackSurfaceStatus],
		[PolicyChangeType.BlastRadiusProgressive]: getProgressiveEnforcementStatus(
			selectedProgressiveOutboundEnforcementLevel
		) as unknown as ProgressiveOutboundPortEnforcementStatus,
		[PolicyChangeType.BlastRadiusEnforcement]:
			AssetStatusReverseMap[selectedBlastRadiusStatus],
	};

	const currentStatuses = {
		[PolicyChangeType.AttackSurfaceEnforcement]:
			data?.lowestInboundPolicyStatus as AssetStatus,
		[PolicyChangeType.AttackSurfaceProgressive]:
			data?.lowestProgressiveInboundPolicyStatus as ProgressiveEnforcementStatus,
		[PolicyChangeType.BlastRadiusEnforcement]:
			data?.lowestOutboundPolicyStatus as AssetStatus,
		[PolicyChangeType.BlastRadiusProgressive]:
			data?.lowestProgressiveOutboundPolicyStatus as ProgressiveOutboundPortEnforcementStatus,
	};

	return (
		<>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={onClose}
				PaperProps={{
					sx: {
						padding: "0px",
						width: "40%",
						height: "100%",
					},
				}}
			>
				<Toolbar />
				<Stack
					alignItems="flex-start"
					sx={{ position: "relative", width: "100%" }}
				>
					<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
						<IconButton
							size="medium"
							aria-label="close drawer"
							onClick={() => onClose(false)}
							sx={{
								position: "absolute",
								right: "16px",
								top: "16px",
								zIndex: 2,
							}}
						>
							<CloseIcon fontSize="medium" />
						</IconButton>
					</Tooltip>
				</Stack>
				<Stack direction="column" spacing={2} sx={{ m: 4 }}>
					<Typography variant="h5">
						<b>{window.getCTTranslatedText("Policy Automation")}</b>
					</Typography>
				</Stack>
				<DialogContent sx={{ display: "flex", flexDirection: "column", pt: 0 }}>
					<PolicyAutomationGenerator
						autoPush={autoPush}
						setAutoPush={setAutoPush}
						assetCount={data?.matchingAssets}
						updateUnreviewedTraffic={updateUnreviewedTraffic}
						trafficReviewed={trafficReviewed}
						isInboundSimulated={attackSurfaceTestMode}
						isOutboundSimulated={blastRadiusTestMode}
						onChangeAttackSurfaceTestMode={setAttackSurfaceTestMode}
						onChangeBlastRadiusTestMode={setBlastRadiusTestMode}
						selectedBlastRadiusStatus={selectedBlastRadiusStatus}
						setSelectedBlastRadiusStatus={(status: SecurityStatus) => {
							setSelectedBlastRadiusStatus(status);
							setBlastRadiusTestMode(true);
						}}
						initialAttackSurfaceStatus={SecurityStatus.Unsecure}
						selectedAttackSurfaceStatus={selectedAttackSurfaceStatus}
						setSelectedAttackSurfaceStatus={(status: SecurityStatus) => {
							setSelectedAttackSurfaceStatus(status);
							setAttackSurfaceTestMode(true);
						}}
						initialProgressiveEnforcementLevel={
							ProgressiveEnforcementStatusMap[
								(data?.lowestProgressiveInboundPolicyStatus ??
									ProgressiveEnforcementStatus.ZeroTrust) as ProgressiveEnforcementStatus
							]
						}
						selectedProgressiveInboundEnforcementLevel={
							selectedProgressiveInboundEnforcementLevel
						}
						setSelectedProgressiveInboundEnforcementLevel={
							setSelectedProgressiveInboundEnforcementLevel
						}
						selectedProgressiveOutboundEnforcementLevel={
							selectedProgressiveOutboundEnforcementLevel
						}
						setSelectedProgressiveOutboundEnforcementLevel={
							setSelectedProgressiveOutboundEnforcementLevel
						}
						isZeroTrustAutomationEditable={isZeroTrustAutomationEditable}
						isLoading={isLoading}
						criteria={criteria}
						policyId={id}
						policyProgressiveLastRefreshed={
							data?.policyProgressiveLastRefreshed
						}
						unreviewedTraffic={unreviewedTraffic}
						trafficCriteria={trafficCriteria}
						updateAggregateTraffic={updateAggregateTraffic}
						currentStatuses={currentStatuses}
						managedBy={managedBy}
					/>
				</DialogContent>

				<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
					<PolicyAutomationActions
						assetCount={data?.matchingAssets}
						criteria={data?.criteria}
						isDeployLoading={updateDeployMutation.isLoading}
						isLoadingTraffic={isTrafficLoading}
						updateDeployMode={onSubmit}
						cancel={() => {
							resetGuardrails();
							onClose(false);
						}}
						trafficReviewed={trafficReviewed}
						unreviewedTraffic={unreviewedTraffic}
					/>
				</DialogActions>
			</Drawer>
			{guardrails.length > 0 ? (
				<CTGuardrail
					open={guardrails.length > 0}
					onClose={resetGuardrails}
					guardrails={guardrails}
					onProceed={() => {
						submitConfigForDeployMode();
					}}
					selectedValues={selectedValues}
					currentStatuses={currentStatuses}
				/>
			) : null}
			<TagPolicyCOnfirmationDialog
				policy={data as TagPolicy}
				isOpen={showConfirmationModal}
				onClose={() => {
					resetGuardrails();
					setShowConfirmationModal(false);
				}}
				title={"Configure policy automation"}
				primaryText={"ConfigurePolicyAutomationConfirmation"}
				isLoading={isLoading}
				onSuccess={submitConfigForDeployMode}
				secondaryTextWarning={"ConfigurePolicyAutomationWarning"}
			/>
		</>
	);
};
