import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { OptInOptions } from "../types";

interface DevicesToProtectProps {
	optIn: string;
	setOptIn: (value: string) => void;
	isFormDisabled: boolean;
}

export function DevicesToProtect({
	optIn,
	setOptIn,
	isFormDisabled,
}: Readonly<DevicesToProtectProps>) {
	return (
		<Stack>
			<FormControl>
				<Stack spacing={2}>
					<Typography variant="subtitle1">
						{window.getCTTranslatedText("deviceManagement")}:
					</Typography>
					<RadioGroup value={optIn} onChange={(e, value) => setOptIn(value)}>
						<FormControlLabel
							value={OptInOptions.No}
							control={<Radio />}
							label={window.getCTTranslatedText(
								DisplayTextMap[OptInOptions.No]
							)}
							disabled={isFormDisabled}
						/>
						<FormControlLabel
							value={OptInOptions.Yes}
							control={<Radio />}
							label={window.getCTTranslatedText(
								DisplayTextMap[OptInOptions.Yes]
							)}
							disabled={isFormDisabled}
						/>
					</RadioGroup>
				</Stack>
			</FormControl>
		</Stack>
	);
}
