import { useDemoController } from "hooks/useDemoController";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PendingWorkRequestsDataGrid } from "modules/PendingWorkRequestsDrawer/components/PendingWorkRequestDataGrid";
import { Agents } from "pages/agents";
import { AgentDownloadPage } from "pages/agents/components/agent-download-page/AgentDownloadPage";
import { AGENT_TYPES } from "pages/agents/components/agent-download-page/constants";
import { ApplianceDetail } from "pages/appliance-detail";
import { Appliances } from "pages/appliances";
import { ApplianceDownloadPage } from "pages/appliances/components/appliance-download-page";
import { Asset } from "pages/asset";
import { Assets } from "pages/assets";
import { Containers } from "pages/containers";
import { CreateTagPolicy } from "pages/create-tag-policy";
import { CreateTagRule } from "pages/create-tag-rule";
import { CrowdStrikeGroups } from "pages/crowdstrike-groups";
import { FIREWALL_HOST_GROUPS_PERMISSION } from "pages/crowdstrike-groups/firewall-host-groups/constants";
import { Dashboard } from "pages/dashboard/Dashboard";
import { Alerts, Logs } from "pages/monitoring";
import { Networks } from "pages/networks";
import { Paths } from "pages/paths";
import { Ports } from "pages/ports";
import { Profile } from "pages/profile";
import { Reports } from "pages/reports";
import { Segments } from "pages/segments";
import { AlertConfig } from "pages/settings/components/alert-config";
import { APIsTab } from "pages/settings/components/apis-tab";
import { General } from "pages/settings/components/general";
import { Integrations } from "pages/settings/components/integrations";
import { OrgManagement } from "pages/settings/components/organization/OrgManagement";
import { TagLabelRules, TagNames } from "pages/tags";
import { TagDetail } from "pages/tags/components/tag-detail";
import CrowdStrikeSegmentsEditPrecedence from "pages/tags/components/tag-policy-list/components/edit-precedence/CrowdStrikeSegmentsEditPrecedence";
import { Templates } from "pages/templates";
import { TrafficVisualizer } from "pages/traffic-visualizer";
import { UserSegmentation } from "pages/users-segmentation";
import { UserDepartments } from "pages/users-segmentation/components/user-departments/UserDepartments";
import { UserGroups } from "pages/users-segmentation/components/user-groups";
import { Users } from "pages/users-segmentation/components/users";
import { useLayoutEffect, useMemo } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import OldPathNavigate from "./OldPathRedirect";
import { ROUTES } from "./routes";

export const PrivateRoutes = () => {
	const { isDemo } = useDemoController();
	const navigate = useNavigate();
	const { isFeatureEnabled: isScimFeaturesEnabled } = useFeatureFlagControl(
		FEATURES.USER_SEGMENTATION
	);
	const { isFeatureEnabled: isCrowdStrikeFeatureEnabled } =
		useFeatureFlagControl(FEATURES.CROWDSTRIKE_EDR);
	const { userPermissions, isLoading: isUserPermissionsLoading } =
		useUserPermissionsStore();

	const allowPage = useMemo(
		() => ({
			USER_SEGMENTATION: isScimFeaturesEnabled,
			CROWDSTRIKE_GROUPS: isCrowdStrikeFeatureEnabled,
			SETTINGS_ORGANIZATION:
				!isDemo &&
				(isUserPermissionsLoading ||
					(userPermissions.has("LIST_SUBJECT") &&
						userPermissions.has("CREATE_SUBJECT"))),
			SETTINGS_APIS:
				!isDemo &&
				(isUserPermissionsLoading || userPermissions.has("LIST_USER_API_KEY")),
			SETTINGS_INTEGRATIONS:
				!isDemo &&
				(isUserPermissionsLoading || userPermissions.has("LIST_INTEGRATION")),
			SETTINGS_ALERTS:
				isUserPermissionsLoading || userPermissions.has("UPDATE_ALERTRULES"),
			MONITORING_LOGS:
				isUserPermissionsLoading || userPermissions.has("LIST_EVENT"),
			CREATE_CROWDSTRIKE_SEGMENT:
				isCrowdStrikeFeatureEnabled &&
				(isUserPermissionsLoading ||
					userPermissions.has(FIREWALL_HOST_GROUPS_PERMISSION.CREATE)),
			EDIT_CROWDSTRIKE_SEGMENT:
				isCrowdStrikeFeatureEnabled &&
				(isUserPermissionsLoading ||
					userPermissions.has("UPDATE_TAGBASEDPOLICY")),
			EDIT_CROWDSTRIKE_SEGMENT_PRECEDENCE:
				isCrowdStrikeFeatureEnabled &&
				(isUserPermissionsLoading ||
					userPermissions.has(FIREWALL_HOST_GROUPS_PERMISSION.EDIT)),
		}),
		[
			userPermissions,
			isUserPermissionsLoading,
			isDemo,
			isScimFeaturesEnabled,
			isCrowdStrikeFeatureEnabled,
		]
	);

	useLayoutEffect(() => {
		Object.entries(allowPage).forEach(([key, value]) => {
			if (
				window.location.pathname.includes(ROUTES[key].pathname) &&
				value === false
			) {
				navigate(ROUTES.DASHBOARD.pathname);
			}
		});
	}, [allowPage, navigate]);

	return (
		<Routes>
			<Route path={ROUTES.DASHBOARD.pathname} element={<Dashboard />} />
			<Route
				path={ROUTES.PANOPTIC_MAP.pathname}
				element={<TrafficVisualizer />}
			/>
			<Route
				path={ROUTES.PORTS.oldPath}
				element={<OldPathNavigate {...ROUTES.PORTS} />}
			/>
			<Route
				path={ROUTES.PORT.oldPath}
				element={<OldPathNavigate {...ROUTES.PORT} />}
			/>
			<Route
				path={ROUTES.PATHS.oldPath}
				element={<OldPathNavigate {...ROUTES.PATHS} />}
			/>
			<Route
				path={ROUTES.PATH.oldPath}
				element={<OldPathNavigate {...ROUTES.PATH} />}
			/>
			<Route
				path={ROUTES.AGENTS.oldPath}
				element={<OldPathNavigate {...ROUTES.AGENTS} />}
			/>
			<Route
				path={ROUTES.AGENT.oldPath}
				element={<OldPathNavigate {...ROUTES.AGENT} />}
			/>
			<Route
				path={ROUTES.APPLIANCES.oldPath}
				element={<OldPathNavigate {...ROUTES.APPLIANCES} />}
			/>
			<Route
				path={ROUTES.APPLIANCE.oldPath}
				element={<OldPathNavigate {...ROUTES.APPLIANCE} />}
			/>
			<Route
				path={ROUTES.CLUSTERS.oldPath}
				element={<OldPathNavigate {...ROUTES.CLUSTERS} />}
			/>
			<Route
				path={ROUTES.INSTALL_AGENTS.oldPath}
				element={<OldPathNavigate {...ROUTES.INSTALL_AGENTS} />}
			/>
			<Route
				path={ROUTES.INSTALL_APPLIANCES.oldPath}
				element={<OldPathNavigate {...ROUTES.INSTALL_APPLIANCES} />}
			/>
			<Route
				path={ROUTES.INSTALL_COLLECTOR.oldPath}
				element={<OldPathNavigate {...ROUTES.INSTALL_COLLECTOR} />}
			/>

			<Route
				path={ROUTES.TAG_RULE.oldPath}
				element={<OldPathNavigate {...ROUTES.TAG_RULE} />}
			/>
			<Route
				path={ROUTES.EDIT_RULE.oldPath}
				element={<OldPathNavigate {...ROUTES.EDIT_RULE} />}
			/>
			<Route
				path={ROUTES.SETTINGS_ORGANIZATION.oldPath}
				element={<OldPathNavigate {...ROUTES.SETTINGS_ORGANIZATION} />}
			/>

			<Route
				path={ROUTES.SETTINGS_INTEGRATIONS.oldPath}
				element={<OldPathNavigate {...ROUTES.SETTINGS_INTEGRATIONS} />}
			/>

			<Route
				path={ROUTES.SETTINGS_APIS.oldPath}
				element={<OldPathNavigate {...ROUTES.SETTINGS_APIS} />}
			/>
			<Route path={ROUTES.ASSETS.pathname} element={<Assets />} />
			<Route path={ROUTES.ASSET.pathname} element={<Asset />} />
			<Route path={ROUTES.AGENT.pathname} element={<Asset />} />
			<Route path={ROUTES.DEVICES.pathname} element={<Asset />} />
			<Route
				path={ROUTES.NETWORK_DATA.pathname}
				element={<Navigate to={ROUTES.NETWORK_DATA.redirect ?? ""} />}
			/>
			<Route path={ROUTES.PORTS.pathname} element={<Ports />} />
			<Route path={ROUTES.PATHS.pathname} element={<Paths />} />
			<Route path={ROUTES.TEMPLATES.pathname} element={<Templates />} />
			<Route
				path={ROUTES.SENSORS.pathname}
				element={<Navigate to={ROUTES.SENSORS.redirect ?? ""} />}
			/>
			<Route
				path={ROUTES.MONITORING.pathname}
				element={<OldPathNavigate {...ROUTES.MONITORING} />}
			/>
			<Route path={ROUTES.MONITORING_ALERTS.pathname} element={<Alerts />} />
			<Route path={ROUTES.MONITORING_LOGS.pathname} element={<Logs />} />
			<Route
				path={ROUTES.MONITORING_BG_PROCESSES.pathname}
				element={<PendingWorkRequestsDataGrid fromMonitoring />}
			/>
			<Route path={ROUTES.NETWORKS.pathname} element={<Networks />} />
			<Route
				path={ROUTES.TAGS.pathname}
				element={<OldPathNavigate {...ROUTES.TAGS} />}
			/>
			<Route path={ROUTES.TAG_NAMES.pathname} element={<TagNames />} />
			<Route
				path={ROUTES.TAG_LABEL_RULES.pathname}
				element={<TagLabelRules />}
			/>
			<Route path={ROUTES.TAG.pathname} element={<TagDetail />} />
			<Route path={ROUTES.AGENTS.pathname} element={<Agents />} />
			<Route path={ROUTES.PROFILE.pathname} element={<Profile />} />
			<Route
				path={ROUTES.INSTALL_AGENTS.pathname}
				element={<AgentDownloadPage />}
			/>
			<Route
				path={ROUTES.INSTALL_APPLIANCES.pathname}
				element={<ApplianceDownloadPage />}
			/>
			<Route
				path={ROUTES.INSTALL_COLLECTOR.pathname}
				element={
					<AgentDownloadPage agentType={AGENT_TYPES.CT_CONTAINER_AGENT} />
				}
			/>
			<Route path={ROUTES.USERS.pathname} element={<Users />} />
			<Route path={ROUTES.GROUPS.pathname} element={<UserGroups />} />
			<Route path={ROUTES.DEPARTMENTS.pathname} element={<UserDepartments />} />
			<Route
				path={ROUTES.VISUALIZER.pathname}
				element={<TrafficVisualizer />}
			/>
			<Route
				path={ROUTES.CONNECTORDOWNLOAD.pathname}
				element={
					<AgentDownloadPage agentType={AGENT_TYPES.CT_REMOTE_CONNECTOR} />
				}
			/>
			<Route path={ROUTES.CLUSTERS.pathname} element={<Containers />} />
			<Route path={ROUTES.APPLIANCES.pathname} element={<Appliances />} />
			<Route path={ROUTES.APPLIANCE.pathname} element={<ApplianceDetail />} />
			<Route
				path={ROUTES.SETTINGS.pathname}
				element={<OldPathNavigate {...ROUTES.SETTINGS} />}
			/>
			<Route path={ROUTES.SETTINGS_GENERAL.pathname} element={<General />} />
			<Route
				path={ROUTES.SETTINGS_ORGANIZATION.pathname}
				element={<OrgManagement />}
			/>
			<Route path={ROUTES.SETTINGS_APIS.pathname} element={<APIsTab />} />
			<Route
				path={ROUTES.SETTINGS_INTEGRATIONS.pathname}
				element={<Integrations />}
			/>
			<Route path={ROUTES.SETTINGS_ALERTS.pathname} element={<AlertConfig />} />
			<Route path={ROUTES.TAG_RULE.pathname} element={<CreateTagRule />} />
			<Route path={ROUTES.EDIT_RULE.pathname} element={<CreateTagRule />} />
			<Route path={ROUTES.REPORTS.pathname} element={<Reports />} />
			<Route
				path={ROUTES.SEGMENT_CREATION.pathname}
				element={<CreateTagPolicy />}
			/>
			<Route
				path={ROUTES.SEGMENT_EDIT.pathname}
				element={<CreateTagPolicy />}
			/>
			<Route
				path={ROUTES.SEGMENT_CLONE.pathname}
				element={<CreateTagPolicy />}
			/>
			<Route path={ROUTES.SEGMENT.pathname} element={<Segments />} />
			<>
				<Route
					path={ROUTES.CROWDSTRIKE_GROUPS.pathname}
					element={<CrowdStrikeGroups />}
				/>
				<Route
					path={ROUTES.CREATE_CROWDSTRIKE_SEGMENT.pathname}
					element={<CreateTagPolicy />}
				/>

				<Route
					path={ROUTES.EDIT_CROWDSTRIKE_SEGMENT.pathname}
					element={<CreateTagPolicy />}
				/>
				<Route
					path={ROUTES.EDIT_CROWDSTRIKE_SEGMENT_PRECEDENCE.pathname}
					element={<CrowdStrikeSegmentsEditPrecedence />}
				/>
			</>
			<Route
				path={ROUTES.USER_SEGMENTATION.pathname}
				element={<UserSegmentation />}
			/>
			<Route
				path={ROUTES.ALL.pathname}
				element={<Navigate to={ROUTES.DASHBOARD.pathname} replace={true} />}
			/>
		</Routes>
	);
};
