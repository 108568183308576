import { Button, capitalize, Paper, Stack, Typography } from "@mui/material";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useNavigate } from "react-router-dom";
import { FirewallHostGroupListItem } from "../types";
import useEditPrecedenceMutation from "./hooks/useEditPrecedenceMutation";

interface Props {
	title?: string;
	orderedRows: Array<FirewallHostGroupListItem>;
	children: React.ReactNode;
	navigatePath: string;
}

export function CrowdStrikeEditPrecedence({
	title = "Edit Precedence",
	children,
	orderedRows,
	navigatePath,
}: Props) {
	const formattedTitle = window.getCTTranslatedText(title);
	const navigate = useNavigate();
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const { mutate } = useEditPrecedenceMutation();

	const onCancel = () => {
		navigate(navigatePath);
	};

	const onSubmit = () => {
		const idsArray = orderedRows.map(row => row.crowdstrikeFirewallHostgroupId);
		const body = {
			crowdstrikeFirewallHostgroupIds: idsArray,
		};
		mutate(body, {
			onSuccess: () => {
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "EditPrecedenceFirewallHostGroupSuccess",
				});
				navigate(navigatePath);
			},
			onError: error => {
				// @ts-ignore: Axios error type
				let message = error?.response?.data?.message ?? "SomethingWentWrong";
				const capitalizedMessage = capitalize(message);

				setSnackbar(true, SnackBarSeverity.Error, capitalizedMessage);
			},
		});
	};

	return (
		<>
			<Stack direction="row" mb={4} justifyContent="flex-end" gap={2}>
				<Typography variant="h6" mr="auto">
					{formattedTitle}
				</Typography>
				<Button variant="outlined" onClick={onCancel}>
					{window.getCTTranslatedText("Cancel")}
				</Button>
				<Button variant="contained" onClick={onSubmit}>
					{window.getCTTranslatedText("Submit")}
				</Button>
			</Stack>

			<Paper component={Stack}>{children}</Paper>
		</>
	);
}
