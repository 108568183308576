import { Box, Button, Tooltip } from "@mui/material";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { CreateReportDialog } from "pages/reports/components/create-report-dialog";
import { useEffect, useState } from "react";
import { useCrowdStrikeTagPolicyStore, useTagPolicyStore } from "../../store";

export interface TagPolicyToolbarProps extends Omit<ToolbarProps, "children"> {
	managedBy?: ManagedByValue;
}

export default function TagPolicyToolbar({
	managedBy = ManagedByValue.ColorTokens,
	...props
}: TagPolicyToolbarProps) {
	const [isCreateReportDrawerOpen, setIsCreateReportDrawerOpen] =
		useState(false);
	const selectedCriteria = useTagPolicyStore(state => state.selectedCriteria);
	const setSelectedCriteria = useTagPolicyStore(
		state => state.setSelectedCriteria
	);
	const requestAPIRefreshColorTokens = useTagPolicyStore(
		store => store.requestAPIRefresh
	);
	const requestAPIRefreshCrowdStrike = useCrowdStrikeTagPolicyStore(
		store => store.requestAPIRefresh
	);

	useEffect(() => {
		setSelectedCriteria([]);
	}, [setSelectedCriteria]);

	const TooltipText =
		selectedCriteria.length > 5
			? window.getCTTranslatedText("PleaseSelectUpto5SegmentsToCreateAReport")
			: "";

	return (
		<GridToolbar
			{...props}
			requestAPIRefresh={
				managedBy === ManagedByValue.CrowdStrike
					? requestAPIRefreshCrowdStrike
					: requestAPIRefreshColorTokens
			}
		>
			{(selectedCriteria?.length ?? 0) > 0 ? (
				<Tooltip title={TooltipText}>
					<Box>
						<Button
							variant="outlined"
							color="primary"
							disabled={selectedCriteria.length > 5}
							onClick={() => {
								setIsCreateReportDrawerOpen(true);
							}}
							sx={{
								ml: 2,
							}}
						>
							{window.getCTTranslatedText("CreateReport")}
						</Button>
					</Box>
				</Tooltip>
			) : null}
			{isCreateReportDrawerOpen && (
				<CreateReportDialog
					selectedCriteria={selectedCriteria}
					open={isCreateReportDrawerOpen}
					onClose={() => {
						setIsCreateReportDrawerOpen(false);
						setSelectedCriteria([]);
					}}
				/>
			)}
		</GridToolbar>
	);
}
