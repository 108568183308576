import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { Scope } from "modules/scope-metadata/types";
import { AssetVulnerabilities } from "pages/assets/components/asset-vulnerabilities";
import {
	UnmanagedDeviceAvailability,
	UnmanagedDeviceIP,
	UnmanagedDeviceMAC,
	UnmanagedDeviceName,
	UnmanagedDeviceVendor,
} from "./components";
import UnmanagedDeviceEditTag from "./components/UnmanagedDeviceEditTag";
import { UnmanagedDeviceMaximumCVSS } from "./components/UnmanagedDeviceMaximumCVSS";
import { UnmanagedDeviceVulnerabilityAvailability } from "./components/UnmanagedDeviceVulnerabilityAvailability";
import { UnmanagedDeviceVulnerabilitySeverity } from "./components/UnmanagedDeviceVulnerabilitySeverity";
import { VulnerabilityTypes } from "./types";

export const DEVICE_COLUMNS = (viewOnly: boolean): GridColDef[] => [
	{
		field: "deviceName",
		headerName: "Device Name",
		width: 180,
		minWidth: 180,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceName device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceCoreTags",
		minWidth: 180,
		headerAlign: "left",
		align: "left",
		sortingOrder: ["desc", "asc"],
		sortable: false,
		headerName: "Tags",
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetCoreTags
					viewOnly={viewOnly}
					coreTags={params.row.deviceCoreTags}
					useAssetCoreTagsStore={useCommonStore}
					scope={Scope.UnmanagedDevice}
				/>
			);
		},
	},
	{
		field: "deviceIp",
		headerName: "IP Address",
		width: 120,
		minWidth: 120,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceIP device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceAvailability",
		headerName: "Status",
		headerAlign: "left",
		align: "left",
		width: 120,
		minWidth: 120,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<UnmanagedDeviceAvailability device={params.row} viewOnly={viewOnly} />
			);
		},
	},
	{
		field: "deviceVendor",
		headerName: "Vendor",
		width: 120,
		minWidth: 120,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceVendor device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceMac",
		headerName: "MAC Address",
		width: 120,
		minWidth: 120,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceMAC device={params.row} viewOnly={viewOnly} />;
		},
	},
	{
		field: "deviceVulnerabilitySeverity",
		headerName: "vulnerabilitySeverity",
		headerAlign: "left",
		align: "left",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<UnmanagedDeviceVulnerabilitySeverity
					device={params.row}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "deviceMaximumCVSSScore",
		headerName: "Maximum CVSS Score",
		headerAlign: "left",
		align: "center",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<UnmanagedDeviceMaximumCVSS device={params.row} viewOnly={viewOnly} />
			);
		},
	},
	{
		field: "deviceLateralMovementVulnerability",
		headerName: "lateralMovementVulnerability",
		description: "remoteCodeExecutionVulnerability",
		headerAlign: "left",
		align: "left",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<UnmanagedDeviceVulnerabilityAvailability
					device={params.row}
					vulnerabilityType={VulnerabilityTypes.LateralMovement}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "deviceKnownExploitVulnerability",
		headerName: "knownExploitVulnerability",
		headerAlign: "left",
		align: "left",
		width: 150,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<UnmanagedDeviceVulnerabilityAvailability
					device={params.row}
					vulnerabilityType={VulnerabilityTypes.KnownExploit}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "deviceVulnerabilities",
		headerName: "Vulnerabilites",
		headerAlign: "left",
		align: "center",
		width: 120,
		minWidth: 120,
		sortingOrder: ["desc", "asc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<AssetVulnerabilities
					assetId={params.row.deviceId}
					assetName={params.row.deviceName}
					assetType="unmanageddevices"
					vulnerabilityType={VulnerabilityTypes.Vulnerabilities}
					vulnerabilities={params.row.deviceVulnerabilities}
					viewOnly={viewOnly}
				/>
			);
		},
	},
	{
		field: "actions",
		headerName: "",
		headerAlign: "left",
		align: "right",
		width: 54,
		minWidth: 54,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UnmanagedDeviceEditTag asset={params.row} />;
		},
	},
];
