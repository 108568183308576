import { Box, Stack, useTheme } from "@mui/material";
import { Loader } from "common/atoms/loader";
import { getFacetGroupInfo } from "common/utils";
import { COMMON_FACETS } from "modules/facets/constants";
import { FacetGroups } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { SEGMENTS_READ_ONLY_DIMENSIONS } from "pages/tags/components/tag-policy-list/constants";
import { useMemo } from "react";
import { PolicyForm } from "./components/PolicyForm";

export function useCoreTagsFacetOrder() {
	let { data: metadata } = useScopeMetadata({ scope: Scope.TagPolicy });

	const facetOrder = useMemo(() => {
		if (!metadata?.columns) {
			return;
		}
		let columnNames = Object.keys(metadata?.columns || {});
		let otherGroup: any = {};
		columnNames.forEach(name => {
			if (metadata?.columns[name]?.userDefined) {
				otherGroup[name] = FacetGroups.Tags;
			}
		});

		let order = {
			...otherGroup,
			...COMMON_FACETS,
		};

		return order;
	}, [metadata?.columns]);

	return facetOrder;
}

export function CreateTagPolicy({
	title,
	navigatePath,
}: {
	title?: string;
	navigatePath?: string;
}) {
	const theme = useTheme();
	const coreTagsFacetOrder = useCoreTagsFacetOrder();
	const facetOrder = getFacetGroupInfo(
		coreTagsFacetOrder,
		SEGMENTS_READ_ONLY_DIMENSIONS
	);

	if (!facetOrder) {
		return <Loader />;
	}

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Box
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					overflowY: "auto",
					flex: 1,
					height: "100%",
				}}
			>
				<PolicyForm
					facetOrder={facetOrder}
					title={title}
					navigatePath={navigatePath}
				/>
			</Box>
		</Stack>
	);
}
