import { Stack, Tab } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { CtTabs } from "common/atoms/ct-tabs";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { ResponseType } from "modules/core/types";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SEGMENTS_TABS } from "./constants";

export function useTagPolicyListAPI(
	managedBy: ManagedByValue = ManagedByValue.ColorTokens
) {
	return useMutation<ResponseType<any>, Error, any>([
		`tag-policy-${managedBy}`,
		"tagbasedpolicies/actions/search?computeTotal=true",
	]);
}

export function TagPolicyList() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { isFeatureEnabled: isCrowdStrikeEnabled } = useFeatureFlagControl(
		FEATURES.CROWDSTRIKE_EDR
	);
	const [selectedTab, setSelectedTab] = useState<ManagedByValue>(
		ManagedByValue.ColorTokens
	);

	const handleTabChange = (
		_: React.SyntheticEvent,
		newValue: ManagedByValue
	) => {
		navigate(`?tab=${newValue}`);
		setSelectedTab(newValue);
	};

	useLayoutEffect(() => {
		const urlParams = new URLSearchParams(search);
		const tabParam = urlParams.get("tab");
		let initialTab = ManagedByValue.ColorTokens;

		if (tabParam === ManagedByValue.CrowdStrike && isCrowdStrikeEnabled) {
			initialTab = ManagedByValue.CrowdStrike;
		}

		setSelectedTab(initialTab);
	}, [search, setSelectedTab, isCrowdStrikeEnabled]);

	return (
		<Stack
			direction={"column"}
			sx={{ height: "100%", width: "100%", position: "relative" }}
		>
			<Stack direction="row" justifyContent={"space-between"}>
				{isCrowdStrikeEnabled && (
					<CtTabs value={selectedTab} onChange={handleTabChange}>
						{Object.entries(SEGMENTS_TABS).map(([tabKey, tab]) => (
							<Tab
								key={tabKey}
								label={window.getCTTranslatedText(tab.label)}
								value={tabKey}
							/>
						))}
					</CtTabs>
				)}
				{SEGMENTS_TABS[selectedTab].actions}
			</Stack>
			{SEGMENTS_TABS[selectedTab].component}
		</Stack>
	);
}
