import { Box, Stack } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";
import { ViewDiagnosticButton } from "./ViewDiagnosticButton";

export function AgentDiagnostic({ agent }: AgentColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ width: 42 }} />;
	}

	return (
		<Stack alignItems="flex-start" justifyItems={"center"}>
			<ViewDiagnosticButton agent={agent} agentId={agent.agentId} />
		</Stack>
	);
}
