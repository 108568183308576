import { useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { CTRole, CTUser } from "pages/settings/components/organization/types";
import { useMemo } from "react";
import { RolesDropdown } from "../invite-member-drawer/components/roles-dropdown";
import { useUpdateRole } from "./hooks/use-update-role";

interface UpdateUserRoleProps {
	rolesList: CTRole[] | undefined;
	user: CTUser;
}

export function UpdateUserRole({ rolesList, user }: UpdateUserRoleProps) {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const queryClient = useQueryClient();
	const updateRoleMutation = useUpdateRole();

	const handleRoleChange = async (role: CTRole) => {
		const body = [
			{
				roleID: role.roleID,
				userID: user.userId,
			},
		];

		await updateRoleMutation.mutateAsync(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["auth"],
				});
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UserRoleUpdatedSuccessfully",
					{ roleName: window.getCTTranslatedText(role?.roleName) }
				);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const role = useMemo(() => {
		const getSelectedRole = (roleID: string | number) => {
			return (rolesList ?? []).find(
				(roleObj: CTRole) => roleObj?.roleID === roleID
			);
		};

		return getSelectedRole(user?.roleID);
	}, [user, rolesList]);

	return (
		<>
			<RolesDropdown
				rolesList={rolesList}
				onSelect={handleRoleChange}
				selectedRole={role}
				typographyVariant="body2"
				width="200px"
			/>
		</>
	);
}
