import { Menu, MenuProps } from "@mui/material";

interface SubMenuProps extends Pick<MenuProps, "anchorEl" | "onClose"> {
	children: React.ReactNode;
}

export function ProfileSubMenu({ children, anchorEl, onClose }: SubMenuProps) {
	const isSubMenuOpen = Boolean(anchorEl);

	return (
		<Menu
			anchorEl={anchorEl}
			open={isSubMenuOpen}
			onClose={onClose}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			sx={{
				pointerEvents: "none",
			}}
			PaperProps={{
				sx: {
					minWidth: 120,
					translate: "0px -2px",
					pointerEvents: "initial",
				},
			}}
		>
			{children}
		</Menu>
	);
}
