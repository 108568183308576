import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useUserStore } from "../users/store";

export interface UserToolbarProps extends Omit<ToolbarProps, "children"> {}

export function UserToolBar(props: UserToolbarProps) {
	const requestAPIRefresh = useUserStore(store => store.requestAPIRefresh);

	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}></GridToolbar>
	);
}
