// CTGuardrail.tsx
import DangerousIcon from "@mui/icons-material/Dangerous";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
	Alert,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import deepmerge from "deepmerge";
import { AssetStatus } from "pages/assets/types";
import {
	ProgressiveEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatus,
} from "pages/ports/types";
import {
	PolicyAggregateData,
	PolicyChangeType,
} from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";
import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CTJoinedText } from "../ct-joined-text/CTJoinedText";
import { BreakdownDialog } from "./BreakdownDialog";
import { CTGuardrailTraffic } from "./CTGuardrailTraffic";
import { GuardrailCheckbox } from "./GuardrailCheckbox";
import {
	Guardrail,
	GuardrailSection,
	GuardrailSections,
	OutputData,
	SelectedValues,
} from "./types";

export interface CurrentStatuses {
	[PolicyChangeType.AttackSurfaceEnforcement]?: AssetStatus;
	[PolicyChangeType.AttackSurfaceProgressive]?: ProgressiveEnforcementStatus;
	[PolicyChangeType.BlastRadiusEnforcement]?: AssetStatus;
	[PolicyChangeType.BlastRadiusProgressive]?: ProgressiveOutboundPortEnforcementStatus;
}

interface CTGuardrailProps {
	open: boolean;
	onClose: () => void;
	onProceed: () => void;
	loading?: boolean;
	guardrails: Guardrail[];
	title?: string;
	description?: string;
	showAssetCount?: boolean;
	selectedValues?: SelectedValues;
	currentStatuses?: CurrentStatuses;
}

export function CTGuardrail({
	open,
	onClose,
	onProceed,
	loading,
	guardrails,
	showAssetCount = true,
	title = window.getCTTranslatedText("Potential Traffic Disruptions"),
	description = window.getCTTranslatedText(
		"This change may cause network disruptions. Review all traffic and consult an Xshield expert."
	),
	selectedValues,
	currentStatuses,
}: CTGuardrailProps) {
	const [acknowledgement, setAcknowledgement] = useState<boolean>(false);
	const [totalAffectedAssets, setTotalAffectedAssets] = useState(0);
	const [expandTrafficDistribution, setExpandTrafficDistribution] =
		useState(false);
	const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [aggregateTraffic, setAggregateTraffic] =
		useState<PolicyAggregateData>();

	const updateAggregateTraffic = (
		id: PolicyChangeType,
		traffic?: OutputData
	) => {
		if (!aggregateTraffic?.[id] && traffic) {
			setAggregateTraffic(prev => ({ ...prev, [id]: traffic }));
		}
	};

	const cumulativeTraffic = useMemo(() => {
		return Object.values(aggregateTraffic ?? {}).reduce((acc, value) => {
			return deepmerge(acc, value);
		}, {});
	}, [aggregateTraffic]);

	useEffect(() => {
		if (
			Object.keys(aggregateTraffic ?? {}).length === guardrails.length &&
			totalAffectedAssets === 0
		) {
			setTotalAffectedAssets(Object.keys(cumulativeTraffic).length);
			setIsLoading(false);
		}
	}, [aggregateTraffic, guardrails, totalAffectedAssets, cumulativeTraffic]);

	function renderGuardrailSection(section: GuardrailSection) {
		return (
			<Stack spacing={1}>
				<Divider sx={{ mb: 2 }} />
				<Typography variant="body2">{section.title}</Typography>
				<Stack spacing={1}>
					{Object.entries(section.subSections).map(([key, value]) => {
						const guardrail = guardrails.find(
							guardrail => guardrail.id === key
						);
						if (guardrail) {
							return (
								<Stack spacing={1} key={key}>
									<Typography variant="overline">{value} :</Typography>
									<CTGuardrailTraffic
										id={guardrail.id}
										baseCriteria={guardrail.baseCriteria}
										showWarning={totalAffectedAssets > 0}
										showAssetCount={showAssetCount}
										updateAggregate={updateAggregateTraffic}
										selectedStatus={selectedValues?.[guardrail.id]}
										currentStatus={currentStatuses?.[guardrail.id]}
									/>
								</Stack>
							);
						}
						return null;
					})}
				</Stack>
			</Stack>
		);
	}

	function renderGuardrailSections() {
		const hasAttackSurface = guardrails.some(
			guardrail =>
				guardrail.id === PolicyChangeType.AttackSurfaceProgressive ||
				guardrail.id === PolicyChangeType.AttackSurfaceEnforcement
		);
		const hasBlastRadius = guardrails.some(
			guardrail =>
				guardrail.id === PolicyChangeType.BlastRadiusProgressive ||
				guardrail.id === PolicyChangeType.BlastRadiusEnforcement
		);

		return (
			<Stack spacing={4} sx={{ mt: 2 }}>
				{hasAttackSurface &&
					renderGuardrailSection(GuardrailSections.AttackSurface)}
				{hasBlastRadius &&
					renderGuardrailSection(GuardrailSections.BlastRadius)}
			</Stack>
		);
	}

	const trafficTitle = [
		isLoading ? (
			<CircularProgress size={16} />
		) : (
			<Link
				component={RouterLink}
				onClick={() => setIsBreakdownOpen(true)}
				to={`#`}
				underline="none"
				sx={{ mr: 0.5 }}
			>
				{totalAffectedAssets}
			</Link>
		),
		window.getCTTranslatedText("assets have network disruption risk"),
	];

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				<Stack direction="row" spacing={2} alignItems="center">
					<Typography variant="h6">
						{window.getCTTranslatedText(title)}
					</Typography>
				</Stack>
			</DialogTitle>

			<DialogContent>
				<Stack
					mb={4}
					spacing={2}
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
				>
					<DangerousIcon
						color="error"
						sx={{
							fontSize: 58,
						}}
					/>
					<Typography>{window.getCTTranslatedText(description)}</Typography>
				</Stack>

				<Alert
					severity="error"
					sx={{
						width: "100%",
						mb: 2,
					}}
					action={
						<>
							<IconButton
								size="small"
								onClick={() =>
									setExpandTrafficDistribution(!expandTrafficDistribution)
								}
								sx={{
									p: 0.5,
								}}
							>
								{expandTrafficDistribution ? (
									<ExpandLessIcon />
								) : (
									<ExpandMoreIcon />
								)}
							</IconButton>
						</>
					}
				>
					<Stack>
						<CTJoinedText items={trafficTitle} />
						<Collapse in={expandTrafficDistribution} timeout="auto">
							{renderGuardrailSections()}
						</Collapse>
					</Stack>
				</Alert>

				<GuardrailCheckbox
					acknowledgement={acknowledgement}
					setAcknowledgement={setAcknowledgement}
				/>

				<BreakdownDialog
					isOpen={isBreakdownOpen}
					onClose={() => setIsBreakdownOpen(false)}
					traffic={cumulativeTraffic}
					baseCriteria={guardrails}
				/>
			</DialogContent>

			<DialogActions sx={{ p: 2 }}>
				<Button variant="outlined" onClick={onClose}>
					{window.getCTTranslatedText("Go Back")}
				</Button>
				<LoadingButton
					loading={loading}
					color="error"
					onClick={onProceed}
					disabled={!acknowledgement}
				>
					{window.getCTTranslatedText("Proceed")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
