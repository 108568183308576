import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { PortToolbar } from "pages/ports/components/port-data-grid-toolbar";
import { Port, PortStatus } from "pages/ports/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PORT_COLUMNS, PortColumnConfig } from "./constants";

export const PortDataGrid = (props: DataGridProps<Port>) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const [searchParams, setSearchParams] = useSearchParams();
	const recommendationId =
		new URLSearchParams(window.location.search).get("recommendation") || "";

	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	const selectedData: Array<Port> | undefined = useMemo(() => {
		return props.rows?.filter((row: Port) => {
			return selection.indexOf(row.lpId) !== -1;
		});
	}, [selection, props.rows]);

	const showPortStatusOption: boolean = useMemo(() => {
		return (selectedData || []).some((row: Port) => {
			return (
				row?.listenPortReviewed !== PortStatus.AllowAny &&
				row?.listenPortReviewed !== PortStatus.AllowIntranet
			);
		});
	}, [selectedData]);

	const showLateralMovementColumn = useMemo(() => {
		return props.rows?.some((row: Port) => {
			return Boolean((row.lateralMovementTechniques ?? []).length);
		});
	}, [props.rows]);

	useEffect(() => {
		if (recommendationId && userPermissions.has("UPDATE_PORT")) {
			const tempSelections: GridRowId[] = (props.rows || []).map(
				item => item.lpId
			);
			if (tempSelections?.length > 0) {
				setSelection(tempSelections);
			}
		}
	}, [
		recommendationId,
		props.rows,
		searchParams,
		setSearchParams,
		userPermissions,
	]);

	const clearSelection = useCallback(() => {
		setSelection([]);
		if (searchParams.has("recommendation")) {
			searchParams.delete("recommendation");
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	const columns = useMemo(
		() =>
			PORT_COLUMNS.filter(col => {
				if (col.field === "lateralMovementTechniques") {
					return showLateralMovementColumn;
				} else {
					return true;
				}
			}),
		[showLateralMovementColumn]
	);

	const selectedRawData: Array<Port> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: Port) => {
			return selection.indexOf(row.lpId) !== -1;
		});
	}, [selection, props?.rawData]);

	const renderToolbar = useCallback(
		() => (
			<PortToolbar
				clearSelection={clearSelection}
				selectedData={selectedData ?? []}
				showPortStatusOption={showPortStatusOption}
				recommendationId={recommendationId}
			/>
		),
		[clearSelection, recommendationId, selectedData, showPortStatusOption]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={PortColumnConfig?.PinnedColumns}
					defaultHiddenColumns={PortColumnConfig.ColumnVisibilityModel}
					checkboxSelection={userPermissions.has("UPDATE_PORT")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					exportRowCount={props.originalRowCount}
					columns={columns}
					pagination
					getRowId={({ lpId }: Port) => lpId}
					paginationMode="server"
					sortingMode="server"
					{...props}
					selectedRawData={selectedRawData}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};
