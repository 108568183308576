import { GridRowId } from "@mui/x-data-grid";
import { parseErrorMessage } from "common/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAlertConfigStatusAPI } from "../../hooks/useAlertConfig/useAlertConfigAPI";
import { AlertNotificationStatus, AlertRuleStatus } from "../../types";

interface AlertRuleStatusActionBarProps {
	selectedStatus: AlertNotificationStatus | undefined;
	selection: GridRowId[];
	isValid: boolean;
	cancel: () => void;
	update: () => void;
}

export const AlertRuleStatusActionBar = ({
	selectedStatus,
	selection,
	cancel,
	update,
	isValid,
}: AlertRuleStatusActionBarProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const alertRuleStatusAPI = useAlertConfigStatusAPI();

	const updateStatus = async (
		selection: GridRowId[],
		selectedStatus?: AlertNotificationStatus | AlertRuleStatus
	) => {
		if (selection?.length > 0 && selectedStatus) {
			const getAllAlertIds = selection.map(obj => `'${obj}'`);
			const body = {
				criteria: `alertruleid in (${getAllAlertIds.join(", ")})`,
				alertRuleStatus:
					selectedStatus === AlertRuleStatus.Enabled ? "Enabled" : "Disabled",
			};

			alertRuleStatusAPI.mutateAsync(body, {
				onSuccess: response => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						"AlertRuleStatusUpdatedSuccessfully",
						{
							status: window.getCTTranslatedText(
								selectedStatus === AlertRuleStatus.Enabled
									? "enabled"
									: "disabled"
							),
						}
					);
					update();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		}
	};

	const confirmAction = () => {
		if (selection?.length > 0) {
			updateStatus(selection, selectedStatus);
		}
	};

	return (
		<>
			{
				<ToolbarAction
					loading={alertRuleStatusAPI?.isLoading}
					isValid={selection?.length > 0 && isValid}
					actionBtnText={"Confirm"}
					save={confirmAction}
					cancel={cancel}
				></ToolbarAction>
			}
		</>
	);
};
