import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	IconButtonProps,
	Radio,
	RadioGroup,
	Stack,
	styled,
	Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { FormSectionHeader } from "common/atoms/form-section-header";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import React, { useState } from "react";
import {
	ApplianceVLANUpdate,
	ConfigAppliances,
	DHCPModes,
	DHCPOptions,
	HAModes,
	HAModeTypes,
	IPRangeInt,
	MTUOptions,
	NetworkInterfaces,
	OptInOptions,
	VLAN,
} from "../types";
import { IP_PLACEHOLDER } from "../utils";
import { DHCP } from "./DHCP";
import { WAN } from "./WAN";

const baseRowStyle = {
	pt: 0,
	pb: 1.75,
	px: 3,
};

const rulesSectionWrapper = {
	py: 1,
	borderRadius: 1,
};

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export interface ProtectedNetworkInterfaceProps {
	HAMode: string;
	appliances: ConfigAppliances[];
	DHCPMode: string;
	onDHCPModeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	DHCPOption: string;
	onDHCPOptionChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	deviceIdentifier: string;
	setDeviceIdentifier: (value: string) => void;
	leaseDuration: string;
	setLeaseDuration: (value: string) => void;
	serverIp: string;
	setServerIp: (value: string) => void;
	networkInterface: string;
	onNetworkInterfaceChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	lanStartIP: string;
	setLanStartIP: (value: string) => void;
	lanEndIP: string;
	setLanEndIP: (value: string) => void;
	lanVirtualIP: string;
	setLanVirtualIP: (value: string) => void;
	lanMTU: string;
	setLanMTU: (value: string) => void;
	VlanList: VLAN[];
	setVlanList: (VlanList: VLAN[]) => void;
	optIn: string;
	lanGatewayAddress: string;
	setLanGatewayAddress: (value: string) => void;
	gatewayAddress: string;
	setGatewayAddress: (value: string) => void;
	wanVirtualIP: string;
	setWanVirtualIP: (value: string) => void;
	DNSServers: IPRangeInt[];
	setDNSServers: (ipRanges: IPRangeInt[]) => void;
	wanMTU: string;
	setWanMTU: (value: string) => void;
	HAModeType: string;
	handleVlanChange: (value: ApplianceVLANUpdate) => void;
	setAppliances: (appliances: ConfigAppliances[]) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
	serverIps: string[];
	setServerIps: (value: string[]) => void;
	currentVersion: string | undefined;
}

export function isDHCPEnabled(dhcpOption: string) {
	if (dhcpOption === DHCPOptions.DHCP || dhcpOption === DHCPOptions.Mixed) {
		return true;
	}
	return false;
}

export function ProtectedNetworkInterface({
	DHCPMode,
	appliances,
	onDHCPModeChange,
	DHCPOption,
	onDHCPOptionChange,
	deviceIdentifier,
	setDeviceIdentifier,
	leaseDuration,
	setLeaseDuration,
	serverIp,
	setServerIp,
	networkInterface,
	onNetworkInterfaceChange,
	lanStartIP,
	setLanStartIP,
	lanEndIP,
	setLanEndIP,
	lanVirtualIP,
	setLanVirtualIP,
	HAMode,
	lanMTU,
	setLanMTU,
	VlanList,
	setVlanList,
	optIn,
	lanGatewayAddress,
	setLanGatewayAddress,
	gatewayAddress,
	setGatewayAddress,
	wanVirtualIP,
	setWanVirtualIP,
	DNSServers,
	setDNSServers,
	wanMTU,
	setWanMTU,
	HAModeType,
	handleVlanChange,
	setAppliances,
	keyListener,
	isFormDisabled,
	serverIps,
	setServerIps,
	currentVersion,
}: ProtectedNetworkInterfaceProps) {
	const [expandLANForm, setExpandLANForm] = useState(true);
	const [expandWANForm, setExpandWANForm] = useState(true);

	const handleVlanFieldChange = (e: TextFieldUpdate, index: number) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			const field = e?.field as keyof VLAN;
			let newVlanList = [...VlanList];
			let oldValue = newVlanList[index][field];
			newVlanList[index][field] = value;
			if (field === "id") {
				let newAppliances = [...appliances];
				for (let appliance of newAppliances) {
					for (let vlan of appliance?.vlans) {
						if (vlan?.vlanId === oldValue) {
							vlan.vlanId = value;
							break;
						}
					}
				}
				setAppliances(newAppliances);
			}
			setVlanList(newVlanList);
		}
	};

	const addNewVLAN = () => {
		const newVlanList = [...VlanList];
		newVlanList.push({
			id: "",
			gatewayIP: "",
			startIP: "",
			endIP: "",
			virtualIP: "",
			mtu: "1500",
		});
		let newAppliances = [...appliances];
		for (let appliance of newAppliances) {
			appliance.vlans.push({
				vlanId: "",
				wanIp: "",
				lanIp: "",
			});
		}
		setAppliances(newAppliances);
		setVlanList(newVlanList);
		setTimeout(() => {
			scrollToLastVLAN(newVlanList?.length);
		});
	};

	const scrollToLastVLAN = (vlanLength: number) => {
		const element = document.getElementById(`vlan-item-${vlanLength}`);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	};

	const removeVLAN = (index: number) => {
		const newVlanList = [...VlanList];
		const oldVlanId = newVlanList[index].id;
		newVlanList.splice(index, 1);
		setVlanList(newVlanList);
		for (let appliance of appliances) {
			appliance.vlans = appliance.vlans.filter(
				vlan => vlan.vlanId !== oldVlanId
			);
		}
		setAppliances(appliances);
	};

	function renderLANForm() {
		return (
			<Stack spacing={2}>
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					{isDHCPEnabled(DHCPOption) && DHCPMode === DHCPModes.Server ? (
						<>
							<Stack width="100%">
								<CTIPInput
									field={"startIP"}
									displayName={window.getCTTranslatedText("startIP")}
									placeholder={IP_PLACEHOLDER}
									value={lanStartIP}
									handleUpdate={(event: TextFieldUpdate) =>
										setLanStartIP(event?.value)
									}
									disabled={isFormDisabled}
									onKeyUp={keyListener}
								/>
							</Stack>
							<Stack width="100%">
								<CTIPInput
									field={"endIP"}
									displayName={window.getCTTranslatedText("endIP")}
									placeholder={IP_PLACEHOLDER}
									value={lanEndIP}
									handleUpdate={(event: TextFieldUpdate) =>
										setLanEndIP(event?.value)
									}
									disabled={isFormDisabled}
									onKeyUp={keyListener}
								/>
							</Stack>
						</>
					) : null}
					{HAMode === HAModes.Primary || HAMode === HAModes.Standby ? (
						<Stack>
							<CTIPInput
								field="lanVirtualIP"
								displayName={window.getCTTranslatedText("virtualIP")}
								placeholder={IP_PLACEHOLDER}
								value={lanVirtualIP}
								handleUpdate={(event: TextFieldUpdate) =>
									setLanVirtualIP(event?.value)
								}
								disabled={isFormDisabled}
								onKeyUp={keyListener}
							/>
						</Stack>
					) : null}
					{DHCPMode === DHCPModes.Server &&
					isDHCPEnabled(DHCPOption) &&
					optIn === OptInOptions.Yes ? (
						<Stack>
							<CTInputField
								field="lanGatewayAddress"
								displayName={window.getCTTranslatedText("existingGatewayIP")}
								placeholder={IP_PLACEHOLDER}
								value={lanGatewayAddress}
								handleUpdate={(event: TextFieldUpdate) =>
									setLanGatewayAddress(event?.value)
								}
								disabled={isFormDisabled}
								onKeyUp={keyListener}
							/>
						</Stack>
					) : null}
					<Stack>
						<CTSelectDropDown
							field={"lanMTU"}
							displayName={window.getCTTranslatedText("MTU")}
							selectedValue={lanMTU}
							handleUpdate={(event: TextFieldUpdate) => setLanMTU(event.value)}
							data={MTUOptions}
							showLabel={true}
						/>
					</Stack>
				</Grid>
			</Stack>
		);
	}

	function renderVLANForm() {
		return (
			<Box sx={{ minWidth: "100%" }}>
				<Stack
					alignItems="flex-start"
					justifyContent="flex-start"
					spacing={0}
					sx={{
						...rulesSectionWrapper,
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent={"space-between"}
						sx={{ width: "100%", my: 2 }}
					>
						<Stack alignItems="center">
							<Typography
								variant="subtitle1"
								sx={{
									color: theme => theme.palette.text.disabled,
								}}
							>
								{window.getCTTranslatedText("VLAN Interfaces")}
							</Typography>
						</Stack>
						<Stack alignItems="center">
							<Button
								variant="text"
								startIcon={<AddCircleOutlineIcon />}
								color="primary"
								onClick={() => addNewVLAN()}
								disabled={isFormDisabled}
							>
								{window.getCTTranslatedText("Add VLAN Interface")}
							</Button>
						</Stack>
					</Stack>
					<Stack sx={{ width: "100%" }}>
						{VlanList &&
							VlanList.length > 0 &&
							VlanList.map((item, index) => {
								const showLabel = true;
								return (
									<Stack
										direction={"row"}
										key={`ip-${index}`}
										id={`vlan-item-${index + 1}`}
										alignItems="center"
										sx={{
											background: theme =>
												theme.palette.mode === "dark"
													? theme.palette.background.paper
													: theme.palette.custom.lightGreyBg,
										}}
										mb={2}
									>
										<Stack
											direction="row"
											justifyContent="flex-start"
											alignContent="center"
											spacing={2}
											sx={{
												...baseRowStyle,
												width: "90%",
											}}
										>
											<Stack>
												<CTInputField
													field={"id"}
													displayName={window.getCTTranslatedText("ID")}
													value={item?.id}
													handleUpdate={(event: TextFieldUpdate) =>
														handleVlanFieldChange(event, index)
													}
													showLabel={showLabel}
													onKeyUp={keyListener}
													disabled={isFormDisabled}
												/>
											</Stack>
											{isDHCPEnabled(DHCPOption) &&
											DHCPMode === DHCPModes.Server ? (
												<>
													<Stack>
														<CTIPInput
															field={"startIP"}
															displayName={window.getCTTranslatedText(
																"startIP"
															)}
															placeholder={IP_PLACEHOLDER}
															value={item?.startIP}
															handleUpdate={(event: TextFieldUpdate) =>
																handleVlanFieldChange(event, index)
															}
															showLabel={showLabel}
															onKeyUp={keyListener}
															disabled={isFormDisabled}
														/>
													</Stack>
													<Stack>
														<CTIPInput
															field={"endIP"}
															displayName={window.getCTTranslatedText("endIP")}
															placeholder={IP_PLACEHOLDER}
															value={item?.endIP}
															handleUpdate={(event: TextFieldUpdate) =>
																handleVlanFieldChange(event, index)
															}
															showLabel={showLabel}
															onKeyUp={keyListener}
															disabled={isFormDisabled}
														/>
													</Stack>
												</>
											) : null}
											{HAMode !== HAModes.StandAlone ? (
												<>
													<Stack>
														<CTIPInput
															field={"virtualIP"}
															displayName={window.getCTTranslatedText(
																"lanVirtualIP"
															)}
															placeholder={IP_PLACEHOLDER}
															value={item?.virtualIP}
															handleUpdate={(event: TextFieldUpdate) =>
																handleVlanFieldChange(event, index)
															}
															showLabel={showLabel}
															onKeyUp={keyListener}
															disabled={isFormDisabled}
														/>
													</Stack>
												</>
											) : null}
											{HAMode !== HAModes.StandAlone &&
											HAModeType === HAModeTypes.Active &&
											networkInterface === NetworkInterfaces.VLAN ? (
												<Stack>
													<CTIPInput
														field={"wanVirtualIP"}
														displayName={window.getCTTranslatedText(
															"wanVirtualIP"
														)}
														placeholder={IP_PLACEHOLDER}
														value={item?.wanVirtualIP}
														handleUpdate={(event: TextFieldUpdate) =>
															handleVlanFieldChange(event, index)
														}
														showLabel={showLabel}
														onKeyUp={keyListener}
														disabled={isFormDisabled}
													/>
												</Stack>
											) : null}

											{DHCPMode === DHCPModes.Server &&
											isDHCPEnabled(DHCPOption) &&
											optIn === OptInOptions.Yes ? (
												<Stack>
													<CTInputField
														field="gatewayAddress"
														displayName={window.getCTTranslatedText(
															"existingGatewayIP"
														)}
														placeholder={IP_PLACEHOLDER}
														value={item?.gatewayAddress}
														handleUpdate={(event: TextFieldUpdate) =>
															handleVlanFieldChange(event, index)
														}
														disabled={isFormDisabled}
														onKeyUp={keyListener}
													/>
												</Stack>
											) : null}

											<Stack>
												<CTSelectDropDown
													field={"mtu"}
													displayName={window.getCTTranslatedText("MTU")}
													selectedValue={item?.mtu}
													handleUpdate={(event: TextFieldUpdate) =>
														handleVlanFieldChange(event, index)
													}
													data={MTUOptions}
													showLabel={true}
													disabled={isFormDisabled}
												/>
											</Stack>
										</Stack>
										<Stack
											justifyContent="center"
											alignItems="center"
											width="10%"
										>
											<IconButton
												size="small"
												aria-label="Remove VLAN"
												onClick={() => removeVLAN(index)}
												disabled={isFormDisabled}
											>
												<RemoveCircleOutlineIcon fontSize="small" />
											</IconButton>
										</Stack>
									</Stack>
								);
							})}
					</Stack>
				</Stack>
			</Box>
		);
	}

	return (
		<Box sx={{ minWidth: "100%" }}>
			<Stack>
				<FormControl>
					<Stack spacing={2}>
						<Card
							sx={{
								mb: 2,
							}}
						>
							<CardContent>
								<DHCP
									DHCPOption={DHCPOption}
									onDHCPOptionChange={onDHCPOptionChange}
									DHCPMode={DHCPMode}
									onDHCPModeChange={onDHCPModeChange}
									deviceIdentifier={deviceIdentifier}
									setDeviceIdentifier={setDeviceIdentifier}
									leaseDuration={leaseDuration}
									setLeaseDuration={setLeaseDuration}
									serverIp={serverIp}
									setServerIp={setServerIp}
									keyListener={keyListener}
									isFormDisabled={isFormDisabled}
									serverIps={serverIps}
									setServerIps={setServerIps}
									currentVersion={currentVersion}
								/>
							</CardContent>
						</Card>
						<FormSectionHeader
							header={window.getCTTranslatedText("Interfaces")}
						/>
						<Card>
							<CardHeader
								sx={{ px: 3, py: 2 }}
								action={
									<ExpandMore
										sx={{
											mt: 1,
										}}
										expand={expandLANForm}
										onClick={() => setExpandLANForm(!expandLANForm)}
										aria-expanded={expandLANForm}
									>
										<ExpandMoreIcon />
									</ExpandMore>
								}
								title={
									<Stack alignItems="center" direction={"row"}>
										<RadioGroup
											row
											aria-labelledby="select-source-destination-label"
											name="path-direction-radio-buttons-group"
											defaultValue={NetworkInterfaces.LAN}
											value={networkInterface}
											onChange={onNetworkInterfaceChange}
										>
											<FormControlLabel
												value={NetworkInterfaces.LAN}
												control={<Radio size="small" />}
												label={window.getCTTranslatedText(
													DisplayTextMap[NetworkInterfaces.LAN]
												)}
												disabled={isFormDisabled}
											/>
											<FormControlLabel
												value={NetworkInterfaces.VLAN}
												control={<Radio size="small" />}
												label={window.getCTTranslatedText(
													DisplayTextMap[NetworkInterfaces.VLAN]
												)}
												disabled={isFormDisabled}
											/>
										</RadioGroup>
									</Stack>
								}
							/>
							<Collapse in={expandLANForm} timeout="auto">
								<CardContent>
									{networkInterface === NetworkInterfaces.LAN
										? renderLANForm()
										: renderVLANForm()}
								</CardContent>
							</Collapse>
						</Card>
						<Card>
							<CardHeader
								sx={{ px: 3, py: 2 }}
								action={
									<ExpandMore
										sx={{
											mt: 1,
										}}
										expand={expandWANForm}
										onClick={() => setExpandWANForm(!expandWANForm)}
										aria-expanded={expandWANForm}
									>
										<ExpandMoreIcon />
									</ExpandMore>
								}
								title={
									<FormSectionHeader
										header={window.getCTTranslatedText("WAN")}
									/>
								}
							/>
							<Collapse in={expandWANForm} timeout="auto">
								<CardContent>
									<WAN
										HAMode={HAMode}
										appliances={appliances}
										HAModeType={HAModeType}
										networkInterface={networkInterface}
										handleVlanChange={handleVlanChange}
										gatewayAddress={gatewayAddress}
										setGatewayAddress={setGatewayAddress}
										wanVirtualIP={wanVirtualIP}
										setWanVirtualIP={setWanVirtualIP}
										DNSServers={DNSServers}
										setDNSServers={setDNSServers}
										wanMTU={wanMTU}
										setWanMTU={setWanMTU}
										keyListener={keyListener}
										isFormDisabled={isFormDisabled}
									/>
								</CardContent>
							</Collapse>
						</Card>
					</Stack>
				</FormControl>
			</Stack>
		</Box>
	);
}
