import { DashboardWidget } from "@colortokens/ng-ui";
import { Stack, debounce } from "@mui/material";
import { useCommonStore } from "common/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useEffect, useMemo, useState } from "react";
import { RecommendedActions } from "../security-progress-predictions/components/recommended-actions";
import { Recommendation } from "../security-progress-predictions/components/recommended-actions/types";
import { useRecommedationAPI } from "../security-progress/hooks";

export const RecommendationWidgetWrapper = () => {
	const [recommendationsData, setRecommendationsData] = useState<
		Recommendation[] | undefined
	>(undefined);

	const recommendationsMutation = useRecommedationAPI();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const recommendationsMutationObj = useMemo(
		() => debounce(recommendationsMutation.mutate, 500),
		[recommendationsMutation.mutate]
	);

	const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

	useEffect(() => {
		let criteria = searchCriteria || "*";

		const requestObj = {
			criteria: criteria,
		};
		recommendationsMutationObj(requestObj, {
			onSuccess(data) {
				if (data) {
					const responseData = data;
					const items = responseData?.items ?? [];

					if (items) {
						setRecommendationsData(items);
					}
				}
			},
			onError: error => {
				setRecommendationsData([]);
				setSnackbar(
					true,
					SnackBarSeverity.Error,
					"Failed to fetch Recommendations"
				);
			},
		});
	}, [recommendationsMutationObj, searchCriteria, setSnackbar]);

	return (
		<DashboardWidget
			title={`${window.getCTTranslatedText("Recommendations")}`}
			isLoading={recommendationsMutation?.isLoading || !recommendationsData}
			children={
				<Stack sx={{ width: "100%", height: "100%" }} pb={3}>
					<RecommendedActions
						data={recommendationsData}
						isLoading={
							recommendationsMutation?.isLoading || !recommendationsData
						}
					/>
				</Stack>
			}
			showTrend={false}
		/>
	);
};
