import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "../../../../modules/facets/types";

export enum UnmanagedDeviceCoreTagKey {
	DEVICE_MANUFACTURER = "devicemanufacturer",
	DEVICE_SUBCATEGORY = "devicesubcategory",
	DEVICE_CATEGORY = "devicecategory",
	DEVICE_MODEL = "devicemodel",
	DEVICE_PRODUCT_VERSION = "deviceversion",
	DEVICE_SERIAL_NUMBER = "deviceserialnumber",
}

const UNMANAGED_DEVICES_CORE_TAGS = Object.values(
	UnmanagedDeviceCoreTagKey
).reduce(
	(acc, key) => ({
		...acc,
		[key]: FacetGroups.Tags,
	}),
	{}
);

export const UNMANAGED_DEVICES_CORE_TAG_LABELS: {
	[key in UnmanagedDeviceCoreTagKey]: string;
} = {
	[UnmanagedDeviceCoreTagKey.DEVICE_MANUFACTURER]: "Manufacturer",
	[UnmanagedDeviceCoreTagKey.DEVICE_SUBCATEGORY]: "Sub Category",
	[UnmanagedDeviceCoreTagKey.DEVICE_CATEGORY]: "Category",
	[UnmanagedDeviceCoreTagKey.DEVICE_MODEL]: "Model",
	[UnmanagedDeviceCoreTagKey.DEVICE_PRODUCT_VERSION]: "Version",
	[UnmanagedDeviceCoreTagKey.DEVICE_SERIAL_NUMBER]: "Serial Number",
};

export const FACET_ORDER: FacetGroupInfo = {
	...UNMANAGED_DEVICES_CORE_TAGS,
	devicename: FacetGroups.Devices,
	deviceip: FacetGroups.Devices,
	devicemac: FacetGroups.Devices,
	deviceavailability: FacetGroups.Devices,
	devicevulnerabilities: FacetGroups.Security,
	devicevulnerabilityseverity: FacetGroups.Security,
	deviceknownexploitvulnerability: FacetGroups.Security,
	devicelateralmovementvulnerability: FacetGroups.Security,
	agentname: FacetGroups.Gatekeepers,
	agentlocation: FacetGroups.Gatekeepers,
	devicevendor: FacetGroups.Gatekeepers,
	agentstatus: FacetGroups.Agents,
	currentversion: FacetGroups.Agents,
};

export const UNMANAGED_DEVICES_DEVICE_AVAILABILITY: Record<string, number> = {
	active: 0,
	connected: 1,
	disconnected: 2,
};

export const MAX_MANAGE_COUNT = 10;
