import { Link, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CTDrawer } from "common/molecules/drawer";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { portLateralMovementTechniquesProps } from "pages/ports/types";

type Props = {
	lateralMovementTechniques: portLateralMovementTechniquesProps[] | undefined;
	onClose: () => void;
	isOpen: boolean;
};

const columns = [
	{
		field: "techniqueid",
		headerName: "Technique ID",
		flex: 0.7,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Link
					underline="hover"
					component={"a"}
					href={params.row.techniqueUrl ?? ""}
					typography={"body2"}
					sx={{ color: "primary.main" }}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					{params.row.techniqueId}
				</Link>
			);
		},
	},
	{
		field: "techniquename",
		headerName: "Technique Name",
		flex: 1,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip title={params.row.techniqueName}>
					<Typography
						sx={{
							maxWidth: "100%",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
						variant="body2"
					>
						{params.row.techniqueName}
					</Typography>
				</Tooltip>
			);
		},
	},
];

function PortLateralMovementDrawer({
	lateralMovementTechniques,
	onClose,
	isOpen,
}: Props) {
	return (
		<CTDrawer
			open={isOpen}
			onClose={onClose}
			title={window.getCTTranslatedText("MITRE Attack Techniques")}
			drawerWidth="50%"
		>
			<DataGrid
				rowHeight={64}
				columns={columns}
				rows={lateralMovementTechniques}
				rowCount={(lateralMovementTechniques ?? []).length}
				getRowId={props => props.techniqueId}
				pagination
				sortingMode="client"
				checkboxSelection={false}
			/>
		</CTDrawer>
	);
}

export default PortLateralMovementDrawer;
