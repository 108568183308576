export type FirewallHostGroupListItem = {
	crowdstrikeFirewallHostgroupName: string;
	crowdstrikeFirewallHostgroupId: string;
	description: string;
	criteria: string;
	criteriaAsParams: string;
	createdAt: string;
};

export type FirewallhostGroupDataGridRowItem = FirewallHostGroupListItem & {
	precedence: number;
};

export enum ManagedByValue {
	ColorTokens = "colortokens",
	CrowdStrike = "crowdstrike",
}
