import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";

export interface Route {
	pathname: string;
	oldPath?: string;
	title?: string;
	queryParams?: string;
	redirect?: string;
	displayTitle?: boolean;
	secondaryBreadcrumbTitle?: string;
}

interface Routes {
	[key: string]: Route;
}

export const ROUTES: Routes = {
	ALL: {
		pathname: "*",
	},
	DASHBOARD: {
		pathname: "/dashboard",
		title: "Dashboard",
	},
	AUTH_LOGIN: {
		pathname: "/auth/login",
		title: "Login",
	},
	AUTH_INVITE: {
		pathname: "/auth/invite",
		title: "Invite",
	},
	AUTH_SIGNUP: {
		pathname: "/auth/signup",
		title: "Signup",
	},
	[FEATURES.PANOPTIC_MAP]: {
		pathname: "/visualizer",
		title: "Panoptic Map",
	},
	ASSETS: {
		pathname: "/assets",
		title: "Assets",
	},
	ASSET: {
		pathname: "/assets/:assetId",
		title: "Asset",
	},
	USER_SEGMENTATION: {
		title: "User Groups",
		pathname: "/users",
	},
	TAGS: {
		pathname: "/tags",
		title: "Tags",
		redirect: "/tags/tag-rules",
	},
	TAG: {
		pathname: "/tags/:tag",
		title: "Tag",
	},
	SEGMENT: {
		pathname: "/segment",
		redirect: "/segment",
		title: "Segments",
	},
	NETWORK_DATA: {
		pathname: "/network-data",
		title: "Network Data",
		redirect: "/network-data/ports",
	},
	PORTS: {
		pathname: "/network-data/ports",
		redirect: "/network-data/ports",
		title: "Ports",
		oldPath: "/ports",
	},
	PORT: {
		pathname: "/network-data/port",
		redirect: "/network-data/port",
		title: "Port",
		oldPath: "/port",
	},
	PATHS: {
		pathname: "/network-data/paths",
		redirect: "/network-data/paths",
		title: "Paths",
		oldPath: "/paths",
	},
	PATH: {
		pathname: "/network-data/path",
		redirect: "/network-data/path",
		title: "Path",
		oldPath: "/path",
	},
	TEMPLATES: {
		pathname: "/templates",
		title: "Templates",
	},
	TEMPLATE: {
		pathname: "/templates/:templateId",
		title: "Template",
	},
	NETWORKS: {
		pathname: "/networks",
		title: "Named Networks",
	},
	NETWORK: {
		pathname: "/networks/:networkId",
		redirect: "/networks/:networkId",
		title: "Network",
	},
	CONNECTORDOWNLOAD: {
		pathname: "/connectors/download",
		title: "Download",
	},
	CROWDSTRIKE_GROUPS: {
		pathname: "/crowdstrike-groups",
		title: "Host Groups",
	},
	CREATE_CROWDSTRIKE_SEGMENT: {
		pathname: "/segment/create-crowdstrike-segment",
		redirect: "/segment/create-crowdstrike-segment",
		title: "Create CrowdStrike Segment",
	},
	EDIT_CROWDSTRIKE_SEGMENT: {
		pathname: "/segment/edit-crowdstrike-segment/:tagBasedPolicyId",
		redirect: "/segment/edit-crowdstrike-segment/:tagBasedPolicyId",
		title: "Edit CrowdStrike Segment",
	},
	EDIT_CROWDSTRIKE_SEGMENT_PRECEDENCE: {
		title: "Edit CrowdStrike Segment Precedence",
		pathname: "/segment/edit-crowdstrike-segment-precedence",
		redirect: "/segment/edit-crowdstrike-segment-precedence",
	},
	AGENTS: {
		pathname: "/sensors/agents",
		title: "Agents",
		oldPath: "/agents",
	},
	APPLIANCES: {
		pathname: "/sensors/appliances",
		redirect: "/sensors/appliances",
		title: "Gatekeepers",
		oldPath: "/appliances",
	},
	APPLIANCE: {
		pathname: "/sensors/appliances/:applianceName",
		redirect: "/sensors/appliances/:applianceName",
		title: "Gatekeeper",
		oldPath: "/appliances/:applianceName",
	},
	DEVICES: {
		pathname: "/devices/:assetId",
		title: "Gatekeepers",
		secondaryBreadcrumbTitle: "Devices",
	},

	CLUSTERS: {
		pathname: "/sensors/containers",
		redirect: "/sensors/containers",
		title: "Containers",
		oldPath: "/containers",
	},
	AGENT: {
		pathname: "/sensors/agents/:assetId",
		redirect: "/sensors/agents/:assetId",
		title: "Asset",
		oldPath: "/agents/:assetId",
	},
	SENSORS: {
		pathname: "/sensors",
		title: "Sensors",
		redirect: "/sensors/agents",
	},
	MONITORING: {
		pathname: "/monitoring",
		title: "Monitor",
		redirect: "/monitoring/alerts",
	},
	MONITORING_ALERTS: {
		pathname: "/monitoring/alerts",
		title: "Alerts",
	},
	MONITORING_LOGS: {
		pathname: "/monitoring/logs",
		title: "Logs",
	},
	MONITORING_BG_PROCESSES: {
		pathname: "/monitoring/system-tasks",
		title: "System Tasks",
	},
	SETTINGS: {
		pathname: "/settings",
		title: "Settings",
		redirect: "/settings/general",
	},
	SETTINGS_GENERAL: {
		pathname: "/settings/general",
		title: "General",
	},
	SETTINGS_ORGANIZATION: {
		pathname: "/settings/user-management",
		redirect: "/settings/user-management",
		title: "User Management",
		oldPath: "/organization",
	},
	SETTINGS_ALERTS: {
		pathname: "/settings/alert-rules",
		title: "Alert Rules",
	},
	SETTINGS_INTEGRATIONS: {
		pathname: "/settings/integrations",
		redirect: "/settings/integrations",
		title: "Integrations",
		oldPath: "/integrations",
	},
	SETTINGS_APIS: {
		pathname: "/settings/api-keys",
		redirect: "/settings/api-keys",
		oldPath: "/api-keys",
		title: "API Keys",
	},
	PROFILE: {
		pathname: "/profile",
		title: "Profile",
	},
	USERS: {
		pathname: "/integrations/users",
		title: "Users",
		displayTitle: true,
	},
	GROUPS: {
		pathname: "/integrations/groups",
		title: "User Groups",
		displayTitle: true,
	},
	DEPARTMENTS: {
		pathname: "/integrations/departments",
		title: "Departments",
		displayTitle: true,
	},
	INSTALL_AGENTS: {
		pathname: "/sensors/agents/install-agents",
		redirect: "/sensors/agents/install-agents",
		title: "Install Agent",
		oldPath: "/agents/install-agents",
	},
	REPORTS: {
		pathname: "/reports",
		title: "Reports",
	},
	INSTALL_APPLIANCES: {
		pathname: "/sensors/appliances/install-appliances",
		redirect: "/sensors/appliances/install-appliances",
		title: "installGatekeeper",
		oldPath: "/appliances/install-appliances",
	},
	INSTALL_COLLECTOR: {
		pathname: "/sensors/containers/install-agent",
		redirect: "/sensors/containers/install-agent",
		title: "Install Agent",
		oldPath: "/containers/install-agent",
	},
	VISUALIZER: {
		pathname: "/visualizer",
		title: "Visualizer",
	},
	TAG_NAMES: {
		pathname: "/tags/tag-names",
		title: "Tag Names",
	},
	TAG_LABEL_RULES: {
		pathname: "/tags/tag-rules",
		redirect: "/tags/tag-rules",
		title: "Tag Rules",
	},

	TAG_RULE: {
		oldPath: "/tag-rules/create",
		pathname: "/tags/tag-rules/create",
		title: "Tag Rule",
		redirect: "/tags/tag-rules/create",
	},
	EDIT_RULE: {
		oldPath: "/tag-rules/edit-rule/:ruleId",
		pathname: "/tags/tag-rules/edit-rule/:ruleId",
		redirect: "/tags/tag-rules/edit-rule/:ruleId",
		title: "Edit",
	},
	SEGMENT_CREATION: {
		pathname: "/segment/create",
		title: "segments",
		redirect: "/segment/create",
		oldPath: "/segment/create",
	},
	EDIT_POLICY: {
		pathname: "/tag-policy/edit-policy/:tagBasedPolicyId",
		title: "Edit",
	},
	SEGMENT_EDIT: {
		pathname: "/segment/edit/:tagBasedPolicyId",
		redirect: "/segment/edit/:tagBasedPolicyId",
		title: "Edit",
	},
	CLONE_POLICY: {
		pathname: "/tag-policy/clone-policy/:tagBasedPolicyId",
		title: "Clone",
	},
	SEGMENT_CLONE: {
		pathname: "/segment/clone/:tagBasedPolicyId",
		redirect: "/segment/clone/:tagBasedPolicyId",
		title: "Clone",
	},
};
export const SECONDARY_TITLE_TO_PATH_MAP: Record<string, string> = {
	APPLIANCES: "devices",
};
