import { Button, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { Link } from "react-router-dom";
import { ROUTES } from "routes";
import { TagRulesList } from "./components/tag-rules-list";

export function TagLabelRules() {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	return (
		<Stack sx={{ height: "100%" }}>
			{userPermissions.has("CREATE_TAGRULE") && (
				<Stack direction="row" justifyContent="flex-end" mb="1.5rem">
					<Link to={ROUTES.TAG_RULE.pathname}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {}}
							sx={{
								ml: 2,
							}}
						>
							{window.getCTTranslatedText("Create tag label rule")}
						</Button>
					</Link>
				</Stack>
			)}
			<TagRulesList />
		</Stack>
	);
}
