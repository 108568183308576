import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import { CopyButton } from "common/atoms/copy-button";
import { CTDrawer } from "common/molecules/drawer";
import { CTDrawerProps } from "common/molecules/drawer/CTDrawer";
import { Fragment, useMemo, useState } from "react";

interface APIKeysCredentialsResult {
	userId: string;
	tenantId: string;
}

export function APIKeyCreds() {
	const [showCredential, setShowCredentials] = useState(false);

	return (
		<>
			<Button
				variant="outlined"
				onClick={() => {
					setShowCredentials(true);
				}}
			>
				{window.getCTTranslatedText("View Credentials")}
			</Button>
			<CredentialsDrawer
				title="ColorTokens API IDs"
				open={showCredential}
				onClose={() => {
					setShowCredentials(false);
				}}
			/>
		</>
	);
}

function useAPIKeysCredentials() {
	return useQuery<APIKeysCredentialsResult, Error>([
		"apiKeyCredentials",
		"auth/user/api-key-creds",
	]);
}

function CredentialsDrawer(props: CTDrawerProps) {
	const { data, isLoading } = useAPIKeysCredentials();

	const items = useMemo(
		() => [
			{
				label: window.getCTTranslatedText("Tenant ID"),
				value: data?.tenantId,
			},
			{
				label: window.getCTTranslatedText("User ID"),
				value: data?.userId,
			},
		],
		[data]
	);

	return (
		<CTDrawer
			variant="temporary"
			anchor="right"
			drawerWidth="560px"
			secondaryBtnVariant="contained"
			{...props}
		>
			{isLoading && (
				<CircularProgress
					sx={{ alignSelf: "center", justifySelf: "center" }}
					size={24}
				/>
			)}
			{items
				.filter(({ value }) => !!value)
				.map(({ label, value }) => (
					<Fragment key={label}>
						<Typography variant="subtitle1">
							{window.getCTTranslatedText(label)}
						</Typography>
						<Paper
							key={label}
							elevation={0}
							variant="outlined"
							sx={{ mt: "0.5rem", mb: "2rem", pl: 3, pr: 2, py: 2 }}
						>
							<Stack
								direction={"row"}
								spacing={1}
								alignItems="center"
								justifyContent={"space-between"}
							>
								<Typography variant="body1" fontWeight={400}>
									{value}
								</Typography>
								<CopyButton text={value} />
							</Stack>
						</Paper>
					</Fragment>
				))}
		</CTDrawer>
	);
}
