import { ChevronRight } from "@mui/icons-material";
import {
	Collapse,
	List,
	ListItemButton,
	Tooltip,
	Typography,
} from "@mui/material";
import useIcons from "common/hooks/useIcons";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { IconDictionary } from "common/types/types";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavMainItemKey } from "../constants";
import { NavBottomItems, NavSubItems, NavTopItems } from "../types";
import useNavSidebarStore from "../useNavSidebarStore";
import { StyledNavListItem } from "./styles";

interface ItemProps {
	active: boolean;
	Icon: (overrideProps?: any) => JSX.Element;
	variant: "main" | "sub";
	label: string;
	hasSubItems: boolean;
	isMainItemSubMenuOpen: boolean;
}

const Item = ({
	active,
	Icon,
	variant,
	label,
	hasSubItems,
	isMainItemSubMenuOpen,
}: ItemProps) => {
	const { isSidebarExpanded: isMenuExpanded } = useUserPreferencesStore();

	return (
		<ListItemButton
			className={`
				${active ? "active" : ""} 
				${isMenuExpanded ? "menuExpanded" : "menuClosed"} 
				${isMainItemSubMenuOpen ? "subMenuOpen" : ""}
			`}
			disableRipple
		>
			<Icon className="menuIcon" />
			<Collapse
				className="textCollapse"
				orientation="horizontal"
				in={(variant === "main" && isMenuExpanded) || variant === "sub"}
			>
				<Typography
					variant="body2"
					color={theme => `${theme.palette.text.primary} !important`}
				>
					{window.getCTTranslatedText(label ?? "")}
				</Typography>
				{hasSubItems && <ChevronRight className="subMenuArrow" />}
			</Collapse>
		</ListItemButton>
	);
};

const NavList = ({
	items,
	variant,
}: {
	items: NavTopItems | NavBottomItems | NavSubItems;
	variant: "main" | "sub";
}) => {
	const {
		selectedMainNavItemKey,
		setSubMenuAnchor,
		setSelectedMainNavItemKey,
		closeSubMenu,
		subMenuAnchor,
	} = useNavSidebarStore();

	const { isSidebarExpanded: isMenuExpanded } = useUserPreferencesStore();

	const { getIcon } = useIcons();
	const { pathname } = useLocation();

	const [tooltipKey, setTooltipKey] = useState<string | null>(null);

	const itemsToRender = Object.entries(items).filter(([_, item]) =>
		"subItems" in item
			? Object.values(item.subItems ?? {})?.some(({ permission }) =>
					typeof permission === "boolean" ? permission : true
				)
			: item.permission !== false
	);

	return (
		<List disablePadding>
			{itemsToRender.map(([key, item], index) => {
				const fullPath = item.path + (item?.searchParams ?? "");
				const hasSubItems = "subItems" in item;
				const Icon =
					variant === "main"
						? getIcon(IconDictionary.NAVBAR, key as NavMainItemKey)
						: () => <></>;

				return (
					<Tooltip
						title={window.getCTTranslatedText(item?.label ?? "")}
						disableHoverListener={
							isMenuExpanded || hasSubItems || variant === "sub"
						}
						placement="right"
						key={`tooltip-${key}-${index}`}
						disableInteractive
						open={tooltipKey === key}
						onOpen={() => setTooltipKey(key)}
						onClose={() => {
							setTooltipKey(null);
						}}
					>
						<StyledNavListItem
							key={key}
							animate={false}
							animationDelay={100 * index}
							disablePadding
						>
							<NavLink
								to={fullPath || ".."}
								onMouseEnter={e => {
									if (variant === "main") {
										setSelectedMainNavItemKey(key as NavMainItemKey);
									}
									if (hasSubItems) {
										setTooltipKey(null);
										setSubMenuAnchor(e.currentTarget);
									} else if (variant === "main") {
										closeSubMenu();
									}
								}}
								onClick={e => {
									if (hasSubItems) {
										e.preventDefault();
									} else {
										closeSubMenu();
									}
								}}
							>
								{({ isActive }) => {
									const active = hasSubItems
										? Object.values(item.subItems ?? {}).some(({ path }) =>
												path ? pathname.includes(path) : false
											)
										: isActive;

									return (
										<Item
											key={key}
											isMainItemSubMenuOpen={
												key === selectedMainNavItemKey && subMenuAnchor !== null
											}
											active={active}
											Icon={Icon}
											variant={variant}
											label={item.label}
											hasSubItems={hasSubItems}
										/>
									);
								}}
							</NavLink>
						</StyledNavListItem>
					</Tooltip>
				);
			})}
		</List>
	);
};

export default NavList;
