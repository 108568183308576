import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton, Tooltip } from "@mui/material";
import { PathRule } from "pages/paths/types";
import { PortRule } from "pages/ports/types";
import { useState } from "react";
import { TemplateRemoveRule } from "../template-remove-rule";

export interface TemplatePortRule extends PortRule {
	oobTemplate?: boolean;
	templateId?: string;
}

export interface TemplatePathRule extends PathRule {
	oobTemplate?: boolean;
	templateId?: string;
}

interface RemoveTemplateRuleProps {
	rule: TemplatePortRule | TemplatePathRule;
	page: string;
	secondaryText?: string | JSX.Element;
}

export function RemoveTemplateRuleDialog({
	rule,
	page,
	secondaryText,
}: RemoveTemplateRuleProps) {
	const [allowRemove, setAllowRemove] = useState(false);

	const handleAllowRemove = (value: boolean) => {
		if (!rule?.oobTemplate) {
			setAllowRemove(value);
		}
	};

	const getTooltipTitleText = () => {
		if (rule?.oobTemplate) {
			return "System template cannot be modified";
		}

		if (page === "port") {
			return "removePortFromTemplate";
		}

		return "removePathFromTemplate";
	};

	return (
		<Box>
			<Tooltip title={window.getCTTranslatedText(getTooltipTitleText())}>
				<span>
					<IconButton
						disabled={rule?.oobTemplate}
						aria-label="Remove"
						onClick={() => handleAllowRemove(true)}
						disableRipple={rule?.oobTemplate ? true : false}
						sx={{ cursor: rule?.oobTemplate ? `not-allowed` : "pointer" }}
					>
						<DeleteOutlineIcon
							color={rule?.oobTemplate ? "disabled" : "action"}
						/>
					</IconButton>
				</span>
			</Tooltip>
			{allowRemove && (
				<TemplateRemoveRule
					rule={rule}
					page={page}
					allowRemove={allowRemove}
					handleAllowRemove={handleAllowRemove}
					secondaryText={secondaryText}
				/>
			)}
		</Box>
	);
}
