import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { IPRange } from "pages/networks/types";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import { AssignNetworkDialog } from "../AssignNetworkDialog";

export interface AssignNetworksDrawerProps {
	isOpen: boolean;
	page: string;
	title?: string;
	rules?: Array<IPRange> | Array<String> | Array<GridRowId> | undefined;
	onCancel: () => void;
	onConfirm: () => void;
	btnTitle?: string;
	criteria?: string;
	multiselect?: boolean;
	onSelectCallback?: Function;
	policyId?: string;
	hasPermission?: boolean;
	policy?: TagPolicy;
	showDialog?: boolean;
	autoSuggestName?: string;
}

export const AssignNetworksDrawer = ({
	isOpen,
	page,
	title,
	rules,
	onCancel,
	onConfirm,
	btnTitle = "assign",
	criteria,
	multiselect = false,
	onSelectCallback,
	policyId,
	hasPermission,
	policy,
	showDialog,
	autoSuggestName,
}: AssignNetworksDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onCancel}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onCancel}
						sx={{ position: "absolute", right: "16px", top: "8px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<AssignNetworkDialog
				onCancel={onCancel}
				onConfirm={onConfirm}
				page={page}
				open={isOpen}
				rules={rules}
				title={title || "Assign Network"}
				btnTitle={btnTitle}
				criteria={criteria}
				multiselect={multiselect}
				onSelectCallback={onSelectCallback}
				policyId={policyId}
				hasPermission={hasPermission}
				policy={policy}
				showDialog={showDialog}
				autoSuggestName={autoSuggestName}
			/>
		</Drawer>
	);
};
