import { GridApiPro, GridRowId } from "@mui/x-data-grid-pro";
import { MutableRefObject } from "react";

export const scrollToDataGridRow = (
	dataGridApiRef: MutableRefObject<GridApiPro>,
	rowId: GridRowId
) => {
	if ("getRowIndexRelativeToVisibleRows" in dataGridApiRef.current) {
		const { getRowIndexRelativeToVisibleRows, scrollToIndexes } =
			dataGridApiRef.current;
		const rowIndex = getRowIndexRelativeToVisibleRows(rowId);
		scrollToIndexes({ rowIndex });
	}
};
