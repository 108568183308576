import { Box, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import {
	getAbsoluteLastObserved,
	getFacetGroupInfo,
	getRelativeLastObserved,
} from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useCore } from "modules/core";
import { SortOrder } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { RecommendationsButton } from "modules/recommendation-workflows";
import { PathRecommendationPageType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { Scope } from "modules/scope-metadata/types";
import prettyBytes from "pretty-bytes";
import { useCallback, useMemo } from "react";
import { PathDataGrid } from "./components/path-data-grid";
import { isRowSelectable } from "./components/path-data-grid/helpers";
import { formatCoreTagsAsset } from "./components/path-data-grid/helpers/columnHelpers";
import { usePathsAPI } from "./components/path-data-grid/hooks";
import { PATH_TIME_FILTER_OPTIONS, usePathsFacetsOrder } from "./constants";
import { usePathStore } from "./store";
import { Path } from "./types";

const readOnlyDimensions = ["pathlastobserved"];

export const Paths = () => {
	const theme = useTheme();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const pathsFacetOrder = usePathsFacetsOrder();
	const PATHS_FACET_GROUP_INFO_WITHOUT_READ_ONLY_DIMENSIONS = useMemo(
		() => getFacetGroupInfo(pathsFacetOrder, readOnlyDimensions),
		[pathsFacetOrder]
	);

	const coreResponse = useCore<Path>({
		useStore: usePathStore,
		facetGroupInfo: PATHS_FACET_GROUP_INFO_WITHOUT_READ_ONLY_DIMENSIONS,
		scope: Scope.Path,
		dataMapper: path => {
			path.rawBandwidthInBytes = path.bandwidthInBytes;
			path.rawPathLastObserved = path.pathLastObserved;
			path.bandwidthInBytes = prettyBytes(Number(path.bandwidthInBytes));
			path.pathLastObservedAbsolute = getAbsoluteLastObserved(
				path.pathLastObserved
			);
			path.pathLastObserved = getRelativeLastObserved(
				path.pathLastObserved,
				true
			);
			path.isRowSelectable = isRowSelectable(path);
			if (path?.sourceAsset) {
				path.sourceAsset.coreTags = formatCoreTagsAsset(path?.sourceAsset);
			}
			if (path?.destinationAsset) {
				path.destinationAsset.coreTags = formatCoreTagsAsset(
					path?.destinationAsset
				);
			}
			path.matchedByTemplatesList = `"${path.matchedByTemplates
				?.map(template => template?.templateName ?? "")
				?.join(", ")}"`;

			return path;
		},
		defaultSortOrder: [{ field: "pathlastobserved", order: "desc" }],
		useApi: usePathsAPI,
		pageSize: 100,
	});

	const onSortChange = useCallback(
		(sortOrder: SortOrder[]) => {
			if (sortOrder?.length > 0) {
				sortOrder = sortOrder.map(item => {
					item.field = item.field.toLowerCase();
					return item;
				});

				const newSortOrder = sortOrder.map(sort => {
					if (sort.field === "pathlastobservedtimestamp") {
						return {
							field: "pathlastobserved",
							order: sort.order,
						};
					}

					return sort;
				});

				coreResponse.onSortChange(newSortOrder);
			}
		},
		[coreResponse]
	);

	const metadata = useCommonStore(state => state.metadata);
	const criteria = useCommonStore(state => state.currentSearchCriteria);
	const rowCount =
		coreResponse.rowCount > 0 &&
		coreResponse.rowCount === coreResponse.maxRowCount
			? coreResponse.rowCount - 1
			: coreResponse.rowCount;

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack direction={"row"} alignItems={"center"}>
				<FacetOpenCloseController
					facetsOpen={coreResponse.facetsOpen}
					setFacetsOpen={coreResponse.setFacetsOpen}
					readOnlyDimensions={readOnlyDimensions}
					scope={Scope.Path}
					timeFilterConfig={{
						shouldShowTimeFilter: true,
						options: PATH_TIME_FILTER_OPTIONS,
					}}
				>
					<FacetControllerGroup
						config={coreResponse.facetConfig}
						value={coreResponse.facetState}
						onChange={coreResponse.updateFacet}
					/>
				</FacetOpenCloseController>
				{userPermissions.has("UPDATE_TEMPLATE") && (
					<Box sx={{ ml: 2 }}>
						<RecommendationsButton
							page={PathRecommendationPageType.Paths}
							criteria={criteria}
							disabled={!Boolean(coreResponse?.facetState?.size)}
						/>
					</Box>
				)}
			</Stack>

			<Box
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					overflowY: "auto",
					flex: 1,
					height: "100%",
				}}
			>
				<Paper sx={{ height: "100%" }}>
					<PathDataGrid
						initialState={{
							sorting: {
								sortModel: [{ field: "pathlastobserved", sort: "desc" }],
							},
						}}
						rows={coreResponse.rows}
						originalRowCount={coreResponse.rowCount}
						rowCount={rowCount}
						maxRowCount={coreResponse.maxRowCount}
						mutation={coreResponse.mutation}
						onPageChange={coreResponse.onPageChange}
						page={coreResponse.page}
						pageSize={coreResponse.pageSize}
						onPageSizeChange={coreResponse.onPageSizeChange}
						onSortChange={onSortChange}
						metadata={metadata}
						triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
						getExportStatus={coreResponse?.getExportStatus}
						getUrlToDownload={coreResponse?.getUrlToDownload}
						resetDownloadUrl={coreResponse?.resetDownloadUrl}
						rawData={coreResponse?.rawData}
					/>
				</Paper>
			</Box>
		</Stack>
	);
};
