export const RULES_TITLE_WRAPPER = {
	py: 3,
	px: 4,
	borderRadius: 1,
};

export const BASE_ROW_STYLE = {
	py: 3,
	px: 2,
};
