import { Box, Button } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import { useTagTemplates } from "../../hooks";
import { useAssignedTemplatesTags } from "./hooks";

export function AssignedTemplatesTags({
	criteria,
	multiselect = false,
	policyId,
	open,
	onClose,
	policy,
	showDialog,
}: {
	criteria?: string;
	multiselect?: boolean;
	policyId?: string;
	open: boolean;
	onClose: () => void;
	policy?: TagPolicy;
	showDialog?: boolean;
}) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const { templateList, isLoading, onSortChange, onSearchCriteriaChange } =
		useAssignedTemplatesTags({
			criteria,
			policyId,
		});

	const {
		showAddToTemplateDrawer,
		openAddToTemplateDrawer,
		closeAddToTemplateDrawer,
	} = useTagTemplates();

	if (!criteria) {
		return <Box style={{ width: 42 }} />;
	}

	return (
		<>
			{showAddToTemplateDrawer && (
				<AddToTemplateDrawer
					isOpen={showAddToTemplateDrawer}
					page={"tags"}
					title="Assign Template"
					rules={[]}
					onCancel={closeAddToTemplateDrawer}
					onConfirm={closeAddToTemplateDrawer}
					btnTitle={"assign"}
					criteria={criteria}
					multiselect={multiselect}
					policyId={policyId}
					hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
					actionType={TemplateActionType.assign}
					policy={policy}
					showDialog={showDialog}
				/>
			)}

			{open && (
				<AssignedTemplatesViewDrawer
					isOpen={open}
					onClose={onClose}
					templateList={templateList}
					templatesSuggestMutation={undefined}
					isLoading={isLoading}
					AssignTemplatesOption={() =>
						AssignTemplatesOption({
							openAddToTemplateDrawer,
							hasPermission: userPermissions.has("UPDATE_TAGBASEDPOLICY"),
						})
					}
					page={"tags"}
					policyId={policyId}
					onSortChange={onSortChange}
					multiselect={true}
					hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
					showSearch={true}
					onSearchCriteriaChange={onSearchCriteriaChange}
					policy={policy}
					showDialog={showDialog}
				/>
			)}
		</>
	);
}

export const AssignTemplatesOption = ({
	openAddToTemplateDrawer,
	hasPermission,
}: {
	openAddToTemplateDrawer: () => void;
	hasPermission?: boolean;
}) => {
	return (
		<>
			{hasPermission ? (
				<Button
					variant="contained"
					color="primary"
					onClick={openAddToTemplateDrawer}
					sx={{
						minWidth: "max-content",
					}}
				>
					{window.getCTTranslatedText("Assign Templates")}
				</Button>
			) : null}
		</>
	);
};
