import { GridRowId } from "@mui/x-data-grid";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { PathStatus } from "pages/paths/types";
import { PortStoreType } from "pages/ports/store/types";
import { PortStatus } from "pages/ports/types";

interface PortStatusActionbarProps {
	selectedStatus: PortStatus | undefined;
	selectedPathStatus?: PathStatus | undefined;
	selection: GridRowId[];
	update: () => void;
	cancel: () => void;
	useStore?: PortStoreType;
	comment?: string | null;
}

export const PortStatusActionBar = ({
	selectedStatus,
	selectedPathStatus = PathStatus.Allow,
	selection,
	update,
	cancel,
}: PortStatusActionbarProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	return (
		<>
			{userPermissions.has("UPDATE_PORT") && (
				<ToolbarAction
					isValid={
						selection?.length > 0 &&
						!!selectedStatus &&
						(selectedStatus !== PortStatus.PathRestricted ||
							(selectedPathStatus &&
								selectedStatus === PortStatus.PathRestricted))
					}
					save={update}
					hidePrimaryBtn
					cancel={cancel}
					hasPermission={userPermissions.has("UPDATE_PATH")}
				></ToolbarAction>
			)}
		</>
	);
};
