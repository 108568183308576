import { Box, Stack } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { AgentColumnRenderProps } from "pages/agents/types";

enum FirewallCoexistenceState {
	DISABLED_RUNNING = "disabled_running",
	ENABLED_STOPPED = "enabled_stopped",
	ENABLED_RUNNING = "enabled_running",
	DISABLED_STOPPED = "disabled_stopped",
}

export function AgentFirewallCoexistenceStatus({
	agent,
	viewOnly,
}: AgentColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ width: 120 }} />;
	}

	const isDisabled = () => {
		if (agent.isFirewallCoexistenceRunning) {
			return true;
		}
		return false;
	};

	const firewallCoexistenceStatusText = () => {
		const statusKey = `${agent.isFirewallCoexistenceEnabled ? "enabled" : "disabled"}_${
			agent.isFirewallCoexistenceRunning ? "running" : "stopped"
		}`;

		switch (statusKey) {
			case FirewallCoexistenceState.ENABLED_RUNNING:
				return window.getCTTranslatedText("Enabling");
			case FirewallCoexistenceState.DISABLED_RUNNING:
				return window.getCTTranslatedText("Disabling");
			case FirewallCoexistenceState.ENABLED_STOPPED:
				return window.getCTTranslatedText("Enabled");
			case FirewallCoexistenceState.DISABLED_STOPPED:
				return window.getCTTranslatedText("Disabled");
			default:
				return "";
		}
	};
	return (
		<Stack sx={{ width: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				asText={viewOnly === true}
				isDisabled={isDisabled()}
				facetName={"fwcoexistencecfg"}
				facetOption={agent.fwCoexistenceConfiguration as string}
			>
				{firewallCoexistenceStatusText()}
			</CTDatagridButton>
		</Stack>
	);
}
