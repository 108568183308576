import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { TIMELINE_LIST } from "pages/dashboard/constants";
import { DashboardTimeFilterProps, TIMELINES } from "pages/dashboard/types";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";

export const DashboardTimeFilter = ({
	selectedTimeline,
	handleTimeLineUpdate,
}: DashboardTimeFilterProps) => {
	const getDisplayText = () => {
		switch (selectedTimeline) {
			case TIMELINES.WEEK:
				return "Last Week";
			case TIMELINES.MONTH:
				return "Last Month";
			default:
				return "";
		}
	};

	return (
		<CTSelectDropDown
			field={"time"}
			displayName={"Time"}
			selectedValue={getDisplayText()}
			handleUpdate={(e: TextFieldUpdate) =>
				handleTimeLineUpdate(e?.value as TIMELINES)
			}
			data={TIMELINE_LIST}
			required={false}
			showLabel={false}
			size="medium"
			showSymbols={false}
		/>
	);
};
