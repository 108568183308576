import { Box, Popover, PopoverProps } from "@mui/material";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import {
	NAV_SIDEBAR_COLLAPSED_WIDTH,
	NAV_SIDEBAR_EXPANDED_WIDTH,
} from "../constants";
import useNavSidebarStore from "../useNavSidebarStore";

interface SubMenuProps extends Omit<PopoverProps, "anchorEl" | "open"> {
	children: React.ReactNode;
}

export function NavSubMenu({ children, ...rest }: SubMenuProps) {
	const { subMenuAnchor, closeSubMenu } = useNavSidebarStore();
	const { isSidebarExpanded: isMenuExpanded } = useUserPreferencesStore();

	const isSubMenuOpen = Boolean(subMenuAnchor);
	const backdropOffset =
		(isMenuExpanded
			? NAV_SIDEBAR_EXPANDED_WIDTH
			: NAV_SIDEBAR_COLLAPSED_WIDTH) + 2;

	return (
		<Popover
			anchorEl={subMenuAnchor}
			open={isSubMenuOpen}
			components={{
				Backdrop: () => (
					<Box sx={{ height: "100%" }} onMouseOver={closeSubMenu} />
				),
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			sx={{
				left: `${backdropOffset}px`,
			}}
			PaperProps={{
				elevation: 8,
				sx: {
					p: "1rem 0.75rem",
					minWidth: 200,
					translate: `${-backdropOffset + 14}px`,
					pointerEvents: "initial",
				},
			}}
			{...rest}
		>
			{children}
		</Popover>
	);
}
