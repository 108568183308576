import { AssetSecurityLevelIcons, SecurityLevels } from "@colortokens/ng-ui";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { VulnerabilityReadOnlyBody } from "modules/vulnerability-drawer/types";
import { getVulnerabilitySeverityText } from "./utils";

export const VULNERABILITY_COLUMNS: GridColDef[] = [
	{
		field: "cveID",
		headerName: "CVE ID",
		minWidth: 150,
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <CVEIDCell vulnerability={params.row} />;
		},
	},
	{
		field: "description",
		headerName: "Description",
		minWidth: 220,
		flex: window.screen.availWidth > 1200 ? 1 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip title={params.row.description}>
					<Typography variant="body2">{params.row.description}</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "product",
		headerName: "Product",
		minWidth: 150,
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip
					title={`(${params.row.vendor}) ${params.row.product} ${params.row.version}`}
				>
					<Typography variant="body2">
						{params.row.product} {params.row.version}
					</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "cvssscore",
		headerName: "CVSS Score",
		minWidth: 100,
		align: "center",
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <Typography variant="body2">{params.row.cvssscore}</Typography>;
		},
	},
	{
		field: "severity",
		headerName: "Severity",
		minWidth: 100,
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <SeverityCell vulnerability={params.row} />;
		},
	},
	{
		field: "exploitURL",
		headerName: "Known Exploit URL",
		minWidth: 160,
		align: "center",
		flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <URLCell url={params.row.exploitURL} />;
		},
	},
	{
		field: "patchURL",
		headerName: "Patch URL",
		minWidth: 160,
		align: "center",
		flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <URLCell url={params.row.patchURL} />;
		},
	},
	{
		field: "remotelyExploitable",
		headerName: "lateralMovementVulnerability",
		description: "remoteCodeExecutionVulnerability",
		minWidth: 190,
		flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
		sortable: true,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Typography variant="body2">
					{window.getCTTranslatedText(
						params.row.remotelyExploitable ? "Yes" : "No"
					)}
				</Typography>
			);
		},
	},
	{
		field: "affectedPorts",
		headerName: "Affected Ports",
		minWidth: 180,
		align: "left",
		flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AffectedPortsCell vulnerability={params.row} />;
		},
	},
];

const AffectedPortsCell = ({ vulnerability }: VulnerabilityReadOnlyBody) => {
	return (
		<Typography variant="body2">
			{vulnerability?.affectedPorts?.join(", ")}
		</Typography>
	);
};

const CVEIDCell = ({ vulnerability }: VulnerabilityReadOnlyBody) => {
	return (
		<Link
			underline="none"
			component={"a"}
			rel="noreferrer"
			target="_blank"
			href={`https://nvd.nist.gov/vuln/detail/${vulnerability.cveID}`}
			display="flex"
		>
			<Typography variant="body2">{vulnerability.cveID}</Typography>
			<OpenInNewIcon sx={{ ml: 1 }} color="action" fontSize="small" />
		</Link>
	);
};

const URLCell = ({ url }: { url: string }) => {
	return (
		<>
			{url ? (
				<Link
					underline="none"
					component={"a"}
					color="inherit"
					target="_blank"
					href={url}
				>
					<OpenInNewIcon color="action" fontSize="small" />
				</Link>
			) : null}
		</>
	);
};

const SeverityCell = ({ vulnerability }: VulnerabilityReadOnlyBody) => {
	const severity = getVulnerabilitySeverityText(vulnerability.severity);
	return (
		<Stack direction="row" alignItems="center">
			<AssetSecurityLevelIcons
				securityLevel={severity as SecurityLevels}
				sx={{ mr: 1 }}
			/>
			<Typography variant="body2">
				{window.getCTTranslatedText(severity)}
			</Typography>
		</Stack>
	);
};
