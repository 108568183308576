import { Box, Tooltip } from "@mui/material";
import React from "react";

interface Props {
	disabled: boolean;
	children: React.ReactNode;
}

function AssetDetailOperationsWrapper({ disabled, children }: Props) {
	if (!disabled) return <>{children}</>;

	return (
		<Tooltip
			title={window.getCTTranslatedText(
				"CrowdStrikeAssetEnforcementOperationDisabled"
			)}
			followCursor
		>
			<Box>
				<Box
					sx={{
						opacity: disabled ? 0.5 : 1,
						pointerEvents: disabled ? "none" : "all",
					}}
				>
					{children}
				</Box>
			</Box>
		</Tooltip>
	);
}

export default AssetDetailOperationsWrapper;
