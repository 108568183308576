import { Direction, SecurityStatus } from "../constants";

interface BuildPortsCriteriaProps {
	assetNames?: string[];
	assetIds?: string[];
	currentStatus: SecurityStatus;
	securityStatus: SecurityStatus;
}

interface BuildPathsCriteriaProps {
	assetNames?: string[];
	assetIds?: string[];
	currentStatus?: SecurityStatus;
	securityStatus: SecurityStatus;
	direction: Direction;
}

export const buildPortsCriteria = ({
	assetNames,
	assetIds,
	currentStatus,
	securityStatus,
}: BuildPortsCriteriaProps) => {
	let criteria = `assetId in ('${assetIds?.join("','")}') AND assetname in ('${assetNames?.join("','")}') AND 'listenportreviewed' in ('unreviewed')`;

	// Unsecure -> Secure Internet OR Secure Internet -> Unsecure
	if (
		(currentStatus === SecurityStatus.Unsecure &&
			securityStatus === SecurityStatus.Internet) ||
		(currentStatus === SecurityStatus.Internet &&
			securityStatus === SecurityStatus.Unsecure)
	) {
		criteria += ` AND 'listeningonpublicinterface' in ('true')`;
	}

	// Secure Internet -> Secure All OR Secure All -> Secure Internet
	if (
		(currentStatus === SecurityStatus.Internet &&
			securityStatus === SecurityStatus.All) ||
		(currentStatus === SecurityStatus.All &&
			securityStatus === SecurityStatus.Internet)
	) {
		criteria += ` AND 'listeningonpublicinterface' in ('false')`;
	}

	return criteria;
};

export const buildPathsCriteria = ({
	assetNames,
	assetIds,
	currentStatus,
	securityStatus,
	direction,
}: BuildPathsCriteriaProps) => {
	let criteria = `assetId in ('${assetIds?.join("','")}') AND assetname in ('${assetNames?.join("','")}') AND 'direction' in ('${direction === Direction.Inbound ? "inbound" : "outbound"}') AND 'reviewed' in ('unreviewed')`;

	// Unsecure -> Secure Internet OR Secure Internet -> Unsecure
	if (
		(currentStatus === SecurityStatus.Unsecure &&
			securityStatus === SecurityStatus.Internet) ||
		(currentStatus === SecurityStatus.Internet &&
			securityStatus === SecurityStatus.Unsecure)
	) {
		criteria += ` AND 'compassdirection' in ('north-south')`;
	}

	// Secure Internet -> Secure All OR Secure All -> Secure Internet
	if (
		(currentStatus === SecurityStatus.Internet &&
			securityStatus === SecurityStatus.All) ||
		(currentStatus === SecurityStatus.All &&
			securityStatus === SecurityStatus.Internet)
	) {
		criteria += ` AND 'compassdirection' in ('east-west')`;
	}

	return criteria;
};

export function convertCriteriaToUrlParam(
	criteria: string,
	type?: "ports" | "paths"
) {
	const conditions = splitQuery(criteria);
	let hasTimeFilter = false;

	// Process each condition
	const params = conditions
		.map(condition => {
			// Remove extra spaces
			condition = condition.trim();

			// Check for BETWEEN condition for time filters
			const timeFilterMatch = condition.match(
				/'?(listenportlastobserved|pathlastobserved)'?\s+BETWEEN\s+(\d+)\s+AND\s+(\d+)/i
			);
			if (timeFilterMatch) {
				hasTimeFilter = true;
				return "";
			}

			// Handle BETWEEN conditions
			const betweenMatch = condition.match(
				/'?([^']+)'?\s+BETWEEN\s+(\d+)\s+AND\s+(\d+)/i
			);
			if (betweenMatch) {
				const [, key, min, max] = betweenMatch;
				return `${key}=${min}%2520-%2520${max}`;
			}

			// Handle NOT IN conditions
			const notInMatch = condition.match(/'?([^']+)'?\s+NOT\s+IN\s+\((.*?)\)/i);
			if (notInMatch) {
				const [, key, valuesStr] = notInMatch;
				const values = valuesStr
					.split(",")
					.map(v => v.trim().replace(/^'|'$/g, ""))
					.filter(Boolean)
					.join(",");
				return values ? `${key}!=${values}` : "";
			}

			// Handle IN conditions
			const inMatch = condition.match(/'?([^']+)'?\s+IN\s+\((.*?)\)/i);
			if (inMatch) {
				const [, key, valuesStr] = inMatch;
				const values = valuesStr
					.split(",")
					.map(v => v.trim().replace(/^'|'$/g, ""))
					.filter(Boolean)
					.join(",");
				return values ? `${key}=${values}` : "";
			}

			// If no special conditions match, return the condition as-is
			return condition;
		})
		.filter(Boolean); // Remove any empty strings from the results

	// Add time filter params if needed
	if (!hasTimeFilter) {
		if (type === "paths") {
			params.push("pathlastobserved=alltime");
		}
		if (type === "ports") {
			params.push("listenportlastobserved=alltime");
		}
	} else if (type === "ports") {
		params.push("portdatavolume=0");
		params.push("listenportlastobserved=alltime");
	}

	return params.join("|");
}

export function splitQuery(query: string): string[] {
	const result: string[] = [];
	let currentPart = "";
	let parenthesesCount = 0;
	let betweenFlag = false;
	let numberCountAfterBetween = 0;
	const AND_SEPARATOR = " AND ";

	for (let i = 0; i < query.length; i++) {
		const char = query[i];
		const remainingString = query.substring(i);

		// Update between status
		if (remainingString.toUpperCase().startsWith("BETWEEN")) {
			betweenFlag = true;
		}

		// Track numbers after BETWEEN
		if (betweenFlag && isNewNumber(char, i > 0 ? query[i - 1] : "")) {
			numberCountAfterBetween++;
			if (numberCountAfterBetween === 2) {
				betweenFlag = false;
				numberCountAfterBetween = 0;
			}
		}

		// Track parentheses
		if (char === "(") parenthesesCount++;
		if (char === ")") parenthesesCount--;

		// Handle AND separator
		if (
			remainingString.startsWith(AND_SEPARATOR) &&
			canSplitHere(parenthesesCount, betweenFlag)
		) {
			if (currentPart.trim()) {
				result.push(currentPart.trim());
			}
			currentPart = "";
			i += AND_SEPARATOR.length - 1;
			continue;
		}

		currentPart += char;
	}

	// Add final part
	if (currentPart.trim()) {
		result.push(currentPart.trim());
	}

	return result;
}

function isNewNumber(currentChar: string, previousChar: string): boolean {
	return (
		/\d/.test(currentChar) && (previousChar === "" || !/\d/.test(previousChar))
	);
}

function canSplitHere(parenthesesCount: number, betweenFlag: boolean): boolean {
	return parenthesesCount === 0 && !betweenFlag;
}
