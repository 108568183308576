import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Button,
	Fab,
	Paper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CompanyLogo } from "assets/svgs";
import { FileUploader } from "modules/file-uploader";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Tenant, useTenantProfileAPI } from "./useTenantProfileAPI";

const imageUploadConfig = {
	fileTypes: ["image/*"],
	maxFiles: 1,
	showImageEditor: true,
};

export function TenantProfileEditor() {
	const tenantProfileAPI = useTenantProfileAPI();
	const [isEditing, setIsEditing] = useState(false);

	return (
		<Stack
			sx={{ p: 4, minWidth: 320, overflowY: "auto" }}
			alignItems={"start"}
			direction={"column"}
			component={Paper}
			spacing={"1.5rem"}
		>
			{isEditing && (
				<Typography fontWeight={600}>
					{window.getCTTranslatedText("Edit Details")}
				</Typography>
			)}
			<TenantLogoImageEditor isEditing={isEditing} />
			{tenantProfileAPI.data &&
				(isEditing ? (
					<TenantProfileMetaEditor
						tenantProfile={tenantProfileAPI.data}
						setIsEditing={setIsEditing}
					/>
				) : (
					<TenantProfileMetaView
						tenantProfile={tenantProfileAPI.data}
						setIsEditing={setIsEditing}
					/>
				))}
		</Stack>
	);
}

function TenantLogoImageEditor({ isEditing }: { isEditing: boolean }) {
	const [showImageEditor, setShowImageEditor] = useState<boolean | undefined>(
		undefined
	);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (showImageEditor === false) {
			queryClient.invalidateQueries({
				queryKey: ["tenant-logo"],
			});
		}
	}, [showImageEditor, queryClient]);

	return (
		<Stack
			sx={{ width: 120, height: 120 }}
			alignItems="center"
			justifyContent={"center"}
		>
			<Box sx={{ position: "relative" }}>
				<CompanyLogo width={120} height={120} isEditor={true} />
				{isEditing && (
					<>
						<Fab
							color="primary"
							sx={{ position: "absolute", right: 0, bottom: 0, zIndex: 100 }}
							onClick={() => {
								setShowImageEditor(true);
							}}
						>
							<EditIcon />
						</Fab>
						<Stack
							alignItems={"center"}
							justifyContent={"center"}
							sx={{
								position: "absolute",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								margin: "auto",
							}}
						>
							<FileUploader
								config={imageUploadConfig}
								open={showImageEditor || false}
								onRequestClose={() => setShowImageEditor(false)}
							/>
						</Stack>
					</>
				)}
			</Box>
		</Stack>
	);
}

function TenantProfileMetaView({
	tenantProfile,
	setIsEditing,
}: {
	tenantProfile: Tenant;
	setIsEditing: (isEditing: boolean) => void;
}) {
	return (
		<>
			<Stack spacing={"0.25rem"} width={"100%"} mb={"1.5rem"}>
				<Typography fontWeight={600}>{tenantProfile.name}</Typography>
				<Typography variant="body2">{tenantProfile.email}</Typography>
				<Typography variant="body2">{tenantProfile.website}</Typography>
			</Stack>

			<Button variant="outlined" fullWidth onClick={() => setIsEditing(true)}>
				{window.getCTTranslatedText("Edit Details")}
			</Button>
		</>
	);
}

function TenantProfileMetaEditor({
	tenantProfile,
	setIsEditing,
}: {
	tenantProfile: Tenant;
	setIsEditing: (isEditing: boolean) => void;
}) {
	const changeTenantProfile = useMutation<any, Error, Partial<Tenant>>([
		"tenant-profile",
		"auth/admin/tenant-profile",
		"PUT",
	]);

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const { handleSubmit, control } = useForm({
		defaultValues: {
			...tenantProfile,
		},
	});

	const queryClient = useQueryClient();

	const onSubmit = (values: Tenant) => {
		changeTenantProfile.mutate(values, {
			onSuccess: () => {
				setIsEditing(false);
				setSnackbar(true, SnackBarSeverity.Info, "UpdatedSuccessfully");
				queryClient.invalidateQueries({
					queryKey: ["tenants"],
				});
				queryClient.invalidateQueries({
					queryKey: ["tenant-profile"],
				});
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, error.message);
			},
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: "100%" }}>
			<Stack spacing={"1.5rem"} width={"100%"}>
				<Controller
					render={({ field: { name, value, onChange } }) => (
						<TextField
							fullWidth
							name={name}
							value={value}
							onChange={onChange}
							label={window.getCTTranslatedText("Organization Name")}
							variant="standard"
						/>
					)}
					name={"name"}
					control={control}
					rules={{ required: { value: true, message: "Invalid input" } }}
				/>
				<Controller
					render={({ field: { name, value, onChange } }) => (
						<TextField
							name={name}
							fullWidth
							value={value}
							onChange={onChange}
							label={window.getCTTranslatedText("Organization Email")}
							variant="standard"
						/>
					)}
					name={"email"}
					control={control}
				/>
				<Controller
					render={({ field: { name, value, onChange } }) => (
						<TextField
							name={name}
							fullWidth
							value={value || ""}
							onChange={onChange}
							label={window.getCTTranslatedText("Website")}
							variant="standard"
						/>
					)}
					name={"website"}
					control={control}
				/>
				<Stack direction="row" gap={"1.5rem"}>
					<Button
						fullWidth
						type="button"
						color="primary"
						variant="outlined"
						onClick={() => setIsEditing(false)}
					>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<LoadingButton
						fullWidth
						loading={changeTenantProfile.isLoading}
						type="submit"
						color="primary"
						variant="contained"
						size="medium"
					>
						{window.getCTTranslatedText("Save")}
					</LoadingButton>
				</Stack>
			</Stack>
		</form>
	);
}
