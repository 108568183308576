import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { Appliance, ApplianceDrawerModes } from "pages/appliances/types";
import { ApplianceConfigForm } from "./components/appliance-config-form";
import { ApplianceForm } from "./components/appliance-form";
import { ApplianceRecoverForm } from "./components/appliance-recover-form";

interface ApplianceFormDrawerProps {
	isOpen?: boolean;
	onClose?: () => void;
	appliance?: Appliance;
	updateApplianceData?: Function;
	mode?: string;
	title?: string;
	btnTitle?: string;
}

export const ApplianceFormDrawer = ({
	isOpen,
	onClose,
	appliance,
	title,
	updateApplianceData,
	btnTitle = "save",
	mode,
}: ApplianceFormDrawerProps) => {
	if (mode === ApplianceDrawerModes.Recover) {
		return (
			<ApplianceRecoverForm
				title={title}
				cancel={onClose}
				updateApplianceData={updateApplianceData}
				isOpen={isOpen}
				appliance={appliance}
				btnTitle={btnTitle}
			></ApplianceRecoverForm>
		);
	}
	if (mode === ApplianceDrawerModes.Config) {
		return (
			<ApplianceConfigForm
				title={title}
				cancel={onClose}
				isOpen={isOpen}
				appliance={appliance}
				btnTitle={btnTitle}
				updateApplianceData={updateApplianceData}
			></ApplianceConfigForm>
		);
	}
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "50%",
					maxwidth: "786px",
				},
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "20px", top: "20px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<ApplianceForm
				title={window.getCTTranslatedText("Edit gatekeeper details")}
				updateApplianceData={updateApplianceData}
				cancel={onClose}
				appliance={appliance}
				btnTitle={btnTitle}
			></ApplianceForm>
		</Drawer>
	);
};
