import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useAuditLogStore } from "pages/monitoring/store";

export interface AuditLogToolbarProps extends Omit<ToolbarProps, "children"> {
	setTime: () => void;
}

export function AuditLogToolBar(props: AuditLogToolbarProps) {
	const requestAPIRefresh = useAuditLogStore(store => store.requestAPIRefresh);
	const callApi = () => {
		props.setTime();
		requestAPIRefresh();
	};
	return <GridToolbar {...props} requestAPIRefresh={callApi}></GridToolbar>;
}
