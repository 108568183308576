import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { Network } from "pages/networks/types";
import { NetworkDetail } from "../network-detail/NetworkDetail";

interface NetworkDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	namedNetworkId: string | undefined;
	namedNetwork?: Network;
}

export const NetworkViewDrawer = ({
	isOpen,
	onClose,
	namedNetworkId,
	namedNetwork,
}: NetworkDrawerProps) => {
	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					p: 0,
					width: "70%",
					maxwidth: "1000px",
					minWidth: "600px",
				},
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="large"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "28px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<NetworkDetail
				namedNetworkId={namedNetworkId}
				namedNetwork={namedNetwork}
			/>
		</Drawer>
	);
};
