import { IconsProps } from "./common";

export const SiemensIcon = ({
	width = "20px",
	height = "20px",
	color = "#000",
}: IconsProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="33"
		height="32"
		fill="none"
		viewBox="0 0 33 32"
	>
		<rect width="32" height="32" x="0.333" fill="#099" rx="2"></rect>
		<g clipPath="url(#clip0_15780_123383)">
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M22.35 6.71v3.74c-1.94-.73-3.663-1.1-5.173-1.1-.898 0-1.608.165-2.144.485-.535.323-.809.72-.809 1.19 0 .624.613 1.178 1.831 1.675l3.519 1.711c2.834 1.356 4.248 3.155 4.248 5.416q0 2.821-2.251 4.488c-1.511 1.12-3.519 1.675-6.04 1.675q-1.74.001-3.13-.155c-.925-.099-1.977-.303-3.165-.596v-3.914c2.171.732 4.14 1.102 5.902 1.102 2.116 0 3.168-.613 3.168-1.85 0-.615-.428-1.112-1.295-1.501l-3.908-1.664c-1.453-.652-2.525-1.441-3.215-2.377-.701-.944-1.044-2.026-1.044-3.262q0-2.595 2.174-4.185c1.441-1.051 3.372-1.578 5.77-1.578.786 0 1.673.069 2.678.196.994.135 1.958.3 2.884.505"
				clipRule="evenodd"
			></path>
		</g>
		<defs>
			<clipPath id="clip0_15780_123383">
				<path fill="#fff" d="M8.835 6h14.997v20H8.835z"></path>
			</clipPath>
		</defs>
	</svg>
);
