import { BarChartDatum, BarChartDatum as ChartDatum } from "@colortokens/ng-ui";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ParentSize } from "@visx/responsive";
import { BarGroupBar, SeriesPoint, StackKey } from "@visx/shape/lib/types";
import { CTWidget } from "common/molecules/widget";
import { BarStackChart } from "modules/charts";
import { useDevicesSegmentationAPI } from "pages/appliance-detail/hooks/useDevicesSegmentationAPI";
import {
	ApplianceTabs,
	ApplianceWidgetProps,
	DeviceSegmentationLevels,
	DeviceTypes,
} from "pages/appliance-detail/types";
import { HaRole } from "pages/appliances/types";
import { useMemo } from "react";

interface WidgetProps {
	chartData?: ChartDatum[];
	totalDevices?: number;
	isLoading?: boolean;
	applianceName?: string;
	setDeviceType?: (value: string) => void;
	placeholder: string;
}

const DevicesWidgetContent = ({
	chartData,
	totalDevices,
	setDeviceType,
	applianceName,
	placeholder,
}: WidgetProps) => {
	const getDataLink = (
		bar: Omit<BarGroupBar<StackKey>, "key" | "value"> & {
			bar: SeriesPoint<BarChartDatum>;
			key: StackKey;
		}
	) => {
		const tab = bar?.bar?.data?.x;
		const availabilityKey =
			tab === ApplianceTabs.Devices
				? "assetavailability"
				: "deviceavailability";
		let filters = `agentname=${applianceName}|type=device|${availabilityKey}=${bar.key}`;
		return `/appliances/${applianceName}?tab=${tab}&filters=${encodeURIComponent(
			filters
		)}`;
	};

	return (
		<Stack
			width="100%"
			spacing={2}
			flex={1}
			justifyContent="center"
			minHeight={220}
		>
			{!chartData ? (
				<Stack alignSelf={"center"}>
					<Typography variant="caption">{placeholder}</Typography>
				</Stack>
			) : (
				<ParentSize>
					{parent => {
						return (
							<Stack>
								<BarStackChart
									width={parent.width}
									height={parent.height}
									data={chartData}
									dataPoint={`${window.getCTTranslatedText("Assets")}`}
									total={totalDevices}
									showAllLabels={true}
									isDialogWidget={false}
									getDataLink={getDataLink}
									tooltipTitle={`${window.getCTTranslatedText(
										"devices"
									)} ${window.getCTTranslatedText("Status")}`}
								/>
							</Stack>
						);
					}}
				</ParentSize>
			)}
		</Stack>
	);
};

export const ApplianceDevices = ({
	appliance,
	applianceName,
	setDeviceType,
	isParent,
}: ApplianceWidgetProps) => {
	const { data, isLoading } = useDevicesSegmentationAPI(
		appliance?.agentId,
		isParent
	);

	const chartData = useMemo(() => {
		const getMappedData = () => {
			let mappedData: ChartDatum[] = [];
			for (let deviceType of Object.values(DeviceTypes)) {
				let chartData: ChartDatum = {
					x: deviceType,
					values: {},
				};
				for (let segmentationLevel of Object.values(DeviceSegmentationLevels)) {
					chartData.values[segmentationLevel] = {
						count: data[deviceType][segmentationLevel] ?? 0,
					};
				}
				mappedData.push(chartData);
			}
			return mappedData;
		};

		return {
			data: data ? getMappedData() : [],
			total:
				(appliance?.managedDeviceCount ?? 0) +
				(appliance?.unmanagedDeviceCount ?? 0),
		};
	}, [data, appliance]);

	return (
		<CTWidget
			title={window.getCTTranslatedText("devices")}
			isLoading={isLoading}
			children={
				<DevicesWidgetContent
					chartData={chartData.data}
					totalDevices={chartData.total}
					isLoading={isLoading}
					setDeviceType={setDeviceType}
					applianceName={applianceName}
					placeholder={
						appliance?.haRole === HaRole.Standby
							? window.getCTTranslatedText("notApplicableForStandby")
							: window.getCTTranslatedText("No data")
					}
				/>
			}
		/>
	);
};
