import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
	Button,
	Chip,
	InputAdornment,
	Paper,
	Popper,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface HideColumnsMenuType {
	isHideColumnMenuOpen: boolean;
	setIsHideColumnMenuOpen: (type: boolean) => void;
	hiddenColumns: GridColumnVisibilityModel | undefined;
	hideableColumns: { [key: string]: string };
	pinnedColumns: {
		left: string[];
		right: string[];
	};
	handleHideUnhideColumn: (cols: GridColumnVisibilityModel) => void;
}

export const HideColumnsMenu = ({
	isHideColumnMenuOpen,
	setIsHideColumnMenuOpen,
	hiddenColumns,
	hideableColumns,
	pinnedColumns,
	handleHideUnhideColumn,
}: HideColumnsMenuType) => {
	const parent = document.querySelector("#CUSTOM_COLUMN_MENU");
	const theme = useTheme();
	const isDarkTheme = theme.palette.mode === "dark";

	const [_hiddenColumns, setHiddenColumns] = useState(hiddenColumns ?? {});
	const _hideableColumnsKeys = Object.keys(hideableColumns);
	const [hideableColumnsKeys, setHideableColumnsKeys] =
		useState(_hideableColumnsKeys);
	const [areAllColumnsSelected, setAreAllColumnsSelected] = useState(false);
	const [searchText, setSearchText] = useState("");

	const handleChange = (col: string, isChecked: boolean, isPinned: boolean) => {
		if (isPinned) {
			return;
		}
		setHiddenColumns((prev: GridColumnVisibilityModel) => {
			const _hiddenColumns = { ...prev };
			if (isChecked) {
				_hiddenColumns[col] = true;
			} else {
				_hiddenColumns[col] = false;
			}
			return _hiddenColumns;
		});
	};

	const arePinnedColumnsPresent = useMemo(() => {
		return _hideableColumnsKeys.some(
			key =>
				pinnedColumns.left.includes(key) || pinnedColumns.right.includes(key)
		);
	}, [_hideableColumnsKeys, pinnedColumns]);

	const isButtonDisabled = useMemo(() => {
		if (arePinnedColumnsPresent) {
			return false;
		} else {
			const totalHddencols = Object.values(_hiddenColumns).filter(
				value => value === false
			).length;
			return totalHddencols === _hideableColumnsKeys.length;
		}
	}, [_hideableColumnsKeys.length, arePinnedColumnsPresent, _hiddenColumns]);

	useEffect(() => {
		if (
			areAllColumnsSelected &&
			!Object.values(_hiddenColumns).filter(value => value === false).length
		) {
			setAreAllColumnsSelected(false);
		}
	}, [_hiddenColumns, areAllColumnsSelected]);

	const totalPinnedColumns = useMemo(
		() =>
			Object.keys(_hiddenColumns).filter(
				value =>
					pinnedColumns.left.includes(value) ||
					pinnedColumns.right.includes(value)
			).length,
		[_hiddenColumns, pinnedColumns]
	);

	const isIndeterminate = useMemo(() => {
		return (
			areAllColumnsSelected &&
			_hideableColumnsKeys.length - totalPinnedColumns !==
				Object.values(_hiddenColumns).filter(value => value === false).length
		);
	}, [
		_hiddenColumns,
		_hideableColumnsKeys.length,
		areAllColumnsSelected,
		totalPinnedColumns,
	]);

	const hideColumns = () => {
		handleHideUnhideColumn(_hiddenColumns);
		setIsHideColumnMenuOpen(false);
	};

	const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setSearchText(event.target.value);
		setHideableColumnsKeys(() => {
			return _hideableColumnsKeys.filter(col => {
				return hideableColumns[col]
					.toLowerCase()
					.includes(event.target.value.toLowerCase());
			});
		});
	};

	const onSelectAll = useCallback(() => {
		setHiddenColumns((prev: GridColumnVisibilityModel) => {
			const _hiddenColumns = { ...prev };
			_hideableColumnsKeys.map(col => {
				const isPinned =
					pinnedColumns.left.includes(col) || pinnedColumns.right.includes(col);
				return (_hiddenColumns[col] =
					areAllColumnsSelected || isPinned ? true : false);
			});
			return _hiddenColumns;
		});
		setAreAllColumnsSelected(prev => !prev);
	}, [_hideableColumnsKeys, areAllColumnsSelected, pinnedColumns]);

	return (
		<Popper
			anchorEl={parent}
			open={isHideColumnMenuOpen}
			placement="right-start"
			sx={{
				zIndex: 9999,
			}}
		>
			<Stack
				sx={{
					border: "1px solid #0000001F",
					boxShadow: "0px 1px 3px 0px #0000001F",
					borderRadius: "4px",
					minWidth: "350px",
					maxHeight: "500px",
				}}
				component={Paper}
				elevation={8}
			>
				<Stack
					sx={{ px: 2, py: 1 }}
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="overline">
						{window.getCTTranslatedText("Hide Columns")}
					</Typography>
					<CloseIcon
						onClick={() => setIsHideColumnMenuOpen(false)}
						sx={{ cursor: "pointer" }}
						fontSize="small"
					/>
				</Stack>
				<Stack
					sx={{
						width: "100%",
						height: "1px",
						background: isDarkTheme ? "grey" : "#0000001F",
					}}
				/>
				<TextField
					autoFocus
					id="filled-basic"
					variant="standard"
					onChange={onSearch}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon sx={{ ml: 1, width: "20px" }} />
							</InputAdornment>
						),
						sx: {
							borderRadius: "20px",
							background: isDarkTheme ? "#202326" : "#00447714",
							mx: 2,
							mt: 1,
						},
						disableUnderline: true,
					}}
					size="small"
				/>
				{!searchText && (
					<>
						<Stack
							flexDirection="row"
							alignItems="center"
							onClick={onSelectAll}
							sx={{ cursor: "pointer" }}
							m={1}
							px={2}
							py={0.5}
						>
							<Checkbox
								sx={{ height: "15px", width: "15px" }}
								checked={areAllColumnsSelected}
								indeterminate={isIndeterminate}
							/>
							<Typography variant="body2" ml={1}>
								{window.getCTTranslatedText("Select all")}
							</Typography>
						</Stack>
						<Stack
							sx={{
								width: "100%",
								height: "1px",
								background: isDarkTheme ? "grey" : "#0000001F",
							}}
						/>
					</>
				)}
				<Stack
					p={2}
					sx={{
						overflowY: "scroll",
						"&::-webkit-scrollbar": {
							width: "5px",
						},
						"&::-webkit-scrollbar-track": {
							background: "none",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "grey",
							borderRadius: "5px",
						},
					}}
				>
					{hideableColumnsKeys.map((col: string) => {
						const isChecked = _hiddenColumns[col] === false;
						const isPinned =
							pinnedColumns.left.includes(col) ||
							pinnedColumns.right.includes(col);
						return (
							<Stack
								key={col}
								flexDirection="row"
								alignItems="center"
								onClick={() => handleChange(col, isChecked, isPinned)}
								sx={{ cursor: isPinned ? "not-allowed" : "pointer" }}
								m={1}
							>
								<Checkbox
									sx={{ height: "15px", width: "15px" }}
									checked={isChecked}
									disabled={isPinned}
								/>
								<Typography variant="body2" ml={1}>
									{hideableColumns[col]}
								</Typography>
								{isPinned && (
									<Chip
										size="small"
										label={window.getCTTranslatedText("pinned")}
										variant="outlined"
										sx={{ ml: 1 }}
									/>
								)}
							</Stack>
						);
					})}
				</Stack>
				<Stack
					sx={{
						width: "100%",
						height: "1px",
						background: isDarkTheme ? "grey" : "#0000001F",
					}}
				/>
				<Stack direction="row" justifyContent="flex-end" p={2}>
					<Button
						size={"small"}
						variant="outlined"
						onClick={() => setIsHideColumnMenuOpen(false)}
					>
						{window.getCTTranslatedText("Cancel")}
					</Button>
					<Tooltip
						title={
							isButtonDisabled
								? window.getCTTranslatedText("hideColumnDisabledTooltip")
								: ""
						}
					>
						<span>
							<Button
								size={"small"}
								variant="contained"
								type="submit"
								onClick={hideColumns}
								sx={{ ml: 1 }}
								disabled={isButtonDisabled}
							>
								{window.getCTTranslatedText("Confirm")}
							</Button>
						</span>
					</Tooltip>
				</Stack>
			</Stack>
		</Popper>
	);
};
