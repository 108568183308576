import { Box, Stack, Typography } from "@mui/material";
import { PortColumnRenderProps } from "pages/ports/types";

export function PortLastObservedTimestamp({ port }: PortColumnRenderProps) {
	if (!port.lpId) {
		return <Box style={{ width: 130 }} />;
	}

	return (
		<Stack alignItems="left" justifyContent="center">
			<Typography noWrap variant="body2">
				{port?.listenPortLastObservedAbsolute}
			</Typography>
		</Stack>
	);
}
