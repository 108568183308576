import { Stack } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";
import { AgentDiagnostic } from "../agent-diagnostic";
import AgentPushToFirewall from "./AgentPushToFirewall";

function AgentMetadataIcons({ agent }: AgentColumnRenderProps) {
	const showDiagnosticIcon = agent.isDiagnosticsSupported;
	const showPTFfirewallCoexistence =
		agent?.asset?.pendingFWCoexistenceUpdateChanges;

	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems={"center"}
			justifyContent="space-between"
			flex={1}
		>
			{showPTFfirewallCoexistence ? <AgentPushToFirewall /> : <div />}
			{showDiagnosticIcon ? <AgentDiagnostic agent={agent} /> : <div />}
		</Stack>
	);
}

export default AgentMetadataIcons;
