import { Box } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import {
	AssetAttributesBlock,
	VisxAttributesBlock,
} from "modules/attributes-block";
import { AttributeLocation } from "modules/attributes-block/types";
import { AssignTemplatesOption } from "pages/tags/components/tag-policy-list/components/tag-templates/components/assigned-templates-tags/AssignedTemplatesTags";
import { AssignedTemplatesViewDrawer } from "./assigned-templates-view-drawer";
import { useAssetTemplates } from "./hooks";

export function AssetTemplates({
	criteria,
	page = AttributeLocation.Asset,
	multiselect = true,
	onSelectCallback,
	btnTitle,
	showNonWindows = false,
	hideAssign = false,
}: {
	criteria: string;
	page?: AttributeLocation;
	multiselect?: boolean;
	onSelectCallback?: Function;
	btnTitle?: string;
	showNonWindows?: boolean;
	hideAssign?: boolean;
}) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const {
		showAddToTemplateDrawer,
		openAddToTemplateDrawer,
		closeAddToTemplateDrawer,
		showAssignedTemplatesDrawer,
		openAssignedTemplatesDrawer,
		closeAssignedTemplatesDrawer,
		templateList,
		total,
		isLoading,
		templateListMutation,
		onSortChange,
		sort,
		setPageSize,
		setPage,
		pageSize,
		page: pageCount,
		totalCount,
		onSearchCriteriaChange,
	} = useAssetTemplates({ criteria });

	const hasPermission =
		userPermissions.has("UPDATE_ASSET") &&
		userPermissions.has("UPDATE_TEMPLATE");

	const canAssign = hasPermission && !hideAssign;

	if (!criteria) {
		return <Box style={{ width: 42 }} />;
	}

	return (
		<>
			{page === AttributeLocation.Visx ? (
				<VisxAttributesBlock
					isLoading={isLoading}
					title="Templates"
					count={total}
					canAssign={canAssign}
					showAssigned={openAssignedTemplatesDrawer}
					assign={openAddToTemplateDrawer}
				/>
			) : (
				<AssetAttributesBlock
					isLoading={isLoading}
					title="Templates"
					count={total}
					canAssign={canAssign}
					showAssigned={openAssignedTemplatesDrawer}
					assign={openAddToTemplateDrawer}
				/>
			)}
			<AddToTemplateDrawer
				isOpen={showAddToTemplateDrawer}
				page={"assets"}
				title="Assign Template"
				rules={[]}
				onCancel={closeAddToTemplateDrawer}
				onConfirm={closeAddToTemplateDrawer}
				btnTitle={"assign"}
				actionType={TemplateActionType.assign}
				criteria={criteria}
				multiselect={multiselect}
				onSelectCallback={onSelectCallback}
				showNonWindows={showNonWindows}
				hasPermission={hasPermission}
			/>

			{showAssignedTemplatesDrawer && (
				<AssignedTemplatesViewDrawer
					isOpen={showAssignedTemplatesDrawer}
					onClose={closeAssignedTemplatesDrawer}
					templateList={templateList}
					templatesSuggestMutation={templateListMutation}
					page={"assets"}
					onSortChange={onSortChange}
					AssignTemplatesOption={() =>
						AssignTemplatesOption({
							openAddToTemplateDrawer,
							hasPermission: canAssign,
						})
					}
					multiselect={multiselect}
					criteria={criteria}
					hasPermission={hasPermission}
					sort={sort}
					isLoading={isLoading}
					setPage={setPage}
					setPageSize={setPageSize}
					pageCount={pageCount}
					pageSize={pageSize}
					totalCount={totalCount}
					onSearchCriteriaChange={onSearchCriteriaChange}
				/>
			)}
		</>
	);
}
