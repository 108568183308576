import { UseMutationResult } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	AutoUpgradeStatus,
	DebugLogStatus,
} from "pages/agents/components/agent-drawers/helpers/constants";
import { useAgentStore } from "pages/agents/store";
import { useCallback } from "react";

interface SubmitAgentProps {
	selectedItems: string[];
	selectedIds?: string;
	mutation: UseMutationResult<any, Error, any, unknown>;
	onCancel: () => void;
	action?: string;
}

export const useSubmitAgent = () => {
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const requestAgentAPIRefresh = useAgentStore(
		store => store.requestAPIRefresh
	);

	const confirmAgentConnectDiagnostic = useCallback(
		({
			selectedItems,
			selectedIds: selectedAgentIds,
			mutation,
			onCancel,
		}: SubmitAgentProps) => {
			if (selectedItems) {
				const body = {
					criteria: `agentId in (${selectedAgentIds})`,
				};
				mutation.mutateAsync(body, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "AgentDiagnosticRequestSubmitted",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentRestart = useCallback(
		({
			selectedItems,
			selectedIds: selectedAgentIds,
			mutation,
			onCancel,
		}: SubmitAgentProps) => {
			const agentRestartBody = {
				criteria: `agentId in (${selectedAgentIds})`,
				version: "@latest",
			};

			mutation.mutateAsync(agentRestartBody, {
				onSuccess: response => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "AgentRestartRequestSubmittedSuccessfully",
					});
					onCancel();
					requestAgentAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					onCancel();
				},
			});
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentDecommission = useCallback(
		({
			selectedItems,
			selectedIds: selectedAgentIds,
			mutation,
			onCancel,
		}: SubmitAgentProps) => {
			if (selectedItems) {
				const agentDecommissionBody = {
					criteria: `agentId in (${selectedAgentIds})`,
					version: "@latest",
				};
				mutation.mutateAsync(agentDecommissionBody, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "agentDecommissionRequestSubmitted",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentDebugLog = useCallback(
		({
			selectedItems,
			selectedIds: selectedAgentIds,
			mutation,
			onCancel,
			action,
		}: SubmitAgentProps) => {
			if (selectedItems) {
				const agentDebugBody = {
					criteria: `agentId in (${selectedAgentIds})`,
					debugLogDetails: {
						debugLogEnabled: action === DebugLogStatus.Enable,
					},
				};
				mutation.mutateAsync(agentDebugBody, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "agentDebugLogRequestSubmittedSuccessfully",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentTrafficConfig = useCallback(
		({ selectedItems, mutation, onCancel, action }: SubmitAgentProps) => {
			if (selectedItems) {
				const selectedAgentIds = selectedItems
					?.map((element: string) => `'${element}'`)
					.join(", ");
				const agentTrafficConfigBody = {
					criteria: `agentId in (${selectedAgentIds})`,
					updateDetails: {
						trafficConfiguration: action,
					},
				};
				mutation.mutateAsync(agentTrafficConfigBody, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "AgentTrafficConfigRequestedSuccessfully",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentTrafficConfigNorthSouth = useCallback(
		({ selectedItems, mutation, onCancel, action }: SubmitAgentProps) => {
			if (selectedItems) {
				const selectedAgentIds = selectedItems
					?.map((element: string) => `'${element}'`)
					.join(", ");
				const body = {
					criteria: `agentId in (${selectedAgentIds})`,
					updateDetails: {
						northSouthTrafficConfiguration: action,
					},
				};
				mutation.mutateAsync(body, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "AgentNsTrafficConfigRequestedSuccessfully",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentPolicyTamperMonitoring = useCallback(
		({
			selectedItems,
			selectedIds,
			mutation,
			onCancel,
			action,
		}: SubmitAgentProps) => {
			if (selectedItems) {
				const body = {
					criteria: `agentId in (${selectedIds})`,
					updateDetails: {
						policyTamperMonitoringConfiguration: action,
					},
				};
				mutation.mutateAsync(body, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "agentpolicyTampermonitoringRequestSubmittedSuccessfully",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentFirewallCoexistence = useCallback(
		({
			selectedItems,
			selectedIds,
			mutation,
			onCancel,
			action,
		}: SubmitAgentProps) => {
			if (selectedItems) {
				const body = {
					criteria: `agentId in (${selectedIds})`,
					updateDetails: {
						fwCoexistenceConfiguration: action,
					},
				};
				mutation.mutateAsync(body, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "agentFirewallCoexistenceRequestSubmittedSuccessfully",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	const confirmAgentAutoUpgrade = useCallback(
		({
			selectedItems,
			selectedIds: selectedAgentIds,
			mutation,
			onCancel,
			action,
		}: SubmitAgentProps) => {
			if (selectedItems) {
				const agentAutoUpgradeBody = {
					criteria: `agentId in (${selectedAgentIds})`,
					updateDetails: {
						autoUpgradeEnabled:
							action === AutoUpgradeStatus.Enable ? true : false,
					},
				};
				mutation.mutateAsync(agentAutoUpgradeBody, {
					onSuccess: response => {
						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "AgentAutoUpgradeRequestSubmittedSuccessfully",
						});
						onCancel();
						requestAgentAPIRefresh();
					},
					onError: error => {
						setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
						onCancel();
					},
				});
			}
		},
		[notify, requestAgentAPIRefresh, setSnackbar]
	);

	return {
		confirmAgentConnectDiagnostic,
		confirmAgentRestart,
		confirmAgentDecommission,
		confirmAgentDebugLog,
		confirmAgentTrafficConfig,
		confirmAgentAutoUpgrade,
		confirmAgentTrafficConfigNorthSouth,
		confirmAgentPolicyTamperMonitoring,
		confirmAgentFirewallCoexistence,
	};
};
