import { AssetAttributesBlock } from "modules/attributes-block";
import { TargetingSegmentsDrawer } from "modules/targeting-segments-drawer";
import { useState } from "react";

export function AssetTbp({
	criteria,
	count,
	assetName,
}: {
	criteria: string;
	count: number;
	assetName: string;
}) {
	const [showPolicies, setShowPolicies] = useState(false);

	return (
		<>
			<AssetAttributesBlock
				assign={() => {}}
				count={count ?? 0}
				canAssign={false}
				isLoading={false}
				showAssigned={() => {
					setShowPolicies(true);
				}}
				title={"Segments"}
			/>

			<TargetingSegmentsDrawer
				open={showPolicies}
				onClose={() => {
					setShowPolicies(false);
				}}
				criteria={criteria}
				subtitle={`${window.getCTTranslatedText("Asset")}: ${assetName}`}
			/>
		</>
	);
}
