import { MetadataItemType } from "pages/asset/components/asset-metadata-wrapper/types";

export const TAG_CAPITALIZE_WHITELIST = [
	MetadataItemType.BusinessValue,
	"businessvalue",
	"assetrisk",
	"type",
];

export const TAG_CAPITALIZE_BLACKLIST = ["osname", "osName"];
