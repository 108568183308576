export function isNumberInRange(numberStr: string, rangeStr: string) {
	const number = Number(numberStr);
	if (isNaN(number)) {
		return rangeStr.includes(numberStr);
	}
	const [start, end] = rangeStr.split("-").map(Number);
	if (end) {
		return number >= start && number <= end;
	} else {
		return rangeStr.includes(numberStr);
	}
}
