import { Box, Stack, Tooltip, Typography } from "@mui/material";
import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AssetStatus } from "pages/assets/types";
import FirewallHostGroupCriteria from "pages/crowdstrike-groups/firewall-host-groups/components/FirewallHostGroupCriteria";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { ProgressiveEnforcementStatus } from "pages/ports/types";
import { ColorTokensSegmentsHeader } from "pages/segments/ColorTokensSegmentsHeader";
import CrowdStrikeSegmentsHeader from "pages/segments/CrowdStrikeSegmentsHeader";
import { TagPolicyProgressiveStatus } from "pages/tags/components/tag-policy-list/components/tag-policy-progressive-status";
import BaselineTargetScore from "./components/baseline-target-score/BaselineTargetScore";
import { MatchedAssetCount } from "./components/matched-asset-count";
import { NetworkAssignments } from "./components/network-assignments";
import { PolicyCriteria } from "./components/policy-criteria";
import {
	ColorTokensTagPolicyList,
	CrowdStrikeTagPolicyList,
} from "./components/policy-list";
import { TagPolicyActions } from "./components/tag-policy-actions";
import { TagPolicyAutomation } from "./components/tag-policy-automation";
import { TagPolicyName } from "./components/tag-policy-name";
import { TemplateAssignments } from "./components/template-assignments";
import {
	useCrowdStrikeTagPolicyFacetStore,
	useTagPolicyFacetStore,
} from "./store";
import { CrowdStrikeTagPolicy, TagPolicy } from "./types";

export const BASE_TAG_POLICY_COLS = (
	managedBy: ManagedByValue = ManagedByValue.ColorTokens,
	isRowReorderingEnabled: boolean = false
): GridColDef<TagPolicy | CrowdStrikeTagPolicy>[] => [
	...(managedBy === ManagedByValue.CrowdStrike
		? [
				{
					field: isRowReorderingEnabled ? "__reorder__" : "precedence",
					headerName: "Precedence",
					sortable: false,
					pinnable: true,
					flex: 1,
					minWidth: 150,
					renderCell: (
						params: GridRenderCellParams<TagPolicy | CrowdStrikeTagPolicy>
					) => {
						const row = params.row as CrowdStrikeTagPolicy;
						return (
							<Stack
								direction="row"
								alignItems="center"
								gap={2}
								sx={{
									"& .MuiDataGrid-rowReorderCell": {
										flex: 0,
										pb: "2px",
									},
								}}
							>
								{isRowReorderingEnabled &&
									GRID_REORDER_COL_DEF.renderCell?.(params)}
								{row.crowdstrikeFirewallHostGroup?.precedence}
							</Stack>
						);
					},
				},
			]
		: []),
	{
		field: "name",
		headerName: "Name",
		width: 220,
		minWidth: 200,
		sortable: managedBy === ManagedByValue.ColorTokens,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <TagPolicyName policy={params.row} />;
		},
	},
	{
		field: "criteria",
		headerName: "Criteria",
		width: 240,
		minWidth: 200,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			const row = params.row as CrowdStrikeTagPolicy;
			const managedBy = row.managedBy ?? ManagedByValue.ColorTokens;

			switch (managedBy) {
				case ManagedByValue.CrowdStrike:
					return (
						<Box sx={{ pointerEvents: "none" }}>
							<FirewallHostGroupCriteria
								data={row.crowdstrikeFirewallHostGroup}
								useFacetStore={useCrowdStrikeTagPolicyFacetStore}
							/>
						</Box>
					);
				case ManagedByValue.ColorTokens:
				default:
					return (
						<PolicyCriteria
							policy={params.row}
							useFacetStore={useTagPolicyFacetStore}
							readOnlyDimensions={SEGMENTS_READ_ONLY_DIMENSIONS}
						/>
					);
			}
		},
	},
	{
		field: "description",
		headerName: "Description",
		width: 220,
		minWidth: 200,
		sortable: false,
		renderCell: params => params.row.description,
	},
	{
		field: "baselineTargetScore",
		headerName: "Baseline / Target Score",
		width: 220,
		minWidth: 200,
		sortable: false,
		renderCell: params => <BaselineTargetScore {...params.row} />,
	},
	{
		field: "templatesAssigned",
		headerName: "Templates",
		width: 120,
		minWidth: 120,
		sortable: managedBy === ManagedByValue.ColorTokens,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <TemplateAssignments policy={params?.row} />;
		},
	},
	{
		field: "namednetworksAssigned",
		headerName: "Named Networks",
		width: 160,
		minWidth: 160,
		sortable: managedBy === ManagedByValue.ColorTokens,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <NetworkAssignments policy={params?.row} />;
		},
	},
	{
		field: "matchingAssets",
		headerName: "Assets",
		width: 100,
		minWidth: 100,
		sortable: managedBy === ManagedByValue.ColorTokens,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <MatchedAssetCount policy={params?.row} />;
		},
	},
	{
		field: "policyAutomation-attacksurface",
		headerName: `${managedBy === ManagedByValue.ColorTokens ? "Min. " : ""}Attack Surface`,
		width: 160,
		minWidth: 160,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: params => {
			return (
				<>
					<TagPolicyAutomation
						policy={params.row}
						children={() => {
							return (
								<Tooltip title="Attack Surface">
									<Typography variant="body2">
										{window.getCTTranslatedText(
											params.row.lowestInboundPolicyStatus ??
												AssetStatus.Unsecured
										)}
									</Typography>
								</Tooltip>
							);
						}}
						disabled={
							!params.row.policyAutomationConfigurable &&
							params.row?.lowestProgressiveInboundPolicyStatus ===
								ProgressiveEnforcementStatus.ZeroTrust
						}
					/>
				</>
			);
		},
	},
	{
		field: "policyAutomation-progressiveinboundstatus",
		headerName: `${managedBy === ManagedByValue.ColorTokens ? "Min. " : ""}Progressive Inbound Status`,
		width: 180,
		minWidth: 180,
		sortable: false,
		renderCell: params => {
			return (
				<TagPolicyProgressiveStatus
					policy={params.row}
					status={params.row.lowestProgressiveInboundPolicyStatus}
				/>
			);
		},
	},
	{
		field: "policyAutomation-progressiveoutboundstatus",
		headerName: `${managedBy === ManagedByValue.ColorTokens ? "Min. " : ""}Progressive Outbound Status`,
		width: 180,
		minWidth: 180,
		sortable: false,
		renderCell: params => {
			return (
				<TagPolicyProgressiveStatus
					policy={params.row}
					status={params.row.lowestProgressiveOutboundPolicyStatus}
				/>
			);
		},
	},
	{
		field: "policyAutomation-blastradius",
		headerName: `${managedBy === ManagedByValue.ColorTokens ? "Min. " : ""}Blast Radius`,
		width: 160,
		minWidth: 160,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: params => {
			return (
				<>
					<TagPolicyAutomation
						policy={params.row}
						children={() => {
							return (
								<Typography variant="body2">
									{window.getCTTranslatedText(
										params.row.lowestOutboundPolicyStatus ??
											AssetStatus.Unsecured
									)}
								</Typography>
							);
						}}
					/>
				</>
			);
		},
	},
	{
		field: "policyAutomation-autoSync",
		headerName: "Auto push firewall",
		width: 160,
		minWidth: 160,
		sortable: false,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: params => {
			return (
				<>
					<TagPolicyAutomation
						policy={params.row}
						children={() => {
							return (
								<Typography variant="body2">
									{window.getCTTranslatedText(
										params.row.autoSynchronizeEnabled ? "Enabled" : "Disabled"
									)}
								</Typography>
							);
						}}
					/>
				</>
			);
		},
	},
];

export const TAG_POLICY_COLS = (
	managedBy: ManagedByValue = ManagedByValue.ColorTokens,
	isRowReorderingEnabled: boolean = false
): GridColDef<TagPolicy>[] => [
	...BASE_TAG_POLICY_COLS(managedBy, isRowReorderingEnabled),
	{
		field: "actions",
		headerName: "",
		width: 80,
		minWidth: 80,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: params => {
			return <TagPolicyActions policy={params.row} />;
		},
	},
];

const pinnedColumns = {
	left: [
		GRID_CHECKBOX_SELECTION_COL_DEF.field,
		GRID_REORDER_COL_DEF.field,
		"precedence",
		"name",
		"criteria",
	],
	right: ["actions"],
};

export const tabBasedPolicyColumnConfig = {
	pinnedColumns: pinnedColumns,
};

export const SEGMENTS_TABS: Record<
	ManagedByValue,
	{
		label: string;
		additionalCriteria: string;
		actions: JSX.Element;
		component: JSX.Element;
	}
> = {
	[ManagedByValue.ColorTokens]: {
		label: "ColorTokens",
		additionalCriteria: `'managedby' in ('${ManagedByValue.ColorTokens}')`,
		actions: <ColorTokensSegmentsHeader />,
		component: <ColorTokensTagPolicyList />,
	},
	[ManagedByValue.CrowdStrike]: {
		label: "CrowdStrike",
		additionalCriteria: `'managedby' in ('${ManagedByValue.CrowdStrike}')`,
		actions: <CrowdStrikeSegmentsHeader />,
		component: <CrowdStrikeTagPolicyList />,
	},
};

export const SEGMENTS_READ_ONLY_DIMENSIONS = ["managedby"];
