import { Operator } from "@colortokens/ng-ui";
import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useCore } from "modules/core";
import { FacetGroupInfo, SortOrder } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { FacetGroups } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { FACET_ORDER as TEMPLATE_FACET_ORDER } from "pages/templates/constants";
import { useCallback, useEffect, useMemo } from "react";
import { SEGMENTS_TABS, tabBasedPolicyColumnConfig } from "../../constants";
import {
	useCrowdStrikeTagPolicyFacetStore,
	useCrowdStrikeTagPolicyStore,
} from "../../store";
import { useTagPolicyListAPI } from "../../TagPolicyList";
import { CrowdStrikeTagPolicy, TagPolicy } from "../../types";
import { TagPolicyDataGrid } from "../TagPolicyDataGrid";

export function CrowdStrikeTagPolicyList() {
	const theme = useTheme();

	const { isFeatureEnabled: isProgressiveEnabled } = useFeatureFlagControl(
		FEATURES.PROGRESSIVE
	);
	const { isFeatureEnabled: isProgressiveOutboundV1Enabled } =
		useFeatureFlagControl(FEATURES.PROGRESSIVE_OUTBOUND_V1);

	const { data: metaData } = useScopeMetadata({
		scope: Scope.TagBasedPolicy,
	});
	const updateMetadata = useCrowdStrikeTagPolicyFacetStore(
		state => state.updateMetadata
	);
	useEffect(() => {
		updateMetadata(metaData);
	}, [metaData, updateMetadata]);

	const facetOrderGroup = useMemo(() => {
		let commonFacetOrder: FacetGroupInfo = {
			lowestpolicyinboundstatus: FacetGroups.PolicyAutomation,
			lowestpolicyoutboundstatus: FacetGroups.PolicyAutomation,
			policyautomationconfigurable: FacetGroups.PolicyAutomation,
			namednetworkid: FacetGroups.Network,
			namednetworkname: FacetGroups.Network,
			...TEMPLATE_FACET_ORDER,
			templateid: FacetGroups.Templates,
			policyautosynchronize: FacetGroups.PolicyAutomation,
			...(isProgressiveEnabled
				? { lowestpolicyprogressiveinboundstatus: FacetGroups.PolicyAutomation }
				: {}),
			...(isProgressiveOutboundV1Enabled
				? {
						lowestpolicyprogressiveoutboundstatus: FacetGroups.PolicyAutomation,
					}
				: {}),
		};

		return commonFacetOrder;
	}, [isProgressiveEnabled, isProgressiveOutboundV1Enabled]);

	const coreResponse = useCore<CrowdStrikeTagPolicy | TagPolicy>({
		useStore: useCrowdStrikeTagPolicyStore,
		useFacetStore: useCrowdStrikeTagPolicyFacetStore,
		facetGroupInfo: facetOrderGroup,
		scope: Scope.TagBasedPolicy,
		useApi: () => useTagPolicyListAPI(ManagedByValue.CrowdStrike),
		pageSize: 10000,
		additionalCriteria:
			SEGMENTS_TABS[ManagedByValue.CrowdStrike].additionalCriteria,
		dataMapper: item => ({ ...item, managedBy: ManagedByValue.CrowdStrike }),
	});

	const rows =
		coreResponse.rows === undefined
			? []
			: coreResponse.rows
					.filter(item => "crowdstrikeFirewallHostGroup" in item)
					.sort(
						(a, b) =>
							a.crowdstrikeFirewallHostGroup!.precedence -
							b.crowdstrikeFirewallHostGroup!.precedence
					)
					.map((obj, i) => ({
						...obj,
						crowdstrikeFirewallHostGroup: {
							...obj.crowdstrikeFirewallHostGroup,
							precedence: i + 1,
						},
					}));

	const onSortChange = useCallback(
		(sortOrder: SortOrder[]) => {
			const newSortOrder = sortOrder.map(sort => {
				if (sort.field === "name") {
					return {
						field: "tagbasedpolicyname",
						order: sort.order,
					};
				}

				return sort;
			});

			coreResponse.onSortChange(newSortOrder);
		},
		[coreResponse]
	);

	return (
		<>
			<Stack direction="row">
				<Stack sx={{ flex: 1, py: 2 }}>
					<FacetOpenCloseController
						useFacetStore={useCrowdStrikeTagPolicyFacetStore}
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							useFacetStore={useCrowdStrikeTagPolicyFacetStore}
							config={coreResponse.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
							disabledOperators={[Operator.NOT_EQUAL]}
						/>
					</FacetOpenCloseController>
				</Stack>
			</Stack>

			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
			>
				<TagPolicyDataGrid
					defaultPinnedColumns={tabBasedPolicyColumnConfig.pinnedColumns}
					rowCount={rows.length}
					rows={getUniqueListBy(rows ?? [], "tagBasedPolicyId")}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={onSortChange}
					hasNoData={
						coreResponse.mutation.isSuccess && coreResponse.rowCount === 0
					}
					metadata={
						{
							...coreResponse.metadata,
							scope: ManagedByValue.CrowdStrike,
						} as ScopeMetadata
					}
					triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
					getExportStatus={coreResponse?.getExportStatus}
					getUrlToDownload={coreResponse?.getUrlToDownload}
					resetDownloadUrl={coreResponse?.resetDownloadUrl}
					rawData={coreResponse?.rawData}
					managedBy={ManagedByValue.CrowdStrike}
					checkboxSelection={false}
					initialState={{
						sorting: {
							sortModel: [{ field: "precedence", sort: "asc" }],
						},
					}}
				/>
			</Paper>
		</>
	);
}
