import { OutputData } from "common/atoms/ct-guardrail/types";
import { AssetStatus } from "pages/assets/types";
import {
	ProgressiveEnforcementLevel,
	ProgressiveOutboundPortEnforcementLevel,
} from "pages/ports/types";

export enum PolicyChangeType {
	AttackSurfaceProgressive = "attackSurfaceProgressive",
	AttackSurfaceEnforcement = "attackSurfaceEnforcement",
	BlastRadiusProgressive = "blastRadiusProgressive",
	BlastRadiusEnforcement = "blastRadiusEnforcement",
}

export interface PolicyUnreviewedTraffic {
	[PolicyChangeType.AttackSurfaceProgressive]?: UnreviewedTraffic;
	[PolicyChangeType.AttackSurfaceEnforcement]?: UnreviewedTraffic;
	[PolicyChangeType.BlastRadiusProgressive]?: UnreviewedTraffic;
	[PolicyChangeType.BlastRadiusEnforcement]?: UnreviewedTraffic;
}

export interface PolicyAggregateData {
	[PolicyChangeType.AttackSurfaceProgressive]?: OutputData;
	[PolicyChangeType.AttackSurfaceEnforcement]?: OutputData;
	[PolicyChangeType.BlastRadiusProgressive]?: OutputData;
	[PolicyChangeType.BlastRadiusEnforcement]?: OutputData;
}

// New interface for simple traffic counts
export interface DetailedCount {
	unreviewed?: number;
	denied?: number;
	deniedByTemplate?: number;
	violations?: number;
}

export interface UnreviewedTraffic {
	assets?: number;
	paths?: DetailedCount;
	ports?: DetailedCount;
	status?: string;
	stateSkipped?: boolean;
	progressivePorts?: number;
	progressivePaths?: number;
}

export type PolicyChangeValues =
	| ProgressiveEnforcementLevel
	| ProgressiveOutboundPortEnforcementLevel
	| AssetStatus
	| undefined;

export enum TrafficType {
	Path = "path",
	Port = "port",
}

export interface TrafficCriteria {
	[PolicyChangeType.AttackSurfaceProgressive]?: Traffic;
	[PolicyChangeType.AttackSurfaceEnforcement]?: Traffic;
	[PolicyChangeType.BlastRadiusProgressive]?: Traffic;
	[PolicyChangeType.BlastRadiusEnforcement]?: Traffic;
}

export interface TrafficLoading {
	[PolicyChangeType.AttackSurfaceProgressive]?: boolean;
	[PolicyChangeType.AttackSurfaceEnforcement]?: boolean;
	[PolicyChangeType.BlastRadiusProgressive]?: boolean;
	[PolicyChangeType.BlastRadiusEnforcement]?: boolean;
}
export interface Traffic {
	paths?: string;
	ports?: string;
	assets?: string;
}
