import { DisplayTextMap } from "common/constants/displayTextMap";
import { getFacetDisplayName } from "modules/facets/helpers";
import { FacetState } from "modules/facets/types";
import { ScopeMetadata } from "modules/scope-metadata/types";

const EXCLUDED_FACETS = [
	"listenportlastobserved",
	"pathlastobserved",
	"Path Last Observed",
	"Port Last Observed",
];

export const getSegmentsNameFromCriteria = (
	facets: FacetState,
	metadata?: ScopeMetadata
) => {
	let result = "";
	facets?.forEach((innerMap, outerKey) => {
		const facetDisplayName = getFacetDisplayName(outerKey, metadata, false);
		if (EXCLUDED_FACETS.includes(facetDisplayName)) {
			return;
		}

		result += `${facetDisplayName}: `;
		if (innerMap instanceof Map) {
			const innerKeys = Array.from(innerMap.keys())
				.map(key => DisplayTextMap[key] ?? key)
				.join(", ");
			result += innerKeys;
		}
		result += " | ";
	});
	return result.slice(0, -3).substring(0, 255);
};
