import { Box, Stack, Typography } from "@mui/material";
import { PathColumnRenderProps } from "pages/paths/types";

export function PathLastObservedTimestamp({ path }: PathColumnRenderProps) {
	if (!path.channelHash) {
		return <Box style={{ width: 90 }} />;
	}

	return (
		<Box style={{ width: "fit-content" }}>
			<Stack alignItems="left" justifyContent="center">
				<Typography noWrap variant="body2">
					{path?.pathLastObservedAbsolute}
				</Typography>
			</Stack>
		</Box>
	);
}
