import { Stack, Typography } from "@mui/material";
import { getRelativeLastObserved } from "common/utils";

interface AssetProgressiveStatusProps {
	displayTextProgressiveMode: string;
	lastRefreshed: string | undefined;
}

export const AssetProgressiveStatus = ({
	displayTextProgressiveMode,
	lastRefreshed,
}: AssetProgressiveStatusProps) => {
	return (
		<Stack
			direction="row"
			alignItems="flex-start"
			justifyContent="space-between"
			height={"40px"}
		>
			{displayTextProgressiveMode && (
				<Stack direction="row" alignItems="center">
					<Typography variant="body2">
						{window.getCTTranslatedText("progressive")}:
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: theme => theme.palette.text.secondary,
							ml: 1,
						}}
					>
						{window.getCTTranslatedText(displayTextProgressiveMode)}
					</Typography>
				</Stack>
			)}

			{lastRefreshed && (
				<Stack direction="row" alignItems="center">
					<Typography variant="body2">
						{window.getCTTranslatedText("lastSynced")}:
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: theme => theme.palette.text.secondary,
							ml: 1,
						}}
					>
						{getRelativeLastObserved(lastRefreshed ?? "")}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};
