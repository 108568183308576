import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { useCallback, useEffect, useState } from "react";
import { formatCreationTime } from "./AuditLogUtils";
import { AuditLogTab } from "./components/audit-log-tab";

interface CreationTimeFilterType {
	startTime: string;
	endTime: string;
}

export function Logs() {
	const globalSelectedTime = useUserPreferencesStore(state => state.timeFilter);

	const defaultCreationFilterType: CreationTimeFilterType = {
		startTime: "",
		endTime: "",
	};
	const [creationFilter, setCreationFilter] = useState<CreationTimeFilterType>(
		defaultCreationFilterType
	);
	const setTime = useCallback(() => {
		const formatedTime = formatCreationTime(globalSelectedTime);
		setCreationFilter({
			startTime: formatedTime?.startTime,
			endTime: formatedTime?.endTime,
		});
	}, [globalSelectedTime]);

	useEffect(() => {
		setTime();
	}, [setTime]);

	return <AuditLogTab creationFilter={creationFilter} setTime={setTime} />;
}
