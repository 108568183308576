import { Stack, Tooltip, useTheme } from "@mui/material";
import { FirewallWarningIcon } from "assets/svgs";

function AgentPushToFirewall() {
	const theme = useTheme();

	return (
		<Tooltip title={window.getCTTranslatedText("PTF_FirewallCoexistence")}>
			<Stack alignItems={"center"}>
				<FirewallWarningIcon
					color={theme.palette.warning.main}
					width={"20px"}
					height={"20px"}
				/>
			</Stack>
		</Tooltip>
	);
}

export default AgentPushToFirewall;
