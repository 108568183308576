import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
	Box,
	Button,
	Drawer,
	FormControl,
	IconButton,
	InputLabel,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
} from "@mui/material";
import { CircularCenteredLoader } from "common/atoms/loader";
import { Toolbar } from "common/atoms/toolbar";
import { NoDataFound } from "common/molecules/no-data-found";
import { AgentType } from "pages/agents/types";
import { LogOption } from "pages/asset/types";
import { AssetType } from "pages/assets/types";
import { useEffect, useState } from "react";
import { LazyLog } from "react-lazylog";

interface LogsDrawerProps {
	assetType?: AssetType;
	agentType?: string;
	isOpen: boolean;
	onClose: () => void;
	label: string;
	areOptionsLoading: boolean;
	hasPermission: boolean;
	logOptions?: any[];
	downloadPrefix: string;
}

export const LogsDrawer = ({
	assetType,
	agentType,
	isOpen,
	onClose,
	label,
	areOptionsLoading,
	logOptions,
	downloadPrefix,
	hasPermission,
}: LogsDrawerProps) => {
	const [selectedOption, setSelectedOption] = useState<LogOption>();

	useEffect(() => {
		if (logOptions) {
			setSelectedOption(logOptions[0]);
		}
	}, [logOptions]);

	const onChange = (event: SelectChangeEvent) => {
		setSelectedOption(
			logOptions?.find(option => option.value === event.target.value)
		);
	};

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "16px",
					width: "50%",
					overflow: "hidden",
				},
			}}
		>
			<Toolbar />
			<Stack alignItems={"flex-start"} sx={{ mb: 1 }}>
				<IconButton size="small" aria-label="close drawer" onClick={onClose}>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Stack>
			{!logOptions?.length ? (
				<NoDataFound text="No data" />
			) : (
				<>
					{areOptionsLoading || !logOptions?.length ? (
						<CircularCenteredLoader />
					) : (
						<Stack direction="row" justifyContent="space-between">
							<FormControl sx={{ minWidth: 180 }}>
								<InputLabel id={label}>
									{window.getCTTranslatedText(label)}
								</InputLabel>
								<Select
									labelId={label}
									value={selectedOption?.value ?? ""}
									label={window.getCTTranslatedText(label)}
									onChange={onChange}
								>
									{logOptions?.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{hasPermission && (
								<Stack direction="row" alignItems="center" spacing={1}>
									<Box>
										<Typography variant="body2">
											{window.getCTTranslatedText("Size")}:{" "}
											{selectedOption?.fileSize}
										</Typography>
									</Box>
									<Link
										underline="none"
										component={"a"}
										href={`${downloadPrefix}${selectedOption?.id}?download=true`}
									>
										<Button
											color="info"
											variant="outlined"
											startIcon={<DownloadIcon fontSize="small" />}
										>
											{window.getCTTranslatedText("Download")}
										</Button>
									</Link>
								</Stack>
							)}
						</Stack>
					)}
					{selectedOption &&
						agentType !== AgentType.CT_CONTAINER_AGENT &&
						assetType !== AssetType.Service && (
							<Box sx={{ flex: 1, mt: 2 }}>
								<LazyLog
									fetchOptions={{ credentials: "include" }}
									selectableLines
									enableSearch
									url={`${downloadPrefix}${selectedOption?.id}`}
								/>
							</Box>
						)}
				</>
			)}
		</Drawer>
	);
};
