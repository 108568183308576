import { ListItem, Paper, styled } from "@mui/material";
import {
	NAV_SIDEBAR_COLLAPSED_WIDTH,
	NAV_SIDEBAR_EXPANDED_WIDTH,
} from "../constants";

interface StyledNavSidebarProps {
	isMenuExpanded: boolean;
}

export const StyledNavSidebar = styled(Paper, {
	shouldForwardProp: prop => prop !== "isMenuExpanded",
})<StyledNavSidebarProps>(
	({ theme, isMenuExpanded }) => `
	--appBarHeight: 54px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: calc(var(--appBarHeight) + 1rem) 0.75rem 1rem;
	width: ${NAV_SIDEBAR_COLLAPSED_WIDTH}px;
	height: 100dvh;
	transition: width ${theme.transitions.duration.standard}ms;
	
	flex-shrink: 0;
	white-space: nowrap;
	box-sizing: border-box;
	position: relative;
	border-right: 1px solid ${theme.palette.divider};

	.menuExpandButton {
		position: absolute;
		z-index: 1;
		right: 0;
		top: calc(var(--appBarHeight) + 0.5rem);
		translate: 50% 0;
		rotate: 0deg;
		transition: rotate ${theme.transitions.duration.leavingScreen}ms;
		background-color: ${theme.palette.mode === "dark" ? "#323638" : theme.palette.background.paper};
		outline: 1px solid ${theme.palette.divider};
		padding: 0;

		&:hover {
			background-color: ${theme.palette.primary.main};
			outline-color: ${theme.palette.primary.main};
			color: ${theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.common.white};
		}
	}

	${
		isMenuExpanded
			? `
		& {
			width: ${NAV_SIDEBAR_EXPANDED_WIDTH}px;

			.menuExpandButton {
				rotate: 180deg;
			}
		}`
			: ``
	}
	

	.menu-expander {
		position: absolute;
		top: 60px;
		right: 0;
		translate: 50% 0;
	}

	.MuiPopover-paper .MuiTypography-overline {
		color: ${theme.palette.mode === "dark" ? "#FFFFFFB2" : "#00000099"};
	}

	@keyframes fadeUp {
		100% {
			opacity: 1;
			translate: 0 0;
		}
	}
`
);

interface StyledNavListItemProps {
	animate: boolean;
	animationDelay: number;
}

export const StyledNavListItem = styled(ListItem, {
	shouldForwardProp: prop => prop !== "animate" && prop !== "animationDelay",
})<StyledNavListItemProps>(
	({ theme, animate, animationDelay }) => `
	display: flex;
	flex-direction: column;
	align-items: stretch;
	${
		animate
			? `
				animation: fadeUp 200ms ${animationDelay}ms forwards ease-out;
				opacity: 0;
				translate: 0 10px;
				`
			: ""
	}
	

	a {
		color: inherit;
		text-decoration: none;
		display: block;
		width: 100%;
	}

	.subMenuArrow {
		transition: rotate 200ms;
		margin-left: auto;
	}

	.MuiButtonBase-root {
		display: flex;
		min-height: 48px;
		padding: 0.75rem 0.5rem 0.75rem 1rem;
		width: 100%;
		border-radius: 0.25rem;

		&.active   {
			background: ${theme.palette.mode === "dark" ? "#0481F529" : theme.palette.secondary.main};
		}

		.menuIcon {
			pointer-events: none;
		}

		&.active .menuIcon {
			color: ${theme.palette.primary.main};
		}

		&.active p {
			color: ${theme.palette.mode === "dark" ? theme.palette.primary.main : "initial"};
		}

		&.menuClosed span {
			flex-basis: initial;
		}

		&.subMenuOpen  {
			background: ${theme.palette.action.hover};
		}

		&.subMenuOpen .menuIcon {
			color: ${theme.palette.text.secondary};
		}

		&.subMenuOpen p {
			color: ${theme.palette.text.primary};
		}

		.menuIcon + .textCollapse {
			margin-left: 0.75rem;
		}

		.textCollapse, .textCollapse .MuiCollapse-horizontal {
			flex-grow: 1;
			display: flex;
			align-items: center;
		}
	}


	.MuiListItemText-root {
		overflow: hidden;
	}

	span,
	svg {
		flex-basis: 24px;
		flex-shrink: 0;
		color: ${theme.palette.action.active};
	}
`
);
