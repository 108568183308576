import { SortOrder } from "modules/core/types";
import { Network } from "pages/networks/types";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";

export enum NamedNetworkDrawerType {
	"Assign" = "Assign",
	"Assigned" = "Assigned",
}

export interface AssignedNetworksViewDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	networkList: Network[];
	networksSuggestMutation: any;
	isLoading?: boolean;
	title?: string;
	AssignNetworksOption?: () => JSX.Element;
	onSearchCriteriaChange?: (str: string) => void;
	page?: string;
	policyId?: string;
	onSortChange: (sort: Array<SortOrder>) => void;
	canDelete?: boolean;
	multiselect?: boolean;
	criteria?: string;
	hasPermission?: boolean;
	showSearch?: boolean;
	setPage?: (pageNumber: number) => void;
	setPageSize?: (pageSize: number) => void;
	pageSize?: number;
	pageCount?: number;
	totalCount?: number;
	policy?: TagPolicy;
	showDialog?: boolean;
}
