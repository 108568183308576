import { DisplayTextMap } from "common/constants/displayTextMap";
import { TIME_FILTER_OPTIONS } from "common/molecules/TimeFilter/TimeFilter";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { PathStatus } from "pages/paths/types";
import { useMemo } from "react";
import { ALL_TIME, COMMON_FACETS } from "../../modules/facets/constants";

export const NIL = "<nil>";

export const PATH_TRAFFIC_FACET_ORDER: FacetGroupInfo = {
	pathlastobserved: FacetGroups.Paths,
	enforced: FacetGroups.Paths,
	reviewed: FacetGroups.Paths,
	compassdirection: FacetGroups.Paths,
	direction: FacetGroups.Paths,
	bandwidthinbytes: FacetGroups.Paths,
	connectioncount: FacetGroups.Paths,
	internetbandwidthinbytes: FacetGroups.Paths,
	intranetbandwidthinbytes: FacetGroups.Paths,
	internetfacing: FacetGroups.Paths,
	dstprocess: FacetGroups.Paths,
	port: FacetGroups.Ports,
	portname: FacetGroups.Ports,
	protocol: FacetGroups.Ports,
	firewalllastreported: FacetGroups.Firewall,
	srcip: FacetGroups.Paths,
	dstip: FacetGroups.Paths,
};

const BASE_PATHS_FACET_ORDER: FacetGroupInfo = {
	...COMMON_FACETS,
	...PATH_TRAFFIC_FACET_ORDER,
	attacksurface: FacetGroups.Security,
	assetinboundstatus: FacetGroups.Security,
	blastradius: FacetGroups.Security,
	assetoutboundstatus: FacetGroups.Security,
	assetrisk: FacetGroups.Security,
	totalports: FacetGroups.Security,
	totalpaths: FacetGroups.Security,
	assetname: FacetGroups.Assets,
	internetoutboundenforcementready: FacetGroups.Assets,
	intranetoutboundenforcementready: FacetGroups.Assets,
	assetinternetfacing: FacetGroups.Assets,
	agentstatus: FacetGroups.Agents,
	currentversion: FacetGroups.Agents,
	policystatus: FacetGroups.Agents,
	agenttype: FacetGroups.Agents,
	firewalllastreported: FacetGroups.Firewall,
	namednetworkname: FacetGroups.Network,
	provider: FacetGroups.Network,
	isoobnetwork: FacetGroups.Network,
	cveid: FacetGroups.Security,
	vulnerabilities: FacetGroups.Security,
	securitypatches: FacetGroups.Security,
	package: FacetGroups.Security,
};

export const PATHS_FACET_ORDER: FacetGroupInfo = {
	...BASE_PATHS_FACET_ORDER,
	agentname: FacetGroups.Gatekeepers,
	agentlocation: FacetGroups.Gatekeepers,
};

const ENDPOINTS_FACETS: FacetGroupInfo = {
	groupname: FacetGroups.Groups,
	idpname: FacetGroups.IdP,
};

export const usePathsFacetsOrder = (
	mode?: AssetReviewModeType,
	direction?: Direction,
	excludedFacetGroups?: FacetGroups[]
) => {
	const { isFeatureEnabled: isUserGroupFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.USER_GROUP_FILTERS);
	const { isFeatureEnabled: isDevicesFiltersFeatureEnabled } =
		useFeatureFlagControl(FEATURES.DEVICES_FILTERS);

	const showEndpointFacets =
		isUserGroupFiltersFeatureEnabled &&
		!(mode === AssetReviewModeType.Review && direction === Direction.Inbound);

	const FACET_ORDER: FacetGroupInfo = useMemo(() => {
		if (isDevicesFiltersFeatureEnabled && showEndpointFacets) {
			return {
				...PATHS_FACET_ORDER,
				...ENDPOINTS_FACETS,
			};
		}
		if (isDevicesFiltersFeatureEnabled) {
			return PATHS_FACET_ORDER;
		}
		if (showEndpointFacets) {
			return {
				...BASE_PATHS_FACET_ORDER,
				...ENDPOINTS_FACETS,
			};
		}
		return BASE_PATHS_FACET_ORDER;
	}, [isDevicesFiltersFeatureEnabled, showEndpointFacets]);

	// Apply filtering to exclude specific facet groups
	const filteredFacetOrder = useMemo(() => {
		if (!excludedFacetGroups?.length) return FACET_ORDER;

		return Object.fromEntries(
			Object.entries(FACET_ORDER).filter(
				([, value]) => !excludedFacetGroups.includes(value as FacetGroups)
			)
		);
	}, [FACET_ORDER, excludedFacetGroups]);

	return filteredFacetOrder;
};

export const StatusOptionsText: { [key: string]: string } = DisplayTextMap;

export const StatusOptions: { [key: string]: string } = DisplayTextMap;

export { PathStatus };

export const StatusText = StatusOptions;

export const PATH_TIME_FILTER_OPTIONS = [...TIME_FILTER_OPTIONS, ALL_TIME];
