import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { API_KEYS_COLUMNS } from "./constants";
import { APIKeysResult, UserAPIKey } from "./types";

function useAPIKeys() {
	return useQuery<APIKeysResult, Error>(["apiKey", "auth/user/api-key"]);
}

function APIsDataGrid() {
	const { data: apiKeys, isLoading } = useAPIKeys();

	return (
		<Paper sx={{ flex: 1, overflow: "hidden" }}>
			<DataGrid
				isLoading={isLoading}
				rows={getUniqueListBy(apiKeys || [], "fingerprint")}
				rowCount={apiKeys?.length || 0}
				columns={API_KEYS_COLUMNS}
				getRowId={({ fingerprint }: UserAPIKey) => fingerprint}
			/>
		</Paper>
	);
}

export default APIsDataGrid;
