import { useTheme } from "@mui/material";
import { BarGroupBar, SeriesPoint, StackKey } from "@visx/shape/lib/types";
import { defaultStyles } from "@visx/tooltip";
import { getColor, } from "common/constants/colors";
import { dayjsWithPlugins } from "common/utils";
import { Dayjs } from "dayjs";
import { BarStackChart as BarChart, BarChartDatum, LineChartDatum } from "@colortokens/ng-ui";
import { getLabel } from "pages/dashboard/components/security-progress/SecurityProgress";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const tooltipStyles = {
  ...defaultStyles,
  minWidth: 200,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
};

export type ChartDatum = BarChartDatum;

export type BarStackProps<T extends BarChartDatum,  K extends LineChartDatum> = {
  data: Array<T> | undefined;
  lineChartData?:  Array<K>;
  width: number;
  height: number;
  hideAxis?: boolean;
  dataPoint?: string;
  lineChartDataPoint?: string;
  category?: string;
  showAllLabels?: boolean;
  total?: number;
  isDialogWidget?: boolean;
  getDataLink?: (bar: Omit<BarGroupBar<StackKey>, "key" | "value"> & {
    bar: SeriesPoint<BarChartDatum>;
    key: StackKey;
  }) => string;
  tooltipTitle?: string;
};



export function BarStackChart<T extends BarChartDatum,  K extends LineChartDatum>({
  width,
  height,
  ...props
}: BarStackProps<T, K>) {
  const theme = useTheme();
  const navigate = useNavigate();
  const getDataLink = props?.getDataLink;
  const margin = useMemo(
    () => ({
      top: 20,
      right: props.lineChartData ? 70 : 0,
      bottom: 30,
      left: 70,
    }),
    [props?.lineChartData]
  );

  const onBarClick = useCallback(
    (
      category: string | undefined,
      bar: Omit<BarGroupBar<StackKey>, "key" | "value"> & {
        bar: SeriesPoint<BarChartDatum>;
        key: StackKey;
      }
    ) => {
      const dataLink = getDataLink?.(bar)
      if ((category && bar?.key) || dataLink) {
        const text: string = bar?.key as string;
        const link = dataLink ?? getBtnLink(text, category);
        if (link) {
          navigate(link);
        }
      }
    },
    [navigate, getDataLink]
  );

  const getXAxisLabel = useCallback(
    (
      value: string | number | Dayjs | Date | null | undefined,
      index: number
    ) => {
      let showLabel = false;

      if (!props.data) {
        return "";
      }

      if (
        props.showAllLabels ||
        index === 0 ||
        index === Math.round(props.data?.length - 1) ||
        index === Math.round(props.data.length / 2 - 1) ||
        index === Math.round((3 * props.data.length) / 4 - 1) ||
        index === Math.round(props.data.length / 4 - 1)
      ) {
        showLabel = true;
      }

      if (value && showLabel) {
        let label = "";
        try {
          value = value.valueOf();
          label = dayjsWithPlugins(value).format("MMM DD");
        } catch (error) {
          label = getLabel(value as string);
        }
        if (label === "Invalid Date") {
          label = getLabel(value as string);
        }
        return window.getCTTranslatedText(label);
      }
      return "";
    },
    [props.data, props.showAllLabels]
  );

  const axisRightLabel = `${window.getCTTranslatedText("BarStackChartLabel", {
    lineChartDataPoint: window.getCTTranslatedText(
      props.lineChartDataPoint ?? ""
    ),
  })}`;

  return (<BarChart
    width={width}
    height={height}
    margin={margin}
    axisLeftLabel={window.getCTTranslatedText("Number of Assets")}
    axisRightLabel={axisRightLabel}
    getColor={(key) => getColor(key)}
    onBarClick={onBarClick}
    getXAxisLabel={getXAxisLabel}
    getLabel={key => window.getCTTranslatedText(getLabel(key))}
    theme={theme}
    {...props}
  />)
}

function getBtnLink(text: string, type?: string) {
  let link = "";
  let queryParam = encodeURI(`${type}=${text}`);
  link = `/assets?filters=${queryParam}`;

  return link;
}
