import { useMutation } from "@tanstack/react-query";
import { Scope } from "modules/scope-metadata/types";
import { UnmanagedDevice } from "pages/appliance-detail/components/appliance-unmanaged-devices/types";
import { AssetEditProps } from "pages/asset/components/asset-metadata-wrapper/components/metadata-edit/types";

export function useUpdateAssetAnnotations(
	assetIds: AssetEditProps["assetIds"]
) {
	const isBulkUpdate = assetIds.length > 1;
	const path = `assets/${isBulkUpdate ? "" : assetIds[0] + "/"}annotations`;
	return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useUpdateUnmanagedDevice(
	deviceId: UnmanagedDevice["deviceId"]
) {
	const base = Scope.UnmanagedDevice + "s";
	const path = `${base}/${deviceId}`;
	return useMutation<any, Error, any>(["asset", path, "put"]);
}
