import { debounce } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import { useNetworksSuggestions } from "modules/assign-networks-to-assets/components/AssignNetworkDialog/helpers";
import { nil } from "modules/facets/FacetUtils";
import { Scope } from "modules/scope-metadata/types";
import { useAppliancesAPI } from "pages/appliances/components/appliance-data-grid/hooks";
import { Appliance } from "pages/appliances/types";
import { useAssetsAPI } from "pages/assets/components/asset-data-grid/hooks";
import { usePathsAPI } from "pages/paths/components/path-data-grid/hooks";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { useEffect, useId, useMemo, useState } from "react";
import { CTAutoSuggest } from "../ct-auto-suggest/CTAutoSuggest";
import {
	applianceDataMapper,
	assetDataMapper,
	assetIpv4Filter,
	networkDataMapper,
	onLoadPathData,
	segmentsDataMapper,
} from "./helpers";
import { AllowedField, Suggestion } from "./types";

function useSummarize() {
	const path = "fields/actions/summarize";
	return useMutation<any, Error, any>(["suggestions", path]);
}

interface CTAutoSuggestWrapperProps {
	key?: string;
	displayName: string;
	selectedValue?: Suggestion;
	handleUpdate: (suggestion: Suggestion | undefined) => void;
	required?: boolean;
	showLabel?: boolean;
	scopeConfig?: {
		scope: Scope;
		field?: AllowedField;
		additionalCriteria?: string;
	}[];
	disabled?: boolean;
	error?: boolean;
	helperText?: string;
	agentId?: string;
	defaultSearchText?: string;
}

export function CTScopeAutoSuggest(props: CTAutoSuggestWrapperProps) {
	const {
		scopeConfig: scopeConfigFromParent,
		agentId,
		selectedValue,
		handleUpdate,
	} = props;
	const [scopeConfig, setScopeConfig] = useState(scopeConfigFromParent);

	useEffect(() => {
		setScopeConfig(old => {
			if (isEqual(old, scopeConfigFromParent)) {
				return old;
			}
			return scopeConfigFromParent;
		});
	}, [scopeConfigFromParent]);

	const applianceSuggestionMutation = useAppliancesAPI();
	const assetsSuggestionMutation = useAssetsAPI();
	const pathSuggestMutation = usePathsAPI();
	const networksSuggestMutation = useNetworksSuggestions();
	const segmentsSuggestMutation = useTagPolicyListAPI();
	const summarizeMutation = useSummarize();

	const [suggestions, setSuggestions] = useState<Array<Suggestion>>([]);
	const [searchText, setSearchText] = useState("");

	const pathMutate = useMemo(
		() => debounce(pathSuggestMutation.mutate, 300),
		[pathSuggestMutation.mutate]
	);

	const networkMutate = useMemo(
		() => debounce(networksSuggestMutation.mutate, 300),
		[networksSuggestMutation.mutate]
	);

	const segmentsMutate = useMemo(
		() => debounce(segmentsSuggestMutation.mutate, 300),
		[segmentsSuggestMutation.mutate]
	);

	const applianceMutate = useMemo(
		() => debounce(applianceSuggestionMutation.mutate, 300),
		[applianceSuggestionMutation.mutate]
	);

	const summarizeMutate = useMemo(
		() => debounce(summarizeMutation.mutate, 300),
		[summarizeMutation.mutate]
	);

	const assetMutate = useMemo(
		() => debounce(assetsSuggestionMutation.mutate, 300),
		[assetsSuggestionMutation.mutate]
	);

	useEffect(() => {
		if (!scopeConfig) return;

		const promises: Array<Promise<Suggestion[]>> = scopeConfig?.map(
			async config => {
				const additionalCriteria = config.additionalCriteria;
				const criteria: string = searchText
					? `'${searchText}' ${
							additionalCriteria ? " AND " + additionalCriteria : ""
						} `
					: (additionalCriteria ?? "*");

				const summarize = () => {
					const summarizeBody = {
						criteria: additionalCriteria ?? "*",
						facetField: `${config.field}`,
						facetFieldFilter: searchText || "",
						scope: config.scope,
					};

					return new Promise<Suggestion[]>(resolve => {
						summarizeMutate(summarizeBody, {
							onSuccess: response => {
								if (!response?.Facet?.values) {
									resolve([]);
								}
								const values = (
									Object.keys(response?.Facet?.values || {}) || []
								).filter(v => v !== nil);
								resolve(
									values.map((v): Suggestion => {
										return {
											displayName: v,
											type: config.scope,
											value: v,
										};
									})
								);
							},
							onError: () => {
								resolve([]);
							},
						});
					});
				};

				switch (config.scope) {
					case Scope.Path:
						if (
							config.field === AllowedField.PathPort ||
							config.field === AllowedField.Process
						) {
							return summarize();
						}
						const pathReqBody = {
							criteria: criteria,
							pagination: {
								offset: 0,
								limit: 10,
								sort: [{ field: "pathlastobserved", order: "desc" }],
							},
						};

						return new Promise<Suggestion[]>(resolve => {
							pathMutate(pathReqBody, {
								onSuccess: response => {
									resolve(
										onLoadPathData(
											searchText,
											response?.items || [],
											config.field === AllowedField.PathWithDomain
										)
									);
								},
								onError: () => {
									resolve([]);
								},
							});
						});

					case Scope.Port:
					case Scope.Template:
						return summarize();

					case Scope.Asset:
						const assetBody = {
							criteria: criteria,
							pagination: {
								offset: 0,
								limit: 10,
								sort: [{ field: "assetrisk", order: "desc" }],
							},
						};

						return new Promise<Suggestion[]>(resolve => {
							assetMutate(assetBody, {
								onSuccess: response => {
									if (!response?.items) {
										resolve([]);
									}
									resolve(
										(response?.items || [])
											.filter(assetIpv4Filter)
											.map(assetDataMapper)
									);
								},
								onError: () => {
									resolve([]);
								},
							});
						});

					case Scope.Network:
						const networkBody = {
							criteria: criteria,
							pagination: {
								offset: 0,
								limit: 10,
								sort: [{ field: "isOOBNetwork", order: "asc" }],
							},
						};

						return new Promise<Suggestion[]>(resolve => {
							networkMutate(networkBody, {
								onSuccess: response => {
									if (!response?.items) {
										resolve([]);
									}
									resolve((response?.items || []).map(networkDataMapper));
								},
								onError: () => {
									resolve([]);
								},
							});
						});

					case Scope.TagBasedPolicy:
						const tbpBody = {
							criteria: criteria,
							pagination: {
								offset: 0,
								limit: 10,
								sort: [{ field: "tagBasedPolicyName", order: "asc" }],
							},
						};

						return new Promise<Suggestion[]>(resolve => {
							segmentsMutate(tbpBody, {
								onSuccess: (response: any) => {
									if (!response?.items) {
										resolve([]);
									}
									resolve((response?.items || []).map(segmentsDataMapper));
								},
								onError: () => {
									resolve([]);
								},
							});
						});

					case Scope.Appliance:
						const applianceBody = {
							criteria: criteria,
							pagination: {
								offset: 0,
								limit: 10,
								sort: [{ field: "agentname", order: "asc" }],
							},
						};

						return new Promise<Suggestion[]>(resolve => {
							applianceMutate(applianceBody, {
								onSuccess: response => {
									if (!response?.items) {
										resolve([]);
									}
									resolve(
										applianceDataMapper(
											(response?.items ?? []) as Appliance[],
											agentId
										)
									);
								},
								onError: () => {
									resolve([]);
								},
							});
						});

					default:
						return Promise.resolve([]);
				}
			}
		);

		const updateSuggestionsPartially = () => {
			let flatResponses: Array<Suggestion> = [];

			const handleResponse = (response: Array<Suggestion>) => {
				flatResponses = flatResponses.concat(response);

				let suggestionList = uniqBy(flatResponses, v => {
					return v.type + v.value;
				});

				if (scopeConfig?.length > 1) {
					suggestionList = suggestionList
						.filter(s => s.displayName)
						.map(s => {
							s._group = s.type;
							if (s.type === Scope.Network) {
								s._group = window.getCTTranslatedText("Networks");
							}
							if (s.type === Scope.TagBasedPolicy) {
								s._group = window.getCTTranslatedText("Segments");
							}
							if (s.type === Scope.Asset) {
								s._group = window.getCTTranslatedText("Assets");
							}
							return s;
						});
				}

				suggestionList.sort((a, b) => {
					if (a._group && b._group) {
						return a._group?.localeCompare(b._group);
					}
					if (a.displayName && b.displayName) {
						return a.displayName?.localeCompare(b.displayName);
					}
					return 0;
				});
				setSuggestions(suggestionList);
			};

			promises.forEach(promise => {
				promise
					.then((response: Array<Suggestion>) => {
						handleResponse(response);
					})
					.catch(error => {
						console.error(error);
					});
			});
		};

		updateSuggestionsPartially();
	}, [
		pathMutate,
		networkMutate,
		searchText,
		scopeConfig,
		applianceMutate,
		summarizeMutate,
		segmentsMutate,
		assetMutate,
		agentId,
	]);

	const searchTextChange = (searchText: string) => {
		if (searchText !== undefined) {
			setSearchText(searchText);
		}
	};

	const id = useId();

	useEffect(() => {
		const value = suggestions.find(
			list =>
				list.value === selectedValue?.value &&
				selectedValue?.displayName === undefined
		);

		if (value) {
			handleUpdate(value);
		}
	}, [suggestions, handleUpdate, selectedValue]);

	return (
		<>
			<CTAutoSuggest
				{...props}
				key={props.key ?? id}
				list={suggestions}
				selectedValue={
					props.selectedValue?.type === "Default"
						? undefined
						: props.selectedValue
				}
				handleUpdate={({ value }) => {
					let suggestedValue = value as Suggestion;
					if (suggestedValue?.value) {
						props.handleUpdate(suggestedValue);
					} else if ((value?.toString()?.length ?? 0) > 0) {
						props.handleUpdate({
							type: "Default",
							value: value as string,
						});
					} else {
						props.handleUpdate(undefined);
					}
				}}
				onSearchTextChange={searchTextChange}
			/>
		</>
	);
}
