import { Box, Stack } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";

import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

enum DebugLogState {
	DISABLED_RUNNING = "disabled_running",
	ENABLED_STOPPED = "enabled_stopped",
	ENABLED_RUNNING = "enabled_running",
	DISABLED_STOPPED = "disabled_stopped",
}

export function DebugLogStatus({ agent, viewOnly }: AgentColumnRenderProps) {
	if (!agent?.agentId) {
		return <Box style={{ width: 120 }} />;
	}

	const isDisabled = () => {
		if (agent.isDebugLogRunning) {
			return true;
		}
		return false;
	};

	const debugLogStatusText = () => {
		const statusKey = `${agent.isDebugLogEnabled ? "enabled" : "disabled"}_${
			agent.isDebugLogRunning ? "running" : "stopped"
		}`;

		switch (statusKey) {
			case DebugLogState.DISABLED_RUNNING:
				return window.getCTTranslatedText("Enabling");
			case DebugLogState.ENABLED_STOPPED:
				return window.getCTTranslatedText("Enabled");
			case DebugLogState.ENABLED_RUNNING:
				return window.getCTTranslatedText("Disabling");
			case DebugLogState.DISABLED_STOPPED:
				return window.getCTTranslatedText("Disabled");
			default:
				return "";
		}
	};
	return (
		<Stack sx={{ width: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				asText={viewOnly === true}
				isDisabled={isDisabled()}
				facetName={"currentdebuglogstate"}
				facetOption={agent.debugLogState}
			>
				{debugLogStatusText()}
			</CTDatagridButton>
		</Stack>
	);
}
