import { AssetStatus } from "pages/assets/types";
import { ProgressiveEnforcementLevel } from "pages/ports/types";
import { PolicyChangeType, PolicyChangeValues } from "./types";

export const buildPortsCriteria = (
	changeType: PolicyChangeType,
	selectedLevel: PolicyChangeValues
) => {
	switch (changeType) {
		case PolicyChangeType.AttackSurfaceProgressive:
			if (selectedLevel === ProgressiveEnforcementLevel.AllowWithBandwidth) {
				return `('listenportlastobserved' BETWEEN 720 AND 50000) AND 'listenportreviewed' in ('allowed-by-progressive')`;
			}
			return `'listenportreviewed' in ('allowed-by-progressive')`;

		case PolicyChangeType.AttackSurfaceEnforcement:
			if (
				selectedLevel === AssetStatus.SecureInternet ||
				selectedLevel === AssetStatus.SimulateSecureAll
			) {
				return `'listeningonpublicinterface' in ('true')`;
			}
			return ``;

		default:
			return ``;
	}
};

export const buildPathsCriteria = (
	changeType: PolicyChangeType,
	selectedLevel: PolicyChangeValues
) => {
	switch (changeType) {
		case PolicyChangeType.BlastRadiusProgressive:
			return `'reviewed' in ('allowed-by-progressive')`;

		case PolicyChangeType.AttackSurfaceEnforcement:
		case PolicyChangeType.BlastRadiusEnforcement:
			if (
				selectedLevel === AssetStatus.SecureInternet ||
				selectedLevel === AssetStatus.SimulateSecureAll
			) {
				return `'compassdirection' in ('north-south')`;
			}
			return ``;

		default:
			return ``;
	}
};

export const combineCriteria = (criteria1?: string, criteria2?: string) => {
	if (criteria1 && criteria2) {
		return `${criteria1} AND ${criteria2}`;
	}
	return criteria1 || criteria2 || "";
};
