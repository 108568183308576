import { Box, Typography } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";

export function AssetMaximumCVSS({ asset, viewOnly }: AssetColumnRenderProps) {
	if (!asset.assetId) {
		return <Box style={{ width: 150 }} />;
	}

	if (asset.maximumCVSSScore) {
		return <Typography variant="body2">{asset.maximumCVSSScore}</Typography>;
	}

	return null;
}
