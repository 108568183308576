import LoadingButton from "@mui/lab/LoadingButton";
import {
	Button,
	FormControl,
	FormControlLabel,
	Link,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CopyButton } from "common/atoms/copy-button";
import { CTDrawer } from "common/molecules/drawer";
import { FilePicker } from "modules/file-picker";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useMemo, useState } from "react";
import { GenerateAPIKeyButton } from "./GenerateAPIKeyButton";
import { APIKey } from "./types";

const OPTIONS = {
	UPLOAD: "upload",
	GENERATE: "generate",
};

function useNewAPIKey() {
	const path = `auth/user/api-key`;
	return useMutation<any, Error, APIKey>(["apiKey", path]);
}

const validPrefixes = [
	"-----BEGIN RSA PUBLIC KEY-----",
	"-----BEGIN PUBLIC KEY-----",
];

export function NewAPIKeyButton({
	defaultShowForm,
}: {
	defaultShowForm?: boolean;
}) {
	const [option, onChangeOption] = useState(OPTIONS.UPLOAD);
	const [showForm, setShowForm] = useState(defaultShowForm === true);
	const [publicKey, setPublicKey] = useState("");
	const createKeyMutation = useNewAPIKey();
	const queryClient = useQueryClient();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const createKey = async () => {
		await createKeyMutation.mutateAsync({
			publicKey: window.btoa(publicKey),
		});
		resetDrawer();
		queryClient.invalidateQueries({
			queryKey: ["apiKey"],
		});
	};

	const checkValid = (text: string) => {
		let isValid = false;
		validPrefixes.forEach(prefix => {
			if (text.startsWith(prefix)) {
				isValid = true;
			}
		});
		return isValid;
	};

	const onChangeFile = async (files: File[]) => {
		if (!files.length) {
			return;
		}
		const text = await files[0].text();
		const isValid = checkValid(text);
		if (!isValid) {
			setSnackbar(true, SnackBarSeverity.Error, "InvalidKey");
			return;
		}
		setPublicKey(text);
	};

	const resetDrawer = () => {
		setPublicKey("");
		setShowForm(false);
		onChangeOption(OPTIONS.UPLOAD);
	};

	const isKeyInvalid = useMemo(() => {
		if (!publicKey) {
			return true;
		}
		return false === checkValid(publicKey);
	}, [publicKey]);

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={() => setShowForm(true)}
				size="medium"
			>
				{window.getCTTranslatedText("New API Key")}
			</Button>

			<CTDrawer
				title="New API Key"
				anchor="right"
				open={showForm}
				onClose={resetDrawer}
				drawerWidth="1000px"
				actions={
					<LoadingButton
						disabled={isKeyInvalid}
						loading={createKeyMutation.isLoading}
						variant="contained"
						onClick={createKey}
					>
						{window.getCTTranslatedText("Save")}
					</LoadingButton>
				}
			>
				<Typography variant="body2" mb={4}>
					{window.getCTTranslatedText(
						`An API key is required to access ColorTokens API. You can either upload public key file of an RSA key (minimum 2048 bits) or generate a new one in the browser.`
					)}
				</Typography>

				<FormControl
					sx={{
						width: "100%",
						mb: 4,
					}}
				>
					<RadioGroup
						sx={{ flexDirection: "row" }}
						value={option}
						onChange={(e, value) => onChangeOption(value)}
					>
						<FormControlLabel
							value={OPTIONS.UPLOAD}
							disabled={Boolean(publicKey)}
							control={<Radio size="small" />}
							label={window.getCTTranslatedText(
								"Upload Public Key File (Recommended)"
							)}
						/>
						<FormControlLabel
							sx={{ ml: 8 }}
							value={OPTIONS.GENERATE}
							disabled={Boolean(publicKey)}
							control={<Radio size="small" />}
							label={window.getCTTranslatedText(
								"Generate a new private key in browser"
							)}
						/>
					</RadioGroup>
				</FormControl>

				{option === OPTIONS.UPLOAD && !Boolean(publicKey) && (
					<Stack alignItems="center" justifyContent={"center"}>
						<Stack sx={{ mb: 2, width: "100%", height: "160px" }}>
							<FilePicker
								onChangeFiles={onChangeFile}
								options={{
									accept: {
										"text/html": [".pem"],
									},
									maxFiles: 1,
								}}
							/>
						</Stack>
						<Link
							href="https://colortokens.atlassian.net/wiki/spaces/~6307a5cbf87d21c16228ac7c/pages/3306356835/How+to+enable+API+key+access+to+your+ColorTokens+account?atlOrigin=eyJpIjoiZGU5OTY5ZTYzYjczNGMzYzk5MjE2NDJjMzMzNzRjNDMiLCJwIjoiYyJ9"
							target="_blank"
						>
							{window.getCTTranslatedText(
								"How to generate RSA keys on your machine?"
							)}
						</Link>
					</Stack>
				)}

				{option === OPTIONS.GENERATE && !Boolean(publicKey) && (
					<>
						<GenerateAPIKeyButton
							onGenerate={publicKey => {
								setPublicKey(publicKey);
							}}
						/>
					</>
				)}

				{Boolean(publicKey) && (
					<>
						<Typography variant="body1" mb="0.5rem">
							{window.getCTTranslatedText("Public Key")}
						</Typography>
						<Paper
							elevation={2}
							sx={{
								display: "flex",
								alignItems: "start",
								height: "80px",
								overflowY: "auto",
								px: "1rem",
								pt: "0.875rem",
								pb: 0,
								boxSizing: "content-box",
							}}
						>
							<Typography
								variant="body2"
								whiteSpace={"pre-line"}
								pb={"0.875rem"}
								textOverflow={"ellipsis"}
								sx={{
									flexGrow: 1,
									overflowX: "auto",
								}}
							>
								{publicKey}
							</Typography>
							<CopyButton
								text={publicKey}
								sx={{ position: "sticky", top: 0 }}
							/>
						</Paper>
					</>
				)}
			</CTDrawer>
		</>
	);
}
