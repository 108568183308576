import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { CustomCommonStoreType } from "common/types/types";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { PortLastObservedTimestamp } from "pages/ports/components/port-last-observed/PortLastObservedTimestamp";
import { PortStatus } from "pages/ports/types";
import { MatchedByTemplate } from "../../../../modules/matched-by-templates";
import { PortAnnotations } from "../port-annotations";
import { PortAsset } from "../port-asset";
import { PortAssetTags } from "../port-asset-tags";
import { PortCategory } from "../port-category";
import { PortConnectionCount } from "../port-connection-count";
import { PortDataVolume } from "../port-data-volume";
import { PortDetails } from "../port-details";
import { PortLastObserved } from "../port-last-observed";
import { PortLateralMovement } from "../port-lateral-movement";
import { PortPathCount } from "../port-path-count";
import { PortProcess } from "../port-process";
import { PortService } from "../port-service";
import { PortStatusEnforced } from "../port-status-enforced";
import { PortStatusView } from "../port-status-view";

export enum ColumnType {
	DRAWER = "drawer",
	FULLSCREEN = "fullscreen",
}

const isPortControlledByTemplate = (status: PortStatus) => {
	return (
		status === PortStatus.AllowAnyByTemplate ||
		status === PortStatus.DeniedByTemplate ||
		status === PortStatus.AllowIntranetByTemplate ||
		status === PortStatus.PathRestrictedByTemplate
	);
};

export const BASE_PORT_COLUMNS = (
	type: ColumnType,
	useFacetStore?: CustomCommonStoreType
) =>
	[
		{
			field: "listenport",
			headerName: "Listen Port",
			width: 160,
			minWidth: 160,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortDetails port={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "portcategory",
			headerName: "Port Category",
			width: 120,
			minWidth: 140,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortCategory port={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "listenportname",
			headerName: "Service",
			minWidth: 120,
			width: 120,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortService port={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "listenprocessnames",
			headerName: "Port Process Name",
			width: type === ColumnType.FULLSCREEN ? 140 : 160,
			minWidth: type === ColumnType.FULLSCREEN ? 140 : 160,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortProcess port={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "portdatavolume",
			headerName: "Data Volume",
			width: 160,
			minWidth: 160,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortDataVolume port={params.row} viewOnly={false} />;
			},
		},
		{
			field: "pathCount",
			headerName: "Paths",
			width: 70,
			minWidth: 70,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortPathCount port={params.row} />;
			},
		},
		{
			field: "assetname",
			headerName: "Asset Name",
			flex: type === ColumnType.FULLSCREEN ? 0.8 : 1,
			width: type === ColumnType.FULLSCREEN ? 140 : 160,
			minWidth: type === ColumnType.FULLSCREEN ? 140 : 160,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortAsset port={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "assettags",
			headerName: "Tags",
			flex: type === ColumnType.FULLSCREEN ? 0.8 : 1,
			width: type === ColumnType.FULLSCREEN ? 140 : 160,
			minWidth: type === ColumnType.FULLSCREEN ? 140 : 160,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PortAssetTags port={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "listenportlastobserved",
			headerName: "Last Observed",
			width: type === ColumnType.FULLSCREEN ? 160 : 130,
			minWidth: type === ColumnType.FULLSCREEN ? 160 : 130,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortLastObserved port={params.row} />;
			},
		},
		{
			field: "listenportlastobservedtimestamp",
			headerName: "Port Last Observed (Timestamp)",
			width: type === ColumnType.FULLSCREEN ? 200 : 170,
			minWidth: type === ColumnType.FULLSCREEN ? 200 : 170,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortLastObservedTimestamp port={params.row} />;
			},
		},
		{
			field: "portconnectioncount",
			headerName: "Connections",
			width: type === ColumnType.FULLSCREEN ? 160 : 130,
			minWidth: type === ColumnType.FULLSCREEN ? 160 : 130,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortConnectionCount port={params.row} />;
			},
		},
		{
			field: "matchedByTemplates",
			headerName: "Matched By Templates",
			width: 130,
			minWidth: 130,
			sortable: false,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const port = params.row;
				return (
					<MatchedByTemplate
						id={port?.lpId}
						assetId={port?.listenAsset?.assetId}
						port={port}
						type={MatchedByTemplateType.PORT}
						matchedByTemplates={port.matchedByTemplates}
						showDeleteIcon={
							isPortControlledByTemplate(port?.listenPortEnforced) &&
							!isPortControlledByTemplate(port?.listenPortReviewed)
						}
					/>
				);
			},
		},
		{
			field: "lateralMovementTechniques",
			headerName: "MITRE Attack Techniques",
			width: 150,
			minWidth: 150,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PortLateralMovement port={params.row} />;
			},
		},
		{
			field: "listenportenforced",
			headerName: "Enforced Port Status",
			width: 120,
			minWidth: 140,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PortStatusEnforced port={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
	] as GridColDef[];

export const PORT_COLUMNS: GridColDef[] = [
	...BASE_PORT_COLUMNS(ColumnType.FULLSCREEN),
	{
		field: "listenportreviewed",
		headerName: "Port Status",
		sortingOrder: ["asc", "desc"],
		width: 120,
		minWidth: 120,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <PortStatusView port={params.row}></PortStatusView>;
		},
	},
	{
		field: "comment",
		headerName: "",
		headerAlign: "left",
		align: "left",
		width: 50,
		minWidth: 50,
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		sortingOrder: ["desc", "asc"],
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <PortAnnotations port={params.row} />;
		},
	},
];

// default hidden columns
const ColumnVisibilityModel = {
	listenportlastobservedtimestamp: false,
};

const PinnedColumns = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "listenport"],
	right: ["comment"],
};

export const PortColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};
