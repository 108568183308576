import { Box, Stack } from "@mui/material";
import { AssetGuardRailColumnConfig } from "common/atoms/ct-guardrail/constants";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { OutputData } from "./types";

export const CTGuardrailBreakdownDateGrid = (
	props: DataGridProps<OutputData>
) => {
	return (
		<Stack
			sx={{
				flex: 1,
				minHeight: 0,
				width: "100%",
				overflow: "hidden",
			}}
		>
			<Box
				sx={{
					height: "100%",
					width: "100%",
					overflow: "hidden",
				}}
			>
				<DataGrid
					pinnedColumns={AssetGuardRailColumnConfig.PinnedColumns}
					defaultPinnedColumns={AssetGuardRailColumnConfig.PinnedColumns}
					rowHeight={64}
					pagination
					getRowId={row => {
						return Object.keys(row)[0];
					}}
					paginationMode="client"
					sortingMode="client"
					{...props}
				/>
			</Box>
		</Stack>
	);
};
