import { Stack } from "@mui/material";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useTagRuleStore } from "../../store";

export interface TagRulesToolbarProps extends Omit<ToolbarProps, "children"> {}

export default function TagRulesToolbar(props: TagRulesToolbarProps) {
	const requestAPIRefresh = useTagRuleStore(store => store.requestAPIRefresh);
	return (
		<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
			<Stack
				direction="row"
				spacing={2}
				sx={{ width: "100%" }}
				justifyItems="flex-end"
				alignItems={"center"}
				justifyContent="flex-end"
			></Stack>
		</GridToolbar>
	);
}
