import { Grid } from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { Scope } from "modules/scope-metadata/types";
import { ReactElement } from "react";
import {
	Controller,
	ControllerRenderProps,
	FieldValues,
	useForm,
} from "react-hook-form";
import BusinessValueSelect from "./BusinessValueSelect";
import EditTagAutocomplete from "./EditTagAutocomplete";
import { FormBuilderElement } from "./types";

interface Props {
	data: FormBuilderElement[];
	scope: Scope;
}

function useMetadataEditForm({ data, scope }: Props) {
	const { control, handleSubmit } = useForm();

	const textFormElementDisplayProps: Record<string, any> = {
		assetName: {
			placeholder: "Enter asset name",
		},
		deviceName: {
			placeholder: "Enter asset name",
		},
		vendorInfo: {
			placeholder: "Enter vendor info",
		},
	};

	const generateFormElements = () => {
		const createFormElementWithController = ({
			rhfKey,
			label,
			type,
			value,
			icon,
		}: FormBuilderElement) => {
			const formElementRenderMap: Record<
				string,
				({
					field,
				}: {
					field: ControllerRenderProps<FieldValues, string>;
				}) => ReactElement
			> = {
				text: ({
					field: { onChange: handleUpdate, name: field, ref, ...rest },
				}) => {
					return (
						<CTInputField
							field={field}
							handleUpdate={handleUpdate}
							type="text"
							{...textFormElementDisplayProps[field]}
							displayName={label}
							startIcon={icon}
							sx={{
								"& input::placeholder": {
									fontSize: "inherit",
								},
							}}
							{...rest}
						/>
					);
				},
				tag: ({ field: { ref, name, ...rest } }) => {
					const nameArr = name.split(".");
					const key = nameArr[nameArr.length - 1];

					return (
						<EditTagAutocomplete
							tagKey={key}
							label={window.getCTTranslatedText(label)}
							icon={icon}
							scope={scope}
							{...rest}
						/>
					);
				},
				businessValue: ({ field: { ref, name, ...rest } }) => {
					return <BusinessValueSelect {...rest} />;
				},
			};

			const render = formElementRenderMap[type];

			return (
				<Controller
					defaultValue={value}
					control={control}
					name={rhfKey}
					render={render}
				/>
			);
		};

		return data.map(obj => (
			<Grid item xs={obj.columns} key={obj.key}>
				{createFormElementWithController(obj)}
			</Grid>
		));
	};

	const formElements = generateFormElements();

	return { formElements, handleSubmit };
}

export default useMetadataEditForm;
