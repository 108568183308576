import { CTConfirmation } from "common/atoms/ct-confirmation";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useScanApplianceAPI } from "pages/appliance-detail/hooks/useScanApplianceAPI";
import { useApplianceStore } from "pages/appliances/store";
import { ApplianceScanProps } from "../types";

export const ApplianceScan = ({
	agent,
	showScan,
	setShowScan,
}: ApplianceScanProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const applianceScanMutation = useScanApplianceAPI(agent?.agentId);
	const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);

	const scanAppliance = () => {
		applianceScanMutation.mutateAsync(
			{},
			{
				onSuccess(response) {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						window.getCTTranslatedText("scanGatekeeperSuccess")
					);
					setShowScan(false);
					requestAPIRefresh();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};
	return (
		<CTConfirmation
			open={showScan}
			onClose={() => setShowScan(false)}
			title={window.getCTTranslatedText("scanGatekeeper")}
			primaryText={window.getCTTranslatedText("gatekeeperScanConfirmation")}
			secondaryText={window.getCTTranslatedText("gatekeeperScanNote")}
			primaryButtonText={window.getCTTranslatedText("scanButton")}
			isLoading={applianceScanMutation.isLoading}
			onSuccess={scanAppliance}
		/>
	);
};
