import { ChevronRight, PendingActions } from "@mui/icons-material";
import {
	capitalize,
	CircularProgress,
	Divider,
	Menu,
	MenuItem,
	PaletteMode,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { ColorModeContext } from "assets/ctTheme/CTThemeProvider";
import { useDemoController } from "hooks/useDemoController";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { LANG_MAP, MENU_ID } from "modules/AppBar/constants";
import { PendingWorkRequestsDrawer } from "modules/PendingWorkRequestsDrawer";
import { PendingWorkRequestLocationType } from "modules/PendingWorkRequestsDrawer/constants";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store";
import React, { useCallback, useContext, useState } from "react";
import { usePollPendingWorkRequest, useTenantListAPI } from "../hooks";
import { ProfileSubMenu } from "./profile-sub-menu";
import OrganizationsMenuItems from "./profile-sub-menu/OrganizationsMenuItems";

interface UserProfileMenuProps {
	anchorEl: HTMLElement | null;
	setAnchorEl: Function;
	onLogout: Function;
}

type MainMenuItem = {
	title?: string | undefined;
	subtitle?: string;
	onClick?: () => void;
	subMenuItems?: React.ReactNode[];
	renderIcon?: JSX.Element;
};

type MenuSection = MainMenuItem[];

type SubMenuState = {
	anchorEl: HTMLElement | null;
	mainMenuIndex: number;
	sectionIndex: number;
};

export const UserProfileMenu = ({
	anchorEl,
	setAnchorEl,
	onLogout,
}: UserProfileMenuProps) => {
	const [subMenuState, setSubMenuState] = useState<SubMenuState>({
		anchorEl: null,
		mainMenuIndex: -1,
		sectionIndex: -1,
	});

	const { data: tenants } = useTenantListAPI();

	const isMenuOpen = Boolean(anchorEl);
	const colorMode = useContext(ColorModeContext);
	const theme = useTheme();

	const { isDemo, handleDemoClick, handleHomeClick } = useDemoController();

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSubMenuState(prev => ({
			...prev,
			anchorEl: null,
		}));
	};

	const handleSwitchDemoClick = () => {
		handleMenuClose();
		isDemo ? handleHomeClick() : handleDemoClick();
	};

	const handleLogout = () => {
		onLogout();
		handleMenuClose();
		try {
			window.localStorage.clear();
		} catch (e) {
			console.error("failed to clear storage storage on logout");
		}
	};
	const { isFeatureEnabled: isWorkRequestEnabled } = useFeatureFlagControl(
		FEATURES.WORK_REQUEST
	);
	const isPendingWorkRequestsDrawerOpen = useWorkRequestStore(
		state => state.isOpen
	);
	const setIsPendingWorkRequestsDrawerOpen = useWorkRequestStore(
		state => state.setOpen
	);
	const onBackgroundProcessesDrawerClose = useCallback(
		() => setIsPendingWorkRequestsDrawerOpen(false),
		[setIsPendingWorkRequestsDrawerOpen]
	);

	const onBackgroundProcessesDrawerOpen = () => {
		setIsPendingWorkRequestsDrawerOpen(true);
		handleMenuClose();
	};

	const MENU_ITEMS: MenuSection[] = [
		[
			{
				title: "Organizations",
				subtitle: tenants?.current.name,
				subMenuItems: OrganizationsMenuItems(),
			},
		],
		[
			{
				title: isDemo ? "Exit demo" : "Switch to demo",
				onClick: handleSwitchDemoClick,
			},
			...[
				isWorkRequestEnabled && isMenuOpen
					? {
							title: "system-tasks",
							onClick: onBackgroundProcessesDrawerOpen,
							renderIcon: <RenderIcon />,
						}
					: {},
			],
			{
				title: "Theme",
				subMenuItems: (["light", "dark"] as PaletteMode[]).map(mode => (
					<MenuItem
						key={mode}
						selected={mode === theme.palette.mode}
						onClick={() => colorMode.setColorMode(mode)}
					>
						{window.getCTTranslatedText(capitalize(mode))}
					</MenuItem>
				)),
			},
			{
				title: "Change Language",
				subMenuItems: Object.entries(LANG_MAP).map(([lang, label]) => (
					<MenuItem
						selected={window.CTLangCode === lang}
						key={lang}
						onClick={() => window.changeLanguage(lang)}
					>
						{label}
					</MenuItem>
				)),
			},
		],
		[
			{
				title: "Logout",
				onClick: handleLogout,
			},
		],
	];

	return (
		<>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				id={MENU_ID}
				open={isMenuOpen}
				onClose={handleMenuClose}
				PaperProps={{
					sx: { width: "240px" },
				}}
			>
				{MENU_ITEMS.map((section, index) => (
					<div key={index}>
						{index !== 0 && <Divider variant="middle" sx={{ px: 3, my: 1 }} />}
						{section.map((item, i) => (
							<MenuItem
								key={item.title}
								onMouseOver={e => {
									if (item.subMenuItems && !isPendingWorkRequestsDrawerOpen) {
										setSubMenuState({
											anchorEl: e.currentTarget,
											mainMenuIndex: index,
											sectionIndex: i,
										});
									} else {
										setSubMenuState(prev => ({
											...prev,
											anchorEl: null,
										}));
									}
								}}
								onClick={() => {
									item?.onClick?.();
								}}
								sx={{ flexWrap: "wrap", justifyContent: "space-between" }}
							>
								<Stack>
									<Stack
										direction="row"
										alignItems={"center"}
										justifyContent={"space-between"}
										flex={"1 0 100%"}
									>
										<Typography variant="body2">
											{window.getCTTranslatedText(item.title as string)}
										</Typography>
										{item.subMenuItems && <ChevronRight sx={{ ml: "auto" }} />}
									</Stack>
									{item.subtitle && (
										<Typography
											variant="caption"
											sx={{ color: theme.palette.text.secondary }}
										>
											{window.getCTTranslatedText(item.subtitle)}
										</Typography>
									)}
								</Stack>
								{item.renderIcon && item.renderIcon}
							</MenuItem>
						))}
					</div>
				))}
			</Menu>
			<ProfileSubMenu
				anchorEl={subMenuState.anchorEl}
				onClose={() => setSubMenuState(s => ({ ...s, anchorEl: null }))}
			>
				{
					MENU_ITEMS[subMenuState.mainMenuIndex]?.[subMenuState.sectionIndex]
						?.subMenuItems
				}
			</ProfileSubMenu>
			{isPendingWorkRequestsDrawerOpen && (
				<PendingWorkRequestsDrawer
					isOpen={isPendingWorkRequestsDrawerOpen}
					onClose={onBackgroundProcessesDrawerClose}
					componentType={PendingWorkRequestLocationType.DRAWER}
				/>
			)}
		</>
	);
};

const RenderIcon = () => {
	const { badgeConfig, isLoading } = usePollPendingWorkRequest();
	if (isLoading) {
		return (
			<CircularProgress
				sx={{
					width: "16px !important",
					height: "16px !important",
				}}
			/>
		);
	}
	if (badgeConfig.count === 0) {
		return null;
	}
	return <PendingActions color="primary" fontSize="small" />;
};
