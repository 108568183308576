import { Box, Typography } from "@mui/material";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";

export function UnmanagedDeviceMaximumCVSS({
	device,
	viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
	if (!device.deviceId) {
		return <Box style={{ width: 150 }} />;
	}

	if (device.deviceMaximumCVSSScore) {
		return (
			<Typography variant="body2">{device.deviceMaximumCVSSScore}</Typography>
		);
	}

	return null;
}
