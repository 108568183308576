import { Button, Stack, Typography } from "@mui/material";
import { EmptyAlertPageProps } from "./types";

export const EmptyAlertPage = ({ onClick }: EmptyAlertPageProps) => {
	return (
		<Stack
			alignItems={"center"}
			justifyContent={"center"}
			sx={{
				height: "100%",
			}}
			flex={1}
		>
			<Typography variant="body2">
				{window.getCTTranslatedText("AllAlertsDisabled")}
			</Typography>
			<Typography variant="body2">
				{window.getCTTranslatedText("AdjustTimeFilterInstruction")}
			</Typography>
			<Stack alignItems="center" direction={"row"} justifyContent={"center"}>
				<Button
					variant="outlined"
					color="primary"
					sx={{
						mr: 3,
						mt: 2,
					}}
					onClick={onClick}
				>
					{window.getCTTranslatedText("Refresh") ?? ""}
				</Button>
			</Stack>
		</Stack>
	);
};
