import { useQuery } from "@tanstack/react-query";

export function useDevicesSegmentationAPI(
	applianceId?: string,
	isParent?: boolean
) {
	const path = `gateways/${applianceId}/devicestatus${isParent ? "?hagroup=true" : ""}`;
	return useQuery<any, Error>(["appliance-devices", path], {
		enabled: !!applianceId,
	});
}
