import { Box, Stack } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { AgentColumnRenderProps } from "pages/agents/types";
export function NorthSouthTrafficCollectionStatus({
	agent,
	viewOnly,
}: Readonly<AgentColumnRenderProps>) {
	if (!agent?.agentId) {
		return <Box style={{ width: 120 }} />;
	}
	return (
		<Stack sx={{ width: "100%" }} alignItems="left" justifyContent="center">
			<CTDatagridButton
				asText={viewOnly === true}
				facetName={"nstrafficstatus"}
				facetOption={agent.northSouthTrafficConfiguration}
			>
				{window.getCTTranslatedText(agent.northSouthTrafficConfiguration)}
			</CTDatagridButton>
		</Stack>
	);
}
