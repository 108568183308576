import { capitalize, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";

import { AxiosError } from "axios";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { getSegmentsNameFromCriteria } from "common/utils/getSegmentsNameFromCriteria";
import { useAntlrParser } from "hooks/useAntlrParser/useAntlrParser";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import { FacetUtils } from "modules/facets";
import { getParsedCriteria } from "modules/facets/components/criteria-chips/CriteriaChips";
import { FacetState } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { AssetVisualizeButton } from "pages/assets/components/asset-visualize-button";
import { FIREWALL_HOST_GROUPS_PERMISSION } from "pages/crowdstrike-groups/firewall-host-groups/constants";
import useDeleteFirewallHostGroup from "pages/crowdstrike-groups/firewall-host-groups/hooks/useDeleteFirewallHostGroup";
import { useFirewallHostGroupStore } from "pages/crowdstrike-groups/firewall-host-groups/store";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import {
	useCrowdStrikeTagPolicyStore,
	useTagPolicyFacetStore,
	useTagPolicyStore,
} from "../../store";
import { CrowdStrikeTagPolicy, TagPolicy } from "../../types";
import { PolicyAutomationDrawer } from "../policy-automation-drawer";
import { AssignedNetworksTags } from "../tag-networks/components/assigned-networks-tags";
import { TagPolicyCOnfirmationDialog } from "../tag-policy-confirmation-dialog";
import {
	RecommendationType,
	TagPolicyRecommendationsDrawer,
} from "../tag-policy-recommendations/TagPolicyRecommendations";
import { AssignedTemplatesTags } from "../tag-templates/components/assigned-templates-tags";

export function TagPolicyActions({
	policy,
}: {
	policy: TagPolicy | CrowdStrikeTagPolicy;
}) {
	const parseCriteria = useAntlrParser();
	const metadata = useTagPolicyFacetStore(state => state.metadata);
	const managedBy = policy.managedBy ?? ManagedByValue.ColorTokens;
	const { data } = useScopeMetadata({ scope: Scope.Asset });
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const navigate = useNavigate();

	const [showPolicyCreator, setShowPolicyCreator] = useState(false);
	const [isRingFence, setRingFence] = useState(false);
	const [policyFacets, setPolicyFacets] = useState<FacetState>(undefined);

	const { isFeatureEnabled: isCrowdStrikeEnabled } = useFeatureFlagControl(
		FEATURES.CROWDSTRIKE_EDR
	);
	const { setIdForDeletion } = useFirewallHostGroupStore();
	const isCrowdStrikePolicy =
		isCrowdStrikeEnabled && managedBy === ManagedByValue.CrowdStrike;

	const getFacetState = () => {
		const parseResult = parseCriteria(policy?.criteria ?? "");
		if (parseResult) {
			return getParsedCriteria(parseResult, metadata);
		}
	};
	const facetState = getFacetState();
	const autoSuggestName = getSegmentsNameFromCriteria(facetState, metadata);

	const requestAPIRefresh = useTagPolicyStore(state => state.requestAPIRefresh);

	const [automationDrawerVisibility, setAutomationDrawerVisibility] =
		useState(false);
	const [showAssignedTemplates, setShowAssignedTemplates] = useState(false);
	const [showAssignedNetworks, setShowAssignedNetworks] = useState(false);

	const onCloseAutomationDrawer = (refresh: boolean) => {
		setAutomationDrawerVisibility(false);
		refresh && requestAPIRefresh();
	};

	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const canDelete = userPermissions.has(
		managedBy === ManagedByValue.ColorTokens
			? "DELETE_TAGBASEDPOLICY"
			: FIREWALL_HOST_GROUPS_PERMISSION.DELETE
	);

	const [selectedRecommendationType, setSelectedRecommendationType] = useState<
		RecommendationType | undefined
	>();

	const menuOptions = [];

	if (userPermissions.has("UPDATE_TAGBASEDPOLICY") && !isCrowdStrikePolicy) {
		menuOptions.push({
			label: "Create Access Policy",
			handler: () => {
				setPolicyFacets(getFacetState());
				setShowPolicyCreator(true);
			},
		});
		menuOptions.push({
			label: "Create Ring Fence",
			handler: () => {
				setPolicyFacets(getFacetState());
				setRingFence(true);
				setShowPolicyCreator(true);
			},
		});
	}

	if (userPermissions.has("LIST_EVENT")) {
		menuOptions.push({
			label: "View history",
			handler: () => {
				navigate(
					`${ROUTES.MONITORING_LOGS.pathname}?filters=resourceid%3D${policy.tagBasedPolicyId}`
				);
			},
		});
	}

	if (userPermissions.has("UPDATE_TAGBASEDPOLICY")) {
		menuOptions.push({
			label: "EditSegment",
			handler: () => {
				const basePath = isCrowdStrikePolicy
					? ROUTES.EDIT_CROWDSTRIKE_SEGMENT.pathname
					: ROUTES.SEGMENT_EDIT.pathname;
				const basePathWithoutId = basePath.split("/").slice(0, -1).join("/");
				navigate(`${basePathWithoutId}/${policy.tagBasedPolicyId}`);
			},
		});
	}

	if (!isCrowdStrikePolicy && userPermissions.has("CREATE_TAGBASEDPOLICY")) {
		menuOptions.push({
			label: "CloneSegment",
			handler: () => {
				navigate(`/segment/clone/${policy.tagBasedPolicyId}`);
			},
		});
	}

	menuOptions.push({
		label: "Port Recommendations",
		handler: () => {
			setSelectedRecommendationType(RecommendationType.Port);
		},
	});

	menuOptions.push({
		label: "Path Recommendations",
		handler: () => {
			setSelectedRecommendationType(RecommendationType.Path);
		},
	});

	if (userPermissions.has("UPDATE_TAGBASEDPOLICY")) {
		menuOptions.push({
			label: "Manage Templates",
			handler: () => setShowAssignedTemplates(true),
		});

		menuOptions.push({
			label: "Manage Named Networks",
			handler: () => setShowAssignedNetworks(true),
		});
	}

	if (canDelete) {
		menuOptions.push({
			label: "DeleteSegment",
			handler: () => {
				setShowDeleteDialog(true);
				isCrowdStrikePolicy &&
					setIdForDeletion(
						(policy as CrowdStrikeTagPolicy).crowdstrikeFirewallHostGroup
							.crowdstrikeFirewallHostgroupId
					);
			},
			disabled: Boolean(
				policy.templatesAssigned || policy.namednetworksAssigned
			),
			tooltipMessage: window.getCTTranslatedText("deleteSegmentTooltip"),
		});
	}

	if (
		userPermissions.has("UPDATE_POLICY_AUTOMATION") &&
		policy.policyAutomationConfigurable
	) {
		menuOptions.unshift({
			label: "Configure policy automation",
			handler: () => {
				setAutomationDrawerVisibility(true);
			},
		});
	}

	const getCriteria = (policy?: TagPolicy) => {
		const parseResult = parseCriteria(policy?.criteria ?? "");
		if (parseResult) {
			return `filters=${encodeURIComponent(FacetUtils.getURLQueryFromFacets(getParsedCriteria(parseResult, data)))}`;
		}

		return "";
	};

	return (
		<>
			<Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
				<AssetVisualizeButton
					useAsIcon={true}
					paramsURL={getCriteria(policy)}
				/>
				<MoreOptionsMenu menuOptions={menuOptions} />
			</Stack>
			{showDeleteDialog && (
				<DeleteTagPolicyDialog
					policy={policy}
					onClose={() => {
						setShowDeleteDialog(false);
					}}
				/>
			)}

			{showPolicyCreator && (
				<CreateTagBasedPolicyDrawer
					isOpen={showPolicyCreator}
					defaultSourceFacets={policyFacets}
					defaultDestinationFacets={isRingFence ? policyFacets : undefined}
					templateRules={[]}
					isRingFence={isRingFence}
					defaultSourceTagBasedPolicyId={policy.tagBasedPolicyId}
					onClose={() => {
						requestAPIRefresh();
						setRingFence(false);
						setShowPolicyCreator(false);
					}}
					policy={policy}
					showDialog={true}
				/>
			)}

			{selectedRecommendationType !== undefined && Boolean(policy.criteria) && (
				<TagPolicyRecommendationsDrawer
					criteria={policy.criteria!}
					onClose={() => {
						requestAPIRefresh();
						setSelectedRecommendationType(undefined);
					}}
					selectedTab={selectedRecommendationType}
					policyId={policy.tagBasedPolicyId}
					autoSuggestName={autoSuggestName}
				/>
			)}

			{automationDrawerVisibility && (
				<PolicyAutomationDrawer
					isOpen={automationDrawerVisibility}
					onClose={onCloseAutomationDrawer}
					id={policy.tagBasedPolicyId}
					criteria={policy.criteria}
					isZeroTrustAutomationEditable={policy?.policyAutomationConfigurable}
					policy={policy}
					showDialog={true}
				/>
			)}

			{showAssignedTemplates && (
				<AssignedTemplatesTags
					criteria={policy?.criteria}
					multiselect={true}
					policyId={policy?.tagBasedPolicyId}
					open={showAssignedTemplates}
					onClose={() => setShowAssignedTemplates(false)}
					policy={policy}
					showDialog={true}
				/>
			)}

			{showAssignedNetworks && (
				<AssignedNetworksTags
					criteria={policy?.criteria}
					multiselect={true}
					policyId={policy?.tagBasedPolicyId}
					open={showAssignedNetworks}
					onClose={() => setShowAssignedNetworks(false)}
					policy={policy}
					showDialog={true}
				/>
			)}
		</>
	);
}

function useDeletePolicy(tagBasedPolicyId: string) {
	const path = `tagbasedpolicies/${tagBasedPolicyId}`;
	return useMutation<any, Error>(["tagbasedpolicies", path, "delete"]);
}

function DeleteTagPolicyDialog({
	policy,
	onClose,
}: {
	policy: TagPolicy;
	onClose: VoidFunction;
}) {
	const managedBy = policy?.managedBy ?? ManagedByValue.ColorTokens;
	const { idForDeletion, setIdForDeletion } = useFirewallHostGroupStore();
	const deleteMutationColorTokens = useDeletePolicy(policy?.tagBasedPolicyId);
	const deleteMutationCrowdStrike = useDeleteFirewallHostGroup({
		crowdstrikeFirewallHostgroupId: idForDeletion ?? "",
	});
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const refreshRequestColorTokens = useTagPolicyStore(
		state => state.requestAPIRefresh
	);
	const refreshRequestCrowdStrike = useCrowdStrikeTagPolicyStore(
		state => state.requestAPIRefresh
	);

	const { isFeatureEnabled: isCrowdStrikeEnabled } = useFeatureFlagControl(
		FEATURES.CROWDSTRIKE_EDR
	);

	const isCrowdStrikePolicy =
		isCrowdStrikeEnabled && managedBy === ManagedByValue.CrowdStrike;

	const deleteMutation = isCrowdStrikePolicy
		? deleteMutationCrowdStrike
		: deleteMutationColorTokens;
	const refreshRequest = isCrowdStrikePolicy
		? refreshRequestCrowdStrike
		: refreshRequestColorTokens;

	const closeRef = useRef(onClose);
	useEffect(() => {
		if (deleteMutation.isSuccess) {
			notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
				label: "DeleteSegmentRequestSubmittedSuccessfully",
			});
			setIdForDeletion(null);
			closeRef.current();
			refreshRequest();
		}
	}, [
		deleteMutation.isSuccess,
		notify,
		refreshRequest,
		setSnackbar,
		setIdForDeletion,
	]);

	useEffect(() => {
		if (deleteMutation.isError) {
			let message = "SomethingWentWrong";
			const error = deleteMutation.error as AxiosError;
			if (error?.response?.status === 409) {
				message = "segmentDeleteErrorToastMessage";
			}
			// @ts-ignore: Axios error type
			else if (error?.response?.data?.message) {
				// @ts-ignore: Axios error type
				message = capitalize(error?.response?.data?.message);
			}
			setSnackbar(
				true,
				SnackBarSeverity.Error,
				window.getCTTranslatedText(message)
			);
		}
	}, [deleteMutation.error, deleteMutation.isError, setSnackbar]);

	return (
		<TagPolicyCOnfirmationDialog
			policy={policy as TagPolicy}
			isOpen={true}
			onClose={() => onClose()}
			title={"DeleteSegment"}
			primaryText={"DeleteSegmentTitle"}
			isLoading={deleteMutation.isLoading}
			onSuccess={() => deleteMutation.mutate()}
			secondaryTextWarning={"DeleteSegmentWarning"}
		/>
	);
}
