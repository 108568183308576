import { DeleteOutline } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { CTUser } from "pages/settings/components/organization/types";
import { useState } from "react";
import { DeleteUser } from "../delete-user";

interface UserActionsMenuProps {
	user: CTUser;
}

export function UserActionsMenu({ user }: UserActionsMenuProps) {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const handleDeleteUser = (visibility: boolean) => {
		setShowDeleteConfirmation(visibility);
	};

	const cancelCallback = () => {
		setShowDeleteConfirmation(false);
	};

	return (
		<>
			<IconButton onClick={() => handleDeleteUser(true)}>
				<Tooltip title={window.getCTTranslatedText("Delete")}>
					<DeleteOutline sx={{ fontSize: "1.25rem" }} />
				</Tooltip>
			</IconButton>
			<DeleteUser
				user={user}
				allowDelete={showDeleteConfirmation}
				handleClose={cancelCallback}
			/>
		</>
	);
}
