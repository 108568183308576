import { Grid, Link, Stack, Tooltip, Typography } from "@mui/material";
import { VulnerabilityDrawer } from "modules/vulnerability-drawer";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

interface AssetVulnerabiltiesProps {
	assetId?: string;
	assetName?: string;
	assetType?: string;
	vulnerabilityType?: string;
	vulnerabilities?: boolean;
	viewOnly?: boolean;
}

export function AssetVulnerabilities({
	assetId,
	assetName,
	assetType = "assets",
	vulnerabilityType,
	vulnerabilities,
	viewOnly,
}: AssetVulnerabiltiesProps) {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	let missingValue = false;
	if (vulnerabilities === null || vulnerabilities === undefined) {
		missingValue = true;
	}

	const openDrawer = (
		e:
			| React.MouseEvent<HTMLAnchorElement, MouseEvent>
			| React.MouseEvent<HTMLSpanElement, MouseEvent>
	) => {
		e.preventDefault();
		setIsDrawerOpen(true);
	};

	const isClickable = !viewOnly && vulnerabilities;

	return (
		<>
			<Stack alignItems="center" justifyContent="center">
				<Grid
					container
					direction="row"
					justifyContent="start"
					alignItems="center"
				>
					<Tooltip
						title={window.getCTTranslatedText(
							isClickable ? "View vulnerabilities" : ""
						)}
					>
						{!isClickable ? (
							<Typography variant="body2" sx={{ color: "warning.main" }}>
								{missingValue ? "" : vulnerabilities}
							</Typography>
						) : (
							<Link
								typography={"body2"}
								component={RouterLink}
								to={"#"}
								onClick={e => openDrawer(e)}
								sx={{ color: "warning.main" }}
							>
								{missingValue ? "" : vulnerabilities}
							</Link>
						)}
					</Tooltip>
				</Grid>
			</Stack>
			{isDrawerOpen && (
				<VulnerabilityDrawer
					isOpen={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					vulnerabilityType={vulnerabilityType}
					assetId={assetId || ""}
					assetName={assetName || ""}
					assetType={assetType}
				/>
			)}
		</>
	);
}
