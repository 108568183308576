import { Operator } from "@colortokens/ng-ui";
import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { getFacetGroupInfo } from "common/utils";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useCore } from "modules/core";
import { SortOrder } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { FacetGroups } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useCoreTagsFacetOrder } from "pages/create-tag-policy/CreateTagPolicy";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { FACET_ORDER as TEMPLATE_FACET_ORDER } from "pages/templates/constants";
import { useCallback, useEffect, useMemo } from "react";
import { useTagPolicyListAPI } from "../../TagPolicyList";
import {
	SEGMENTS_READ_ONLY_DIMENSIONS,
	SEGMENTS_TABS,
	tabBasedPolicyColumnConfig,
} from "../../constants";
import { useTagPolicyFacetStore, useTagPolicyStore } from "../../store";
import { TagPolicy } from "../../types";
import { TagPolicyDataGrid } from "../TagPolicyDataGrid";

export function ColorTokensTagPolicyList() {
	const theme = useTheme();
	const { isFeatureEnabled: isProgressiveEnabled } = useFeatureFlagControl(
		FEATURES.PROGRESSIVE
	);
	const { isFeatureEnabled: isProgressiveOutboundV1Enabled } =
		useFeatureFlagControl(FEATURES.PROGRESSIVE_OUTBOUND_V1);

	const { data: metaData } = useScopeMetadata({
		scope: Scope.TagBasedPolicy,
	});
	const updateMetadata = useTagPolicyFacetStore(state => state.updateMetadata);
	useEffect(() => {
		updateMetadata(metaData);
	}, [metaData, updateMetadata]);

	const facetOrder = useCoreTagsFacetOrder();
	const facetOrderGroup = useMemo(() => {
		let commonFacetOrder = {
			...facetOrder,
			lowestpolicyinboundstatus: FacetGroups.PolicyAutomation,
			lowestpolicyoutboundstatus: FacetGroups.PolicyAutomation,
			policyautomationconfigurable: FacetGroups.PolicyAutomation,
			namednetworkid: FacetGroups.Network,
			namednetworkname: FacetGroups.Network,
			TEMPLATE_FACET_ORDER,
			templateid: FacetGroups.Templates,
			policyautosynchronize: FacetGroups.PolicyAutomation,
		};

		if (isProgressiveEnabled) {
			commonFacetOrder = {
				...commonFacetOrder,
				lowestpolicyprogressiveinboundstatus: FacetGroups.PolicyAutomation,
			};
		}

		if (isProgressiveOutboundV1Enabled) {
			commonFacetOrder = {
				...commonFacetOrder,
				lowestpolicyprogressiveoutboundstatus: FacetGroups.PolicyAutomation,
			};
		}

		return getFacetGroupInfo(commonFacetOrder, SEGMENTS_READ_ONLY_DIMENSIONS);
	}, [facetOrder, isProgressiveEnabled, isProgressiveOutboundV1Enabled]);

	const coreResponse = useCore<TagPolicy>({
		useStore: useTagPolicyStore,
		useFacetStore: useTagPolicyFacetStore,
		facetGroupInfo: facetOrderGroup,
		scope: Scope.TagBasedPolicy,
		useApi: useTagPolicyListAPI,
		pageSize: 100,
		defaultSortOrder: [{ field: "tagbasedpolicyname", order: "asc" }],
		additionalCriteria:
			SEGMENTS_TABS[ManagedByValue.ColorTokens].additionalCriteria,
	});

	const onSortChange = useCallback(
		(sortOrder: SortOrder[]) => {
			const newSortOrder = sortOrder.map(sort => {
				if (sort.field === "name") {
					return {
						field: "tagbasedpolicyname",
						order: sort.order,
					};
				}

				return sort;
			});

			coreResponse.onSortChange(newSortOrder);
		},
		[coreResponse]
	);

	return (
		<>
			<Stack direction="row">
				<Stack sx={{ flex: 1, py: 2 }}>
					<FacetOpenCloseController
						useFacetStore={useTagPolicyFacetStore}
						facetsOpen={coreResponse.facetsOpen}
						setFacetsOpen={coreResponse.setFacetsOpen}
					>
						<FacetControllerGroup
							useFacetStore={useTagPolicyFacetStore}
							config={coreResponse.facetConfig}
							value={coreResponse.facetState}
							onChange={coreResponse.updateFacet}
							disabledOperators={[Operator.NOT_EQUAL]}
						/>
					</FacetOpenCloseController>
				</Stack>
			</Stack>

			<Paper
				sx={{
					transition: theme.transitions.create(["width", "margin"], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					flex: 1,
					overflow: "hidden",
				}}
			>
				<TagPolicyDataGrid
					defaultPinnedColumns={tabBasedPolicyColumnConfig.pinnedColumns}
					rowCount={coreResponse.rowCount}
					rows={getUniqueListBy(coreResponse.rows ?? [], "tagBasedPolicyId")}
					mutation={coreResponse.mutation}
					onPageChange={coreResponse.onPageChange}
					page={coreResponse.page}
					pageSize={coreResponse.pageSize}
					onPageSizeChange={coreResponse.onPageSizeChange}
					onSortChange={onSortChange}
					hasNoData={
						coreResponse.mutation.isSuccess && coreResponse.rowCount === 0
					}
					metadata={coreResponse.metadata}
					triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
					getExportStatus={coreResponse?.getExportStatus}
					getUrlToDownload={coreResponse?.getUrlToDownload}
					resetDownloadUrl={coreResponse?.resetDownloadUrl}
					rawData={coreResponse?.rawData}
				/>
			</Paper>
		</>
	);
}
