import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CircularProgress, Stack, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
	DataGridProProps,
	GridGroupNode,
	GridRenderCellParams,
	GridRow,
	GridRowId,
	useGridApiContext,
	useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useCommonStore } from "common/store";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import difference from "lodash/difference";
import { useCriteriaBuilder } from "modules/core/Core";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Appliance } from "pages/appliances/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApplianceToolbar } from "../appliance-data-grid-toolbar";
import {
	ACTION_COLUMN,
	ApplianceColumnConfig,
	BASE_APPLIANCE_COLUMNS,
	GROUP_COLUMN,
	LOG_COLUMN,
} from "./constants";
import { isParentRow, mapAppliance } from "./helpers/columnHelpers";
import { useAppliancesAPI } from "./hooks";

const CustomRow = (props: React.ComponentProps<typeof GridRow>) => {
	const theme = useTheme();
	const apiRef = useGridApiContext();
	const rowNode = apiRef.current.getRowNode(props?.rowId) as GridGroupNode;
	const backgroundColorChild = theme.palette.custom.rowBackground;
	const textColorChild = theme.palette.text.secondary;

	return (
		<GridRow
			{...props}
			style={
				(rowNode?.depth ?? 0) > 0
					? {
							backgroundColor: props?.selected
								? undefined
								: backgroundColorChild,
							color: textColorChild,
						}
					: undefined
			}
		/>
	);
};

const CustomGridTreeDataGroupingCell = React.memo(
	({ row, hasChildren, isExpanded, isLoading, onToggle }: any) => {
		const handleClick = useCallback(
			(event: React.MouseEvent) => {
				event.stopPropagation();
				onToggle(row, isExpanded);
			},
			[onToggle, row, isExpanded]
		);

		return (
			<Box sx={{ display: "flex", alignItems: "center" }}>
				{hasChildren && (
					<IconButton onClick={handleClick} size="small">
						{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				)}
				{isLoading && <CircularProgress size={16} />}
				{row.name}
			</Box>
		);
	}
);

export const ApplianceDataGrid = (props: DataGridProps<Appliance>) => {
	const apiRef = useGridApiRef();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const applianceMutation = useAppliancesAPI();
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const [rows, setRows] = useState<Appliance[]>(props.rows ?? []);
	const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
		{}
	);
	const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>(
		{}
	);
	const [selectionModel, setSelectionModel] = useState<Array<GridRowId>>([]);

	const facetState = useCommonStore(state => state.facets);
	const metadata = useScopeMetadata({ scope: Scope.Appliance });

	const aggregateCriteria = useCriteriaBuilder("", facetState, metadata.data);

	useEffect(() => {
		setRows(props.rows ?? []);
	}, [props.rows]);

	const columns = useMemo(() => {
		let applianceCols = [...BASE_APPLIANCE_COLUMNS];

		if (userPermissions.has("READ_AGENT_LOG")) {
			applianceCols = [...applianceCols, ...LOG_COLUMN];
		}
		if (userPermissions.has("UPDATE_GATEWAY")) {
			applianceCols = [...applianceCols, ...ACTION_COLUMN];
		}
		applianceCols = [...applianceCols, ...GROUP_COLUMN];
		return applianceCols;
	}, [userPermissions]);

	const getApplianceChildren = useCallback(
		async (row: Appliance) => {
			if (row.children?.length) {
				return row.children;
			}
			let criteria = `'virtualId' in ('${row.virtualId}')`;
			if (aggregateCriteria !== "*") {
				criteria = criteria + ` AND ${aggregateCriteria}`;
			}
			const body = {
				criteria: criteria,
				pagination: {
					offset: 0,
					limit: 10,
					sort: [],
				},
				facetFields: [],
			};

			return applianceMutation
				.mutateAsync(body)
				.then(response => {
					return (
						response?.items?.map((appliance: Appliance) => {
							const mappedAppliance = { ...appliance };
							mapAppliance(mappedAppliance);
							return {
								...mappedAppliance,
								agentId: `${appliance?.agentId}-child`,
								matchIds: [row.agentId, `${appliance?.agentId}-child`],
							};
						}) ?? []
					);
				})
				.catch(error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					return [];
				});
		},
		[applianceMutation, setSnackbar, aggregateCriteria]
	);

	const getAllChildrenIds = useCallback(
		async (row: Appliance): Promise<GridRowId[]> => {
			if (!isParentRow(row)) {
				return [];
			}

			let childrenIds: GridRowId[] = [];

			if (!row.children || row.children.length === 0) {
				// Fetch children if they haven't been loaded yet
				const children = await getApplianceChildren(row);
				childrenIds = children.map((child: Appliance) => child.agentId);
			} else {
				childrenIds = row.children.map(child => child.agentId);
			}

			return childrenIds;
		},
		[getApplianceChildren]
	);

	const getParentId = useCallback(
		(rowId: GridRowId): GridRowId | null => {
			const rowNode = apiRef.current.getRowNode(rowId);
			return rowNode?.parent ? rowNode.parent : null;
		},
		[apiRef]
	);

	const handleRowExpansion = useCallback(
		async (row: Appliance, isExpanded: boolean) => {
			if (!isParentRow(row)) {
				return; // Don't expand child rows
			}

			const isCurrentlyExpanded = isExpanded;
			if (!isCurrentlyExpanded) {
				if (!row.children || row.children.length === 0) {
					setLoadingRows(prev => ({ ...prev, [row.agentId]: true }));
					const children = await getApplianceChildren(row);
					setRows(prevRows => {
						const updatedRows = prevRows.map(r =>
							r.agentId === row.agentId ? { ...r, children } : r
						);
						return [...updatedRows, ...children];
					});
					setLoadingRows(prev => ({ ...prev, [row.agentId]: false }));
				}
				requestAnimationFrame(() => {
					apiRef.current.setRowChildrenExpansion(row.agentId, true);
				});
			} else {
				apiRef.current.setRowChildrenExpansion(row.agentId, false);
			}
			setExpandedRows({ ...expandedRows, [row.agentId]: !isCurrentlyExpanded });
		},
		[expandedRows, apiRef, getApplianceChildren]
	);

	const onSelectionModelChange = useCallback(
		async (newSelectionModel: Array<GridRowId>) => {
			let finalSelection = new Set(newSelectionModel);

			const processRow = async (rowId: GridRowId, isSelected: boolean) => {
				const row = rows.find(r => r.agentId === rowId);
				if (row && isParentRow(row)) {
					if (isSelected) {
						await handleRowExpansion(row, false);
						const childrenIds = await getAllChildrenIds(row);
						childrenIds.forEach(childId => finalSelection.add(childId));
					} else {
						const childrenIds = await getAllChildrenIds(row);
						childrenIds.forEach(childId => finalSelection.delete(childId));
					}

					// Check/uncheck parent if all siblings are checked/unchecked
					const parentId = getParentId(rowId);
					if (parentId) {
						const parentRow = rows.find(r => r.agentId === parentId);
						if (parentRow && isParentRow(parentRow)) {
							const siblingIds = await getAllChildrenIds(parentRow);
							const allSiblingsSelected = siblingIds.every(id =>
								finalSelection.has(id)
							);
							if (allSiblingsSelected) {
								finalSelection.add(parentId);
							} else {
								finalSelection.delete(parentId);
							}
						}
					}
				}
			};

			const addedRows = difference(newSelectionModel, selectionModel);
			const removedRows = difference(selectionModel, newSelectionModel);

			for (const rowId of addedRows) {
				await processRow(rowId, true);
			}

			for (const rowId of removedRows) {
				await processRow(rowId, false);
			}

			setSelectionModel(Array.from(finalSelection));
		},
		[selectionModel, rows, handleRowExpansion, getAllChildrenIds, getParentId]
	);

	let selectedData: Array<Appliance> | undefined = useMemo(() => {
		return rows?.filter((row: Appliance) => {
			return selectionModel.indexOf(row?.agentId) !== -1;
		});
	}, [selectionModel, rows]);

	let selectedRawData: Array<Appliance> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: Appliance) => {
			return selectionModel.indexOf(row?.agentId) !== -1;
		});
	}, [selectionModel, props.rawData]);

	const onClickClearSelection = () => {
		setSelectionModel([]);
	};

	const getTreeDataPath = useCallback((row: Appliance) => {
		return row.matchIds ?? [row.agentId];
	}, []);

	const cleanAgentId = useCallback((agentId: string) => {
		return agentId.replace("-child", "");
	}, []);

	const filterSelectedData = useCallback(
		(selectedData: Appliance[] | undefined): Appliance[] => {
			if (!selectedData) return [];
			return selectedData
				.filter(row => !isParentRow(row) || !row.virtualId)
				.map(row => ({
					...row,
					agentId: cleanAgentId(row.agentId),
					rowId: cleanAgentId(row.agentId),
				}));
		},
		[cleanAgentId]
	);

	const filterSelection = useCallback(
		(selection: GridRowId[]): GridRowId[] => {
			return selection.map(id => cleanAgentId(id as string));
		},
		[cleanAgentId]
	);

	const filteredSelectedData = useMemo(
		() => filterSelectedData(selectedData),
		[selectedData, filterSelectedData]
	);
	const filteredSelection = useMemo(
		() => filterSelection(selectionModel),
		[selectionModel, filterSelection]
	);

	const groupingColDef: DataGridProProps["groupingColDef"] = {
		headerName: "",
		headerClassName: "hideRightSeparator",
		renderCell: params => renderCell(params),
	};

	function renderCell(params: GridRenderCellParams) {
		const row = params.row;
		const rowNode = params.rowNode as GridGroupNode;
		const isExpanded = rowNode?.childrenExpanded;
		const isLoading = loadingRows[row.agentId];
		const hasChildren =
			isParentRow(row) || (row.children && row.children.length > 0);

		return (
			<CustomGridTreeDataGroupingCell
				row={row}
				hasChildren={hasChildren}
				isExpanded={isExpanded}
				isLoading={isLoading}
				onToggle={handleRowExpansion}
			/>
		);
	}

	const renderToolbar = useCallback(
		() => (
			<ApplianceToolbar
				hideToolbar={onClickClearSelection}
				selectedData={filteredSelectedData}
				selection={filteredSelection}
			/>
		),
		[filteredSelectedData, filteredSelection]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					defaultPinnedColumns={ApplianceColumnConfig.PinnedColumns}
					groupingColDef={groupingColDef}
					slots={{
						row: CustomRow,
					}}
					treeData
					getTreeDataPath={getTreeDataPath}
					checkboxSelection={userPermissions.has("UPDATE_AGENT")}
					rowSelectionModel={selectionModel}
					onRowSelectionModelChange={onSelectionModelChange}
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ agentId }: Appliance) => `${agentId}`}
					paginationMode="server"
					sortingMode="client" // Client is Needed for grouping children by MUI for tree data
					apiRef={apiRef}
					{...props}
					rows={rows}
					isLoading={props?.isLoading}
					selectedRawData={selectedRawData}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

export const MemoizedApplianceDataGrid = React.memo(ApplianceDataGrid);
