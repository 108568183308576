import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { UploadIcon } from "assets/svgs/UploadIcon";
import { useEffect, useRef } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

// @ts-ignore:
const getColor = props => {
	if (props.isDragAccept) {
		return "#00e676";
	}
	if (props.isDragReject) {
		return "#ff1744";
	}
	if (props.isFocused) {
		return "#2196f3";
	}
	return "#eeeeee";
};

const Container = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${props => getColor(props)};
	border-style: dashed;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

export function FilePicker({
	onChangeFiles,
	options,
}: {
	onChangeFiles: (files: File[]) => void;
	options?: DropzoneOptions;
}) {
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone(options);
	const changeFilesRef = useRef(onChangeFiles);
	changeFilesRef.current = onChangeFiles;

	useEffect(() => {
		changeFilesRef.current(acceptedFiles);
	}, [acceptedFiles]);

	return (
		<Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
			<input {...getInputProps()} />
			<UploadIcon />
			<Typography variant="body1" mt={1}>
				<Typography
					variant="body1"
					display="inline"
					color={"primary"}
					sx={{ cursor: "pointer" }}
				>
					{window.getCTTranslatedText("Click to upload")}
				</Typography>{" "}
				<Typography variant="body1" display="inline">
					{window.getCTTranslatedText("or")}
				</Typography>{" "}
				{window.getCTTranslatedText("dragAndDrop")}
			</Typography>
		</Container>
	);
}
