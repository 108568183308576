import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { Toolbar } from "common/atoms/toolbar";
import {
	INTEGRATIONS,
	IntegrationConfigType,
	getIntegrationEnum,
} from "pages/Integration/constants";
import { CrowdStrikeConfig } from "../crowdStrike-config";

interface CrowdStrikeConfigProps {
	open: boolean;
	onClose: () => void;
	integration: IntegrationConfigType;
	title: string;
}

export const CrowdStrikeDrawer = ({
	open,
	onClose,
	integration,
	title,
}: CrowdStrikeConfigProps) => {
	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title="Close Drawer">
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
				<Typography variant="h5">
					<b>{title}</b>
				</Typography>
			</Stack>
			{getIntegrationEnum(integration) === INTEGRATIONS.CROWDSTRIKE_CMDB && (
				<CrowdStrikeConfig
					open={open}
					onClose={onClose}
					integration={integration}
				/>
			)}
		</Drawer>
	);
};
