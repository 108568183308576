import { Stack, Tab, Typography } from "@mui/material";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs";
import { CTDrawer } from "common/molecules/drawer";
import { CTDrawerProps } from "common/molecules/drawer/CTDrawer";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { ResponseType } from "modules/core/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { ManagedByValue } from "pages/crowdstrike-groups/firewall-host-groups/types";
import { TagPolicyDataGrid } from "pages/tags/components/tag-policy-list/components/TagPolicyDataGrid";
import { SEGMENTS_TABS } from "pages/tags/components/tag-policy-list/constants";
import {
	useCrowdStrikeTagPolicyFacetStore,
	useCrowdStrikeTagPolicyStore,
	useTagPolicyFacetStore,
	useTagPolicyStore,
} from "pages/tags/components/tag-policy-list/store";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import {
	CrowdStrikeTagPolicy,
	TagPolicy,
} from "pages/tags/components/tag-policy-list/types";
import React, { useEffect, useState } from "react";

interface Props extends Pick<CTDrawerProps, "open" | "onClose"> {
	criteria: string;
	subtitle?: string;
}

const HIDDEN_COLS = ["matchingAssets"];

export const TargetingSegmentsDrawer = ({
	open,
	onClose,
	criteria,
	subtitle,
}: Props) => {
	const { data: metaData } = useScopeMetadata({ scope: Scope.TagPolicy });
	const updateMetadataCT = useTagPolicyFacetStore(
		state => state.updateMetadata
	);
	const updateMetadataCS = useCrowdStrikeTagPolicyFacetStore(
		state => state.updateMetadata
	);

	const [rows, setRows] = useState<
		Record<
			string,
			ResponseType<TagPolicy> | ResponseType<CrowdStrikeTagPolicy> | null
		>
	>({
		[ManagedByValue.ColorTokens]: null,
		[ManagedByValue.CrowdStrike]: null,
	});

	useEffect(() => {
		updateMetadataCT(metaData);
		updateMetadataCS(metaData);
	}, [metaData, updateMetadataCT, updateMetadataCS]);

	const { isFeatureEnabled: isCrowdStrikeEnabled } = useFeatureFlagControl(
		FEATURES.CROWDSTRIKE_EDR
	);
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const { mutate: mutateCT, isLoading: isCTLoading } = useTagPolicyListAPI(
		ManagedByValue.ColorTokens
	);
	const { mutate: mutateCS, isLoading: isCSLoading } = useTagPolicyListAPI(
		ManagedByValue.CrowdStrike
	);

	const apiRefreshRequestCT = useTagPolicyStore(
		store => store.apiRefreshRequest
	);
	const apiRefreshRequestCS = useCrowdStrikeTagPolicyStore(
		store => store.apiRefreshRequest
	);

	const transformCrowdStrikeSegmentData = (
		data: ResponseType<CrowdStrikeTagPolicy>
	) => {
		return {
			...data,
			items: data.items
				.filter(item => "crowdstrikeFirewallHostGroup" in item)
				.sort(
					(a, b) =>
						a.crowdstrikeFirewallHostGroup.precedence -
						b.crowdstrikeFirewallHostGroup.precedence
				)
				.map((obj, i) => ({
					...obj,
					crowdstrikeFirewallHostGroup: {
						...obj.crowdstrikeFirewallHostGroup,
						precedence: i + 1,
					},
					managedBy: ManagedByValue.CrowdStrike,
				})),
		};
	};

	useEffect(() => {
		if (!open) {
			return;
		}
		mutateCT(
			{
				criteria: isCrowdStrikeEnabled
					? criteria +
						" AND " +
						SEGMENTS_TABS[ManagedByValue.ColorTokens].additionalCriteria
					: criteria,
				pagination: {
					offset: 0,
					limit: 100,
				},
			},
			{
				onSuccess: data => {
					setRows(p => ({
						...p,
						[ManagedByValue.ColorTokens]: data,
					}));
				},
			}
		);
		if (isCrowdStrikeEnabled) {
			mutateCS(
				{
					criteria: isCrowdStrikeEnabled
						? criteria +
							" AND " +
							SEGMENTS_TABS[ManagedByValue.CrowdStrike].additionalCriteria
						: criteria,
					pagination: {
						offset: 0,
						limit: 10000,
					},
				},
				{
					onSuccess: data => {
						setRows(p => ({
							...p,
							[ManagedByValue.CrowdStrike]:
								transformCrowdStrikeSegmentData(data),
						}));
					},
				}
			);
		}
	}, [
		criteria,
		open,
		isCrowdStrikeEnabled,
		mutateCS,
		mutateCT,
		apiRefreshRequestCT,
		apiRefreshRequestCS,
	]);

	const isLoading = isCSLoading || isCTLoading;

	return (
		<CTDrawer
			open={open}
			onClose={onClose}
			title={window.getCTTranslatedText("Targeting Segments")}
		>
			<Stack direction="column" sx={{ flexGrow: 1, position: "relative" }}>
				{subtitle && (
					<Typography
						variant="body1"
						sx={{
							position: "relative",
							bottom: "1rem",
							textTransform: "capitalize",
						}}
					>
						{subtitle}
					</Typography>
				)}

				{isCrowdStrikeEnabled && (
					<CtTabs
						value={selectedTab}
						onChange={handleTabChange}
						sx={{ position: "absolute", top: "24px", zIndex: 5 }}
					>
						{Object.entries(SEGMENTS_TABS).map(([key, { label }]) => (
							<Tab
								key={key}
								label={`${window.getCTTranslatedText(label)} ${isLoading ? "" : `(${rows[key]?.metadata?.total ?? 0})`} `}
							/>
						))}
					</CtTabs>
				)}
				{Object.entries(SEGMENTS_TABS).map(([key], index) => (
					<TabPanel
						key={key}
						value={selectedTab}
						index={index}
						style={{ flexGrow: 1 }}
					>
						<TagPolicyDataGrid
							rowCount={rows[key]?.metadata?.total ?? 0}
							rows={rows[key]?.items}
							hiddenColumns={HIDDEN_COLS}
							checkboxSelection={false}
							managedBy={key as ManagedByValue}
							isLoading={isLoading}
						/>
					</TabPanel>
				))}
			</Stack>
		</CTDrawer>
	);
};
