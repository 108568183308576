import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Stack, Tooltip } from "@mui/material";
import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridCellCheckboxRenderer,
	GridColDef,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { CustomCommonStoreType } from "common/types/types";
import { MatchedByTemplate } from "modules/matched-by-templates";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { PathFirewallCount } from "pages/paths/components/path-firewall-count";
import { PathLastObservedTimestamp } from "pages/paths/components/path-last-observed/PathLastObservedTimestamp";
import { PathEnforcedConfigStatus } from "pages/paths/types";
import { ColumnType } from "pages/ports/components/port-data-grid/constants";
import { DestinationProcess } from "../destination-process";
import { PathAnnotations } from "../path-annotations/PathAnnotations";
import { PathAPIDetails } from "../path-api-details";
import { PathConnectionCount } from "../path-connection-count";
import { PathDataVolume } from "../path-data-volume";
import { PathDestination } from "../path-destination";
import { PathDestinationTags } from "../path-destination-tags";
import { PathDetails } from "../path-details";
import { PathLastObserved } from "../path-last-observed";
import { PathPortCategory } from "../path-port-category/PathPortCategory";
import { PathService } from "../path-service";
import { PathSource } from "../path-source";
import { PathSourceTags } from "../path-source-tags";
import { PathStatusEnforced } from "../path-status-enforced";
import { PathStatusView } from "../path-status-view";

const isPathControlledByTemplate = (status: PathEnforcedConfigStatus) => {
	return (
		status === PathEnforcedConfigStatus.AllowedByTemplate ||
		status === PathEnforcedConfigStatus.DeniedByTemplate
	);
};

export const BASE_PATH_COLUMNS = (
	type: ColumnType,
	useFacetStore?: CustomCommonStoreType
) =>
	[
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderCell: params => {
				return (
					<Tooltip
						PopperProps={{ disablePortal: true }}
						placement={"right"}
						title={
							!params.row.isRowSelectable
								? "Please configure port for this Internet path"
								: ""
						}
						followCursor={false}
					>
						{params.row.isRowSelectable ? (
							<GridCellCheckboxRenderer {...params} />
						) : (
							<Stack>
								<CheckBoxOutlineBlankIcon
									sx={{ width: "20px" }}
									color="disabled"
								></CheckBoxOutlineBlankIcon>
							</Stack>
						)}
					</Tooltip>
				);
			},
			type: "singleSelect",
		},
		{
			field: "port",
			headerName: "Port",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathDetails path={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "pathportcategory",
			headerName: "Port Category",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PathPortCategory path={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "portname",
			headerName: "Service",
			minWidth: 120,
			width: 120,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathService path={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "bandwidthinbytes",
			headerName: "Data Volume",
			minWidth: 130,
			width: 130,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathDataVolume path={params.row} />;
			},
		},
		{
			field: "api",
			headerName: "API",
			width: 150,
			minWidth: 150,
			flex: type === ColumnType.FULLSCREEN ? 0.4 : 0.3,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PathAPIDetails path={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "source",
			headerName: "Source",
			minWidth: 180,
			width: 180,
			flex: 1,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathSource path={params.row} useFacetStore={useFacetStore} />;
			},
		},
		{
			field: "sourcetags",
			headerName: "Source Asset Tags",
			minWidth: 180,
			width: 180,
			flex: 1,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PathSourceTags path={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "destination",
			headerName: "Destination",
			minWidth: 180,
			width: 180,
			flex: 1,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PathDestination path={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "destinationProcess",
			headerName: "Destination Process",
			minWidth: 180,
			width: 180,
			flex: 1,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<DestinationProcess path={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
		{
			field: "destinationtags",
			headerName: "Destination Asset Tags",
			minWidth: 180,
			width: 180,
			flex: 1,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PathDestinationTags
						path={params.row}
						useFacetStore={useFacetStore}
					/>
				);
			},
		},
		{
			field: "connectioncount",
			headerName: "Connection Count",
			width: 130,
			minWidth: 130,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathConnectionCount path={params.row} />;
			},
		},
		{
			field: "matchedByTemplates",
			headerName: "Matched By Templates",
			width: 150,
			minWidth: 150,
			sortable: false,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const path = params.row;
				return (
					<MatchedByTemplate
						id={path?.channelHash}
						assetId={
							path?.[
								path?.direction === "inbound"
									? "destinationAsset"
									: "sourceAsset"
							]?.assetId
						}
						path={path}
						type={MatchedByTemplateType.PATH}
						matchedByTemplates={path.matchedByTemplates}
						showDeleteIcon={
							isPathControlledByTemplate(path?.enforced) &&
							!isPathControlledByTemplate(path?.reviewed)
						}
					/>
				);
			},
		},
		{
			field: "pathlastobserved",
			headerName: "Last Observed",
			width: 130,
			minWidth: 130,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathLastObserved path={params.row} />;
			},
		},
		{
			field: "pathlastobservedtimestamp",
			headerName: "Path Last Observed (Timestamp)",
			width: 170,
			minWidth: 170,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathLastObservedTimestamp path={params.row} />;
			},
		},
		{
			field: "enforced",
			headerName: "Enforced Status",
			width: type === ColumnType.FULLSCREEN ? 150 : 130,
			minWidth: type === ColumnType.FULLSCREEN ? 150 : 130,
			sortable: false,
			sortingOrder: ["asc", "desc"],
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<PathStatusEnforced path={params.row} useFacetStore={useFacetStore} />
				);
			},
		},
	] as GridColDef[];

export const FIREWALL_COUNT_COLUMNS: GridColDef[] = [
	{
		field: "firewallDenyCount",
		headerName: "Firewall count",
		width: 170,
		minWidth: 170,
		flex: 0.5,
		sortable: true,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <PathFirewallCount path={params.row} />;
		},
	},
];

export const PATH_COLUMNS: GridColDef[] = [
	...BASE_PATH_COLUMNS(ColumnType.FULLSCREEN),
	{
		field: "reviewed",
		headerName: "Status",
		width: 150,
		minWidth: 150,
		flex: 0.5,
		sortable: false,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <PathStatusView path={params.row}></PathStatusView>;
		},
	},
	{
		field: "comment",
		headerName: "",
		headerAlign: "left",
		align: "left",
		width: 50,
		minWidth: 50,
		sortingOrder: ["desc", "asc"],
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <PathAnnotations path={params.row} />;
		},
	},
];

// default hidden columns
const ColumnVisibilityModel = {
	pathlastobservedtimestamp: false,
};

const PinnedColumns = {
	left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "port"],
	right: ["comment"],
};

export const PathColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};

export const PATHS_DATA_GRID_ID = "paths-data-grid";
