import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { GridRowId } from "@mui/x-data-grid-pro";
import { CTDrawer } from "common/molecules/drawer";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	useAutoUpgradeAgents,
	useDebugLogAgents,
	useDecommissionAgents,
	useDiagnosticAgents,
	useFirewallCoexistenceAgents,
	useNorthSouthTrafficConfigAgents,
	usePolicyTamperMonitoringAgents,
	useRestartAgents,
	useTrafficConfigAgents,
	useUpgradeAgents,
} from "pages/agents/components/agent-data-grid/hooks/useAgentsApi";
import { RenderContentProps } from "pages/agents/components/agent-drawers/AgentUpgradeDrawer";
import {
	DebugLogStatus,
	FirewallCoexistenceStatus,
	NsTrafficConfigStatus,
	PolicyTamperingStatus,
	TrafficConfigStatus,
} from "pages/agents/components/agent-drawers/helpers/constants";
import { useGroupByAgentData } from "pages/agents/components/agent-drawers/hooks";
import { useAgentStore } from "pages/agents/store";
import { Agent } from "pages/agents/types";
import { Appliance } from "pages/appliances/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelectedData } from "../appliance-drawers/hooks/useSelectedData";
import {
	getButtonTitle,
	getColumns,
	getIsRowSelectable,
	getRowID,
	getSecondaryButtonText,
	getSelectedRows,
	getTitle,
	getUpdatedData,
	renderAlert,
} from "./helpers";
import { useSubmitAgent, useSubmitAppliance } from "./hooks";
import { DRAWER_TYPE } from "./types";

export interface AgentBaseDrawerProps {
	isOpen: boolean;
	data?: Appliance[] | Agent[];
	selection?: GridRowId[];
	onCancel: () => void;
	drawerType: DRAWER_TYPE;
	renderContent?: ({
		agentsGroupByPlatforms,
		setAgentsGroupByPlatforms,
		agentOtpData,
		isLoading,
	}: RenderContentProps) => JSX.Element;
	drawerWidth?: string;
	action?:
		| DebugLogStatus
		| TrafficConfigStatus
		| string
		| NsTrafficConfigStatus
		| PolicyTamperingStatus
		| FirewallCoexistenceStatus;
	subTitle?: string;
}

export const AgentBaseDrawer = ({
	selection,
	data,
	onCancel,
	isOpen,
	drawerType,
	drawerWidth,
	renderContent,
	action,
	subTitle,
}: AgentBaseDrawerProps) => {
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const autoUpgradeMutation = useAutoUpgradeAgents();
	const restartMutation = useRestartAgents();
	const upgradeMutation = useUpgradeAgents();
	const diagnosticMutation = useDiagnosticAgents();
	const decommissionMutation = useDecommissionAgents();
	const debugLogMutation = useDebugLogAgents();
	const trafficConfigAgentsMutation = useTrafficConfigAgents();
	const policyTamperMonitoringMutation = usePolicyTamperMonitoringAgents();
	const firewallCoexistenceMutation = useFirewallCoexistenceAgents();
	const northSouthTrafficConfigAgentMutaion =
		useNorthSouthTrafficConfigAgents();

	const requestAgentAPIRefresh = useAgentStore(
		store => store.requestAPIRefresh
	);

	const selectedRows = useMemo(() => {
		return getSelectedRows(drawerType, data ?? [], selection, action);
	}, [selection, data, action, drawerType]);

	const [selectionRowIds, setSelectionRowIds] = useState<Array<GridRowId>>([]);

	const updatedData: Appliance[] | Agent[] = useMemo(() => {
		return getUpdatedData(drawerType, data ?? [], selectionRowIds);
	}, [selectionRowIds, data, drawerType]);

	const { agentsGroupByPlatforms, setAgentsGroupByPlatforms } =
		useGroupByAgentData(
			drawerType === DRAWER_TYPE.AGENT_UPGRADE ? (updatedData as Agent[]) : []
		);

	const selectedItems = useSelectedData(updatedData);

	let selectedAgentIds = selectedItems
		?.map((element: string) => `'${element}'`)
		.join(", ")
		.replace(/"/g, "");

	useEffect(() => {
		if (selectedRows) {
			setSelectionRowIds(selectedRows);
		}
	}, [selectedRows]);

	const {
		confirmApplianceRestart,
		confirmApplianceUpgrade,
		confirmApplianceAutoUpgrade,
		confirmApplianceDiagnostic,
	} = useSubmitAppliance();

	const {
		confirmAgentConnectDiagnostic,
		confirmAgentRestart,
		confirmAgentDecommission,
		confirmAgentDebugLog,
		confirmAgentTrafficConfig,
		confirmAgentAutoUpgrade,
		confirmAgentTrafficConfigNorthSouth,
		confirmAgentPolicyTamperMonitoring,
		confirmAgentFirewallCoexistence,
	} = useSubmitAgent();

	const onSave = useCallback(() => {
		switch (drawerType) {
			case DRAWER_TYPE.APPLIANCE_RESTART:
				confirmApplianceRestart({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: restartMutation,
					onCancel: onCancel,
				});
				break;

			case DRAWER_TYPE.APPLIANCE_UPGRADE:
				confirmApplianceUpgrade({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: upgradeMutation,
					onCancel: onCancel,
				});
				break;

			case DRAWER_TYPE.APPLIANCE_AUTO_UPGRADE:
				confirmApplianceAutoUpgrade({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: autoUpgradeMutation,
					onCancel: onCancel,
					action,
				});
				break;

			case DRAWER_TYPE.APPLIANCE_CONNECT_DIAGNOSTIC:
				confirmApplianceDiagnostic({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: diagnosticMutation,
					onCancel: onCancel,
				});
				break;

			case DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC:
				confirmAgentConnectDiagnostic({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: diagnosticMutation,
					onCancel: onCancel,
				});
				break;

			case DRAWER_TYPE.AGENT_RESTART:
				confirmAgentRestart({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: restartMutation,
					onCancel: onCancel,
				});
				break;

			case DRAWER_TYPE.AGENT_UPGRADE:
				const confirmAgentUpgrade = async () => {
					if (agentsGroupByPlatforms) {
						const exportPromises = [];
						for (const [platform, agentData] of Object.entries(
							agentsGroupByPlatforms
						)) {
							if (
								platform &&
								agentData?.some(
									agent => agent.data?.length > 0 && agent?.version
								)
							) {
								const filteredAgentData = agentData?.filter(
									elem =>
										elem.data.filter(
											agent => agent.architecture === elem.architecture
										).length > 0
								);

								for (const agent of filteredAgentData) {
									let agentVersion = agent?.version?.includes("Latest")
										? "@latest"
										: agent?.version;

									let selAgentIds = agent?.data
										.map(item => `'${item.agentId}'`)
										.flat()
										.join(", ");

									const agentUpgradeBody = {
										criteria: `agentId in (${selAgentIds})`,
										version: agentVersion,
									};

									const promise = await upgradeMutation.mutateAsync(
										agentUpgradeBody,
										{
											onSuccess: response => {},
											onError: error => {
												setSnackbar(
													true,
													SnackBarSeverity.Error,
													parseErrorMessage(error)
												);
												onCancel();
											},
										}
									);

									exportPromises.push(promise);
								}
							}
						}

						await Promise.all(exportPromises);

						notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
							label: "AgentUpgradeRequestSubmittedSuccessfully",
						});

						onCancel();
						requestAgentAPIRefresh();
					}
				};
				confirmAgentUpgrade();
				break;

			case DRAWER_TYPE.AGENT_DECOMMISSION:
				confirmAgentDecommission({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: decommissionMutation,
					onCancel: onCancel,
				});
				break;

			case DRAWER_TYPE.AGENT_DEBUG_LOG:
				confirmAgentDebugLog({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: debugLogMutation,
					onCancel: onCancel,
					action,
				});

				break;

			case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG:
				confirmAgentTrafficConfig({
					selectedItems,
					mutation: trafficConfigAgentsMutation,
					onCancel: onCancel,
					action,
				});
				break;

			case DRAWER_TYPE.AGENT_AUTO_UPGRADE:
				confirmAgentAutoUpgrade({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: autoUpgradeMutation,
					onCancel: onCancel,
					action,
				});
				break;

			case DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH:
				confirmAgentTrafficConfigNorthSouth({
					selectedItems,
					mutation:
						action === NsTrafficConfigStatus.CollectNorthSouthTraffic
							? northSouthTrafficConfigAgentMutaion
							: trafficConfigAgentsMutation,
					onCancel: onCancel,
					action,
				});
				break;
			case DRAWER_TYPE.AGENT_POLICY_TAMPERING:
				confirmAgentPolicyTamperMonitoring({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: policyTamperMonitoringMutation,
					onCancel: onCancel,
					action,
				});
				break;
			case DRAWER_TYPE.FIREWALL_COEXISTENCE:
				confirmAgentFirewallCoexistence({
					selectedItems,
					selectedIds: selectedAgentIds,
					mutation: firewallCoexistenceMutation,
					onCancel: onCancel,
					action,
				});
				break;
			default:
				break;
		}
	}, [
		action,
		agentsGroupByPlatforms,
		autoUpgradeMutation,
		confirmAgentAutoUpgrade,
		confirmAgentConnectDiagnostic,
		confirmAgentDebugLog,
		confirmAgentDecommission,
		confirmAgentFirewallCoexistence,
		confirmAgentPolicyTamperMonitoring,
		confirmAgentRestart,
		confirmAgentTrafficConfig,
		confirmAgentTrafficConfigNorthSouth,
		confirmApplianceAutoUpgrade,
		confirmApplianceDiagnostic,
		confirmApplianceRestart,
		confirmApplianceUpgrade,
		debugLogMutation,
		decommissionMutation,
		diagnosticMutation,
		drawerType,
		firewallCoexistenceMutation,
		notify,
		onCancel,
		policyTamperMonitoringMutation,
		requestAgentAPIRefresh,
		restartMutation,
		selectedAgentIds,
		selectedItems,
		setSnackbar,
		trafficConfigAgentsMutation,
		upgradeMutation,
		northSouthTrafficConfigAgentMutaion,
	]);

	const renderButton = useCallback(() => {
		const buttonTitle = window.getCTTranslatedText(
			getButtonTitle(drawerType, action ?? "")
		);
		return (
			<Box>
				<LoadingButton
					loading={
						autoUpgradeMutation.isLoading ||
						restartMutation.isLoading ||
						upgradeMutation.isLoading ||
						diagnosticMutation.isLoading
					}
					onClick={onSave}
					autoFocus
					variant="contained"
					type="submit"
					sx={{ mx: 2 }}
					disabled={!(selectedItems?.length > 0)}
				>
					{buttonTitle}
				</LoadingButton>
			</Box>
		);
	}, [
		action,
		autoUpgradeMutation.isLoading,
		diagnosticMutation.isLoading,
		drawerType,
		onSave,
		restartMutation.isLoading,
		selectedItems?.length,
		upgradeMutation.isLoading,
	]);

	const getRenderContentProps = useCallback(() => {
		switch (drawerType) {
			case DRAWER_TYPE.AGENT_UPGRADE:
				return { setAgentsGroupByPlatforms, agentsGroupByPlatforms };

			default:
				return {};
		}
	}, [agentsGroupByPlatforms, drawerType, setAgentsGroupByPlatforms]);

	return (
		<CTDrawer
			title={getTitle(drawerType) ?? ""}
			onClose={onCancel}
			open={isOpen}
			actions={renderButton()}
			secondaryBtnText={getSecondaryButtonText(drawerType)}
			drawerWidth={drawerWidth}
			subTitle={subTitle}
		>
			{renderContent ? (
				renderContent(getRenderContentProps())
			) : (
				<Stack sx={{ width: "100%", height: "100%" }}>
					{renderAlert(drawerType, action as string)}
					<Box sx={{ flex: 1, overflow: "hidden" }}>
						<DataGrid<Agent | Appliance>
							checkboxSelection={userPermissions.has("UPGRADE_AGENT")}
							rowSelectionModel={selectionRowIds}
							onRowSelectionModelChange={selectionModel => {
								setSelectionRowIds(selectionModel);
							}}
							initialState={{
								sorting: {
									sortModel: [{ field: "agentstatus", sort: "desc" }],
								},
							}}
							rows={data}
							rowCount={selection?.length ?? 0}
							getRowId={(data: Appliance | Agent) =>
								getRowID({
									agentId: data.agentId,
									drawerType,
									assetId: (data as Agent)?.asset?.assetId,
								})
							}
							columns={getColumns(drawerType, action)}
							isRowSelectable={data =>
								getIsRowSelectable(data, drawerType, action)
							}
						/>
					</Box>
				</Stack>
			)}
		</CTDrawer>
	);
};
