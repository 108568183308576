import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { useToggle } from "common/hooks/useToggle";
import { useCommonStore } from "common/store";
import { useDemoController } from "hooks/useDemoController";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { AssetToolbar } from "pages/assets/components/asset-data-grid-toolbar";
import { Asset, AssetType } from "pages/assets/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ASSET_COLUMNS, AssetColumnConfig } from "./constants";

export const AssetDataGrid = (props: DataGridProps<Asset>) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const recommendationId =
		new URLSearchParams(window.location.search).get("recommendation") || "";

	const [selection, setSelection] = useState<Array<GridRowId>>([]);

	useEffect(() => {
		if (recommendationId && userPermissions.has("UPDATE_ASSET")) {
			const tempSelections: GridRowId[] = (props.rows || []).map(
				item => item.assetId
			);
			if (tempSelections?.length > 0) {
				setSelection(tempSelections);
			}
		}
	}, [
		recommendationId,
		props.rows,
		searchParams,
		setSearchParams,
		userPermissions,
	]);

	const clearSelection = useCallback(() => {
		setSelection([]);
		if (searchParams.has("recommendation")) {
			searchParams.delete("recommendation");
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	const facets = useCommonStore(state => state.facetConfig);
	const appliedFacets = useCommonStore(state => state.facets);
	const appliedTypeFacet = useMemo(() => {
		return appliedFacets?.get("type");
	}, [appliedFacets]);

	const hasDeviceFacet = useMemo(() => {
		if (
			appliedTypeFacet &&
			appliedTypeFacet?.size > 0 &&
			!appliedTypeFacet?.has(AssetType.Device)
		) {
			return false;
		}

		const typeOptions = facets?.[0]?.facets?.find(
			facet => facet.name === "type"
		)?.options;
		if (typeOptions && typeOptions?.length > 0) {
			const index = typeOptions?.findIndex(
				option => option.name === AssetType.Device
			);
			return index !== undefined && index >= 0;
		}

		return appliedTypeFacet?.has(AssetType.Device) ?? false;
	}, [facets, appliedTypeFacet]);

	const hasDevice = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return row.type === AssetType.Device;
		});
	}, [props.rows]);

	let hasVulnerabilities = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return Boolean(
				row.vulnerabilities !== null && row.vulnerabilities !== undefined
			);
		});
	}, [props.rows]);

	let hasLateralVulnerabilities = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return Boolean(
				row.lateralMovementVulnerability !== null &&
					row.lateralMovementVulnerability !== undefined &&
					row.lateralMovementVulnerability !== "no value"
			);
		});
	}, [props.rows]);

	let hasKnownExploits = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return Boolean(
				row.knownExploitVulnerability !== null &&
					row.knownExploitVulnerability !== undefined &&
					row.knownExploitVulnerability !== "no value"
			);
		});
	}, [props.rows]);

	let hasVulnerabilitySeverity = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return Boolean(
				row.vulnerabilitySeverity !== null &&
					row.vulnerabilitySeverity !== undefined &&
					row.vulnerabilitySeverity !== "no value"
			);
		});
	}, [props.rows]);

	let hasPatches = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return Boolean(
				row.securityPatches !== null && row.securityPatches !== undefined
			);
		});
	}, [props.rows]);

	let hasMaxCvss = useMemo(() => {
		return props.rows?.some((row: Asset) => {
			return Boolean(
				row.maximumCVSSScore !== null && row.maximumCVSSScore !== undefined
			);
		});
	}, [props.rows]);

	const assetColumns: GridColDef[] =
		props.columns ?? ASSET_COLUMNS(props.viewOnly ?? false);

	const columns = useMemo(
		() =>
			assetColumns.filter(column => {
				if (column.field === "assetAvailability") {
					return hasDeviceFacet && hasDevice;
				}

				if (column.field === "vulnerabilities") {
					return hasVulnerabilities;
				}

				if (column.field === "vulnerabilitySeverity") {
					return hasVulnerabilitySeverity;
				}

				if (column.field === "lateralMovementVulnerability") {
					return hasLateralVulnerabilities;
				}

				if (column.field === "knownExploitVulnerability") {
					return hasKnownExploits;
				}

				if (column.field === "securityPatches") {
					return hasPatches;
				}

				if (column.field === "maximumCVSSScore") {
					return hasMaxCvss;
				}

				return true;
			}),
		[
			hasDevice,
			hasDeviceFacet,
			hasPatches,
			hasVulnerabilities,
			hasLateralVulnerabilities,
			hasVulnerabilitySeverity,
			hasKnownExploits,
			assetColumns,
			hasMaxCvss,
		]
	);

	const { isDemo, handleDemoClick } = useDemoController();
	const [areAllAssetsSelected, toggleAreAllAssetsSelected] = useToggle(false);

	const selectedData: Array<Asset> | undefined = useMemo(() => {
		return props.rows?.filter((row: Asset) => {
			return selection.indexOf(row.assetId) !== -1;
		});
	}, [props.rows, selection]);

	const selectedRawData: Array<Asset> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: Asset) => {
			return selection.indexOf(row.assetId) !== -1;
		});
	}, [props?.rawData, selection]);

	const renderToolbar = useCallback(
		() => (
			<AssetToolbar
				isLoading={
					props?.mutation?.isLoading ||
					props?.mutation?.isIdle ||
					props.rows === undefined
				}
				totalRows={props.rowCount}
				rowCountOnPage={props.rows?.length ?? 0}
				areAllAssetsSelected={areAllAssetsSelected}
				toggleAreAllAssetsSelected={toggleAreAllAssetsSelected}
				selectedData={selectedData ?? []}
				clearSelection={clearSelection}
				recommendationId={recommendationId}
				showDeviceActions={props.showDeviceActions}
			/>
		),
		[
			props?.mutation?.isLoading,
			props?.mutation?.isIdle,
			props.rows,
			props.rowCount,
			props.showDeviceActions,
			areAllAssetsSelected,
			toggleAreAllAssetsSelected,
			selectedData,
			clearSelection,
			recommendationId,
		]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					allRowsSelected={areAllAssetsSelected}
					defaultPinnedColumns={AssetColumnConfig?.PinnedColumns}
					slots={{
						noRowsOverlay: () =>
							AssetsNoRowsOverlayComp({ isDemo, handleDemoClick, props }),
					}}
					checkboxSelection={userPermissions.has("UPDATE_ASSET")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ assetId }: Asset) => assetId}
					paginationMode="server"
					sortingMode="server"
					{...props}
					selectedRawData={selectedRawData}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

interface AssetsNoRowsOverlayCompProps {
	isDemo?: boolean;
	handleDemoClick: () => Promise<void>;
	props: DataGridProps<Asset>;
}

const AssetsNoRowsOverlayComp = ({
	isDemo,
	handleDemoClick,
	props,
}: AssetsNoRowsOverlayCompProps) => {
	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				zIndex: 100,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			{isDemo && <CircularProgress sx={{ mb: 2 }} size={36} />}
			<Typography variant="body2">
				{window.getCTTranslatedText(
					isDemo ? "Preparing demo data" : "No results"
				)}
			</Typography>
			{!isDemo && props.hasNoData && (
				<Button onClick={handleDemoClick}>
					{window.getCTTranslatedText("Try demo data")}
				</Button>
			)}
		</Stack>
	);
};
