import { PolicyAggregateData } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";
import { create } from "zustand";

interface GuardrailsState {
	guardrailTraffic: PolicyAggregateData;
	setGuardrailTraffic: (guardrailTraffic: PolicyAggregateData) => void;
	resetGuardrailTraffic: () => void;
}

export const useGuardrailsStore = create<GuardrailsState>(set => ({
	guardrailTraffic: {},
	setGuardrailTraffic: (guardrailTraffic: PolicyAggregateData) =>
		set({ guardrailTraffic }),
	resetGuardrailTraffic: () => set({ guardrailTraffic: {} }),
}));
