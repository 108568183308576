import { Stack } from "@mui/material";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { Network } from "pages/networks/types";
import { AssetCount } from "../asset-count";
import { GroupCount } from "../group-count";
import { UsedByTagBasedPolicy } from "../used-by-tag-based-policy";

type Props = {
	network: Network;
	isOpen: boolean;
	onClose: () => void;
	title: string;
	primaryText: string;
	isLoading: boolean;
	onSuccess: () => void;
	secondaryTextWarning: string;
	primaryButtonText?: string;
};

export function NetworkConfirmationDialog({
	network,
	isOpen,
	onClose,
	title,
	primaryText,
	isLoading,
	onSuccess,
	secondaryTextWarning,
	primaryButtonText,
}: Props) {
	const hasAssets = (network?.namedNetworkAssignments ?? 0) > 0;

	const hasSegments = (network?.namednetworkTagBasedPolicyAssignments ?? 0) > 0;

	const hasUGs = (network?.usergroupNamedNetworkAssignments ?? 0) > 0;

	function renderSecondaryText() {
		return (
			<Stack>
				{hasAssets ||
					hasSegments ||
					(hasUGs && window.getCTTranslatedText(secondaryTextWarning))}

				{hasAssets && <AssetCount network={network} openInNewTab={true} />}
				{hasSegments && (
					<UsedByTagBasedPolicy network={network} openInNewTab={true} />
				)}
				{hasUGs && <GroupCount network={network} openInNewTab={true} />}
			</Stack>
		);
	}
	return (
		<>
			<CTConfirmation
				open={isOpen}
				onClose={onClose}
				title={title}
				primaryText={primaryText}
				secondaryText={renderSecondaryText()}
				primaryButtonText={primaryButtonText}
				isLoading={isLoading}
				onSuccess={onSuccess}
			/>
		</>
	);
}
