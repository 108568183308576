import { Skeleton, Stack, Switch, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useMemo, useState } from "react";
import { useAgentSecretAPI } from "../agent-config/hooks/useAgentSecret";
import { useUpdateAgentSecret } from "../agent-config/hooks/useUpdateAgentSecret";
import { AgentSecretConfig } from "../agent-config/types";
import { ConfigCard } from "../common/components/config-card";

export const AgentPasswordConfig = () => {
	const [configStatus, setConfigStatus] = useState<boolean>(false);
	const [password, setPassword] = useState<string>("");
	const [isBtnLoading, setIsBtnLoading] = useState(false);

	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const queryClient = useQueryClient();

	let { data, isLoading }: any = useAgentSecretAPI();
	const updateAgentSecretMutation = useUpdateAgentSecret();

	const agentConfig: AgentSecretConfig = useMemo(() => {
		if (!data) {
			return;
		}
		if (data?.secret) {
			setConfigStatus(true);
			setPassword(data?.secret);
		} else {
			setConfigStatus(false);
			setPassword("");
		}

		return data;
	}, [data]);

	const toggleStatus = (status: boolean) => {
		setConfigStatus(status);
	};

	const updatePassword = (password: string) => {
		setPassword(password);
	};

	const savePassword = () => {
		setIsBtnLoading(true);
		const body = {
			secret: configStatus ? password : "",
		};

		updateAgentSecretMutation.mutate(body, {
			onSuccess: response => {
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"UpdatedAgentSecurityPasswordSuccessfully"
				);
				queryClient.invalidateQueries({
					queryKey: ["agent-secret"],
				});
				setIsBtnLoading(false);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				setIsBtnLoading(false);
			},
		});
	};

	const getTooltipTitle = () => {
		let title = "";
		if (configStatus) {
			if (!password) {
				title = "Please enter the agent security password";
			} else if (agentConfig?.secret === password) {
				title = "Please update the agent security password";
			}
		}

		return title;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		toggleStatus(event.target.checked);
	};

	const showSaveCta =
		Boolean(
			configStatus ? password && password !== data.secret : data?.secret
		) && userPermissions.has("UPDATE_TENANT_AGENT_SECRET");

	if (isLoading) {
		return (
			<Skeleton
				variant="rectangular"
				width={"100%"}
				height={"200px"}
				animation="wave"
			/>
		);
	}

	return (
		<Stack direction={"row"} width="100%" gap={3}>
			<ConfigCard
				title="Agent Security Password"
				showSaveCta={showSaveCta}
				isLoading={isBtnLoading}
				onSaveBtnClick={savePassword}
				saveBtnTooltip={getTooltipTitle()}
			>
				<Stack>
					<Stack
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Stack gap="6px">
							<Typography variant="body2">
								{window.getCTTranslatedText(
									"Configure agent security password to protect agent services from unauthorized access"
								)}
							</Typography>
							<Typography variant="body2">
								{window.getCTTranslatedText(
									"(Note: Supported agent version 5.9.0 and above)"
								)}
							</Typography>
						</Stack>
						<Switch onChange={handleChange} checked={configStatus} />
					</Stack>
					{configStatus && (
						<AgentSecurityPasswordField
							password={password}
							updatePassword={updatePassword}
							configStatus={configStatus}
						/>
					)}
				</Stack>
			</ConfigCard>
		</Stack>
	);
};

interface AgentSecurityPasswordFieldProps {
	password: string;
	updatePassword: (password: string) => void;
	configStatus: boolean;
}

const AgentSecurityPasswordField = ({
	password,
	updatePassword,
	configStatus,
}: AgentSecurityPasswordFieldProps) => {
	const handlePasswordChange = (config: TextFieldUpdate) => {
		updatePassword(config?.value ?? "");
	};

	return (
		<form>
			<CTInputField
				field="password"
				displayName="Password"
				placeholder={"Enter Password"}
				value={password}
				handleUpdate={handlePasswordChange}
				type="password"
				showLabel={true}
				required={true}
				autoFocus={false}
				showClear={true}
				size="small"
			/>
		</form>
	);
};
