import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
	alpha,
	Box,
	IconButton,
	Paper,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { Toolbar } from "common/atoms/toolbar";
import { getWRAdditionalCriteria } from "modules/AppBar/helpers";
import { usePollPendingWorkRequest } from "modules/AppBar/hooks";
import { WorkRequest } from "modules/AppBar/types";
import { PendingWorkRequestLocationType } from "modules/PendingWorkRequestsDrawer/constants";
import { getOptimisticDataGridData } from "modules/PendingWorkRequestsDrawer/helpers";
import { useHandleWRFacetApplication } from "modules/PendingWorkRequestsDrawer/hooks/useHandleWRFacetApplication";
import { PendingWorkRequestData } from "modules/PendingWorkRequestsDrawer/types";
import { useCore } from "modules/core";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePendingWorkRequestAPI } from "../../hooks";
import { useWorkRequestStore } from "../../store";
import { useWorkRequestFacetStore } from "../../store/useWorkRequestStore";
import { PendingWorkRequestToolbar } from "../PendingWorkRequestToolbar";
import { BASE_PENDING_WORK_REQUESTS_COLUMNS, FACET_ORDER } from "./constants";

const readOnlyDimensions = ["status", "workrequestresourceid"];
const DATA_GRID_UPDATE_DELAY = 5000;

interface PendingWorkRequestsDataGridProps {
	onClose?: () => void;
	resourceID?: string;
	fromMonitoring?: boolean;
	componentType?: PendingWorkRequestLocationType;
}

export const PendingWorkRequestsDataGrid = ({
	onClose,
	resourceID,
	fromMonitoring = false,
	componentType = PendingWorkRequestLocationType.DEFAULT,
}: PendingWorkRequestsDataGridProps) => {
	usePollPendingWorkRequest(resourceID);
	const theme = useTheme();
	const [tableData, setTableData] = useState<PendingWorkRequestData>({
		rows: [],
		rowCount: 0,
		page: 0,
		pageSize: 100,
	});
	const externalCriteria = useWorkRequestFacetStore(
		state => state.externalCriteria
	);
	const additionalCriteria = useRef(externalCriteria);

	useEffect(() => {
		additionalCriteria.current = externalCriteria;
	}, [externalCriteria]);

	const shouldReplaceTableDataWithoutDelay = useHandleWRFacetApplication({
		resourceID,
	});

	const coreResponse = useCore<WorkRequest>({
		useStore: useWorkRequestStore,
		facetGroupInfo: FACET_ORDER,
		scope: Scope.WorkRequest,
		useApi: usePendingWorkRequestAPI,
		pageSize: 100,
		useFacetStore: useWorkRequestFacetStore,
		additionalCriteria: additionalCriteria.current,
		skipUseFacetQueryConnector: true,
		skipSearch: true,
		defaultSortOrder: [{ field: "created", order: "desc" }],
	});

	useEffect(() => {
		const tableData = {
			rows: coreResponse.rows ?? [],
			rowCount: coreResponse.rowCount,
			page: coreResponse.page,
			pageSize: coreResponse.pageSize,
		};
		setTableData(prev =>
			shouldReplaceTableDataWithoutDelay
				? getOptimisticDataGridData(prev, tableData)
				: tableData
		);

		if (!shouldReplaceTableDataWithoutDelay) {
			return;
		}

		const timerID = setTimeout(() => {
			setTableData(tableData);
		}, DATA_GRID_UPDATE_DELAY);

		return () => {
			if (timerID) {
				clearTimeout(timerID);
			}
		};
	}, [
		coreResponse.rows,
		coreResponse.rowCount,
		coreResponse.page,
		coreResponse.pageSize,
		shouldReplaceTableDataWithoutDelay,
	]);

	const facetStyle =
		fromMonitoring || componentType === PendingWorkRequestLocationType.DRAWER
			? {
					backgroundColor: theme.palette.background.paper,
					backgroundImage:
						theme.palette.mode === "dark"
							? `linear-gradient(${theme.palette.custom.filterBg}, ${theme.palette.custom.filterBg})`
							: "unset",
					border:
						theme.palette.mode === "dark"
							? "unset"
							: `1px solid ${alpha(theme.palette.common.black, 0.08)}`,
				}
			: {};

	const setExternalCriteria = useWorkRequestFacetStore(
		state => state.setExternalCriteria
	);

	const requestAPIRefresh = useWorkRequestStore(
		state => state.requestAPIRefresh
	);
	const onRefresh = () => {
		additionalCriteria.current = getWRAdditionalCriteria();
		setExternalCriteria(additionalCriteria.current);
		requestAPIRefresh();
	};

	return (
		<Box height={fromMonitoring ? "calc(100% - 20px)" : "calc(100% - 110px)"}>
			{!fromMonitoring && (
				<Stack>
					<Toolbar />
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						sx={{ mb: 1 }}
					>
						<Stack direction={"row"} alignItems="center">
							<Typography variant="h6">
								{window.getCTTranslatedText("System Tasks")}
							</Typography>
						</Stack>
						<IconButton
							size="small"
							aria-label="close drawer"
							onClick={onClose}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Stack>
				</Stack>
			)}
			<Stack
				sx={{ mt: fromMonitoring ? 0 : 2, flex: 1, overflow: "hidden" }}
				height={"100%"}
			>
				<Paper
					elevation={0}
					sx={{
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
						borderRadius: fromMonitoring ? "120px" : "4px",
						mb: fromMonitoring ? 2 : 0,
						height: fromMonitoring ? "40px" : "52px",
						...facetStyle,
					}}
				>
					<Stack
						direction="row"
						spacing={2}
						sx={{
							width: "100%",
							height: "100%",
							flex: 1,
							position: "relative",
							right: "6px",
						}}
						justifyItems="flex-end"
						alignItems={"center"}
						justifyContent="space-between"
					>
						<FacetOpenCloseController
							facetsOpen={coreResponse.facetsOpen}
							setFacetsOpen={coreResponse.setFacetsOpen}
							disableSavedQuery={true}
							useFacetStore={useWorkRequestFacetStore}
							readOnlyDimensions={readOnlyDimensions}
							disableSearchChip
						>
							<FacetControllerGroup
								config={coreResponse.facetConfig}
								value={coreResponse.facetState}
								onChange={coreResponse.updateFacet}
								useFacetStore={useWorkRequestFacetStore}
							/>
						</FacetOpenCloseController>
						{componentType === PendingWorkRequestLocationType.DRAWER && (
							<Stack direction="row" alignItems={"center"}>
								<Stack direction="row">
									<IconButton
										size="small"
										color="primary"
										onClick={onRefresh}
										sx={{ marginLeft: 2 }}
									>
										<RefreshIcon />
									</IconButton>
								</Stack>
							</Stack>
						)}
					</Stack>
				</Paper>
				<Paper
					sx={{
						transition: theme.transitions.create(["width", "margin"], {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
						flex: 1,
						overflow: "hidden",
					}}
				>
					<PendingWorkRequestDataGrid
						initialState={{
							sorting: {
								sortModel: [{ field: "createdAt", sort: "desc" }],
							},
						}}
						resourceID={resourceID}
						rowCount={tableData.rowCount}
						rows={tableData.rows}
						page={tableData.page}
						pageSize={tableData.pageSize}
						mutation={coreResponse.mutation}
						onPageChange={coreResponse.onPageChange}
						onPageSizeChange={coreResponse.onPageSizeChange}
						showToolbar={
							componentType !== PendingWorkRequestLocationType.DRAWER
						}
					/>
				</Paper>
			</Stack>
		</Box>
	);
};

interface PendingWorkRequestDataGridProps extends DataGridProps<WorkRequest> {
	resourceID?: string;
	showToolbar?: boolean;
}

const PendingWorkRequestDataGrid = (props: PendingWorkRequestDataGridProps) => {
	const columns = useMemo(() => {
		let selectedColumns = props.resourceID
			? BASE_PENDING_WORK_REQUESTS_COLUMNS.filter(
					col => col.field !== "resource"
				)
			: BASE_PENDING_WORK_REQUESTS_COLUMNS;

		return selectedColumns;
	}, [props.resourceID]);

	const renderToolbar = useCallback(
		() => (props.showToolbar ? <PendingWorkRequestToolbar /> : null),
		[props?.showToolbar]
	);

	return (
		<Stack sx={{ width: "100%", flex: 1, overflow: "hidden", height: "100%" }}>
			<Stack flex={1} overflow="hidden">
				<DataGrid
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ id }: WorkRequest) => id}
					paginationMode="server"
					sortingMode="server"
					slots={{
						noRowsOverlay: NoRowsOverlayComp,
					}}
					renderToolbar={renderToolbar}
					{...props}
				/>
			</Stack>
		</Stack>
	);
};

export const NoRowsOverlayComp = () => {
	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				zIndex: 100,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			<Typography variant="body2">
				{window.getCTTranslatedText("noBackgroundProcesses")}
			</Typography>
		</Stack>
	);
};
